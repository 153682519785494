import { AiOutlineArrowUp } from 'react-icons/ai';

export default function TotalStatisticCard({ heading, value, progress }) {
	return (
		<div className="col-md-4">
			<div className='clearfix card border-1 border-light rounded'>
				<div className='card-body'>
					<p className='fw-bold m-0'>{heading}</p>
					<h3 className='fw-bold  my-3'>{value}</h3>
					<span className='badge bg-primary-400 text-light d-inline-flex align-items-center'>
						<AiOutlineArrowUp className='me-1' />
						{progress}
					</span>
				</div>
			</div>
		</div>
	);
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import EditMilestoneModal from "./EditMilestoneModal";
import DeleteIcon from "../../../assets/img/delete1.svg";
import EditIcon from "../../../assets/img/edit.svg";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../Store/action";
import Message from "../fields/Message";
import SweetAlert2 from "react-sweetalert2";

export default function ReviewMilestone({
  milestones,
  job_id,
  job_uuid,
  taxPro,
  is_closed,
  reload,
  fetchNotes
}) {
  const [{ deleteMilestone }] = useSelector((state) => [state.milestone]);
  const [{ markJobAsClosed }] = useSelector((state) => [state.jobs]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentMilestone, setCurrentMilestone] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const [message, setMessage] = useState(false);
  const [agreedToClose, setAgreedToClose] = useState(false);
  const [additionalNote, setAdditionalNote] = useState("");
  const dispatch = useDispatch();

  const handleEditMilestone = (milestone) => {
    setCurrentMilestone(milestone);
    setIsEditModalOpen(true);
  };

  const handleSaveMilestone = (updatedMilestone) => {
    // setMilestones(updatedMilestones);
    setIsEditModalOpen(false);
  };

  const closeJob = () => {
    dispatch(allActions.jobs.markJobAsClosed({ uuid: job_uuid,note:additionalNote, }));
    dispatch(allActions.global.Loader(true));
  };

  const handleCloseJob = (id) => {
    setSwalProps((prev) => ({
      ...prev,
      [`job_id-${id}`]: {
        show: true,
        showDenyButton: true,
        title: "Are you sure?",
        confirmButtonText: "Confirm",
        denyButtonText: "Cancel",
        text: "Do you want to close this job?",
        didClose: () =>
          setSwalProps((prev) => ({
            ...prev,
            [`job_id-${id}`]: { show: false },
          })),
        onConfirm: () => closeJob(),
      },
    }));
  };

  const handleDeleteMilestone = (milestone) => {
    setCurrentMilestone(milestone);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteMilestone = () => {
    dispatch(allActions.global.Loader(true));
    dispatch(allActions.milestone.deleteMilestoneReset());
    dispatch(allActions.milestone.deleteMilestone(currentMilestone, taxPro));
  };

  useEffect(() => {
    if (deleteMilestone?.status === true) {
      setIsDeleteModalOpen(false);
      document.querySelector(".reviewMilestoneModalClose").click();
      dispatch(allActions.milestone.getJobMilestone(job_id));
      dispatch(allActions.global.Loader(false));
    } else if (deleteMilestone?.status === false) {
      setMessage({
        show: true,
        type: "Error",
        text: deleteMilestone.message,
      });
      dispatch(allActions.global.Loader(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMilestone?.message, deleteMilestone?.state]);

  useEffect(() => {
    if (markJobAsClosed.status === true) {
      setAdditionalNote('')
      fetchNotes(false,1,10)
      dispatch(allActions.jobs.resetMarkJobAsClosed());
      dispatch(allActions.global.Loader(false));
      setMessage({
        show: true,
        type: "Success",
        text: markJobAsClosed?.message,
      });
      reload();
      setTimeout(() => {
        document.querySelector(".reviewMilestoneModalClose").click();
      }, 2000);
    } else if (markJobAsClosed.status === false) {
      dispatch(allActions.global.Loader(false));
      setMessage({
        show: true,
        type: "Error",
        text: markJobAsClosed?.message,
      });
    }
  }, [markJobAsClosed.status]);

  useEffect(() => {
    if (message?.show) {
      setTimeout(() => {
        setMessage({ show: false });
      }, 2000);
    }
  }, [message]);

  return (
    <div className="p-3">
      {message.show ? <Message message={message} /> : <></>}
      <div className="d-flex flex-column align-items-start gap-3">
        {/* <h5 className="fw-bold">Review Milestones</h5> */}
        {milestones?.map((milestone) => (
          <div
            key={milestone.id}
            className="d-flex justify-content-between w-100 align-items-center border-bottom pb-2 mb-3"
          >
            <div className="d-flex flex-column text-start">
              <span className="fw-bold text-black">{milestone?.name}</span>
              <small className={"text-start"}>
                {dayjs(milestone?.invoices?.due_date).format("DD MMMM YYYY")}
              </small>
            </div>
            <div className="fw-bold">${milestone?.invoices?.amount}</div>
            <div>
              <span
                className={`badge ${
                  milestone.status === 0 ||
                  milestone.status === "pending-payment"
                    ? "bg-warning text-dark"
                    : milestone.status === "paid"
                    ? "bg-black"
                    : milestone.status === "escrow"
                    ? "bg-dark-gradient"
                    : "bg-secondary"
                }`}
              >
                {milestone.status === 0 ? "Escrow" : ""}
              </span>
            </div>
            <div className="d-flex gap-2">
              {!milestone.invoices?.payments[0]?.amount_paid && (
                <>
                  <div
                    onClick={() => {
                      if (!is_closed) {
                        handleEditMilestone(milestone);
                      }
                    }}
                  >
                    <img
                      style={{
                        height: "35px",
                        opacity: is_closed ? 0.5 : 1,
                        cursor: is_closed ? "default" : "pointer",
                      }}
                      src={EditIcon}
                      alt="edit"
                    />
                  </div>
                  <div
                    onClick={() => {
                      if (!is_closed) {
                        handleDeleteMilestone(milestone.id);
                      }
                    }}
                  >
                    <img
                      disabled={true}
                      style={{
                        height: "35px",
                        opacity: is_closed ? 0.5 : 1,
                        cursor: is_closed ? "default" : "pointer",
                      }}
                      src={DeleteIcon}
                      alt="delete"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        ))}

        <button
          disabled={is_closed}
          data-bs-toggle="modal"
          data-bs-target="#addMilestoneModal"
          className="btn btn-outline-primary mt-2"
        >
          Create a new Milestone
        </button>

        <div className="w-100 mt-3 text-start text-black">
          <label htmlFor="additionalNotes" className="form-label text-black">
            Additional notes (Optional)
          </label>
          <textarea
            id="additionalNotes"
            className="form-control pb-5"
            placeholder="...."
            value={additionalNote}
            onChange={(e) => setAdditionalNote(e.target.value)}
          ></textarea>
        </div>
        <div className="d-flex align-items-start text-start gap-2">
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            id="agreeCheckbox"
            checked={agreedToClose || is_closed}
            disabled={is_closed}
            onChange={(e) => setAgreedToClose(e.target.checked)}
          />
          <div
            className="cursor-pointer"
            onClick={() => setAgreedToClose((prev) => !prev)}
          >
            I agree that all necessary requirements of this job has been met and
            won't be needing a new milestone added. I understand that once job
            is closed, the decision can not be reverted.
          </div>
        </div>
        <div className="d-flex justify-content-end w-100 align-items-center mt-4">
          <button className="btn text-primary fw-bold" data-bs-dismiss="modal">
            Cancel
          </button>
          <button
            disabled={is_closed || !agreedToClose}
            onClick={() => handleCloseJob(job_id)}
            className="btn btn-primary"
          >
            Close job
            <SweetAlert2 {...swalProps[`job_id-${job_id}`]} />
          </button>
        </div>
      </div>

      {isEditModalOpen && (
        <EditMilestoneModal
          milestone={currentMilestone}
          onSave={handleSaveMilestone}
          onCancel={() => setIsEditModalOpen(false)}
          job_id={job_id}
          taxPro={taxPro}
        />
      )}
      {isDeleteModalOpen && (
        <div
          className="modal fade show"
          tabIndex="-1"
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1055,
          }}
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content p-3">
              <div className="d-flex justify-content-between">
                <h5 className="modal-title text-black fw-bold">
                  Delete Milestone
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setIsDeleteModalOpen(false)}
                ></button>
              </div>
              <div className="mt-3 text-start">
                <p className={"text-black p-0 mt-0"}>
                  Are you sure you want to delete?
                </p>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn text-primary"
                  onClick={() => setIsDeleteModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={confirmDeleteMilestone}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

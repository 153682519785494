/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Checkbox from "../../../components/forms/fields/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import FileSvg from "../../../assets/img/filesvg.svg";
import RequestDocumentSvg from "../../../assets/img/requestfilesvg.svg";
import PlusSvg from "../../../assets/img/plussvg.svg";
import FileBlueSvg from "../../../assets/img/filebluesvg.svg";
import BinSvg from "../../../assets/img/binsvg.svg";
import QuestionaireSvg from "../../../assets/img/quetenionairesvg.svg";
import EditDocumentSvg from "../../../assets/img/editrequestsvg.svg";
import editIcon from "../../../assets/img/edit1.svg";
import DeleteIcon2 from "../../../assets/img/delete1.svg";
import DeleteIcon from "../../../assets/img/x.svg";
import SearchIcon from '../../../assets/img/search.svg'
import dropdownIcon from '../../../assets/img/angle-down-small.svg'
import { BASEURL, useAutoReplyHook } from "../../../utilites";
import allActions from "../../../Store/action";
import Header from "../components/Header";
import Preloader from "../components/Preloader";
import Select from "../../../components/forms/fields/Select";
import Input from "../../../components/forms/fields/Input";
import TextArea from "../../../components/forms/fields/TextArea";
import DropDown from "../../../components/forms/fields/DropDown";
import Message from "../../../components/forms/fields/Message";
import UserAvatar from "../../../components/UserAvatar";
import { GLOBAL_VARIABLES } from "../../../utilites/variables";
import Modal from "../../../components/Dashboard/Modal";
import FormPreviewModal from "../../../components/forms/Modal/FormPreviewModal";
import { getCurrentFormsObj } from "../../../utilites/functions";
import CreateNewForm from "../../../components/forms/CreateNewForm";
import OpenJobForm from "../../../components/forms/OpenJobForm";
import RequestJobDocumentForm from "../../../components/forms/RequestJobDocumentForm";
import dayjs from 'dayjs'
import { DatePicker } from 'antd';

const invoicePeriodOptions = [
  { value: "3", label: "3" },
  { value: "6", label: "6" },
  { value: "12", label: "12" },
];
const partionalPaymentOption = [
  { value: 20, label: "20%" },
  { value: 40, label: "40%" },
  { value: 60, label: "60%" },
  { value: 80, label: "80%" },
  { value: "custom", label: "Custom" },
];
const invoiceOptions = [
  { value: "FullPayment", label: "Full Payment" },
  { value: "PartialPayment", label: "Partial Payment" },
  // { value: 'RecurringPayment', label: 'Recurring Payment' },
];

const defaultDocument = [
  { id: 1, name: "W-2 (Wages)", description: "W-2 (Wages)", message: "" },
  {
    id: 5,
    name: "Real Estate Taxes",
    description: "Real Estate Taxes",
    message: "",
  },
  {
    id: 2,
    name: "1099 (Banks and Brokerages)",
    description: "1099 (Banks and Brokerages)",
    message: "",
  },
  {
    id: 4,
    name: "1098s (Home Mortgage Interest)",
    description: "1098s (Home Mortgage Interest)",
    message: "",
  },
];

const CustomDeleteModal = ({customModalOpen,setCustomModalOpen,handleDeleteTemplate,dynamicForms}) => {
	return <div className="custom-modal-overlay" onClick={() => setCustomModalOpen(null)} >
	<div className="custom-modal-cont-delete" onClick={(e) => e.stopPropagation()}>
		<h2 className="custom-modal-heading">Delete questionnaire</h2>
		<p className="custom-modal-description">Are you sure you want to delete this template?</p>
		<div className="custom-modal-buttons">
            <button className="btn" onClick={() => {
              setCustomModalOpen(null)
            }}>Cancel</button>
            <button di className="btn btn-danger" onClick={() => {
			  const templateToDelete = dynamicForms?.find(form => form?.form_data?.name === customModalOpen?.data)
              handleDeleteTemplate(templateToDelete?.id)
			  setCustomModalOpen(null)
            }} >Delete</button>
          </div>
		<div className="custom-modal-buttons">
		</div>
	</div>
</div>;
}	
const CreateFormModal = ({children,setCreateModalOpen,formTitle,setFormTitle}) => {
	return <div className="custom-modal-overlay" onClick={() => {
    document.body.style.overflowY = '';
  }}>
		<div className="custom-modal-cont-create-form" onClick={(e) => e.stopPropagation()}>
			{children}
		</div>
	</div>;
  };

  const CreateFormTitleModal = ({setCustomModalOpen,formTitle,setFormTitle,setCreateModalOpen}) => {
	return <div className="custom-modal-overlay" onClick={() => setCustomModalOpen(null)}>
		<div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
			<h2 className="custom-modal-heading">New questionnaire</h2>
			<div className="custom-modal-input-cont">
				  <label htmlFor="section-title" className='text-dark txt-sm'>Enter questionnaire name</label>
				  <input type="text" id="section-title" value={formTitle} onChange={(e) => setFormTitle(e.target.value)} />
			</div>
			<div className="custom-modal-buttons">
			  <button className="btn" onClick={() => {
				setCustomModalOpen(null)
			  }}>Cancel</button>
			  <button di className="btn btn-primary" onClick={() => {
				setCustomModalOpen(null)
				setCreateModalOpen({state:true,editmode:false})
			  }} disabled={formTitle?.length < 3}>Create</button>
			</div>
		</div>
	</div>;
  };

  const EditFormTitleModal = ({setCustomModalOpen,customModalOpen,formTitle,selectedDynamicForms,setSelectedDynamicForms}) => {
    const [title,setTitle] = useState(formTitle)
    return <div className="custom-modal-overlay" onClick={() => setCustomModalOpen(null)}>
      <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
        <h2 className="custom-modal-heading">Edit questionnaire tittle</h2>
        <div className="custom-modal-input-cont">
            <label htmlFor="section-title" className='fs-6'>Enter a single sentence description that best describes the purpose of your questionnaire.</label>
            <input type="text" id="section-title" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="custom-modal-buttons">
          <button className="btn" onClick={() => {
          setCustomModalOpen(null)
          }}>Cancel</button>
          <button di className="btn btn-primary" onClick={() => {
            let updatedDynaimcForms = [...selectedDynamicForms]
             updatedDynaimcForms = updatedDynaimcForms?.map((form) => {
              if(form?.name === customModalOpen?.data){
                return {
                  ...form,
                  name:title
                }
              }
              return form
            }
          ) 
          setSelectedDynamicForms(updatedDynaimcForms)
          setCustomModalOpen(false)
          }} disabled={title?.length < 3}>Create</button>
        </div>
      </div>
    </div>;
    };


const EditJob = () => {
  const [{ getJobMilestone },
    { loginStatus,LoginData },
    { personalConnections }, 
    { documentNames, taxPreparation, taxPlanning },
    {createDocumentGroup,getTaxProdocumentGroup,getTaxProAdmindocumentGroup,deleteDocumentGroup}
  ] = useSelector((state) => [state.milestone, state.user, state.firebase, state.dashboard,state.documentGroup]);

  const [lead, setLead] = useState(null);
  const job = lead?.jobs[0];
  const [alertMessage, setAlertMessage] = useState({ show: false });
  const [messageSaveForm, setmessageSaveForm] = useState({ show: false });
  const [messageDeleteForm, setmessageDeleteForm] = useState({ show: false });
  // Form
  const [serviceType, setServiceType] = useState();
  const [completionDate, setCompletionDate] = useState();
  const [invoiceDeadline, setInvoiceDedline] = useState();
  const [cost, setCost] = useState();
  const [uncleKamFee, setUncleKamFee] = useState();
  const [remaning, setRemanning] = useState();
  const [message, setMessage] = useState();
  const [invoiceType, setInvoiceType] = useState();
  const [agreement, setAgreement] = useState(true);
  const [customPartial, setCustomPartial] = useState();
  const [subscriptionOption, setSubscriptionOption] = useState();
  const [deduction, setDeduction] = useState();
  const [isDraft, setIsDraft] = useState(false);
  //
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [editDocument, setEditDocument] = useState("");
  const [addMessageOnRequest, setAddMessageOnRequest] = useState("");
  const [documentsSelectedInDropdown, setDocumentsSelectedIndropDown] =
    useState([]);
  const [selectedFormNames, setSelectedFormNames] = useState([]);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [formTitle, setFormTitle] = useState("");
  const [selectedDynamicForms, setSelectedDynamicForms] = useState([]);
  const [customModalOpen, setCustomModalOpen] = useState(false);
  const [dynamicForms, setDynamicForms] = useState([]);
  const [dynamicFormsCreatedByAdmins, setDynamicFormsCreatdByAdmins] = useState(
    []
  );
  const [createFormModalOpen, setCreateFormModalOpen] = useState(false);
  const [questionaireSearchValue,setQuestionaireSearchValue] = useState('')
  const [searchModalOpen,setSearchModalOpen] = useState(null)
  const [activeFormData,setActiveFormData] = useState(null)

  // Milestone
  const [milestones, setMilestones] = useState([]);
	const [isMilestonesValid,setIsMilestonesValid] = useState(false);
	const [totalCost, setTotalCost] = useState(0);
	const milestonesFee = totalCost * 0.2;
	const youReceive = totalCost - milestonesFee;


  // handle auto close edit modal
  const [closeEditModal, setCloseEditModal] = useState(false);
  //
  const [documents, setDocuments] = useState([]);
  const [otherDocumentName, setOtherDocumentName] = useState("");
  const [otherDocumentId, setOtherDocumentId] = useState("");

  const [groupName, setGroupName] = useState('');
	const [modalMessage, setmodalMessage] = useState({ show: false });

const modalRef = useRef(null);
  const [errors, setErrors] = useState({
    serviceType: false,
    completionDate: false,
    cost: false,
    message: false,
    documents: false,
    otherDocumentName: false,
    general: "",
  });
  const [docMessage, setdocMessage] = useState({show:false})

  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAutoReply = useAutoReplyHook(dispatch);

  const button = useRef();

  const loadJobByUUID = useCallback((UUID) => {
    axios
      .get(`${BASEURL}/api/jobs/${UUID}`)
      .then((res) => {
        console.log("job data>>>>", res.data);
        setLead(res.data.data);
      })
      .catch((err) => {
        console.log("error>>>>", err);
      });
  }, []);

  useEffect(() => {
    loadJobByUUID(uuid);
  }, [uuid, loadJobByUUID]);

  useEffect(() => {
    dispatch(allActions.dashboard.getDocumentNames());
  }, [dispatch]);

  useEffect(() => {
    const doc = documentsSelectedInDropdown?.find(
      (doc) => doc?.description?.toLowerCase() === "others"
    );

    if (doc) {
      setOtherDocumentId(doc?.id);
    }
  }, [
    selectedDocuments,
    setErrors,
    errors,
    documentNames,
    otherDocumentName,
    documentsSelectedInDropdown,
  ]);


  useEffect(() => {
		if (loginStatus && createDocumentGroup.status !== true && lead) {  
			dispatch(allActions.global.Loader(true));      
			dispatch(allActions.documentsgroup.getTaxProdocumentsgroup(LoginData.isAdmin?lead?.userpro?.user?.id:null
      ));
		}
	}, [loginStatus, dispatch,lead]);

  useEffect(() => {
		if (loginStatus) {
			dispatch(allActions.global.Loader(true));
			dispatch(allActions.documentsgroup.getTaxProAdmindocumentsgroup());
		}
	}, [loginStatus, dispatch]);

	// create Group  document
	useEffect(() => {
		if (!createDocumentGroup.status) {
			dispatch(allActions.global.Loader(false));
			setmodalMessage({
				show: true,
				type: 'Error',
				text: createDocumentGroup.message,
			});
		}
		if (createDocumentGroup.status === true) {
			document.querySelector('.requestJobDocumentsModalClose')?.click();
      dispatch(allActions.documentsgroup.getTaxProdocumentsgroup(LoginData.isAdmin?lead?.userpro?.user?.id:null
      ));
      dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, createDocumentGroup]);


  // get   Group document
	useEffect(() => {
		if (!getTaxProdocumentGroup.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: getTaxProdocumentGroup.message,
			});
		}
		if (getTaxProdocumentGroup.status === true) {
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, getTaxProdocumentGroup]);


    // get admin  Group document
	useEffect(() => {
		if (!getTaxProAdmindocumentGroup.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: getTaxProAdmindocumentGroup.message,
			});
		}
		if (getTaxProAdmindocumentGroup.status === true) {
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, getTaxProAdmindocumentGroup]);


	// delete Group  document
	useEffect(() => {
		if (!deleteDocumentGroup.status) {
			dispatch(allActions.global.Loader(false));      
			setdocMessage({
				show: true,
				type: 'Error',
				text: deleteDocumentGroup.message,
			});
		}
		if (deleteDocumentGroup.status === true) {
				dispatch(allActions.documentsgroup.getTaxProdocumentsgroup(LoginData.isAdmin?lead?.userpro?.user?.id:null
      ));
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, deleteDocumentGroup]);

  const handleGetJobForms = async (id) => {
		if (!id) {
			setMessage({
				show: true,
				type: 'Error',
				text: 'Job ID not found!',
			});
			return;
		}

		try {
			const resp = await axios.get(`${BASEURL}/api/taxform/get-byjob`, {
				params: { job_id: id },
			});
			// setForms(resp?.data?.data);
      const forms = resp?.data?.data?.map((form,_) => {
        return {...form?.jobformsdata?.form_data,status:form?.jobformsdata.status}
      })
      setSelectedFormNames(forms)
      setSelectedDynamicForms(forms)
			changeLoading(false)

		} catch (error) {
			changeLoading(false)
			setMessage({
				show: true,
				type: 'Error',
				text: 'Request error!',
			});
		}
	};

  useEffect(() => {
    if (job) {
      if (job.status_id === 3 || job.status_id === 4) {
        navigate(`/dashboard/jobs/${job?.uuid}`);
      } else {
        setCompletionDate(new Date(job?.completed_at));
        setInvoiceDedline(new Date(job?.payment_due_date));
        setCost(job?.cost);
        //
        // const givenDoc=job?.documents?.map((doc)=>doc?.jobdocument?.documentId.toString())

        // setDocuments()
        setUncleKamFee(
          (
            (parseInt(LoginData?.userpro?.percentage) / 100) *
            job?.cost
          ).toFixed(2)
        );
        setRemanning(
          (
            job?.cost -
            (
              (parseInt(LoginData?.userpro?.percentage) / 100) *
              job?.cost
            ).toFixed(2)
          ).toFixed(2)
        );
        setMessage(job?.description);
        setServiceType(job?.service?.id);
        // If status_id is 5 (draft) then set isDraft to true
        if (job?.status_id === 5) {
          setIsDraft(true);
        }
        // Set Invoice Type
        if (job?.invoice_type === "full") {
          setInvoiceType(invoiceOptions[0]);
        } else if (job?.invoice_type === "partial") {
          setInvoiceType(invoiceOptions[1]);
          setDeduction(
            partionalPaymentOption.find(
              (item) => item.value === job?.invoice_data?.deduction
            )
          );
        } else if (job?.invoice_type === "subscription") {
          setInvoiceType(invoiceOptions[2]);
          setSubscriptionOption(
            invoicePeriodOptions.find(
              (item) => item.value === job?.invoice_data?.deduction
            )
          );
        }
      }
      const filteredDocs = job?.documents?.map((doc) => {
        return {
          id: doc.jobdocument.doc_name_id,
          doc_id: doc.jobdocument.documentId,
          name: doc.jobdocument.name,
          description: doc.jobdocument.name,
          message: doc.jobdocument.message,
        };
      });

      dispatch(allActions?.milestone?.getJobMilestone(job?.id,job?.pro_id))
      // const filteredFormData = job?.form_data
      //   ? job.form_data
      //       .map((form) => {
      //         if (Object.keys(form).includes("formData")) {
      //           return {
			// 	  name:form?.name,
      //             formData: form?.formData,
			// 	  message:''
      //           };
      //         }
      //       })
      //       .filter((item) => item !== undefined)
      //   : [];

        // const filteredFormData = job?.jobforms.map((form) => {
        //       // if (Object.keys(form).includes("formData")) {
        //         return {
        //    id:form?.jobformsdata?.id,       
				//   name:form?.jobformsdata?.form_data?.name,
				//   message:form?.jobformsdata?.form_data?.message,
        //   formData: form?.jobformsdata?.form_data?.formData,
        //         };
        //       // }
        //     })
            // .filter((item) => item !== undefined)
			changeLoading(true)
      handleGetJobForms(job?.id)
      setSelectedDocuments(filteredDocs);
    }
  }, [job]); 
    
  

  useEffect(() => {
    if(getJobMilestone?.status === true){
    const jobMilestones = getJobMilestone?.data?.map((milestone) => {
      return {
        id:milestone?.id,
        name:milestone?.name,
        amount:milestone?.invoices?.amount,
        dueDate:milestone?.invoices?.due_date,
        document_id:milestone?.document_id,
        milestone_id:milestone?.id
      }
    })
    setMilestones(jobMilestones)
    dispatch(allActions?.milestone?.getJobMilestonereset())
    changeLoading(false);
   }
   if(getJobMilestone?.status === false){
    setAlertMessage({
      show: true,
      type: "Error",
      text: getJobMilestone.message,
    });
    changeLoading(false);
    
   }

  },[getJobMilestone.status])

  useEffect(() => {
    if (closeEditModal === false) {
      return;
    }
    // if Success
    if (taxPreparation?.state === true) {
      setAlertMessage({ show: false });
      changeLoading(false);
      navigate(`/dashboard/jobs/${job?.uuid}`);
    }

    // if Error
    if (taxPreparation?.state === false) {
      // Show Message Alert
      setAlertMessage({
        show: true,
        type: "Error",
        text: taxPreparation.message,
      });
      changeLoading(false);
    }
  }, [taxPreparation.message, taxPreparation?.state, closeEditModal]);

  useEffect(() => {
    // if Success
    if (taxPlanning?.state === true) {
      setAlertMessage({ show: false });
      changeLoading(false);
      navigate(`/dashboard/jobs/${job?.uuid}`);
    }

    // if Error
    if (taxPlanning?.state === false) {
      // Show Message Alert
      setAlertMessage({
        show: true,
        type: "Error",
        text: taxPlanning.message,
      });
      changeLoading(false);
    }
  }, [taxPlanning.message, taxPlanning?.state]);

  useEffect(() => {
    setSelectedDocumentIds(selectedDocuments?.map((doc) => doc?.id));
  }, [selectedDocuments]);

  useEffect(() => {
    setSelectedForms(selectedFormNames?.map((name) => name?.value));
  }, [selectedFormNames]);

  const getInvoiceData = () => {
    let obj = { invoice_data: {}, payment_due_date: invoiceDeadline };
    // For Partial Payment
    if (invoiceType?.value === "PartialPayment") {
      obj.invoice_data.deductionType = deduction?.value === "custom" ? "" : "%";
      obj.invoice_type = "partial";
      obj.invoice_data.deduction =
        deduction?.value === "custom" ? customPartial : deduction?.value;
      obj.invoice_data.fullAmount = cost;
      obj.invoice_data.remainingAmount =
        deduction?.value === "custom"
          ? cost - customPartial
          : cost - ((deduction?.value / 100) * cost).toFixed(2);
    }
    // For Subscription Payment
    if (invoiceType?.value === "RecurringPayment") {
      obj.invoice_type = "subscription";
      obj.invoice_data.deduction = subscriptionOption?.value;
    }
    if (invoiceType.value === "FullPayment") {
      obj.invoice_type = "full";
    }
    return obj;
  };
  // *******************************************
  const callTaxPlanning = (taxPlanObj) => {
    reset_mesage();
    dispatch(allActions.dashboard.resetTaxPlanning());
    dispatch(allActions.dashboard.taxPlanning({ obj: taxPlanObj }));
  };

  const callTaxPreparation = (taxPreparationObj) => {
    reset_mesage();
    dispatch(allActions.dashboard.resetTaxPreparation());
    dispatch(allActions.dashboard.taxPreparation({ obj: taxPreparationObj }));
  };

  const reset_mesage = () => {
    setAlertMessage({ show: false });
  };


  useEffect(() => {
    if (parseInt(serviceType) !== 1 && documentNames.length > 0) {
      const getIds = job?.documents?.map((data) =>
        documentNames.find((DD) => DD?.name === data?.name)
      );
      if (getIds) {
        const ff = [
          ...new Set(
            getIds.filter((data) => data).map((data) => data?.id?.toString())
          ),
        ];
        setDocuments(ff);
      }
    }
  }, [job?.documents?.length, serviceType, documentNames]);

  // function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if(milestones?.length === 0){
			setIsMilestonesValid(true)
			setAlertMessage({
				show: true,
				type: 'Error',
				text: 'Please ensure at least one milestone is added for this job before proceeding.'
			});
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});

			return
		}
    if(!validateMilestones(milestones)){
			setIsMilestonesValid(true)
			setAlertMessage({
				show: true,
				type: 'Error',
				text: 'Please fill in all required fields (name, due date, and amount) for all milestones before proceeding.'
			});
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});

			return
		}
	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	const handleAutoMessaging = (uuid) => {
		// if (!isDraft) {
		// 	handleAutoReply({
		// 		personalConnections,
		// 		LoginData,
		// 		receiver: lead?.user,
		// 		type: 'simple',
		// 		encodedString: `<p>Hi ${lead?.user?.firstname}, I have updated the contract based on your request. Please click the link below to view and sign here <a href="${window.location.origin}/dashboard/contract/${uuid}">Contract</a> to get started on the job. Thanks</p>`,
		// 	});
		// }

		var encodedString = window.btoa(
			JSON.stringify({
        job_uuid:job?.uuid,
        milestone_action:'contract-update'				
			})
		);
		handleAutoReply({
			personalConnections,
			LoginData,
			receiver: lead?.user,
			type: "milestone",
			encodedString: encodedString,
		  });
	};

    const documents = selectedDocuments;
    let selectedDocNames = documentNames?.filter((obj) =>
      documents?.some((doc) => doc.id === obj.id)
    );
    let taxPlanObj = {
      lead_id: lead.id,
      service_id: serviceType,
      method: "create",
      docName: selectedDocNames?.map((item) => item?.name),
    };
	taxPlanObj.formName = [...dynamicForms]
    //
    button.current.disabled = true;
    if (errors.general !== "") setErrors({ errors, general: "" });
    changeLoading(true);
    dispatch(allActions.dashboard.resetTaxPlanning());
    dispatch(allActions.dashboard.resetTaxPreparation());
    setAlertMessage({ show: false });
    axios
      .put(`${BASEURL}/api/jobs/update/${job?.uuid}`, {
        pro_id: LoginData?.userpro?.id || job.userpro?.id,
        additional: message,
        completionDate,
        isDraft,
        cost,
        //
        documents,
        // other_document_name: otherDocumentName,
        customer_id: lead.user.id,
        service_id: serviceType,
        job_uuid: job.uuid,
		    formData:selectedDynamicForms,
        //
        // ...getInvoiceData(),
      })
      .then(async (response) => {
        			await milestones.map( async(v,i)=> {
                if(v?.id){
                   dispatch(allActions.global.Loader(true))
                   dispatch( await allActions.milestone.updateMilestone({...v,pro_id:job?.pro_id,job_id:job?.id,status_id:"0",user_id:lead?.user?.id,document_id:v?.document_id,milestone_id:v?.milestone_id}))
                }else{
                  dispatch(allActions.global.Loader(true))
                  dispatch( await allActions.milestone.createMilestone({...v,milestone_id:v?.milestone_id,pro_id:job?.pro_id,job_id:job?.id,status_id:"0",user_id:lead?.user?.id}))
                }

              })
        
        if (
          !(
            taxPlanObj?.service_id === 2 &&
            (LoginData?.isTaxPro ||
              LoginData?.isAdmin ||
              LoginData?.isAccountManager) &&
            taxPlanObj?.service_id === 1
          )
        ) {
          button.current.disabled = false;
        }
        // if SERVICE IS TAX PLAN
        if (
          taxPlanObj?.service_id === "others" ||
          taxPlanObj?.service_id === 2 ||
          taxPlanObj?.service_id === "2" ||
          taxPlanObj?.service_id === 3 ||
          taxPlanObj?.service_id === "3" ||
          taxPlanObj?.service_id === 4 ||
          taxPlanObj?.service_id === "4"
        ) {
          taxPlanObj.formName = selectedFormNames?.map((form) => {
            return { name: form.value, message: form.message };
          });
          taxPlanObj.formData = selectedDynamicForms;
          taxPlanObj.job_uuid=job?.uuid         

          // callTaxPlanning(taxPlanObj);
        }
        // if SERVICE IS TAX PREPARATION
        else if (
          taxPlanObj?.service_id === "1" ||
          taxPlanObj?.service_id === 1
        ) {
          // Modify Object To Tax Preparation
          delete taxPlanObj.docName;
          taxPlanObj.formName = selectedFormNames?.map((form) => {
            return { name: form.value, message: form.message };
          });
          taxPlanObj.formData = {};
          // callTaxPreparation(taxPlanObj);
        }
        // FOR OTHER SERVICES
        else {
          changeLoading(false);
          // Navigate to lead details page
          navigate(`/dashboard/jobs/${response?.data?.data?.uuid}`, {
            state: {
              message: "Contract Created and sent to customer Successfully!",
            },
          });
        }
        changeLoading(false);
        handleAutoMessaging(job.uuid);
        navigate(`/dashboard/jobs/${job?.uuid}`);
        setCloseEditModal(true);
      })
      .catch((error) => {
        console.log("calling", 6);
        changeLoading(false);
        setAlertMessage({
          show: true,
          type: "Error",
          text: error?.response?.data?.error || error?.message,
        });
        button.current.disabled = false;
        // Set general error
        if (errors.general === "")
          setErrors({
            errors,
            general: error?.response?.data?.message || error.message,
          });
      });
  };

  const changeLoading = (status) => {
    dispatch(allActions.global.Loader(status));
  };

  const handleAutoMessaging = (uuid) => {
    if (!isDraft) {
      handleAutoReply({
        personalConnections,
        LoginData,
        receiver: lead?.user,
        type: "simple",
        encodedString: `<p>Hi ${lead?.user?.firstname}, I have updated the contract based on your request. Please click the link below to view and sign here <a href="${window.location.origin}/dashboard/contract/${uuid}">Contract</a> to get started on the job. Thanks</p>`,
      });
    }
  };

  useEffect(() => {
    if (isDraft === true) {
      // Set default values for draft contract payment info part.
      setCompletionDate(new Date());
      setCost(0);
      setUncleKamFee(0);
      setRemanning(0);
      setInvoiceType({ label: "Full Payment", value: "FullPayment" });
      setInvoiceDedline(new Date());
    } else {
      // Clean up the default values for draft contract payment info part.
      setCompletionDate();
      setCost();
      setUncleKamFee();
      setRemanning();
      setInvoiceType();
      setInvoiceDedline();
    }
  }, [isDraft]);

	const handleSelectDocument = (document) => {
    document.forEach((documents)=>{ 
		const isDocument = selectedDocuments.find(doc => doc.id === document.id)
		if (!isDocument) {
			setSelectedDocuments(prev => [...prev, documents])
			setDocumentsSelectedIndropDown(prev => [...prev, documents])
		} else {
			const filterAndDelete = selectedDocuments.filter(document => document.id !== isDocument.id)
			setSelectedDocuments(filterAndDelete)
			setDocumentsSelectedIndropDown(filterAndDelete)
		}
  })
	}

  const handleDeleteSelectedDocument = (id) => {
    const filterAndDelete = selectedDocuments.filter(
      (document) => document.id !== id
    );
    setSelectedDocuments(filterAndDelete);
    setDocumentsSelectedIndropDown(filterAndDelete);
  };

  const openJobData = (form) => {
    let formData = {
      name: form?.value,
      formData: getCurrentFormsObj(form?.value),
    };
    dispatch(allActions.dashboard.resetTaxPreparation());
    dispatch(
      allActions.taxForms.dispatchViewFormDetails({
        document: formData,
        navigate: () => document.querySelector(".formPreview").click(),
      })
    );
  };

  const handleSaveTemplate = async (formTitle, taxForm) => {
    changeLoading(true);
    const endpoint =  "create-dynamic-form"
    try {
      await axios.post(`${BASEURL}/api/taxform/${endpoint}`, {
        form_data: { name: formTitle, data: taxForm, byAdmin:false,},
        pro_user_id:LoginData.isAdmin?lead?.userpro?.user?.id:null 
      });
      if (getDynamicForms) {
        getDynamicForms();
      }
      setmessageSaveForm({
				show: false,
			});
      changeLoading(false);
    } catch (error) {
      changeLoading(false)
      setmessageSaveForm({
        show: true,
        type: 'Error',
        text:error?.response?.data?.error?error?.response?.data?.error:'server error'
      });
      console.log(error);
    }
  };

  const getDynamicForms = async () => {
    const endpoint = LoginData?.isAdmin ?  "/get-dynamic-form-byadmin" : "get-dynamic-form"
    try {
      const resp = await axios.get(`${BASEURL}/api/taxform/${endpoint}`);
      setDynamicForms(resp?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getDynamicFormsCreatedByAddmins = async () => {
    try {
      const resp = await axios.get(
        `${BASEURL}/api/taxform/get-dynamic-form-created-by-admin`
      );
      setDynamicFormsCreatdByAdmins(resp?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteTemplate = async (id) => {
  const endpoint = LoginData?.isAdmin ?  "/delete-dynamic-form-byadmin" : "delete-dynamic-form"
	changeLoading(true)
	try {
			await axios.delete(`${BASEURL}/api/taxform/${endpoint}`,{data:{form_id:id}})
			getDynamicForms()
      setmessageDeleteForm({
				show: false,
			});
			changeLoading(false)
		} catch (error) {
			console.log(error);
      changeLoading(false)
      setmessageDeleteForm({
        show: true,
        type: 'Error',
        text:error?.response?.data?.error?error?.response?.data?.error:'server error'
      });
		}
}	

  useEffect(() => {
    getDynamicForms();
    getDynamicFormsCreatedByAddmins();
  }, []);


  useEffect(() => {
	function handleClickOutside(event) {
	  if (searchModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
		setSearchModalOpen(false);
	  }
	}

	if (searchModalOpen) {
	  document.addEventListener('mousedown', handleClickOutside);
	}

	return () => {
	  document.removeEventListener('mousedown', handleClickOutside);
	};
  }, [searchModalOpen, setSearchModalOpen]);

  const handleRequest = (doc) => {
    let body = {
      doc_data: {
        documents: doc,
        groupName: groupName,
      },
      pro_user_id:LoginData.isAdmin?lead?.userpro?.user?.id:null
    };    
    dispatch(allActions.global.Loader(true));
    dispatch(allActions.documentsgroup.createdocumentsgroupidle());
    dispatch(allActions.documentsgroup.createdocumentsgroup(body,'create-groupDocument'));
  }; 

  const handleDeleteGroupDocument=()=>{
    let body = {
      doc_id: customModalOpen?.data?.id,
      pro_user_id:LoginData.isAdmin?lead?.userpro?.user?.id:null
  
    };
    dispatch(allActions.global.Loader(true));
    dispatch(allActions.documentsgroup.deletedocumentsgroupidle());
    dispatch(allActions.documentsgroup.deletedocumentsgroup(body,'delete-groupDocument'));
    
  }
  const singleDocumentRequestHandle =(docs)=>{  
    setSelectedDocuments([...selectedDocuments,...docs])
    }


    const handleMilestoneChange = (index, field, value) => {
      const updatedMilestones = milestones.map((milestone, i) =>
        i === index ? { ...milestone, [field]: value } : milestone
      );
      setMilestones(updatedMilestones);
  
      // Update total cost
      const newTotal = updatedMilestones.reduce(
        (sum, milestone) => sum + parseFloat(milestone.amount || 0),
        0
      );
      setTotalCost(newTotal);
    };

    const handleDeleteMilestone = (index) => {
      setMilestones((prevMilestones) =>
        prevMilestones.filter((_, i) => i !== index)
      );
  
      setTotalCost((prevTotalCost) =>
        milestones.reduce((sum, milestone, i) =>
            i !== index ? sum + parseFloat(milestone.amount || 0) : sum
          , 0)
      );
    };
    const handleAddMilestone = () => {
      setMilestones([
        ...milestones,
        {name: ``, dueDate: "", amount: 0},
      ]);
    };
  	function validateMilestones(milestones) {
      return milestones.every(
        ({ name, dueDate, amount }) => name && dueDate && amount
      );
    }
    

  if (lead !== null && job !== null) {
    return (
      <div className="clearfix">
        <Header name="Edit Job" />
        {customModalOpen?.state === "delete" && (
          <CustomDeleteModal
            customModalOpen={customModalOpen}
            dynamicForms={dynamicForms}
            setCustomModalOpen={setCustomModalOpen}
            handleDeleteTemplate={handleDeleteTemplate}
          />
        )}
        {customModalOpen?.state === "formtitle" && (
          <CreateFormTitleModal
            setCustomModalOpen={setCustomModalOpen}
            formTitle={formTitle}
            setFormTitle={setFormTitle}
            setCreateModalOpen={setCreateFormModalOpen}
          />
        )}
        {createFormModalOpen?.state && (
          <CreateFormModal setCreateModalOpen={setCreateFormModalOpen}>
            <CreateNewForm
              setSelectedDynamicForms={setSelectedDynamicForms}
              selectedDynamicForms={selectedDynamicForms}
              getDynamicForms={getDynamicForms}
              formTitle={formTitle}
              setFormTitle={setFormTitle}
              setCreateFormModalOpen={setCreateFormModalOpen}
              setCreateF={createFormModalOpen}
              editmode={createFormModalOpen?.editmode}
              formToEdit={createFormModalOpen?.data || {}}
            />
          </CreateFormModal>
        )}
        {customModalOpen?.state === "openjobform" && (
          <CreateFormModal setCreateModalOpen={setCustomModalOpen}>
            <OpenJobForm
              selectedJob={{ jobId: 55 }}
              setIsFormModalOpen={setCustomModalOpen}
              activeFormData={activeFormData}
              setActiveFormData={setActiveFormData}
              previewMode={true}
            />
          </CreateFormModal>
        )}
        {customModalOpen?.state === "editformtitle" && (
          <EditFormTitleModal
            setCustomModalOpen={setCustomModalOpen}
            customModalOpen={customModalOpen}
            formTitle={customModalOpen?.data}
            selectedDynamicForms={selectedDynamicForms}
            setSelectedDynamicForms={setSelectedDynamicForms}
          />
        )}
        {customModalOpen?.state === "delete-groupDocument" && (
          <CustomDeleteModal
            customModalOpen={customModalOpen}
            dynamicForms={dynamicForms}
            setCustomModalOpen={setCustomModalOpen}
            handleDeleteTemplate={handleDeleteGroupDocument}
          />
        )}
        <div className="container page">
          <form className="clearfix pt-4 mb-4" onSubmit={handleSubmit}>
            {alertMessage.show ? <Message message={alertMessage} /> : <></>}
            <div className="card border-1 border-light rounded p-4 mb-4">
              <div className="card-header d-flex justify-content-between bg-white border-0">
                <h3 className="card-title text-primary fw-bold">Client</h3>
                <div className="card-actions">
                  <span className="btn btn-sm text-primary">+</span>
                  <span className="btn btn-sm text-primary">
                    <BsThreeDotsVertical />
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div>
                    <UserAvatar
                      avatar={lead.user.avatar}
                      alt={`${lead.user.firstname} ${lead.user.lastname}`}
                      className="img-fluid object-fit-cover rounded-circle"
                      style={{ height: 40 }}
                    />
                  </div>
                  <p className="text-dark m-0 ms-2">
                    {`${lead.user.firstname} ${lead.user.lastname}`}
                  </p>
                </div>
              </div>
            </div>
            <div className="card border-1 border-light rounded p-4 mb-4">
              <div className="card-header d-flex justify-content-between bg-white border-0">
                <h3 className="card-title text-primary fw-bold">Terms</h3>
                <div className="card-actions">
                  <span className="btn btn-sm text-primary">+</span>
                  <span className="btn btn-sm text-primary">
                    <BsThreeDotsVertical />
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div className="mb-4">
                  <div className="text-dark">Service type</div>
                  <Select
                    value={serviceType}
                    name="serviceType"
                    placeholder="Service type"
                    fn={setServiceType}
                  >
                    <option value={job?.service?.id || "others"}>
                      {job?.service?.name || job?.others}
                    </option>
                  </Select>
                </div>
                <div className={`mb-${job?.status_id === 5 ? "4" : "0"}`}>
                  <div className="text-dark">Description</div>
                  <TextArea
                    inputName="form-control"
                    defaultValue={message}
                    placeholder="Enter details of the job here"
                    name="message"
                    fn={setMessage}
                    row="6"
                    required={false}
                    showLabel={false}
                  />
                </div>
                {job?.status_id === 5 ? (
                  <div className="mb-0">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="isDraft"
                        className="form-check-input"
                        id="isDraft"
                        value={isDraft ? 1 : null}
                        checked={isDraft}
                        onChange={(e) => setIsDraft(e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor="isDraft">
                        Draft Mode
                      </label>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {isDraft ? null : (
              <div className="card border-1 border-light rounded p-4 mb-4">
                <div className="card-header d-flex justify-content-between bg-white border-0">
                  <h3 className="card-title text-primary fw-bold">
                    Payment Info
                  </h3>
                  {/* <div className="card-actions">
										<span className="btn btn-sm text-primary">+</span>
										<span className="btn btn-sm text-primary">
											<BsThreeDotsVertical />
										</span>
									</div> */}
                </div>
                <div className="px-3">
                  {milestones.map((milestone, index) => (
                    <div className="row mb-3 align-items-center" key={index}>
                      <div className="col-md-4">
                        <label className="form-label">Name of milestone</label>
                        <input
                          type="text"
                          className="form-control"
                          value={milestone.name}
                          onChange={(e) =>
                            handleMilestoneChange(index, "name", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">Due date</label>
                        <DatePicker
                          value={
                           milestone?.dueDate
                            ? typeof milestone?.dueDate === "string"
                              ? dayjs(milestone?.dueDate)
                              : milestone?.dueDate
                            : null
                          }
                          onChange={(date) => {
                            if (date) {
                              handleMilestoneChange(
                                index,
                                "dueDate",
                                dayjs(date).format("YYYY-MM-DD") 
                              );
                            }
                          }}
                          format="DD/MM/YYYY"
                          placeholderText="DD/MM/YYYY" 
                          className="question-input form-control"
                          disabledDate={(current) => {
                            return current && current.isBefore(dayjs().startOf('day'));
                        }}
                        />
                        
                      </div>
                      <div className="col-md-3">
                        <label className="form-label">Amount</label>
                        <input
                          type="number"
                          className="form-control"
                          value={milestone.amount}
                          onChange={(e) =>
                            handleMilestoneChange(
                              index,
                              "amount",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-md-1 d-flex align-items-center justify-content-center">
                        <button
                          type="button"
                          className="btn btn-link text-danger mt-3"
                          onClick={() => handleDeleteMilestone(index)}
                        >
                          <img src={DeleteIcon2} alt="Delete milestone" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                {milestones?.length === 0 && (
                  <div className={"px-3 mb-3"}>
                    You currently have no milestones. <br /> Click the button
                    below to add one and start tracking your progress.
                  </div>
                )}

                <div
                  className="btn border border-primary text-primary w-25  d-inline mb-3 mx-3"
                  onClick={handleAddMilestone}
                >
                  + Add Milestone
                </div>

                <div className="column">
                  <div className="w-full mt-3">
                    <label className="form-label">Total cost</label>
                    <input
                      type="text"
                      className={`form-control ${
                        isMilestonesValid ? "border border-danger" : ""
                      }`}
                      value={`$ ${totalCost?.toFixed(2)}`}
                      readOnly
                    />
                  </div>
                  <div className="w-full mt-3">
                    <label className="form-label">20% Uncle Kam fee</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled={true}
                      value={`$ ${milestonesFee?.toFixed(2)}`}
                      readOnly
                    />
                  </div>
                  <div className="w-full mt-3">
                    <label className="form-label">You'll receive</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled={true}
                      value={`$ ${youReceive?.toFixed(2)}`}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            )}
            {/* start updating missing code */}
            <div className="card border-1 border-light rounded p-4 mb-4 px-5">
              <h3 className="card-title text-primary fw-bold ">
                Tax Organization Form
              </h3>
              <div className="d-flex align-items-center justify-content-between mt-3 ">
                <div className="d-flex gap-2 align-items-center ">
                  <img src={QuestionaireSvg} alt="file" />
                  <p className="mt-0 mb-0 text-dark fw-bold">
                    Forms ({selectedDynamicForms?.length})
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <p className="mt-0 mb-0 text-black fw-bold">
                    Add questionnaire
                  </p>
                  <div className="questionaire-search-input-cont">
                    <img src={SearchIcon} alt="search" />
                    <input
                      onChange={(e) =>
                        setQuestionaireSearchValue(e.target.value)
                      }
                      onFocus={() => setSearchModalOpen(true)}
                      className="questionaire-search-input"
                      type="text"
                      placeholder="Search or select"
                    />
                    <img
                      onClick={() => setSearchModalOpen((prev) => !prev)}
                      className="cursor-pointer"
                      src={dropdownIcon}
                      alt="search"
                    />
                    {searchModalOpen && (
                      <div
                        ref={modalRef}
                        className="search-questionaire-modal-cont"
                      >
                        {(questionaireSearchValue
                          ? [
                              ...dynamicFormsCreatedByAdmins,
                              ...dynamicForms,
                            ].filter((form) =>
                              form?.form_data?.name
                                ?.toLowerCase()
                                .includes(
                                  questionaireSearchValue?.toLowerCase()
                                )
                            )
                          : [...dynamicFormsCreatedByAdmins, ...dynamicForms]
                        ).map((option) => {
                          return (
                            <div
                              key={option.label}
                              onClick={() => {
                                const isForm = selectedDynamicForms?.find(
                                  (name) =>
                                    name?.name === option?.form_data?.name
                                );
                                if (isForm) {
                                  const deletedFormName =
                                    selectedDynamicForms?.filter(
                                      (name) =>
                                        name?.name !== option?.form_data?.name
                                    );
                                  setSelectedDynamicForms(deletedFormName);
                                } else {
                                  setSelectedDynamicForms((prev) => [
                                    ...prev,
                                    {
                                      name: option?.form_data?.name,
                                      value: option?.form_data?.name,
                                      label: option?.form_data?.name,
                                      message: "",
                                    },
                                  ]);
                                }
                                //   setAddMessageOnRequest("");
                              }}
                              className={`${
                                selectedDynamicForms?.find(
                                  (form) =>
                                    form?.name === option?.form_data?.name
                                )
                                  ? "border border-primary rounded"
                                  : ""
                              }`}
                            >
                              <div className="form-title">
                                {option?.form_data?.name}{" "}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className='d-flex align-items-center gap-2'>
                                        <p className='mt-0 mb-0 text-dark fw-bold'>Add questionnaire</p>
                                                 <Input
										    		type="search"
												value={customPartial}
												name="partialValue"
												placeholder="Search or Select"
												required={false}
												showLabel={false}
											/>
                                    </div> */}
              </div>
              {messageSaveForm?.show && <Message message={messageSaveForm} />}
              {selectedDynamicForms.length > 0 &&
                selectedDynamicForms.map((name, id) => {
                  return (
                    <div
                      key={name?.name}
                      className="mt-5 mb-3 d-flex align-items-start justify-content-between border-b pb-3"
                    >
                      {console.log("@33333 step", name)}
                      <div className="d-flex gap-4 cursor-pointer">
                        <img
                          className="mt-1 icon-h"
                          src={QuestionaireSvg}
                          alt="requestfile"
                        />
                        <div
                          role="button"
                          onClick={() => {
                            // setMessage({ show: false });
                          }}
                        >
                          <div className="d-flex align-itesm-center gap-5">
                            <div className="d-flex align-items-center gap-3">
                              <p
                                className="mb-0 fw-bold"
                                onClick={() => {
                                  setActiveFormData((prev) => {
                                    return {
                                      formTitle: name?.name,
                                      data: name?.formData,
                                    };
                                  });
                                  setCustomModalOpen({
                                    state: "openjobform",
                                    data: {
                                      name: name?.name,
                                      value: name?.name,
                                      label: name?.name,
                                      data: name?.formData,
                                    },
                                  });
                                }}
                              >
                                {name?.name}
                              </p>
                              <img
                                src={EditDocumentSvg}
                                alt="edit"
                                className="edit-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCustomModalOpen({
                                    state: "editformtitle",
                                    data: name?.name,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {dynamicForms?.find(
                            (form) => form?.form_data?.name === name?.name
                          ) ? (
                            <div
                              onClick={() => {
                                setCustomModalOpen({
                                  state: "delete",
                                  data: name?.name,
                                });
                              }}
                              className="text-danger"
                            >
                              Delete template
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                handleSaveTemplate(name?.name, name?.formData);
                              }}
                              className="text-success"
                            >
                              Save as new template
                            </div>
                          )}

                          {/* <p className='mb-0 mt-1 max-w'>{name.label} </p> */}
                          {/* <p onClick={() => setAddMessageOnRequest(name.value)} className='mt-1 mb-o text-primary cursor-pointer'>Add a Message</p>
										{(addMessageOnRequest === name.value || name?.message) && <textarea
													className='w-100'
													value={name?.message}
													onChange={(e) => {
														const edited = selectedFormNames?.map(n => {
															if (n.value === name.value) {
																return {
																	value: n.value,
																	label:n.label,
																	message: e.target.value
																}
															}
															return n
														})
														setSelectedFormNames(edited)
													}}
												></textarea>} */}
                        </div>
                      </div>
                      <div className="d-flex gap-4">
                        <img
                          className="cursor-pointer icon"
                          onClick={() => {
                            setFormTitle(name?.name);
                            setCreateFormModalOpen({
                              state: true,
                              editmode: true,
                              data: name?.formData,
                            });
                          }}
                          src={editIcon}
                          alt="delete"
                        />
                        <img
                          className="cursor-pointer icon"
                          onClick={() => {
                            const deletedDynaimcFormName =
                              selectedDynamicForms?.filter(
                                (formname) => formname !== name
                              );
                            setSelectedDynamicForms(deletedDynaimcFormName);
                          }}
                          src={DeleteIcon2}
                          alt="delete"
                        />
                      </div>
                    </div>
                  );
                })}

              <div className="border-dashed rounded mt-3 bg-muted-200  pb-5 bg-color">
                <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
                  <h3 className="text-center text-grey-400 fw-bold">
                    Smart Questionnaire
                  </h3>
                  <p className="text-center w-85 mt-2 text-2 px-5 ">
                    Select from among our pre-built Smart Questionnaires. Your
                    Client’s input are saved as they answer each question,
                    allowing them to continue right from where they last left
                    off.
                  </p>
                </div>
                {messageDeleteForm?.show && (
                  <Message message={messageDeleteForm} />
                )}
                <div className="row d-flex justify-content-start  card-cont">
                  <div
                    onClick={() => {
                      setCustomModalOpen({ state: "formtitle", data: {} });
                    }}
                    className="border-solid d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded"
                  >
                    <img style={{ height: "40px" }} src={PlusSvg} alt="plus" />
                    Create New
                  </div>
                  {dynamicForms?.map((option) => {
                    return (
                      <div
                        key={option.label}
                        onClick={() => {
                          const isForm = selectedDynamicForms?.find(
                            (name) => name?.name === option?.form_data?.name
                          );
                          if (isForm) {
                            const deletedFormName =
                              selectedDynamicForms?.filter(
                                (name) => name?.name !== option?.form_data?.name
                              );
                            setSelectedDynamicForms(deletedFormName);
                          } else {
                            setSelectedDynamicForms((prev) => [
                              ...prev,
                              {
                                name: option?.form_data?.name,
                                formData: option?.form_data?.data,
                                message: "",
                                status: 0
                              },
                            ]);
                          }
                          //   setAddMessageOnRequest("");
                        }}
                        className={`dynamic-form-card px-5 text-center border-solid ${
                          selectedDynamicForms?.find(
                            (form) => form?.name === option?.form_data?.name
                          )
                            ? "border-primary"
                            : ""
                        } d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}
                      >
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setCustomModalOpen({
                              state: "delete",
                              data: option?.form_data?.name,
                            });
                            // handleDeleteTemplate(option?.id);
                          }}
                          className="delete"
                          src={DeleteIcon}
                          alt="delete-icon"
                        />
                        <img
                          style={{ height: "40px" }}
                          src={FileBlueSvg}
                          alt="plus"
                        />
                        {option?.form_data?.name}
                        {/* Dynamic */}
                      </div>
                    );
                  })}
                  <div className="w-100 dashed-border my-3"></div>
                  {dynamicFormsCreatedByAdmins?.map((option) => {
                    return (
                      <div
                        key={option.label}
                        onClick={() => {
                          const isForm = selectedDynamicForms?.find(
                            (name) => name?.name === option?.form_data?.name
                          );
                          if (isForm) {
                            const deletedFormName =
                              selectedDynamicForms?.filter(
                                (name) => name?.name !== option?.form_data?.name
                              );
                            setSelectedDynamicForms(deletedFormName);
                          } else {
                            setSelectedDynamicForms((prev) => [
                              ...prev,
                              {
                                name: option?.form_data?.name,
                                formData: option?.form_data?.data,
                                message: "",
                                status:0
                              },
                            ]);
                          }
                          //   setAddMessageOnRequest("");
                        }}
                        className={`dynamic-form-card px-5 text-center border-solid ${
                          selectedDynamicForms?.find(
                            (form) => form?.name === option?.form_data?.name
                          )
                            ? "border-primary"
                            : ""
                        } d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}
                      >
                        <img
                          style={{ height: "40px" }}
                          src={FileBlueSvg}
                          alt="plus"
                        />
                        {option?.form_data?.name}
                        {/* Dynamic */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="card border-1 border-light rounded p-4 mb-4 px-5">
              {/* <h3 className="card-title text-primary fw-bold">Documents request</h3> */}
              <h3 className="card-title text-primary fw-bold">
                Documents request
              </h3>
              <div>
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="d-flex gap-2 align-items-center ">
                    <img src={FileSvg} alt="file" />
                    <p className="mt-0 mb-0 text-dark fw-bold">
                      File Requests ({selectedDocuments?.length})
                    </p>
                  </div>
                  {/* <div className='d-flex align-items-center gap-2'>
                                        <p className='mt-0 mb-0 text-dark fw-bold'>Add questionnaire</p>
                                                 <Input
										    		type="search"
												value={customPartial}
												name="partialValue"
												placeholder="Search or Select"
												required={false}
												showLabel={false}
											/>
                                    </div> */}
                </div>
                {selectedDocuments?.length > 0 &&
                  selectedDocuments.map((document) => {
                    return (
                      <div
                        key={document.id}
                        className="mt-5 mb-3 d-flex align-items-start justify-content-between border-b"
                      >
                        <div className="d-flex gap-4 w-50">
                          <img
                            className="mt-1 icon-h"
                            src={RequestDocumentSvg}
                            alt="requestfile"
                          />
                          <div className="w-100">
                            <div className="d-flex align-itesm-center gap-5">
                              <input
                                disabled={editDocument !== document?.id}
                                className="mb-0 fw-bold w-100 border-0 outline-0"
                                onChange={(e) => {
                                  const edited = selectedDocuments?.map(
                                    (doc) => {
                                      if (doc.id === document.id) {
                                        return {
                                          id: doc.id,
                                          name: e.target.value,
                                          description: doc.description,
                                          message: doc.message,
                                        };
                                      }
                                      return doc;
                                    }
                                  );
                                  setSelectedDocuments(edited);
                                }}
                                value={document.name}
                              />
                            </div>
                            <textarea
                              cols={60}
                              rows={5}
                              onChange={(e) => {
                                const edited = selectedDocuments?.map((doc) => {
                                  if (doc.id === document.id) {
                                    return {
                                      id: doc.id,
                                      name: doc.name,
                                      description: e.target.value,
                                      message: doc.message,
                                    };
                                  }
                                  return doc;
                                });
                                setSelectedDocuments(edited);
                              }}
                              disabled={editDocument !== document?.id}
                              type="text"
                              value={document.description}
                              className="mb-0 mt-1 max-w w-100 border-0 mb-3"
                            />
                            {/* {(addMessageOnRequest === document?.id || document.message) && <textarea
													className='w-100'
													value={document?.message}
													onChange={(e) => {
														const edited = selectedDocuments?.map(doc => {
															if (doc.id === document.id) {
																return {
																	id: doc.id,
																	name: doc.name,
																	description: doc.description,
																	message: e.target.value
																}
															}
															return doc
														})
														setSelectedDocuments(edited)
													}}
												></textarea>}
												<p onClick={() => setAddMessageOnRequest(document?.id)} className='mt-1 mb-o text-primary cursor-pointer'>Add a Message</p> */}
                          </div>
                        </div>
                        <div className="d-flex gap-4 align-items-center justify-content-center">
                          <div className="d-flex align-items-center gap-2">
                            {editDocument && editDocument === document?.id ? (
                              <p
                                className="mb-0 mt-0 text-success fw-bold cursor-pointer"
                                onClick={() => setEditDocument("")}
                              >
                                Save
                              </p>
                            ) : (
                              <img
                                onClick={() => setEditDocument(document?.id)}
                                className="cursor-pointer icon"
                                src={EditDocumentSvg}
                                alt="edit"
                              />
                            )}
                            <img
                              onClick={() =>
                                handleDeleteSelectedDocument(document.id)
                              }
                              className="cursor-pointer icon"
                              src={BinSvg}
                              alt="delete"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div className="clearfix m-t-10">
                  <Link
                    className={`btn btn-primary  rounded-2 `}
                    data-bs-toggle="modal"
                    data-bs-target="#requestJobSingleDocumentsModal"
                  >
                    Request Single Document
                  </Link>
                </div>
                <div className="border-dashed rounded mt-3 bg-muted-200 pb-5 bg-color">
                  <div className="mt-5 d-flex flex-column justify-content-center align-items-center px-3">
                    <h3 className="text-center text-grey-400 fw-bold">
                      Request Forms and Supporting Documents
                    </h3>
                    <p className="text-center w-85 mt-2 text-2 ">
                      Pick from any of the pre-defined request templates or type
                      your own and save as template.
                      <br /> Your client can directly drag and drop files onto
                      your request to complete them.
                    </p>
                  </div>
                  <div className="row d-flex justify-content-start  card-cont">
                    <Link
                      to="#"
                      // className={`dropdown-item text-light invoiceModal`}
                      className="border-solid d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded"
                      data-bs-toggle="modal"
                      data-bs-target="#requestJobDocumentsModal"
                    >
                      <img
                        style={{ height: "40px" }}
                        src={PlusSvg}
                        alt="plus"
                      />
                      Create New
                    </Link>
                    {/* <div data-bs-toggle='modal'
										data-bs-target='#doqumentsList'
										aria-controls='doqumentsList' className='border-solid d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded'>
									</div> */}
                    {/* {defaultDocument.map((document) => {
                      return (
                        <div
                          key={document.id}
                          onClick={() => handleSelectDocument(document)}
                          className={`px-5 text-center border-solid d-flex flex-column ${
                            selectedDocumentIds.includes(document.id)
                              ? "border-primary"
                              : ""
                          } justify-content-center align-items-center gap-2 custom-card rounded`}
                        >
                          <img
                            style={{ height: "40px" }}
                            src={FileBlueSvg}
                            alt="plus"
                          />
                          {document.name}
                        </div>
                      );
                    })} */}
                    {getTaxProdocumentGroup?.data?.map((document) => {
                      return (
                        <div
                          onClick={() =>
                            handleSelectDocument(document?.docs_data?.documents)
                          }
                          className={`dynamic-form-card px-5 text-center border-solid ${
                            selectedDocumentIds.includes(document.id)
                              ? "border-primary"
                              : ""
                          } d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}
                        >
                          <img
                            onClick={(e) => {
                              e.stopPropagation();
                              setCustomModalOpen({
                                state: "delete-groupDocument",
                                data: document,
                              });
                              // handleDeleteTemplate(option?.id);
                            }}
                            className="delete"
                            src={DeleteIcon}
                            alt="delete-icon"
                          />

                          <img
                            style={{ height: "40px" }}
                            src={FileBlueSvg}
                            alt="plus"
                          />
                          {document?.docs_data?.groupName}
                        </div>
                      );
                    })}
                    <div className="w-100 dashed-border my-3"></div>
                    {getTaxProAdmindocumentGroup?.data?.map((document) => {
                      return (
                        <div
                          onClick={() =>
                            handleSelectDocument(document?.docs_data?.documents)
                          }
                          className={`px-5 text-center border-solid ${
                            selectedDocumentIds.includes(document.id)
                              ? "border-primary"
                              : ""
                          } d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}
                        >
                          <img
                            style={{ height: "40px" }}
                            src={FileBlueSvg}
                            alt="plus"
                          />
                          {document?.docs_data?.groupName}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {/* end updating missing code */}
            <div className="clearfix p-2 mt-4">
              <div className="form-check">
                <input
                  type="checkbox"
                  name="agreed"
                  className="form-check-input"
                  id="agreed"
                  value="1"
                  checked={agreement}
                  onChange={(e) => setAgreement(e.target.checked)}
                  disabled={true}
                />
                <label className="form-check-label" htmlFor="agreed">
                  Yes, I understand and agree to the Uncle Kam Terms of Service,
                  including the <Link to="/user-agreement">User Agreement</Link>{" "}
                  and <Link to="/privacy-policy">Privacy Policy</Link>.
                </label>
              </div>
            </div>
            <div className="clearfix p-2 mt-4 d-flex justify-content-end">
              <span
                className="btn btn-outline-primary me-3"
                onClick={() => navigate(-1)}
              >
                Cancel
              </span>
              <button
                className={`btn btn-primary${
                  Object.values(errors).indexOf(true) > -1 || !agreement
                    ? " disabled"
                    : ""
                }`}
                type="submit"
                ref={button}
              >
                {isDraft ? "Save" : "Publish"}
              </button>
            </div>
          </form>
        </div>

        <Modal
          title="Create Group Documents"
          bodyClass="px-5 py-4 text-center"
          trigger="requestJobDocumentsModal"
          showBtn={false}
          btnClass="requestJobDocumentsModalClose"
          size="lg"
        >
          <RequestJobDocumentForm
            closeClass="requestJobDocumentsModalClose"
            sendRequestHandle={(e) => handleRequest(e)}
            secondaryInputField={true}
            setGroupName={setGroupName}
            groupName={groupName}
            modalMessage={modalMessage}
            setmodalMessage={setmodalMessage}
            buttonText={"Create Document Group"}
            Heading={[
              { heading: "Name" },
              { heading: "Description" },
              { heading: "Edit" },
              { heading: "Delete" },
            ]}
          />
        </Modal>

        {/* request single document  */}
        <Modal
          title="Create  Documents"
          bodyClass="px-5 py-4 text-center"
          trigger="requestJobSingleDocumentsModal"
          showBtn={false}
          btnClass="requestJobSingleDocumentsModalClose"
          size="lg"
        >
          <RequestJobDocumentForm
            closeClass="requestJobSingleDocumentsModalClose"
            setGroupName={setGroupName}
            groupName={groupName}
            singleDocumentRequest={true}
            modalMessage={modalMessage}
            setmodalMessage={setmodalMessage}
            singleDocumentRequestHandle={(e) => singleDocumentRequestHandle(e)}
            buttonText={"Create Document"}
            Heading={[
              { heading: "Name" },
              { heading: "Description" },
              { heading: "Edit" },
              { heading: "Delete" },
            ]}
          />
        </Modal>
        <FormPreviewModal />
      </div>
    );
  } else {
    return <Preloader />;
  }
};

export default EditJob;

/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASEURL } from "../../../utilites";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../../Store/action";

const ProfileAddServicesModal = ({ reload, servicesData, setServicesData }) =>  {
    const dispatch = useDispatch();
    const [
       {services}
    ] = useSelector((state) => [state.dashboard]);
    const [selectedServices, setSelectedServices] = useState([]);



    const handleSubmit = async (e) => {
        e.preventDefault();

        dispatch(allActions.global.Loader(true));
        const endpoint = `${BASEURL}/api/professionals/profile/service`

        const method = "patch"
        const data = { selectedServices }

        try {
            await axios[method](endpoint, data);
            reload();
            // resetForm();
            dispatch(allActions.global.Loader(false));
            document.querySelector(".closeAddOrofileService").click();
        } catch (err) {
            console.error("Error saving education:", err);
            dispatch(allActions.global.Loader(false));
            document.querySelector(".closeAddOrofileService").click();
        }
    };


    const handleCheckboxChange = (v) => {

        const isSelected = selectedServices?.some((selected) => selected?.id ===  v?.id)
        if(isSelected){
             setSelectedServices((prev) => prev?.filter((prevService) => prevService?.id !== v?.id))
        }else{
            setSelectedServices((prev) => [...prev,{id:v?.id}])
        }
    }

    useEffect(() => {
        setSelectedServices(servicesData?.map((service) => {
            return {
                id:service?.id
            }
        }))
    }, [servicesData]);


    return (
        <div className="container text-start">
            {services?.map((v, i) => {
                return (
                    <div key={i} className="w-full d-flex align-items-start justfy-content-start gap-3 mt-3">
                        <input
                            className="form-check-input custom-checkbox"
                            type="checkbox"
                            id={`checkbox-${i}`}
                            checked={selectedServices?.find(serv => serv?.id === v.id)}
                            onChange={() => handleCheckboxChange(v)}
                        />
                        <label className="form-check-label text-start" htmlFor={`checkbox-${i}`}>
                            {v.name}
                        </label>
                    </div>
                );
            })}
            <div className={'d-flex justify-content-center align-items-center w-100 mt-3'}>
                <button className={'btn btn-primary'} onClick={handleSubmit}>Update services</button>
            </div>
        </div>
    );
};

export default ProfileAddServicesModal;

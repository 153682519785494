import { JOBS_ACTION_TYPE } from './Type';

const DEFAULT_STATE = {
	jobs: { message: '', status: 'idle', data: {} },
	cancelJobRequest: { message: '', status: 'idle', data: {} },
	markJobAsCompleted: { message: '', status: 'idle', data: {} },
	markJobAsClosed: { message: '', status: 'idle', data: {} },
	taskDocumentForm:{message: '', status: 'idle', data: {} },
	reminderDocumentForm:{message: '', status: 'idle', data: {} },
	reminderDocumentFormSingle:{message: '', status: 'idle', data: {} }
};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const jobs = (state = INITIAL_STATE, action) => {
	if (action.type === JOBS_ACTION_TYPE.getJobs) {
		return {
			...state,
			jobs: action.payload,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.resetJobs) {
		return {
			...state,
			jobs: DEFAULT_STATE.jobs,
		};
	}

	if (action.type === JOBS_ACTION_TYPE.cancelJobRequest) {
		return {
			...state,
			cancelJobRequest: action.payload,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.cancelJobRequestIdle) {
		return {
			...state,
			cancelJobRequest: DEFAULT_STATE.cancelJobRequest,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.markJobAsCompleted) {
		return {
			...state,
			markJobAsCompleted: action.payload,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.markJobAsCompletedIdle) {
		return {
			...state,
			markJobAsCompleted: DEFAULT_STATE.markJobAsCompleted,
		};
	}


	if (action.type === JOBS_ACTION_TYPE.markJobAsClosed) {
		return {
			...state,
			markJobAsClosed: action.payload,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.markJobAsClosedIdle) {
		return {
			...state,
			markJobAsClosed: DEFAULT_STATE.markJobAsClosed,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.taskDocumentFormIdle) {
		return {
			...state,
			taskDocumentForm: DEFAULT_STATE.taskDocumentForm,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.taskDocumentFormSuccess) {
		return {
			...state,
			taskDocumentForm: action.payload,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.taskDocumentFormError) {
		return {
			...state,
			taskDocumentForm: action.payload,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.reminderDocumentFormSingleIdle) {
		return {
			...state,
			reminderDocumentFormSingle: DEFAULT_STATE.reminderDocumentFormSingle,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.reminderDocumentFormSingleSuccess) {
		return {
			...state,
			reminderDocumentFormSingle: action.payload,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.reminderDocumentFormSingleError) {
		return {
			...state,
			reminderDocumentFormSingle: action.payload,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.reminderDocumentFormIdle) {
		return {
			...state,
			reminderDocumentForm: DEFAULT_STATE.reminderDocumentForm,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.reminderDocumentFormSuccess) {
		return {
			...state,
			reminderDocumentForm: action.payload,
		};
	}
	if (action.type === JOBS_ACTION_TYPE.reminderDocumentFormError) {
		return {
			...state,
			reminderDocumentForm: action.payload,
		};
	}

	return state;
};

export default jobs;

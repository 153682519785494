/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import axios from "axios";
import { BASEURL } from "../../../utilites";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../../Store/action";
import dayjs from 'dayjs'

const ProfileAddEducationModal = ({ reload, educationToEdit, setEducationToEdit }) => {
    const dispatch = useDispatch();
    const [
        { LoginData }
    ] = useSelector((state) => [state.user]);

    const [formData, setFormData] = useState({
        school: "",
        completed_at: "",
        // degree: "",
        state: "",
        description: "",
        achievement:""
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: false });    
    };

    const handleYearChange = (date, dateString) => {
        setFormData({ ...formData, completed_at: dateString });
        setErrors({ ...errors, completed_at: false });
    };

    const validateFields = () => {
        const newErrors = {};
        if (!formData.school) newErrors.school = true;
        if (!formData.completed_at) newErrors.completed_at = true;
        // if (!formData.degree) newErrors.degree = true;
        if (!formData.state) newErrors.state = true;
        if (!formData.description) newErrors.description = true;
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateFields()) return;

        dispatch(allActions.global.Loader(true));
        const endpoint = educationToEdit
            ? `${BASEURL}/api/professionals/profile/education`
            : `${BASEURL}/api/professionals/profile/education`;

        const method = Object.keys(educationToEdit)?.length > 0 ? "patch" : "put";
        const data = Object.keys(educationToEdit)?.length > 0
            ? { ...formData, id: educationToEdit.id }
            : { ...formData, user_id: LoginData?.userpro?.user_id };

        try {
            await axios[method](endpoint, data);
            reload();
            resetForm();
            setEducationToEdit({});
            dispatch(allActions.global.Loader(false));
            document.querySelector(".closeAddOrofileAducation").click();
        } catch (err) {
            console.error("Error saving education:", err);
            dispatch(allActions.global.Loader(false));
            document.querySelector(".closeAddOrofileAducation").click();
        }
    };

    const resetForm = () => {
        setFormData({
            school: "",
            completed_at: "",
            // degree: "",
            state: "",
            description: "",
            achievement: ""
        });
        setErrors({});
        // setEducationToEdit({});
    };

    useEffect(() => {
        if (educationToEdit) {
            setFormData({
                school: educationToEdit.school,
                completed_at: educationToEdit.completed_at,
                // degree: educationToEdit.degree,
                state: educationToEdit.state,
                description: educationToEdit.description,
                achievement: educationToEdit.achievement,
            });
        }
    }, [educationToEdit]);

    useEffect(() => {
        if(Object.keys(educationToEdit)?.length === 0){
            resetForm()
        }
    },[educationToEdit])

    return (
        <div className="container text-start">
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="school" className="form-label fw-bold text-black">
                        School
                    </label>
                    <input
                        type="text"
                        id="school"
                        name="school"
                        className={`form-control ${errors.school ? "is-invalid" : ""}`}
                        placeholder="School"
                        value={formData.school}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label fw-bold text-black">Year Completed</label>
                    <DatePicker
                        picker="year"
                        id="completed_at"
                        name="completed_at"
                        placeholder="Select Year"
                        className={`form-control ${errors.completed_at ? "is-invalid" : ""}`}
                        value={
                            formData.completed_at
                              ? typeof formData.completed_at === "string"
                                ? dayjs(formData.completed_at)
                                : formData.completed_at
                              : null
                          }
                        onChange={(date, dateString) => handleYearChange(date, dateString)}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        disabledDate={(current) =>
                            current && current.year() > moment().year()
                          } 
                    />
                </div>
                {/*<div className="mb-3">*/}
                {/*    <label htmlFor="degree" className="form-label fw-bold text-black">*/}
                {/*        Degree*/}
                {/*    </label>*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        id="degree"*/}
                {/*        name="degree"*/}
                {/*        className={`form-control ${errors.degree ? "is-invalid" : ""}`}*/}
                {/*        placeholder="Degree"*/}
                {/*        value={formData.degree}*/}
                {/*        onChange={handleInputChange}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="mb-3">
                    <label htmlFor="state" className="form-label fw-bold text-black">
                        Area of Study
                    </label>
                    <input
                        type="text"
                        id="state"
                        name="state"
                        className={`form-control ${errors.state ? "is-invalid" : ""}`}
                        placeholder="Area of Study"
                        value={formData.state}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="achievement" className="form-label text-black fw-bold">
                        Achievement
                    </label>
                    <input
                        type="text"
                        id="achievement"
                        name="achievement"
                        className={`form-control ${errors.achievement ? "is-invalid" : ""}`}
                        placeholder="Achievement"
                        value={formData.achievement}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label fw-bold text-black">
                        Description
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        className={`form-control ${errors.description ? "is-invalid" : ""}`}
                        placeholder="Description"
                        rows="3"
                        value={formData.description}
                        onChange={handleInputChange}
                    ></textarea>
                </div>
                <div className="d-flex justify-content-end gap-2">
                    <button
                        type="button"
                        className="btn text-muted border border-1 py-2 px-3 fw-bold "
                        onClick={() => {
                              document.querySelector(".closeAddOrofileAducation").click();
                        }}
                    >
                        Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                        {educationToEdit ? "Save Changes" : "Add Education"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ProfileAddEducationModal;

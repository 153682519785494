/* eslint-disable react-hooks/exhaustive-deps */

import { Fragment, useEffect, useState } from "react";
import ListPageWrapper from "../components/ListPageWrapper";
import axios from "axios";
import { BASEURL } from "../../../utilites";
import { useDispatch } from "react-redux";
import allActions from "../../../Store/action";
import { Link } from "react-router-dom";
import moment from "moment";
import SweetAlert2 from "react-sweetalert2";
import Input from "../../../components/forms/fields/Input";
import DeleteIcon2 from "../../../assets/img/delete1.svg";
import { IoCopyOutline,IoCopySharp } from "react-icons/io5";
import {
  setQueryParams,
  getQueryParam,
  removeQueryParam,
} from "../../../utilites";
import Modal from "../../../components/Dashboard/Modal";

const TokenList = () => {
  const dispatch = useDispatch();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertError, setAlertError] = useState(false);
  const [keys, setKeys] = useState([]);
  const [swalProps, setSwalProps] = useState({});
  const [selectedToken, setSelectedToken] = useState(null);
  const [keyword, setKeyword] = useState(getQueryParam("search") || "");
  const [tokenType, setTokenType] = useState(getQueryParam("type") || "");
  const [copied, setCopied] = useState(false);

  // Pagination
  const [perPage, setPerPage] = useState(getQueryParam("per_page") || 10);
  const [page, setPage] = useState(getQueryParam("page") || 1);

  const handleGetkeys = async (keyword) => {
    dispatch(allActions.global.Loader(true));
    try {
      const resp = await axios.get(`${BASEURL}/api/tokens`, {
        params: { fullname: keyword, type: tokenType, page, perPage },
      });
      setKeys(resp?.data?.data);
      dispatch(allActions.global.Loader(false));
      setAlertError(false)
    } catch (error) {
      console.log(error);
      dispatch(allActions.global.Loader(false));
    }
  };

  const handleDeleteKey = async (id) => {
    dispatch(allActions.global.Loader(true));
    try {
      await axios.delete(`${BASEURL}/api/tokens/${id}`);
      handleGetkeys();
      setAlertMessage('API token has been successfully deleted')
    } catch (error) {
      console.log("Error deleting token:", error);
    } finally {
      dispatch(allActions.global.Loader(false));
    }
  };

  const handleGenerateNewToken = async () => {
    dispatch(allActions.global.Loader(true));
    try {
      await axios.put(`${BASEURL}/api/tokens`);
      handleGetkeys();
      document.querySelector(".generateNewTokenModalClose").click();
      // setAlertMessage('A new API token has been successfully generated')
    } catch (error) {
      console.log("Error deleting token:", error);
    } finally {
      dispatch(allActions.global.Loader(false));
      document.querySelector(".generateNewTokenModalClose").click();
    }
  };

  const handleDeleteQuestionClick = (id) => {
    setSwalProps((prev) => ({
      ...prev,
      [`id-${id}`]: {
        show: true,
        showDenyButton: true,
        title: "Are you sure?",
        confirmButtonText: "Confirm",
        denyButtonText: "Cancel",
        text: "Do you want to delete this API token?",
        didClose: () =>
          setSwalProps((prev) => ({ ...prev, [`id-${id}`]: { show: false } })),
        onConfirm: () => handleDeleteKey(id),
      },
    }));
  };

  useEffect(() => {
    handleGetkeys(keyword, tokenType, page, perPage);
  }, [keyword, tokenType, page, perPage]);

  useEffect(() => {
    setTimeout(() => {
        setAlertMessage('')
    },2000)
  },[alertMessage])

  const filterByKeyword = (keyword) => {
    setKeyword(keyword);
    if (!keyword) {
      removeQueryParam("search");
    } else {
      setQueryParams("search", keyword);
    }
  };

  const filterByType = (type) => {
    setTokenType(type);
    if (!type) {
      removeQueryParam("type");
    } else {
      setQueryParams("type", type);
    }
  };

  const moveTo = (page) => {
    setPage(page);
    setQueryParams("page", page);
  };

  const changePerPage = (perPage) => {
    setPerPage(perPage);
    setQueryParams("per_page", perPage);
  };





  return (
    <ListPageWrapper
      heading={"Tokens"}
      dataState={{ loaded: true }}
      headerControls={
        <Fragment>
          <div className="col-md-10">
            <div className="row g-3">
              <div className="col-md-3">
                <Input
                  type="search"
                  value={keyword}
                  name="name"
                  inputClass="search-input border h-auto min-height-initial"
                  required={false}
                  showLabel={false}
                  placeholder="Search by fullname"
                  fn={filterByKeyword}
                  extraSetter={() => {
                    if (page !== 1) moveTo(1);
                  }}
                />
              </div>
              <div className="col-md-2">
                <select
                  style={{ minWidth: "200px" }}
                  className="form-select"
                  id="filterbymembershiptype"
                  name="membershipType"
                  onChange={(e) => {
                    filterByType(e.target.value);
                    if (page !== 1) moveTo(1);
                  }}
                  value={tokenType}
                >
                  <option value="">Token type</option>
                  <option value="5">Local</option>
                  <option value="1">Keap-Calendar</option>
                  <option value="2">Calendly</option>
                  <option value="3">Outlook-Calendar</option>
                  <option value="4">Google-Calendar</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <Link
              to="#"
              className="w-100 btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#generateNewTokenModal"
            >
              Generate new token
            </Link>
          </div>
        </Fragment>
      }
      alert={{ message: alertMessage, setMessage: setAlertMessage, alertError }}
      tableHeader={
        <Fragment>
          <th>ID</th>
          <th>User</th>
          <th>Type</th>
          <th>Key</th>
          <th>Created At</th>
          <th>Action</th>
        </Fragment>
      }
      tableBody={
        <Fragment>
          {keys?.data?.length > 0 &&
            keys?.data?.map((key) => {
              return (
                <tr className="align-middle" key={key.id}>
                  <th scope="row">{key.id}</th>
                  <td>
                    <Link
                      to={`/dashboard/users/${key?.user?.uuid}`}
                      className="text-decoration-none text-dark"
                    >{`${key?.user?.firstName} ${key?.user?.lastName}`}</Link>
                  </td>
                  <td>
                    {key?.type === 5
                      ? "Local"
                       : key?.type === 2
                      ? "Calendly"
                      : key?.type === 3
                      ? "Outlook-Calendar"
                      : key?.type === 1
                      ? "Keap"
                      : key?.type === 4
                      ? "Google-Calendar"
                      : ""}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#reviewTokenModal"
                    className="cursor-pointer"
                    onClick={() => setSelectedToken(key)}
                    style={{
                      filter: `${key?.type === 5 ? "blur(5px)" : "blur(0px)"}`,
                    }}
                  >
                    {key?.token_data?.access_token?.substring(0, 20)}
                  </td>
                  <td>{moment(key?.created_at).format("YYYY-MM-DD")}</td>
                
                 <div className='d-flex gap-5'>
                 <td style={{width:'0px'}}>
                    <Link
                      to="#"
                      onClick={() => {
                        setSelectedToken(key)
                        navigator.clipboard.writeText(key?.token_data?.access_token);
                        setCopied(true);
                        setTimeout(() => setCopied(false), 2000); 
                      }}
                      className={`dropdown-item text-gray`}
                    >
                     {copied && key?.id === selectedToken?.id &&  key?.id ? <IoCopySharp style={{fontSize:'24px'}} className="text-primary" /> : <IoCopyOutline style={{fontSize:'24px'}} className="text-primary" />}
                    </Link>
                  </td>
                  <td style={{width:'0px'}}>
                    <Link
                      to="#"
                      onClick={() => handleDeleteQuestionClick(key?.id)}
                          className={`dropdown-item text-gray`}
                    >
                      <img style={{height:'24px'}} src={DeleteIcon2} alt="Delete token" />
                      <SweetAlert2 {...swalProps[`id-${key?.id}`]} />
                    </Link>
                  </td>
                 </div>
                </tr>
              );
            })}
        </Fragment>
      }
      modals={
        <Fragment>
          <Modal
            title="Generate new token"
            bodyClass="p-4 text-center text-dark text-opacity-50"
            trigger="generateNewTokenModal"
            showBtn={false}
            btnClass="generateNewTokenModalClose"
          >
            <div className="mb-3 text-start">
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary mt-3 fw-bold w-50"
                  onClick={() => handleGenerateNewToken()}
                >
                  Generate
                </button>
              </div>
            </div>
          </Modal>
          <Modal
  title="Review Token"
  bodyClass="p-4 position-relative"
  trigger="reviewTokenModal"
  showBtn={false}
  btnClass="reviewTokenModalClose"
>
  <div className="text-start">
    {/* User Details */}
    <div className="d-flex align-items-center mb-3">
      <span className="fw-bold">User:</span>
      <span className="ms-2 text-primary">
        {`${selectedToken?.user?.firstName} ${selectedToken?.user?.lastName}`}
      </span>
    </div>

    {/* Token Section */}
    <div className="form-group position-relative">
      <label className="form-label fw-bold" htmlFor="tokenTextarea">
        Token:
      </label>
      <textarea
        id="tokenTextarea"
        className="form-control text-monospace pt-4"
        style={{
          height: "200px",
          resize: "none",
          backgroundColor: "#f8f9fa",
          border: "1px solid #dee2e6",
          borderRadius: "5px",
        }}
        value={selectedToken?.token_data?.access_token}
        readOnly
      ></textarea>

      <button
        className="btn btn-outline-secondary"
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          transform: "translate(-10%, 50%)",
          background: "white",
            color: "#797979",
            border: "1px solid #e9e9e9",
            padding: "5px 10px",
            borderRadius: "3px",
            fontSize: "12px",
            cursor: "pointer",
        }}
        onClick={() => {
          navigator.clipboard.writeText(selectedToken?.token_data?.access_token);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); 
        }}
      >
        {copied ? 'Copied' : 'Copy'}
      </button>
    </div>

    {copied && (
      <div className="text-success mt-2 text-center">Token copied to clipboard!</div>
    )}
  </div>
</Modal>
        </Fragment>
      }
      paginationProps={{
        pageCount: Math.ceil(keys?.total_count / perPage),
        currentPage: parseInt(page),
        setCurrentPage: moveTo,
        perPage: perPage,
        setPerPage: (pc) => {
          changePerPage(pc);
          if (page !== 1) moveTo(1);
        },
      }}
    ></ListPageWrapper>
  );
};

export default TokenList;

/* eslint-disable react-hooks/exhaustive-deps */
import SweetAlert2 from 'react-sweetalert2';
import { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Logo from '../../../assets/img/logo-dark.png';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../Store/action';
import { BsSearch, BsBell } from 'react-icons/bs';
import { SlArrowDown } from 'react-icons/sl';
import { FaUsers, FaUsersSlash } from 'react-icons/fa';
import { getQueryParam, removeQueryParam, setQueryParams } from '../../../utilites';
import ActivitiesWrapper from '../../../components/Dashboard/ActivitiesWrapper';
import UserAvatar from '../../../components/UserAvatar';
import { CALL_ACTION_TYPE } from '../../../Store/call/Type';
import { PAYOUT_ACTION_TYPE } from '../../../Store/payout/Type';
import { JOBS_ACTION_TYPE } from '../../../Store/jobs/Type';
import Alert from './Alert';
import { PLAID_ACTION_TYPE } from '../../../Store/plaid/Type';
import moment from 'moment';
import Input from '../../../components/forms/fields/Input';
//import ReactSelect from 'react-select';
const verificationNote = `ID Verification Missing. Please click the link to upload a valid ID as soon as possible to avoid account deactivation.`;
const paymentNote = `You have not add a payment method yet. Payouts and transfers are disabled until you can add a valid debit card or bank.`;

const filteringOptions = [
	{ id: 0, label: 'All time', value: 'all-time' },
	{ id: 1, label: 'Last 24 hours', value: 'last-24-hours' },
	{ id: 2, label: 'Last 7 days', value: 'last-7-days' },
	{ id: 3, label: 'Last 30 days', value: 'last-30-days' },
];

const Navbar = () => {
	const navigate = useNavigate();
	const [
		{ user,dashboard },
		{ stripeAccountStatus, stripeAccountStatusData },
		{ personalConnections, recentNotifications },{loginStatus,LoginData}
	] = useSelector((state) => [state, state.dashboard, state.firebase,state.user]);
	// Alert
	console.log("user specs", user?.LoginData?.usermembership)
	const swalDescriptionText =
		user?.LoginData?.userpro?.status_id === 3
			? 'Do you want to unlock your account?'
			: 'Do you want to lock  your account?';

	const [alertData, setAlertData] = useState([]);
	const [isSearchFieldExpanded, setIsSearchFieldExpanded] = useState(false);
	const [notificationCount, setNotificationCount] = useState(0);
	const myStatusId = user?.LoginData?.userpro?.status_id;
	const [period, ] = useState(getQueryParam('period') || filteringOptions[0].value);
	const [keyword, setKeyword] = useState(getQueryParam('search') || '');
	const [service, ] = useState(getQueryParam('service') || '');
	const [pro, ] = useState(
		getQueryParam('taxpro') ? { value: getQueryParam('taxpro'), label: '' } : null
	);
	const [assignedDate] = useState(
		getQueryParam('assigned_after') ? new Date(getQueryParam('assigned_after')) : null
	);
	const [dateRange,] = useState({
		startDate: getQueryParam('from') ? new Date(getQueryParam('from')) : null,
		endDate: getQueryParam('to') ? new Date(getQueryParam('to')) : null,
	});
	const [isFocused, setIsFocused] = useState(false);
	const [swalProps, setSwalProps] = useState({
		show: false,
		showDenyButton: true,
		title: 'Are you sure?',
		text: swalDescriptionText,

		confirmButtonText: 'Confirm',
		denyButtonText: 'Cancel',
	});

	const dispatch = useDispatch();
	const resetMessages = () => {
		if (!window.location.href.includes('dashboard/messages')) {
			dispatch(allActions.firebase.resetPersonalConnection());
			dispatch(allActions.firebase.resetReadPersonalChatsArray());
		}
		// Reset Anointment Response
		dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.audioCallTokenIdle }));
		dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.videoCallRoomIdle }));
	};
	const getAppointment = () => {
		// Reset Anointment Response
		dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.videoCallRoomIdle }));
	};
	const getJobDocuments = () => {
		if (!window.location.href.includes('dashboard/jobDocuments')) {
			dispatch(allActions.dashboard.resetTaxPlanning());
		}
	};

	useEffect(() => {
		dispatch(allActions.firebase.getNotificationsCount(user.LoginData.uuid));
		dispatch(allActions.firebase.getRecentNotifications(user.LoginData.uuid, setNotificationCount));
	}, [dispatch, user.LoginData?.uuid]);

	const toggleTaxProPause = () => {
		dispatch(allActions.user.toggleTaxProPause());
	};

	const handlePauseSwitchClick = () => {
		setSwalProps((prev) => ({
			...prev,
			show: true,
			text: swalDescriptionText,
			didClose: () => setSwalProps((prev) => ({ ...prev, show: false })),
			onConfirm: () => toggleTaxProPause(),
		}));
	};

	const handleSeen = () => {
		if (recentNotifications.loaded && recentNotifications.data.length > 0) {
			recentNotifications.data.forEach((notification) => {
				if (!notification.seen) {
					dispatch(allActions.firebase.seenNotification(user?.LoginData.uuid, notification.id));
				}
			});
		}
	};

	useEffect(() => {
		if (myStatusId && user?.LoginData?.isTaxPro && myStatusId !== 0) {
			dispatch(allActions.dashboard.getAccountStatus());
		}
	}, [dispatch, myStatusId, user?.LoginData?.isTaxPro]);

	// useEffect for check stripe account status and payment card status
	useEffect(() => {
		const alerts = [];

		if (stripeAccountStatus === true && myStatusId !== 0) {
			var checkVerification = stripeAccountStatusData?.data?.future_requirements?.errors?.find(
				(data) => {
					return data.requirement.indexOf('verification') !== -1;
				}
			);
			if(!checkVerification){
				checkVerification = stripeAccountStatusData?.data?.requirements?.errors?.find(
					(data) => {
						return data.requirement.indexOf('verification') !== -1;
					}
				);
			}
			if (checkVerification) {
				reset_plaid();
				alerts.push({
					notice: '',
					message: generateAlert({
						heading: verificationNote,
						callback: () => {
							navigate('/dashboard/settings', {
								state: { open: true, identity: '.openVerificationModal' },
							});
						},
					}),
					type: 'danger',
				});
			}
			let otherErrorsString = '';
			

			if(!stripeAccountStatusData?.data?.payouts_enabled && stripeAccountStatusData?.data?.requirements?.disabled_reason){
				alerts.push({
					notice: '',
					message: `You cannot receive payout.Because You need to complete your verification "${stripeAccountStatusData?.data?.requirements?.disabled_reason}"`,
					type: 'danger',
				});
			}
			// Iterate through the array and concatenate the strings
			stripeAccountStatusData?.data?.requirements?.errors?.forEach((obj) => {
				if (obj.requirement?.indexOf('verification') === -1) {
					otherErrorsString += obj.reason;
				}
			});

			stripeAccountStatusData?.data?.future_requirements?.errors?.forEach((obj) => {
				if (obj.requirement?.indexOf('verification') === -1) {
					otherErrorsString += obj.reason;
				}
			});
			if (otherErrorsString !== '') {
				alerts.push({
					notice: '',
					message: otherErrorsString,
					type: 'danger',
				});
			}
		}
		if (
			user?.LoginData?.isTaxPro &&
			user?.LoginData?.userpaymentmethod.length === 0 &&
			myStatusId !== 0
		) {
			alerts.push({
				notice: '',
				message: generateAlert({
					heading: paymentNote,
					callback: () => {
						navigate('/dashboard/settings', {
							state: { open: true, identity: '.openPaymentMethodPanel' },
						});
					},
				}),
				type: 'danger',
			});
		}
		if (
			user?.LoginData?.isTaxPro &&
			(!user?.LoginData?.avatar || user?.LoginData?.avatar?.length === 0) &&
			myStatusId !== 0
		) {
			alerts.push({
				notice: '',
				message: generateAlert({
					heading:
						'Most customers create trust when they can see the face of the professional they are about to work with. To upload your profile photo.',
					callback: () => {
						navigate('/dashboard/settings', {
							state: { open: true, identity: '.updateProfilePicture' },
						});
					},
				}),
				type: 'danger',
			});
		}
		if (user?.LoginData?.isTaxPro) {
			// filter membership data 
			let filteredMembershipData = user?.LoginData?.usermembership?.find(({ sub_endTime }) =>
				moment(sub_endTime).isAfter(moment())
			);
			// let trial = false;
			//    check for trial
			if (!filteredMembershipData) {
				var trialFilteredMembershipData = user?.LoginData?.usermembership?.find(({ trial_endTime }) =>
					moment(trial_endTime).isAfter(moment())
				);
				if (trialFilteredMembershipData) {
					// trial = true;
					filteredMembershipData = trialFilteredMembershipData;
				}
			} if (!filteredMembershipData) {
				// alerts.push({
				// 	notice: '',
				// 	message: generateAlert({
				// 		heading:
				// 			'You are no longer eligible to receive new Leeds as your Free Trial has ended! To subscribe to one of our paid memberships.',
				// 		callback: () => {
				// 			navigate('/dashboard/membership');
				// 		},
				// 	}),
				// 	type: 'danger',
				// });
			}

			// alerts.push({
			// 		notice: '',
			// 		message: generateAlert({
			// 			heading:
			// 				'Calling Services on Uncle Kam are currently undergoing maintenance, we will inform you once problem is fixed shortly',
			// 		}),
			// 		type: 'danger',
			// 	});

		}

		setAlertData(alerts);
	}, [
		myStatusId,
		navigate,
		stripeAccountStatus,
		stripeAccountStatusData?.data?.requirements?.currently_due,
		user?.LoginData?.isTaxPro,
		user?.LoginData?.userpaymentmethod,
	]);
	useEffect(() => {
		if(user.LoginData?.userpro?.status_id === 6){
			setAlertData(prev => [...prev,{
				notice: '',
				message: generateAlert({
					heading:
						`Your account is currently inactive. Please your Account Manager ${LoginData?.userpro?.manager}to regain access to your account.`
					
				}),
				type: 'danger',
			}])
		}
	},[navigate])

	const generateAlert = ({ heading, callback }) => {
		return (
			<span className="small">
				{heading}{' '}
				{callback &&
				<span onClick={() => callback()}>
					<Link role="button">Click Here</Link>
				</span>
				}
			</span>
		);
	};

	const reset_plaid = () => {
		dispatch(
			allActions.plaid.dispatchToStore({
				type: PLAID_ACTION_TYPE.plaidCreateIdle,
			})
		);
		dispatch(
			allActions.plaid.dispatchToStore({
				type: PLAID_ACTION_TYPE.plaidGetUploadIdle,
			})
		);
	};

	console.log("ROLES>>>",user?.LoginData);
	const handleLinkClick = () => {
		// Close navbar when link is clicked (remove show class if it exists)
		const navbar = document.querySelector('.navbar-collapse');
		if (navbar?.classList.contains('show')) {
			navbar.classList.remove('show');
		}
	};

	useEffect(() => {
		if (loginStatus) {
			dispatch(
				allActions.dashboard.getLeads(
					keyword,
					assignedDate,
					pro?.value,
					service,
					period,
					dateRange,
					1,
					10
				)
			);
		}
	}, [
		keyword,
	]);

	useEffect(() => {
		const navLinks = document.querySelectorAll('.nav-link');
		navLinks.forEach(link => {
		  link.addEventListener('click', () => {
			navLinks.forEach(otherLink => otherLink.classList.remove('active'));
			link.classList.add('active');
		  });
		});
		return () => {
		  navLinks.forEach(link => link.removeEventListener('click', () => {}));
		};
	  }, []);

	const filterByKeyword = (keyword) => {
		if(!isFocused || keyword !== ''){
			setIsFocused(true)
		}else{
			setIsFocused(false)
		}
		setKeyword(keyword);
		// Set query param
		if (!keyword) {
			removeQueryParam('search');
		} else {
			setQueryParams('search', keyword);
		}
	};

	return (
		<>
			<nav className="navbar navbar-expand-lg bg-white sticky-top" style={{ zIndex: 997 }}>
				<div className="container border-bottom border-light pb-0 pb-md-2">
					<Link className="navbar-brand" to="/">
						<img src={Logo} alt="Uncle Kam App" />
					</Link>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						{user?.isRegular ? (
							<ul className="navbar-nav left px-3 me-auto mb-2 mb-lg-0">
								<li className="nav-item">
									<NavLink className="nav-link" to="dashboard/" onClick={() => handleLinkClick()}>
										Dashboard
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink
										className="nav-link"
										to="dashboard/projects"
										onClick={() => {
											dispatch(allActions.invoice.resetCreateInvoice());
											dispatch(allActions.invoice.resetPayInvoice());
											dispatch({ type: JOBS_ACTION_TYPE.cancelJobRequestIdle });
											dispatch({ type: JOBS_ACTION_TYPE.markJobAsCompletedIdle });
											handleLinkClick();
										}}
									>
										Projects
									</NavLink>
								</li>
								<li className="nav-item" onClick={() => getAppointment()}>
									<NavLink
										className="nav-link"
										to="dashboard/appointments"
										onClick={() => handleLinkClick()}
									>
										Appointments
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink
										className="nav-link"
										to="dashboard/invoices"
										onClick={() => {
											dispatch({ type: 'paymentCardAddIdle' });
											handleLinkClick();
										}}
									>
										Invoices
									</NavLink>
								</li>
								<li className="nav-item" onClick={() => getJobDocuments()}>
									<NavLink
										className="nav-link"
										to="dashboard/documents"
										onClick={() => handleLinkClick()}
									>
										Documents
									</NavLink>
								</li>
								<li className="nav-item d-md-none">
									<Link
										className="nav-link text-danger"
										onClick={() => {
											dispatch(allActions.user.logout());
											dispatch(allActions.dashboard.reset());
											dispatch(allActions.firebase.reset());
										}}
									>
										Logout
									</Link>
								</li>
							</ul>
						) : (
							<ul className="navbar-nav px-3 me-auto mb-2 mb-lg-0">
								<li className="nav-item">
									<NavLink className="nav-link" to="dashboard/" onClick={() => handleLinkClick()}>
										Dashboard
									</NavLink>
								</li>

								<li className="nav-item dropdown">
									<Link
										to="#"
										className="nav-link dropdown-toggle"
										role="button"
										data-bs-toggle="dropdown"
									>
										Connect
										{personalConnections?.notification &&
										parseInt(personalConnections?.notification) > 0 ? (
											<span className="badge badge-notification bg-primary position-absolute top-0 end-0">
												{personalConnections?.notification}
											</span>
										) : (
											''
										)}
									</Link>
									<ul className="dropdown-menu">
										<li>
											<Link
												className="dropdown-item"
												to="dashboard/messages"
												onClick={() => {
													resetMessages();
													handleLinkClick();
												}}
											>
												Messages
												{personalConnections?.notification &&
												parseInt(personalConnections?.notification) > 0 ? (
													<span className="badge badge-notification bg-primary position-absolute top-1 end-1 mx-2">
														{personalConnections?.notification}
													</span>
												) : (
													''
												)}
											</Link>
										</li>
										<li>
											<Link
												className="dropdown-item"
												to="dashboard/calls"
												onClick={() => {
													resetMessages();
													handleLinkClick();
												}}
												target="_blank"
											>
												Calls
											</Link>
										</li>
									</ul>
								</li>

								<li className="nav-item" onClick={() => resetMessages()}>
									<NavLink
										className="nav-link"
										to="dashboard/leads"
										onClick={() => handleLinkClick()}
									>
										Leads
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink
										className="nav-link"
										to="dashboard/jobs"
										onClick={() => {
											dispatch({ type: JOBS_ACTION_TYPE.cancelJobRequestIdle });
											handleLinkClick();
										}}
									>
										Jobs
									</NavLink>
								</li>
								<li className="nav-item dropdown">
									<Link
										to="#"
										className="nav-link dropdown-toggle"
										role="button"
										data-bs-toggle="dropdown"
									>
										Invoices & Payouts
									</Link>
									<ul className="dropdown-menu">
										<li>
											<Link
												className="dropdown-item"
												to="dashboard/invoices"
												onClick={() => {
													dispatch({ type: 'paymentCardAddIdle' });
													handleLinkClick();
												}}
											>
												Invoices
											</Link>
										</li>
										<li>
											<Link
												className="dropdown-item"
												to="dashboard/invoices/payouts"
												onClick={() => {
													dispatch({ type: PAYOUT_ACTION_TYPE.balanceIdle });
													dispatch({ type: PAYOUT_ACTION_TYPE.getPayoutIdle });
													dispatch({ type: PAYOUT_ACTION_TYPE.requestPayoutIdle });
													handleLinkClick();
												}}
											>
												Payouts
											</Link>
										</li>
										{user?.LoginData?.isAdmin ||
										user?.LoginData?.isAccountManager ||
										user?.LoginData?.isSupport ? (
											<li onClick={() => getAppointment()}>
												<Link
													className="dropdown-item"
													to="dashboard/invoices/disputes"
													onClick={() => handleLinkClick()}
												>
													Disputes
												</Link>
											</li>
										) : null}
									</ul>
								</li>
								<li className="nav-item" onClick={() => getAppointment()}>
									<NavLink
										className="nav-link"
										to="dashboard/appointments"
										onClick={() => handleLinkClick()}
									>
										Appointments
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink
										className="nav-link"
										to="dashboard/documents"
										onClick={() => handleLinkClick()}
									>
										Documents
									</NavLink>
								</li>
								{(user?.isAdmin || user?.isAccountManager) && (
									<li
										className="nav-item"
										onClick={() => {
											dispatch(allActions.dashboard.stripeConnectionReset());
										}}
									>
										<NavLink
											className="nav-link"
											to="dashboard/users"
											onClick={() => handleLinkClick()}
										>
											Users
										</NavLink>
									</li>
								)}
								<li className="nav-item d-md-none mt-3">
									<Link
										className="nav-link text-danger"
										onClick={() => {
											dispatch(allActions.user.logout());
											dispatch(allActions.dashboard.reset());
											dispatch(allActions.firebase.reset());
										}}
									>
										Logout
									</Link>
								</li>
							</ul>
						)}
						<ul className="navbar-nav align-items-center">
							{user.LoginData.roles[0].name !== 'customer' && user.LoginData.roles[0].name !== 'accountmanager' && <li
								className={`nav-item d-none d-md-inline Navbar__search${
									isSearchFieldExpanded ? ' Navbar__search--active' : ''
								}`}
							>
								
									<BsSearch
										style={{ fontSize: 18 }}
										onClick={setIsSearchFieldExpanded}
										data-tooltip-id="search-tooltip"
									className='z-3'
								/>

								<div className='position-relative'>
									<Input
										type="search"
										value={keyword}
										name="name"
										placeholder="Search leads"
										fn={filterByKeyword}
										showLabel={false}
										inputClass="search-input form-control border h-auto min-height-initial"
										onLeave={() => setTimeout(() => setIsFocused(false), 200)}
										autoComplete="off"
									required={false}
									/>
									{isFocused && <ul className='border border-1 position-absolute bg-white w-100 p-1 rounded d-flex flex-column' onChange={(e) => { }}>
										{dashboard.leads.data.map((lead) => (
											<Link to={`/dashboard/leads/${lead.uuid}`}  className='bg-hover text-decoration-none p-1 text-dark w-100 text-left text-start' key={lead.id} value={lead.uuid}>
												{lead.user.firstname} {lead.user.lastname}
											</Link>
										))}
									</ul>}
								</div>
							</li>}
							<li className="nav-item d-none d-md-inline">
								<div
									className="nav-link btn position-relative"
									data-tooltip-id="notifications-tooltip"
								>
									<BsBell style={{ fontSize: 19 }} />
									{recentNotifications.loaded && notificationCount > 0 && (
										<span className="badge badge-notification bg-primary position-absolute top-0 end-0">
											{notificationCount > 9 ? '9+' : notificationCount}
											<span className="visually-hidden">unread messages</span>
										</span>
									)}
								</div>
							</li>
							{user?.LoginData.isTaxPro && (
								<li className="nav-item d-none d-md-inline">
									<button
										className="nav-link btn"
										onClick={handlePauseSwitchClick}
										data-tooltip-id="user-lock-tooltip"
									>
										{user?.LoginData?.userpro?.status_id === 3 ? (
											<FaUsersSlash size={24} color={'#b3b3b3'} />
										) : (
											<FaUsers size={24} />
										)}
										<SweetAlert2 {...swalProps} />
									</button>
								</li>
							)}
							<li className="nav-item d-none d-md-inline ms-4">
								<div className="btn-group mr-50" >
									<button
										type="button"
										className="btn border-0"
										data-bs-toggle="dropdown"
										aria-expanded="false"
										data-tooltip-id="user-avatar-tooltip"
									>
										<UserAvatar
											avatar={user?.LoginData.avatar}
											alt={`${user?.LoginData.firstname} ${user?.LoginData.lastname}`}
											className="rounded-circle me-2"
											width="34"
										/>
										<SlArrowDown />
									</button>
									<ul className="dropdown-menu">
										<li>
											<Link className="dropdown-item disabled" href="##">
												{`${user?.LoginData.firstname.toUpperCase()} ${user?.LoginData.lastname.toUpperCase()}`}
											</Link>
										</li>
										{user.LoginData.isTaxPro && (
											<li>
												<Link
													className="dropdown-item"
													to={`profile/${user?.LoginData?.firstname + user?.LoginData?.lastname}/${user?.LoginData?.userpro?.uuid}`}
													target="_blank"
												>
													Public profile
												</Link>
											</li>
										)}
										{user?.LoginData?.isTaxPro && (
											<li>
												<Link
													className="dropdown-item"
													to="dashboard/billing"
													onClick={() => handleLinkClick()}
												>
													Billing
												</Link>
											</li>
										)}
									{(user?.LoginData?.isAdmin) && (
											<li>
												<Link
													className="dropdown-item"
													to="dashboard/adminbilling"
													onClick={() => handleLinkClick()}
												>
												Billing
												</Link>
											</li>
										)}
									{(user?.LoginData?.isSupport || user?.LoginData?.isAdmin) && (
										<>
											<li>
												<Link
													className="dropdown-item"
													to="dashboard/tasks"
													onClick={() => handleLinkClick()}
												>
												Tasks Manager
												</Link>
											</li>
											<li>
												<Link
													className="dropdown-item"
													to="dashboard/form-list"
													onClick={() => handleLinkClick()}
												>
												Forms Manager
												</Link>
											</li>
											<li>
												<Link
													className="dropdown-item"
													to="dashboard/documentgroup-list"
													onClick={() => handleLinkClick()}
												>
												Documents Manager
												</Link>
											</li>
											<li>
												<Link
													className="dropdown-item"
													to="dashboard/tokens"
													onClick={() => handleLinkClick()}
												>
												API Keys Manager
												</Link>
											</li>
											<li>
												<Link
													className="dropdown-item"
													to="dashboard/recordings"
													onClick={() => handleLinkClick()}
												>
												Recordings
												</Link>
											</li>
											</>
										)}

										{!user.LoginData.isCustomer && (
											<li>
												<Link
													className="dropdown-item"
													to="https://help.unclekam.com/en"
													target="_blank"
												>
													Help Center
												</Link>
											</li>
										)}
										<li>
											<Link
												className="dropdown-item"
												to="/dashboard/settings"
												onClick={() => {
													dispatch({ type: 'paymentCardAddIdle' });
													reset_plaid();
												}}
											>
												Settings
											</Link>
										</li>
										<li>
											<Link
												className="dropdown-item text-danger"
												onClick={() => {
													dispatch(allActions.user.logout());
													dispatch(allActions.dashboard.reset());
													dispatch(allActions.firebase.reset());
												}}
											>
												Logout
											</Link>
										</li>
									</ul>
								</div>
							</li>
							<Tooltip
								className="bg-black text-white"
								id="search-tooltip"
								place="bottom"
								content="Search"
							/>
							<Tooltip
								className="bg-white shadow border-secondary-100 rounded"
								style={{ zIndex: 9 }}
								id="notifications-tooltip"
								place="bottom"
								openOnClick={true}
								clickable={true}
								afterShow={() => {
									setNotificationCount(0);
								}}
								afterHide={handleSeen}
								content={<ActivitiesWrapper />}
							/>
							<Tooltip
								className="bg-black text-white"
								id="notifications-tooltip"
								place="bottom"
								content="Notifications"
							/>
							<Tooltip
								className="bg-black text-white"
								id="user-lock-tooltip"
								place="bottom"
								content="Lock/Unlock Account"
							/>
							<Tooltip
								className="bg-black text-white"
								id="user-avatar-tooltip"
								place="bottom"
								content={`${user?.LoginData.firstname} ${user?.LoginData.lastname}`}
							/>
						</ul>
					</div>
				</div>
			</nav>
			<div className="clearfix w-100 d-flex align-items-center justify-content-center flex-column mt-2">
				{alertData &&
					alertData?.length > 0 &&
					alertData?.map((data) => {
						return (
							<Alert
								className={'container'}
								notice={data?.notice}
								message={data?.message}
								type={data?.type}
							/>
						);
					})}
			</div>
		</>
	);
};

export default Navbar;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BiUserPin } from "react-icons/bi";
import { CgPassword } from "react-icons/cg";
import { BsFillShieldLockFill, BsCreditCard } from "react-icons/bs";
import { MdAddBusiness } from "react-icons/md";
import { FaWpforms, FaBusinessTime, FaPlug } from "react-icons/fa";
import { PiIdentificationCard } from "react-icons/pi";
import Header from "../dashboard/components/Header";
import Footer from "../dashboard/components/Footer";
import Alert from "../dashboard/components/Alert";
import Modal from "../../components/Dashboard/Modal";
import Offcanvas from "../../components/Dashboard/Offcanvas";
import ProfileImageForm from "../../components/forms/ProfileImageForm";
import ProfileForm from "../../components/forms/ProfileForm";
import UpdatePassword from "../../components/forms/UpdatePassword";
import TwoAuthVerificationForm from "../../components/forms/TwoAuthVerificationForm";
import EditCurrentProBusinessInfoForm from "../../components/forms/EditCurrentProBusinessInfoForm";
import UserAvailabilityForm from "../../components/forms/UserAvailabilityForm";
import allActions from "../../Store/action";
import PaymentForm from "../../components/forms/PaymentForm";
import UserAvatar from "../../components/UserAvatar";
import DriverLicenseForm from "../../components/forms/DriverLicenseForm";
import { PLAID_ACTION_TYPE } from "../../Store/plaid/Type";
import VerificationForm from "../../components/forms/VerificationForm";
import Integrations from "./components/Integrations";
import Message from "../../components/forms/fields/Message";
import { IoIosLock } from "react-icons/io";
import Select from 'react-select';
import { FaFileSignature,FaCheckCircle } from "react-icons/fa";



const Settings = () => {
  const dispatch = useDispatch();
  const params = useLocation();
  const [message, setMessage] = useState({ show: false });
  const [alertMessage, setAlertMessage] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [verificationAlert, setVerificationAlert] = useState("");
  const [resAwait, setResAwait] = useState();
  const [
    { loginStatus, LoginData,esignRequest },
    { services, stripeAccountStatus, stripeAccountStatusData },
    { plaidCreate, plaidGetUpload },
  ] = useSelector((state) => [state.user, state.dashboard, state.plaid]);
  const [dashboard] = useSelector((state) => [state.dashboard]);
  const [taxProId,setTaxProId] = useState(LoginData?.isAdmin ? null : LoginData?.uuid)
  const [redirectedToDynamic,setRedirectedToDynamic] = useState('https://app.unclekam.com/thankyou')
  const [redirectedTo,setRedirectedTo] = useState('https://app.unclekam.com/thankyou')
  const [isCopied,setIsCopied] = useState({type:'js', copy:false})
  const [isFormUpdated, setIsFormUpdated] = useState(false)
  const [options, setOptions] = useState([]); 
  const [inputValue, setInputValue] = useState(''); 
  const [embeddedFormEnv,setEmbeddedFormEnv] = useState(false)
  const [leadTag,setLeadTag] = useState('')
  const [leadTagDynamic,setLeadTagDynamic] = useState('')




  

  // --------------------------------------------
  // HOOKS (UseEffect used For Different Purposes)
  // --------------------------------------------

  //  *****--HOOKS To Create Plaid Link For Verification--****
  useEffect(() => {
    setMessage({ show: false });
    // if Success

    if (plaidCreate?.status === true) {
      dispatch(allActions.global.Loader(false));
    }
    if (plaidCreate?.status === true && resAwait) {
      setErrorAlert("");
      setResAwait();
      // openMe(plaidCreate?.data?.shareable_url);
      if (document.querySelector(".openVerificationModal")) {
        document.querySelector(".openVerificationModal").click();
      }
      dispatch(allActions.global.Loader(false));
    }
    // if Failure
    if (plaidCreate?.status === false && resAwait) {
      setResAwait();
      dispatch(allActions.global.Loader(false));
      setErrorAlert("Plaid Error :" + plaidCreate?.message);
    }
  }, [plaidCreate, plaidCreate?.status, plaidCreate?.message, resAwait]);

  useEffect(() => {
    if (stripeAccountStatus === true) {
      // Variable to store the concatenated string
      // let otherErrorsString = "";
      let verificationErrorString = "";
      // Iterate through the array and concatenate the strings
      stripeAccountStatusData?.data?.requirements?.errors?.forEach((obj) => {
        // if (obj.requirement?.indexOf("verification") === -1) {
        // 	otherErrorsString += obj.reason;
        // }
        if (obj.requirement?.indexOf("verification") !== -1) {
          verificationErrorString += obj.reason;
        }
      });
      stripeAccountStatusData?.data?.future_requirements?.errors?.forEach(
        (obj) => {
          // if (obj.requirement?.indexOf("verification") === -1) {
          // 	otherErrorsString += obj.reason;
          // }
          if (obj.requirement?.indexOf("verification") !== -1) {
            verificationErrorString += obj.reason;
          }
        }
      );
      // setErrorAlert(otherErrorsString);
      setVerificationAlert(verificationErrorString);
    }
  }, [stripeAccountStatusData, stripeAccountStatus]);
// create esignRequest for tax pro
useEffect(() => {
	// if Success
	if (esignRequest?.status === true) {
		setMessage({ show: false });
    dispatch(allActions.global.Loader(false));    
	}

	// if Error
	if (esignRequest?.status=== false) {
		// Show Message Alert
		setMessage({
			show: true,
			type: 'Error',
			text: esignRequest.message,
		});
    dispatch(allActions.global.Loader(false));
	}
}, [esignRequest]);

  const user = useSelector((state) => state.user);
  const [otpForm, setOtpForm] = useState(false);
  const [twoAuthEnabled, setTwoAuthEnabled] = useState(undefined);
  const [phoneNo, setPhone] = useState("");

  useEffect(() => {
    CloseModalOverlay();
  }, []);
  useEffect(() => {
    if (loginStatus) {
    }
  }, [loginStatus, LoginData]);
  // Functio To Reset Enable 2 Auth Form on Open
  const resetForm = () => {
    setPhone(LoginData?.phone);
    setMessage({ show: false });
    setOtpForm(false);
    dispatch(allActions.factAuth.resetEnableSendOtp());
    dispatch(allActions.factAuth.resetEnableVerifyOtp());
    dispatch(allActions.factAuth.resetOldSendOtp());
    dispatch(allActions.factAuth.resetOldVerifyOtp());
    if (LoginData.authorization === "0") {
      setTwoAuthEnabled(false);
    } else {
      setTwoAuthEnabled(true);
    }
  };
  const CloseModalOverlay = () => {
    try {
      // Remove "modal-open" class from the body tag
      document.body.classList.remove("modal-open");
      // Reset the inline styles on the body tag
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";

      // Remove the modal backdrop element
      var backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.parentNode.removeChild(backdrop);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (params?.state?.openModal) {
      const modalOpenBtn = document.querySelector(params?.state?.openModal);
      if (modalOpenBtn) modalOpenBtn?.click();
      window.history.replaceState({}, document.title);
      return;
    }

    if (params?.state?.open && params?.state?.identity) {
      const modalBtn = document.querySelector(params?.state?.identity);

      // if modal verification
      if (params?.state?.identity === ".openVerificationModal") {
        checkVerification();
        window.history.replaceState({}, document.title);
        return;
      }
      if (modalBtn) modalBtn.click();
      window.history.replaceState({}, document.title);
    }
    if (params?.state?.identity === "keapVerification") {
      document.querySelector(".openIntegrationPanel")?.click();
      if (params?.state?.keapVerificationStatus) {
        setMessage({
          show: true,
          type: "Success",
          text: "Integrated successfully",
        });
      } else {
        setMessage({
          show: true,
          type: "Error",
          text: params?.state?.keapVerification?.message,
        });
      }
      window.history.replaceState({}, document.title);
      return;
    }
    if (params?.state?.identity === "calendlyVerification") {
      document.querySelector(".openIntegrationPanel")?.click();
      if (params?.state?.calendlyVerificationStatus) {
        setMessage({
          show: true,
          type: "Success",
          text: "Calendly integrated successfully",
        });
      } else {
        setMessage({
          show: true,
          type: "Error",
          text: params?.state?.calendlyVerificationError,
        });
      }
      window.history.replaceState({}, document.title);
      return;
    }
    if (params?.state?.identity === "outlookCalendarVerification") {
      document.querySelector(".openIntegrationPanel")?.click();
      if (params?.state?.outlookCalendarVerificationStatus) {
        setMessage({
          show: true,
          type: "Success",
          text: "Outlook-Calendar integrated successfully",
        });
      } else {
        console.log(
          "@333",
          params?.state?.outlookCalendarVerification?.message
        );
        setMessage({
          show: true,
          type: "Error",
          text: params?.state?.outlookCalendarVerificationError,
        });
      }
      window.history.replaceState({}, document.title);
      return;
			
		}
		if (params?.state?.identity === 'googlecalendarverification') {
			document.querySelector('.openIntegrationPanel')?.click();
			if (params?.state?.googleCalendarVerificationStatus) {
				setMessage({
					show: true,
					type: 'Success',
					text: 'Google-Calendar integrated successfully',
				});
			} else {
				console.log("@333",params?.state?.outlookCalendarVerification?.message);
				setMessage({
					show: true,
					type: 'Error',
					text: params?.state?.googleCalendarVerificationError,
				});
			}
			window.history.replaceState({}, document.title);
			return;
			
    }
  }, [params?.state]);
  // Function To Reset Plaid Store & Loading & Messages
  const reset_All = () => {
    setMessage({ show: false });
    dispatch(allActions.global.Loader(false));
    dispatch(
      allActions.plaid.dispatchToStore({
        type: PLAID_ACTION_TYPE.plaidCreateIdle,
      })
    );
    dispatch(
      allActions.plaid.dispatchToStore({
        type: PLAID_ACTION_TYPE.plaidGetUploadIdle,
      })
    );
  };
  const checkVerification = () => {
    reset_All();
    setResAwait(true);
    dispatch(allActions.global.Loader(true));
    dispatch(allActions.plaid.createPlaid());
    // Check Validity
  };
const createSignRequest=()=>{
  dispatch(allActions.global.Loader(true));
  dispatch(allActions.user.signRequestIdle());
  dispatch(allActions.user.signRequest());

}
  console.log(
    "@333",
    plaidGetUpload?.status,
    plaidCreate?.data?.status === "success"
  );

  const scriptCode = `
  <div id="tpg-form-wrapper"
  ${
    (LoginData?.isAdmin || LoginData?.isAccountManager) && leadTag
      ? `data-source="${leadTag}"`
      : " "
  }
  ${
    (LoginData?.isAdmin || LoginData?.isAccountManager) && embeddedFormEnv
      ? `testing="${embeddedFormEnv}"`
      : ""
  } data-title="tpg-form-wrapper" data-redirect="${redirectedTo}" pro-id="${taxProId}"></div>
  <script src="https://embed.unclekam.com/tpg-form.js"></script>
`;
  const cssCode = `<link href="https://embed.unclekam.com/tpg-form.css" rel="stylesheet" />`;

  const handleCopyJsCode = () => {
    navigator.clipboard
      .writeText(scriptCode)
      .then(() => {
        setIsCopied({ type: "js", copy: true });
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

    const handleCopyCssCode = () => {
      navigator.clipboard.writeText(cssCode).then(() => {
       setIsCopied({type:'css',copy:true})
       setTimeout(() => {
        setIsCopied(false)
       },1000)
      }).catch(err => {
        console.error("Failed to copy: ", err);
      });
    };

    const fetchTaxPros = async (value) => {
      dispatch(allActions.dashboard.getUsers(value, null, 3, null, 1, 10));
    };

    useEffect(() => {
      setOptions(
       [...dashboard?.users?.data?.map((user) => ({
        label: `${user?.firstname} ${user?.lastname}`,
        value: user?.uuid,
      })),{
        label: `None`,
        value: null,
      }]
      );
    }, [dashboard]);

    const handleInputChange = (value) => {
      setInputValue(value);
      fetchTaxPros(value);
    };
  
    const handleSelectChange = (selectedOption) => {
       setTaxProId(selectedOption ? selectedOption.value : null)
    };
  
    useEffect(() => {
      setIsFormUpdated(true)
      setTimeout(() => {
      setIsFormUpdated(false)
      },2000)
    },[redirectedTo, taxProId, leadTag])

    useEffect(() => {
      if(LoginData?.isAdmin){
        fetchTaxPros(inputValue);
      }
    },[])
    const checktaxProSignStatus =async()=>{
      dispatch(allActions.global.Loader(true))
          setTimeout(async() => {
            dispatch(allActions.user.ReGetUserData())
            setTimeout(() => {
              dispatch(allActions.global.Loader(false))              
            }, 2000);
          }, 4000);
        }
  return (
    <div className="clearfix px-3">
      <Header name="Profile Settings" />
      <div className="container page">
        <div className="clearfix pt-4 text-center mb-4">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="clearfix mb-5">
                <Link
                  to="#"
                  className="d-inline-block mb-3 updateProfilePicture"
                  data-bs-toggle="modal"
                  data-bs-target="#updateProfilePicture"
                >
                  <UserAvatar
                    avatar={LoginData?.avatar}
                    alt={`${user.firstname} ${user.lastname}`}
                    className="img-fluid rounded-circle border border-gray border-4"
                    style={{ width: 120, height: 120 }}
                  />
                </Link>
                <h1>Settings</h1>
                <p>Click on each section below to update your site settings.</p>
              </div>
              {alertMessage && (
                <Alert
                  message={alertMessage}
                  clickHandler={() => setAlertMessage("")}
                />
              )}
              {errorAlert && (
                <Alert
                  message={errorAlert}
                  type="danger"
                  clickHandler={() => setErrorAlert("")}
                />
              )}
              {/* {user.isTaxPro && LoginData?.userpaymentmethod.length === 0 && (
								<Alert
									notice="You have not add a payment method yet."
									message="Payouts and transfers are disabled until you can add a debit card or bank."
									type="danger"
								/>
							)} */}
              <div className="row text-center g-3">
                <div className="col-md-4">
                  {LoginData?.userpro?.status_id === 6 ? (
                    <div className="card border-1 border-light p-4 shadow-hover">
                      <div className="card-body">
                        <IoIosLock
                          className="fs-1"
                          style={{ color: "#a8a8a8" }}
                        />
                        <small className="d-block text-dark text-opacity-50 mt-2">
                          Edit your profile info*.
                        </small>
                      </div>
                    </div>
                  ) : (
                    <Link
                      to="#"
                      className="d-block text-decoration-none"
                      data-bs-toggle="modal"
                      data-bs-target="#editProfile"
                    >
                      <div className="card border-1 border-light p-4 shadow-hover">
                        <div className="card-body">
                          <BiUserPin className="text-primary fs-1" />
                          <small className="d-block text-dark text-opacity-50 mt-2">
                            Edit your profile info.
                          </small>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
                <div className="col-md-4">
                  {LoginData?.userpro?.status_id === 6 ? (
                    <div className="card border-1 border-light p-4 shadow-hover">
                      <div className="card-body">
                        <IoIosLock
                          className="fs-1"
                          style={{ color: "#a8a8a8" }}
                        />
                        <small className="d-block text-dark text-opacity-50 mt-2">
                          Change Password
                        </small>
                      </div>
                    </div>
                  ) : (
                    <Link
                      to="#"
                      className="d-block text-decoration-none text-dark"
                      data-bs-toggle="modal"
                      data-bs-target="#changePassword"
                    >
                      <div className="card border-1 border-light p-4 shadow-hover">
                        <div className="card-body">
                          <CgPassword className="text-primary fs-1" />
                          <small className="d-block text-dark text-opacity-50 mt-2">
                            Change Password
                          </small>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
                <div className="col-md-4">
                  {LoginData?.userpro?.status_id === 6 ? (
                    <div className="card border-1 border-light p-4 shadow-hover">
                      <div className="card-body">
                        <IoIosLock
                          className="fs-1"
                          style={{ color: "#a8a8a8" }}
                        />
                        <small className="d-block text-dark text-opacity-50 mt-2">
                          {LoginData?.authorization !== "0"
                            ? "2F Auth Enabled"
                            : "Enable 2F Auth"}
                        </small>
                      </div>
                    </div>
                  ) : (
                    <Link
                      to="#"
                      className="d-block text-decoration-none text-dark"
                      data-bs-toggle="modal"
                      data-bs-target="#enable2FactorAuth"
                      onClick={() => resetForm()}
                    >
                      <div className="card border-1 border-light p-4 shadow-hover">
                        <div className="card-body">
                          <BsFillShieldLockFill
                            className={`text-primary fs-1`}
                          />
                          <small className="d-block text-dark text-opacity-50 mt-2">
                            {LoginData?.authorization !== "0"
                              ? "2F Auth Enabled"
                              : "Enable 2F Auth"}
                          </small>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
                {user.isTaxPro && (
                  <div className="col-md-4">
                    {LoginData?.userpro?.status_id === 6 ? (
                      <div className="card border-1 border-light p-4 shadow-hover">
                        <div className="card-body">
                          <IoIosLock
                            className="fs-1"
                            style={{ color: "#a8a8a8" }}
                          />
                          <small className="d-block text-dark text-opacity-50 mt-2">
                            Update Business Info
                          </small>
                        </div>
                      </div>
                    ) : (
                      <Link
                        to="#"
                        className="d-block text-decoration-none text-dark"
                        data-bs-toggle="modal"
                        data-bs-target="#updateBusinessInfo"
                      >
                        <div className="card border-1 border-light p-4 shadow-hover">
                          <div className="card-body">
                            <MdAddBusiness className="text-primary fs-1" />
                            <small className="d-block text-dark text-opacity-50 mt-2">
                              Update Business Info
                            </small>
                          </div>
                        </div>
                      </Link>
                    )}
                  </div>
                )}
                {LoginData?.userpro?.status_id === 6 ? (
                  <div className="col-md-4">
                    <div className="card border-1 border-light p-4 shadow-hover">
                      <div className="card-body">
                        <IoIosLock
                          className="fs-1"
                          style={{ color: "#a8a8a8" }}
                        />
                        <small className="d-block text-dark text-opacity-50 mt-2">
                          Payment Info
                        </small>
                      </div>
                    </div>
                  </div>
                ) : (
                  (user.isTaxPro || user?.isRegular) && (
                    <div className="col-md-4">
                      <Link
                        to="#"
                        className="d-block text-decoration-none text-dark openPaymentMethodPanel"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#openPaymentMethodPanel"
                        aria-controls="openPaymentMethodPanel"
                        onClick={() => {
                          dispatch(allActions.payment.resetAddPayment());
                          dispatch(allActions.payment.resetModifyPayment());
                          dispatch(allActions.invoice.resetCreateCustomer());
                        }}
                      >
                        <div className="card border-1 border-light p-4 shadow-hover">
                          <div className="card-body">
                            <BsCreditCard className="text-primary fs-1" />
                            <small className="d-block text-dark text-opacity-50 mt-2">
                              Payment Info
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                )}
                {LoginData?.userpro?.status_id === 6 ? (
                  <div className="col-md-4">
                    <div className="card border-1 border-light p-4 shadow-hover">
                      <div className="card-body">
                        <IoIosLock
                          className="fs-1"
                          style={{ color: "#a8a8a8" }}
                        />
                        <small className="d-block text-dark text-opacity-50 mt-2">
                          User Availability
                        </small>
                      </div>
                    </div>
                  </div>
                ) : (
                  (user.isAdmin || user.isTaxPro || user.isAccountManager) && (
                    <div className="col-md-4">
                      <Link
                        to="#"
                        className="d-block text-decoration-none text-dark userAvailablityForm"
                        data-bs-toggle="modal"
                        data-bs-target="#userAvailability"
                      >
                        <div className="card border-1 border-light p-4 shadow-hover">
                          <div className="card-body">
                            <FaBusinessTime className="text-primary fs-1" />
                            <small className="d-block text-dark text-opacity-50 mt-2">
                              User Availability
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                )}
                {LoginData?.userpro?.status_id === 6 ? (
                  <div className="col-md-4">
                    <div className="card border-1 border-light p-4 shadow-hover">
                      <div className="card-body">
                        <IoIosLock
                          className="fs-1"
                          style={{ color: "#a8a8a8" }}
                        />
                        <small className="d-block text-dark text-opacity-50 mt-2">
                          Embeddable Form
                        </small>
                      </div>
                    </div>
                  </div>
                ) : (
                  (user.isAdmin || user.isTaxPro || user.isAccountManager) && (
                    <div className="col-md-4">
                      <Link
                        to="#"
                        className="d-block text-decoration-none text-dark"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#openEmbeddableFormPanel"
                        aria-controls="openEmbeddableFormPanel"
                      >
                        <div className="card border-1 border-light p-4 shadow-hover">
                          <div className="card-body">
                            <FaWpforms className="text-primary fs-1" />
                            <small className="d-block text-dark text-opacity-50 mt-2">
                              Embeddable Form
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                )}
                {LoginData?.userpro?.status_id === 6 ? (
                  <div className="col-md-4">
                    <div className="card border-1 border-light p-4 shadow-hover">
                      <div className="card-body">
                        <IoIosLock
                          className="fs-1"
                          style={{ color: "#a8a8a8" }}
                        />
                        <small className="d-block text-dark text-opacity-50 mt-2">
                          Verify Identity
                        </small>
                      </div>
                    </div>
                  </div>
                ) : (
                  user.isTaxPro && (
                    <div className="col-md-4">
                      <Link
                        to="#"
                        className="d-block text-decoration-none text-dark openVerificationModal"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#openVerificationModal"
                        aria-controls="openVerificationModal"
                      ></Link>
                      <Link className="d-block text-decoration-none text-dark">
                        <div
                          className="card border-1 border-light p-4 shadow-hover"
                          onClick={() => checkVerification()}
                        >
                          <div className="card-body">
                            <PiIdentificationCard className="text-primary fs-1" />
                            <small className="d-block text-dark text-opacity-50 mt-2">
                              Verify Identity
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                )}
                <div className="col-md-4">
                  {LoginData?.userpro?.status_id === 6 ? (
                    <div className="card border-1 border-light p-4 shadow-hover">
                      <div className="card-body">
                        <IoIosLock
                          className="fs-1"
                          style={{ color: "#a8a8a8" }}
                        />
                        <small className="d-block text-dark text-opacity-50 mt-2">
                          Integrations
                        </small>
                      </div>
                    </div>
                  ) : (
                    <Link
                      to="#"
                      className="d-block text-decoration-none text-dark openIntegrationPanel"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#openIntegrationPanel"
                      aria-controls="openIntegrationPanel"
                    >
                      <div className="card border-1 border-light p-4 shadow-hover">
                        <div className="card-body">
                          <FaPlug className="text-primary fs-1" />
                          <small className="d-block text-dark text-opacity-50 mt-2">
                            Integrations
                          </small>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
                {LoginData?.userpro?.status_id === 6 ? (
                  <div className="col-md-4">
                    <div className="card border-1 border-light p-4 shadow-hover">
                      <div className="card-body">
                        <IoIosLock
                          className="fs-1"
                          style={{ color: "#a8a8a8" }}
                        />
                        <small className="d-block text-dark text-opacity-50 mt-2">
                         Your Sign
                        </small>
                      </div>
                    </div>
                  </div>
                ) : (
                  user.isTaxPro && (
                    <div className="col-md-4">
                      <Link className="d-block text-decoration-none text-dark"
                               data-bs-toggle="modal"
                               data-bs-target="#opentaxProSignDocumentModal"
                               >
                        <div
                          className="card border-1 border-light p-4 shadow-hover"
                          onClick={() => createSignRequest()}
                        >
                          <div className="card-body">
                            <FaFileSignature className="text-primary fs-1" />
                           {LoginData?.userpro?.sign_document_id  && <FaCheckCircle color="green" size={17} className="position-absolute"/>}
                            <small className="d-block text-dark text-opacity-50 mt-2">
                            Your Sign
                            </small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        title="Update Profile Picture"
        bodyClass="p-4 text-center text-dark text-opacity-50"
        btnText="Update"
        btnClass="profilePictureModalClose"
        trigger="updateProfilePicture"
        showBtn={false}
      >
        <ProfileImageForm
          setAlert={setAlertMessage}
          userAvatar={LoginData.avatar}
          userId={LoginData.id}
          isAdmin={LoginData.isAdmin}
          userFullName={`${LoginData.firstname} ${LoginData.lastname}`}
        />
      </Modal>
      <Modal
        title="Edit Profile"
        bodyClass="p-4"
        btnText="Update Profile"
        trigger="editProfile"
        showBtn={false}
      >
        <ProfileForm data={LoginData} />
      </Modal>
      <Modal
        title="Change Password"
        bodyClass="p-4"
        btnText="Update Password"
        trigger="changePassword"
        showBtn={false}
      >
        <UpdatePassword data={LoginData} />
      </Modal>
      <Modal
        title="2 Factor Authentication"
        bodyClass="p-4 "
        style={{ marginTop: 100 }}
        btnText="Enable 2FA"
        trigger="enable2FactorAuth"
        showBtn={false}
        centerClass={"centerClass"}
      >
        <TwoAuthVerificationForm
          phoneNo={phoneNo}
          setPhone={setPhone}
          otpForm={otpForm}
          setOtpForm={setOtpForm}
          LoginData={LoginData}
          twoAuthEnabled={twoAuthEnabled}
          setTwoAuthEnabled={setTwoAuthEnabled}
          CloseModalOverlay={CloseModalOverlay}
          message={message}
          setMessage={setMessage}
        />
      </Modal>
      {user.isTaxPro && (
        <Modal
          title="Update Business Info"
          bodyClass="p-4"
          btnText="Update Business Info"
          trigger="updateBusinessInfo"
          showBtn={false}
          btnClass="updateBusinessInfoModalClose"
        >
          <div className="clearfix text-center text-dark text-opacity-50">
            <EditCurrentProBusinessInfoForm
              userData={user.LoginData}
              reloadUser={() => dispatch(allActions.user.ReGetUserData)}
              setAlert={setAlertMessage}
              services={services}
            />
          </div>
        </Modal>
      )}
      <Modal
        title="Add New Payment Method"
        bodyClass="p-4"
        btnText="Add Payment"
        trigger="addPaymentMethod"
        showBtn={false}
      >
        <div className="clearfix text-center text-dark text-opacity-50">
          <p>Payment Form Here</p>
        </div>
      </Modal>
      {(user.isAdmin || user.isTaxPro || user.isAccountManager) && (
        <Modal
          title="Set Your Availability"
          bodyClass="p-4"
          btnText="Save Changes"
          trigger="userAvailability"
          showBtn={false}
          btnClass="userAvailabilityModalClose"
          size="lg"
        >
          <UserAvailabilityForm
            reloadUser={() => dispatch(allActions.user.ReGetUserData)}
            setAlert={setAlertMessage}
            data={LoginData?.availability?.time_ranges}
            currentTimezone={LoginData?.settings?.timezone}
            calendlyData={
              LoginData?.tokens?.find((token) => token.type === 2)?.token_data
            }
            user_allow_appointments={LoginData?.settings?.allow_appointments}
          />
        </Modal>
      )}
      <Offcanvas
        header="Payment Methods"
        position="end"
        trigger="openPaymentMethodPanel"
        style={{ width: 600 }}
      >
        <PaymentForm invoice={user?.isRegular ? true : undefined} />
        <div className="w-100 d-flex align-items-center justify-content-center">
          {user?.isRegular && (
            <Link
              className="d-block text-align-center"
              to="#paymentForm"
              data-bs-toggle="modal"
            >
              Add New Payment Method
            </Link>
          )}
        </div>
      </Offcanvas>
      <Offcanvas
        header="Embeddable Form "
        position="end"
        trigger="openEmbeddableFormPanel"
        style={{ width: 600 }}
      >
        <div className="clearfix p-4">
          {LoginData?.isAdmin ? (
            <div className="mb-3">
              <label
                htmlFor="TaxProId"
                className="clearfix text-dark text-opacity-0 text-dark mb-2"
              >
                Tax Pro
              </label>
              <Select
                id="user_id"
                className="question-input"
                value={options.find((opt) => opt.value === taxProId) || null}
                options={options}
                onChange={handleSelectChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                placeholder="Search and select a Tax Pro"
                isClearable
                isSearchable
              />
            </div>
          ) : (
            <div className="d-flex flex-column mb-3">
              <label
                htmlFor="TaxProId"
                className="clearfix text-dark text-opacity-0 text-dark"
              >
                Tax Pro ID field
              </label>
              <input
                value={taxProId}
                className="form-control mt-2"
                type="text"
                id="TaxProId"
                disabled
              />
            </div>
          )}
          <div className="d-flex flex-column mb-3">
            <label
              htmlFor="RedirectedTo"
              className="clearfix text-dark text-opacity-0 text-dark"
            >
              Where should user be redirected to after submitting the form
            </label>
            <input
              onChange={(e) => setRedirectedToDynamic(e.target.value)}
              value={redirectedToDynamic}
              className="form-control mt-2"
              type="text"
              id="RedirectedTo"
            />
          </div>
          {LoginData?.isAdmin &&
            <div className="d-flex flex-column mb-3">
              <label
                htmlFor="TaxProId"
                className="clearfix text-dark text-opacity-0 text-dark"
              >
                Tag
              </label>
              <input
                value={leadTagDynamic}
                onChange={(e) => setLeadTagDynamic(e.target.value)}
                className="form-control mt-2"
                type="text"
                id="TaxProId"
                placeholder="Optional"
              />
            </div>
          }
          {isFormUpdated && (
            <div className="text-primary mb-2">
              The form has been updated. You can copy the code below.
            </div>
          )}
          <div className="d-flex justify-content-start">
            <button
              onClick={() => {
                setRedirectedTo(redirectedToDynamic);
                setLeadTag(leadTagDynamic)
              }}
              className="btn btn-primary btn-lg"
            >
              Update Form
            </button>
          </div>
         { (LoginData?.isAdmin || LoginData?.isAccountManager) && 
          <div className="d-flex align-items-center align-items-end justify-content-end gap-3 mt-5 px-3">
          <label htmlFor="switch" className="text-muted fw-bold">Test environment</label>
          <div class="custom-switch">
            <input
              type="checkbox"
              id="switch"
              class="switch-input"
              checked={embeddedFormEnv  }
              onChange={(e) => setEmbeddedFormEnv(e.target.checked)}
            />
            <label for="switch" class="switch-label"></label>
          </div>
          </div>
         }
          <div className="clearfix text-dark text-opacity-50 mb-5 mt-5">
            <p className="text-dark">
              Copy and Paste this in the header of your page
            </p>
            <pre className="p-0 m-0 text-start bg-light">
              <div
                style={{
                  margin: "20px",
                  fontFamily: "Arial, sans-serif",
                  position: "relative",
                }}
              >
                <pre
                  className="pt-4 m-0 bg-light text-start"
                  style={{
                    background: "#f4f4f4",
                    // padding: "20px 0",
                    borderRadius: "5px",
                    overflowX: "auto",
                    position: "relative",
                  }}
                >
                  {cssCode}
                </pre>
                <button
                  onClick={handleCopyCssCode}
                  className="bg-wite fw-bold"
                  style={{
                    position: "absolute",
                    top: "-15px",
                    right: "5px",
                    background: "white",
                    color: "#797979",
                    border: "1px solid white",
                    padding: "5px 10px",
                    borderRadius: "3px",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                >
                  {isCopied?.copy && isCopied?.type === "css"
                    ? "Copied"
                    : "Copy"}
                </button>
              </div>
            </pre>
          </div>
          <div className="clearfix text-dark text-opacity-50">
            <p className="text-dark">
              Copy and Paste the below code anywhere in the body of your page
              you want the form to appear
            </p>
            <pre className="p-0 m-0 text-start bg-light">
              <div
                style={{
                  margin: "20px",
                  fontFamily: "Arial, sans-serif",
                  position: "relative",
                }}
              >
                <pre
                  className="p-0 m-0 bg-light text-start"
                  style={{
                    background: "#f4f4f4",
                    padding: "0",
                    borderRadius: "5px",
                    overflowX: "auto",
                    position: "relative",
                  }}
                >
                  {scriptCode}
                </pre>
                <button
                  onClick={handleCopyJsCode}
                  className="bg-wite fw-bold"
                  style={{
                    position: "absolute",
                    top: "-15px",
                    right: "5px",
                    background: "white",
                    color: "#797979",
                    border: "1px solid white",
                    padding: "5px 10px",
                    borderRadius: "3px",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                >
                  {isCopied?.copy && isCopied?.type === "js"
                    ? "Copied"
                    : "Copy"}
                </button>
              </div>
            </pre>
          </div>
        </div>
      </Offcanvas>
      <Offcanvas
        header="Integrations"
        position="end"
        trigger="openIntegrationPanel"
        style={{ width: 600 }}
      >
        {message.show ? <Message message={message} /> : <></>}
        <Integrations
          user={LoginData}
          keapVerification={params?.state?.keapVerificationStatus}
          calendlyVerification={params?.state?.calendlyVerificationStatus}
          googleCalendarVerification={
            params.state?.googleCalendarVerificationStatus
          }
          outlookCalendarVerification={
            params?.state?.outlookCalendarVerificationStatus
          }
        />
      </Offcanvas>
      <Offcanvas
        header="Verify Identity"
        position="end"
        trigger="openVerificationModal"
        style={{ width: 500 }}
      >
        {plaidGetUpload?.status !== "progress" &&
        plaidCreate?.data?.status === "success" ? (
          <>
            {verificationAlert && (
              <Alert
                message={verificationAlert}
                type="danger"
                clickHandler={() => setVerificationAlert("")}
              />
            )}
            <DriverLicenseForm />
          </>
        ) : (
          <VerificationForm
            note="Please complete your verification on generated link, when you have completed your verification on plaid click Verify identity again to continue the process."
            steps={false}
          />
        )}
        {plaidGetUpload?.status === "progress" && (
          <VerificationForm
            note="Please complete your verification on generated link, when you have completed your verification on plaid click Verify identity again to continue the process."
            steps={false}
          />
        )}
      </Offcanvas>
      <Modal
				// title="Document Viewer"
				bodyClass="p-4 text-center text-dark text-opacity-50"
				trigger="opentaxProSignDocumentModal"
				showBtn={false}
				btnClass="opentaxProSignDocumentModalClose"
				size="xl"
        zIndex='none'
        onClose={()=>{
          checktaxProSignStatus()

        }}
			>
						{esignRequest?.status === false && message && (
							<Message message={message} />
						)}
        <div style={{height:'70vh'}}>
<iframe src={esignRequest?.data?.url}  width="100%" 
    height="100%" 
    title="Full Screen Iframe"

    ></iframe>

        </div>
</Modal>
    </div>
  );
};

export default Settings;

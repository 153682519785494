import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BASEURL } from '../../../utilites'
import moment from 'moment'
import { MdNotificationImportant } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { getQueryParam, setQueryParams } from '../../../utilites';
import ListPageWrapper from '../components/ListPageWrapper';

const JobNotes = ({ job, fetchNotes, notes, notesCount }) => {
  const [render, setRender] = useState('all-notes')
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [isImportant, setIsImportant] = useState(false)
  const [loading, setLoading] = useState(false)      
  const [selectedNoteId, setSelectedNoteId] = useState(null)
  const [editNote, setEditNote] = useState(false)
  const jobId = job?.id
  const [perPage, setPerPage] = useState(getQueryParam('per_page') || 12);
  const [page, setPage] = useState(getQueryParam('page') || 1);

  const moveTo = (page) => {
    setPage(page);
    // Set query param
    setQueryParams('page', page);
  };

  const changePerPage = (perPage) => {
    setPerPage(perPage);
    // Set query param
    setQueryParams('per_page', perPage);
  };

  const handleSubmit = async (e) => {
    const note = { title, content, isImportant }
    try {
      setLoading(true)
      axios.post(`${BASEURL}/api/jobs/${jobId}/notes`, note)
      setTitle('')
      setContent('')
      setIsImportant(false)
      // fetchNotes(false)
      setTimeout(() => {
        setLoading(false)
        setRender('all-notes')
      }, 1000)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  }


  const handleDeleteNote = async (noteId) => {
    setLoading(true)
    try {
      await axios.delete(`${BASEURL}/api/jobs/${noteId}/deleteNote`)
      setTimeout(() => {
        fetchNotes(false, page, perPage)
        setLoading(false)
      }, 1000)
    } catch (error) {
      console.log(error)
    }
  }
  const handleEditNote = async (e) => {
    const note = { title, content, isImportant }
    try {
      setLoading(true)
      axios.patch(`${BASEURL}/api/jobs/${selectedNoteId}/editNote`, note)
      setTitle('')
      setContent('')
      setIsImportant(false)
      fetchNotes(false, page, perPage)
      setTimeout(() => {
        setLoading(false)
        setRender('all-notes')
      }, 1000)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  }

  useEffect(() => {
    if (render === 'all-notes') {
      fetchNotes(false, page, perPage)
      setEditNote(false)
      setTitle('')
      setContent('')
      setIsImportant(false)
    } else if (render === 'important-notes') {
      fetchNotes(true, page, perPage)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render])

  useEffect(() => {
    if (editNote && selectedNoteId) {
      const noteToEdit = notes.find(note => note?.id === selectedNoteId)
      setTitle(noteToEdit?.title)
      setContent(noteToEdit?.content)
      setIsImportant(noteToEdit?.isImportant)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editNote])

  useEffect(() => {
    fetchNotes(false, page, perPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage])

  return (
    <ListPageWrapper
            showFooter={false}
            alert={{ message: null, setMessage: {} }}
            dataState={{
              loaded:{},
              fetching:{},
              }}
              paginationProps={{
                pageCount: Math.ceil(notesCount / perPage),
                currentPage: parseInt(page),
                setCurrentPage: moveTo,
                perPage: perPage,
                setPerPage: (pc) => {
                  changePerPage(pc);
                  if (page !== 1) moveTo(1);
      },
    }}
     headerControls={
      <div class="page-content container note-has-grid">
        <ul class="nav nav-pills p-3 bg-white mb-1   rounded-pill align-items-center">
          <li class="nav-item cursor-pointer">
            <div class={`nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 ${render === 'all-notes' ? 'active' : ''}`} id="all-category">
              <i class="icon-layers mr-1"></i><span class=" d-md-block" onClick={() => setRender('all-notes')}>All Notes</span>
            </div>
          </li>
          <li class="nav-item cursor-pointer">
            <div class={`nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 ${render === 'important-notes' ? 'active' : ''}`} id="note-important" onClick={() => setRender('important-notes')}> <i class="icon-tag mr-1"></i><span class="d-md-block">Important</span></div>
          </li>
          <li class="nav-item ml-auto cursor-pointer" onClick={() => setRender('add-note')}>
            <div class={`nav-link btn-primary rounded-pill d-flex align-items-center px-3 ${render === 'add-note' ? 'active' : ''}`} id="add-notes"> <i class="icon-note m-1" ></i><span class="d-md-block font-14">Add Notes</span></div>
          </li>
        </ul>
        <p className='text-muted px-3'>Only you can see these notes,  so keep track of important details, reminders, and insights for this job.</p>
        {(render === 'all-notes' || render === 'important-notes') && <div class="tab-content bg-transparent">
          <div id="note-full-container" class="note-has-grid row  justify-content-center justify-content-md-start">
            {notes.length > 0 && notes?.map((note) => {
              return <div key={note?.id} class="col-md-4 single-note-item all-category note-important mt-2" >
                <div key={note?.id} class="card card-body" style={{ background: note?.isImportant ? 'rgb(255 206 206 / 50%)' : '' }}>
                  <span class="side-stick"></span>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h5 class="note-title text-truncate w-75 mb-0" data-noteheading="Give salary to employee">{note?.title}<i class="point fa fa-circle ml-1 font-10"></i></h5>
                    {note?.isImportant && <MdNotificationImportant style={{ fontSize: '20px', color: 'rgba(255, 80, 80, 0.5)' }} />}
                  </div>
                  <p class="not   e-date font-12 text-muted">{moment(note?.updated_at).format('MMM D YYYY')}</p>
                  <div class="note-content">
                    <p class="note-inner-content text-muted" data-notecontent="Blandit tempus porttitor aasfs. Integer posuere erat a ante venenatis.">{note?.content}</p>
                  </div>
                  <div class="d-flex justify-content-end gap-2">
                    <CiEdit onClick={() => {
                      setEditNote(true)
                      setSelectedNoteId(note?.id)
                      setRender('add-note')
                    }} style={{ color: 'gray', fontSize: '17px' }} className='cursor-pointer' />
                    {loading && selectedNoteId === note?.id ? <div class="spinner-border spinner-border-sm" role="status">
                    </div> :
                      <MdDeleteOutline onClick={() => {
                        handleDeleteNote(note?.id)
                        setSelectedNoteId(note?.id)
                      }} style={{ color: 'gray', fontSize: '17px' }} className='cursor-pointer' />
                    }
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>}
        {render === 'add-note' && <div>
          <div className="form-group">
            <label htmlFor="noteTitle">Title</label>
            <input
              type="text"
              className="form-control mt-2"
              id="noteTitle"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="noteContent">Content</label>
            <textarea
              className="form-control mt-2"
              id="noteContent"
              rows="3"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="form-group mt-3">
            <label htmlFor="noteCategory">Category</label>
            <select
              className="form-control mt-2"
              id="noteCategory"
              value={isImportant ? 'Important' : 'not-important'}
              onChange={(e) => {
                if (e.target.value === 'important') {
                  setIsImportant(true)
                } else {
                  setIsImportant(false)
                }
              }}
              required
            >
              <option className='d-none' value="important">Important</option>
              <option value="important">Important</option>
              <option value="not-important">Not-important</option>
            </select>
          </div>
          {loading && !editNote ? <div class="spinner-border text-primary mt-3" role="status">
          </div> : !editNote ? <button onClick={(e) => {
            e.preventDefault()
            handleSubmit()
          }} className="btn btn-primary mt-3">
            Add Note
          </button> : ''}
          {loading && editNote && selectedNoteId ? <div class="spinner-border text-primary mt-3" role="status">
          </div> : editNote ? <button onClick={(e) => {
            e.preventDefault()
            handleEditNote(selectedNoteId)
          }} className="btn btn-primary mt-3">
            Edit Note
          </button> : ''}

        </div>}
      </div>
     }
    >
    </ListPageWrapper>
  )
}
export default JobNotes
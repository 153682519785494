/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */


import React, {useEffect, useState} from "react";
// import Layout from "../components/Layout";
import StarSvg from "../assets/img/blue-star.svg";
import FileSvg from "../assets/img/file-text.svg";
import EditSvg from "../assets/img/editSvg.svg";
import DeleteSvg from "../assets/img/deleteSvg.svg";
import AddSvg from "../assets/img/addSvg.svg";

import Modal from "../components/Dashboard/Modal";
import ProfileAboutModal from "../components/forms/Modal/ProfileAboutModal";
import ProfileAddLanguageModal from "../components/forms/Modal/ProfileAddLanguageModal";
import ProfileAddEducationModal from "../components/forms/Modal/ProfileAddEducationModal";
import ProfileAddCertificateModal from "../components/forms/Modal/ProfileAddCertificateModal";
import {useParams,Link} from "react-router-dom";
import axios from "axios";
import {BASEURL} from "../utilites";
import UserAvatar from "../components/UserAvatar";
import {useSelector, useDispatch} from "react-redux";
import allActions from "../Store/action";
import dayjs from "dayjs";
import ProfileAddServicesModal from "../components/forms/Modal/ProfileAddServicesModal";
import SweetAlert2 from "react-sweetalert2";
import Pagination from "../components/Pagination";
import {
    setQueryParams,
    getQueryParam,
} from '../utilites';
import { moneyFormatter } from "../utilites";


const Profile = () => {
    const {uuid} = useParams();
    const [
        { user }
    ] = useSelector((state) => [state,state.user]);
    // eslint-disable-next-line no-unused-vars
    const [workHistory, setWorkHistory] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [education, setEducation] = useState([]);
    const [educationToEdit,setEducationToEdit] = useState({})
    // eslint-disable-next-line no-unused-vars
    const [certifications, setCertifications] = useState([]);
    const [certificationToEdit,setCertificationToEdit] = useState({})
    // eslint-disable-next-line no-unused-vars
    const [languages, setLanguages] = useState([]);
    const [languageToEdit, setLanguageToEdit] = useState({})
    const [taxProPublicDetails, setTaxProPublicDetails] = useState({})
    const [publicView,setPublicView] = useState(false)
    const [about,setAbout] = useState({})
    const dispatch = useDispatch()
    const [services,setServices] = useState([]);
    const [swalProps, setSwalProps] = useState({});
    // Pagination
    const [perPage, setPerPage] = useState(getQueryParam('per_page') || 10);
    const [page, setPage] = useState(getQueryParam('page') || 1);
    const [readMore,setReadMore] = useState(true)

    const getTaxProfessionalDetails = () => {
        dispatch(allActions.global.Loader(true))
        axios
            .get(`${BASEURL}/api/professionals/public-details/${uuid}`)
            .then((response) => {
                setTaxProPublicDetails(response.data);
                dispatch(allActions.global.Loader(false))
            })
            .catch((error) => {
                console.error(error);
                dispatch(allActions.global.Loader(false))
            });

    }

    const getTaxProLanguages = () => {
        dispatch(allActions.global.Loader(true))
        axios
            .get(`${BASEURL}/api/professionals/profile/languages/${uuid}`)
            .then((response) => {
                setLanguages(response?.data?.data);
                 dispatch(allActions.global.Loader(false))
            })
            .catch((error) => {
                console.error(error);
                dispatch(allActions.global.Loader(false))
            });
    }
    const handleTaxProLanguageDelete = (id) => {
        dispatch(allActions.global.Loader(true))
        axios
            .delete(`${BASEURL}/api/professionals/profile/languages/${id}`)
            .then((response) => {
                getTaxProLanguages()
                dispatch(allActions.global.Loader(false))
            })
            .catch((error) => {
                console.error(error);
                dispatch(allActions.global.Loader(false))
            });
    }

    const getTaxProCertifications = () => {
        dispatch(allActions.global.Loader(true))
        axios
            .get(`${BASEURL}/api/professionals/profile/certifications/${uuid}`)
            .then((response) => {
                setCertifications(response?.data?.data);
                dispatch(allActions.global.Loader(false))
            })
            .catch((error) => {
                console.error(error);
                dispatch(allActions.global.Loader(false))
            });
    }

    const getAbout = () => {
        dispatch(allActions.global.Loader(true))
        axios
            .get(`${BASEURL}/api/professionals/profile/about/${uuid}`)
            .then((response) => {
                setAbout(response?.data);
                dispatch(allActions.global.Loader(false))
            })
            .catch((error) => {
                console.error(error);
                dispatch(allActions.global.Loader(false))
            });
    }
    const getWorkHistory = (page, perPage) => {
        dispatch(allActions.global.Loader(true));
        const url = `${BASEURL}/api/professionals/profile/workhistory/${uuid}?page=${page}&per_page=${perPage}`;
        axios
            .get(url)
            .then((response) => {
                setWorkHistory(response?.data?.data);
                dispatch(allActions.global.Loader(false));
            })
            .catch((error) => {
                console.error(error);
                dispatch(allActions.global.Loader(false));
            });
    };

    const getEducation = () => {
        dispatch(allActions.global.Loader(true))
        axios
            .get(`${BASEURL}/api/professionals/profile/education/${uuid}`)
            .then((response) => {
                setEducation(response?.data?.data);
                dispatch(allActions.global.Loader(false))
            })
            .catch((error) => {
                console.error(error);
                dispatch(allActions.global.Loader(false))
            });
    }
    const handleEducationDelete = (id) => {
        dispatch(allActions.global.Loader(true))
        axios
            .delete(`${BASEURL}/api/professionals/profile/education/${id}`)
            .then((response) => {
                getEducation()
                dispatch(allActions.global.Loader(false))
            })
            .catch((error) => {
                console.error(error);
                dispatch(allActions.global.Loader(false))
            });
    }
    const handleDeleteCertification = (id) => {
        dispatch(allActions.global.Loader(true))
        axios
            .delete(`${BASEURL}/api/professionals/profile/certifications/${id}`)
            .then((response) => {
                getTaxProCertifications()
                dispatch(allActions.global.Loader(false))
            })
            .catch((error) => {
                console.error(error);
                dispatch(allActions.global.Loader(false))
            });
    }

    const getServices = () => {
        dispatch(allActions.global.Loader(true))
        axios
            .get(`${BASEURL}/api/professionals/profile/service/${uuid}`)
            .then((response) => {
                setServices(response?.data?.data);
                dispatch(allActions.global.Loader(false))
            })
            .catch((error) => {
                console.error(error);
                dispatch(allActions.global.Loader(false))
            });
    }
    const handleTaxProServiceDelete = (id) => {
        dispatch(allActions.global.Loader(true))
        axios
            .delete(`${BASEURL}/api/professionals/profile/service/${id}`)
            .then((response) => {
                getServices()
                dispatch(allActions.global.Loader(false))
            })
            .catch((error) => {
                console.error(error);
                dispatch(allActions.global.Loader(false))
            });
    }


    const handleDeleteEducationClick = (id) => {
        setSwalProps((prev) => ({
            ...prev,
            [id]: {
                show: true,
                showDenyButton: true,
                title: 'Are you sure?',
                text: '',
                confirmButtonText: 'Yes, delete it!',
                denyButtonText: 'Cancel',
                didClose: () => setSwalProps((prev) => ({ ...prev, [id]: { show: false } })),
                onConfirm: () => handleEducationDelete(id),
            },
        }));
    };
    const handleDeleteCertificationClick = (id) => {
        setSwalProps((prev) => ({
            ...prev,
            [id]: {
                show: true,
                showDenyButton: true,
                title: 'Are you sure?',
                text: '',
                confirmButtonText: 'Yes, delete it!',
                denyButtonText: 'Cancel',
                didClose: () => setSwalProps((prev) => ({ ...prev, [id]: { show: false } })),
                onConfirm: () => handleDeleteCertification(id),
            },
        }));
    };

    const handleTaxProLanguageDeleteClick = (id) => {
        setSwalProps((prev) => ({
            ...prev,
            [id]: {
                show: true,
                showDenyButton: true,
                title: 'Are you sure?',
                text: '',
                confirmButtonText: 'Yes, delete it!',
                denyButtonText: 'Cancel',
                didClose: () => setSwalProps((prev) => ({ ...prev, [id]: { show: false } })),
                onConfirm: () =>  handleTaxProLanguageDelete(id),
            },
        }));
    }

    const handleTaxProServiceClick = (id) => {
        setSwalProps((prev) => ({
            ...prev,
            [id]: {
                show: true,
                showDenyButton: true,
                title: 'Are you sure?',
                text: '',
                confirmButtonText: 'Yes, delete it!',
                denyButtonText: 'Cancel',
                didClose: () => setSwalProps((prev) => ({ ...prev, [id]: { show: false } })),
                onConfirm: () =>  handleTaxProServiceDelete(id),
            },
        }));
    }
    const moveTo = (page) => {
        setPage(page);
        // Set query param
        setQueryParams('page', page);
    };

    const changePerPage = (perPage) => {
        setPerPage(perPage);
        // Set query param
        setQueryParams('per_page', perPage);
    };

    // get users list
    useEffect(() => {
       getWorkHistory(page,perPage  )
    }, [page, perPage]);

useEffect(() => {
    getTaxProfessionalDetails()
    getTaxProLanguages()
    getTaxProCertifications()
    getAbout()
    getEducation()
    getServices()
    getWorkHistory()
},[])


    return (
        <div className="container mt-5 mb-5 px-4">
            {!publicView && <div className="container mt-3">
                <div className="d-flex align-items-center justify-content-between">
                    {user?.loginStatus && user?.isTaxPro &&
                        <>
                            <h4 className="mb-0 fw-bold d-none d-md-block">Profile</h4>
                            <div className="d-flex align-items-center">
                                <button className="btn btn-outline-primary me-2 fw-bold btn-sm"
                                        onClick={() => setPublicView(true)}>See public view
                                </button>
                                <Link to={'/dashboard/settings'}>
                                    <button className="btn btn-outline-primary me-2 fw-bold btn-sm">Profile settings
                                    </button>
                                </Link>
                                {/*<div className="rounded-circle px-2 pt-3 border border-primary">*/}
                                {/*    <FaEllipsisV/>*/}
                                {/*</div>*/}
                            </div>
                        </>
                    }
                </div>
            </div>}
            {(user?.isTaxPro || user?.isAdmin) && publicView &&
                <div className={'d-flex justify-content-end'}>
                    <button className="btn btn-outline-primary me-2 fw-bold"
                            onClick={() => setPublicView(false)}>Edit profile
                    </button>
                </div>
            }
            <div className="row mt-5">
                <div className="col-md-4">
                    <div className="card border border-light rounded">
                        <div className="card-body text-center mt-3">
                            <UserAvatar
                                avatar={taxProPublicDetails?.users?.avatar}
                                // alt={`${taxProPublicDetails.firstname} ${taxProPublicDetails.lastname}`}
                                className="img-fluid rounded-circle border border-gray border-4"
                                style={{width: 120, height: 120}}
                            />
                            {/*<img*/}
                            {/*    style={{height:'150px',width:'150px'}}*/}
                            {/*    src="https://imgs.search.brave.com/fRjEd2QhX_GBGc-z7D1l6pf2IRSOozksvl401RRd1oA/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly93YWxs/cGFwZXJzLmNvbS9p/bWFnZXMvaGQvY2Fz/dWFsLXByZXR0eS13/b21hbi1wcm9maWxl/LWxxM29qYWtmbzh3/OWZiN20uanBn"*/}
                            {/*    alt="Profile"*/}
                            {/*    className="rounded-circle object-fit-cover mb-3"*/}
                            {/*/>*/}
                            {taxProPublicDetails?.users?.firstname && <>
                                <h5 className={'fw-bold mt-2 mb-1'}>{taxProPublicDetails?.users?.firstname + ' ' + taxProPublicDetails?.users?.lastname}</h5>
                                <small className={'mb-0 text-muted'}>{taxProPublicDetails?.users?.userpro?.businessName}</small>
                            </>}

                            <div className={'d-flex align-items-center justify-content-center mb-2'}>
                                <div className={' gap-2 align-items-center m-auto'}>
                                {taxProPublicDetails?.users?.userpro?.ratings?.length > 0 && taxProPublicDetails?.users?.userpro?.ratings[0]?.ratings > 0 &&
                                        <div className={'d-flex align-items-center my-3'}>
                                            {taxProPublicDetails?.users?.userpro?.ratings?.length > 0 && taxProPublicDetails?.users?.userpro?.ratings[0]?.ratings > 0 && Array.from({length: taxProPublicDetails?.users?.userpro?.ratings[0]?.ratings})?.map((rating, index) => {
                                                return <img src={StarSvg} alt={'starsvg'}/>
                                            })}
                                            {taxProPublicDetails?.users?.userpro?.ratings?.length > 0 &&
                                                <p className={'m-0 gap-2 mx-2'}>{taxProPublicDetails?.users?.userpro?.ratings[0]?.ratings}.0</p>
                                            }
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {taxProPublicDetails?.users?.userpro?.jobs?.length > 0 &&
                    <div className="card p-4 mt-4 rounded-4 border border-light rounded">
                        <div className="card-body px-0 d-flex justify-content-around align-items-center">
                            <div className="text-center">
                                <p className="text-black mb-1" style={{fontSize: '13px'}}>Total jobs</p>
                                <h5 className={'fw-bold '}>{workHistory?.count}</h5>
                            </div>
                            <div className="vr"></div>
                            <div className="text-center">
                                <p className="text-black mb-1" style={{fontSize: '13px'}}>Total earnings</p>
                                <h5 className={'fw-bold '}>{moneyFormatter(taxProPublicDetails?.totalEarnings)}</h5>
                            </div>
                        </div>
                    </div>
                    }
                     <div className="card mt-4 rounded-4 border border-light rounded p-4">
                     <div className="card-body px-0">
                         <div className={'d-flex justify-content-between align-items-center cursor-pointer'}>
                             <h5 className={'fw-bold text-primary'}>Services</h5>
                             <div data-bs-toggle="modal"
                                  data-bs-target="#addProfileService">
                                 {user?.loginStatus && user?.isTaxPro && !publicView && <img src={AddSvg} alt={'addSvg'}/> }
                             </div>
                         </div>
                         <ul className="list-unstyled mt-3">
                             {services?.length > 0 && services?.map((service,idx) => {
                                 return (<div key={service?.id} className={'d-flex justify-content-between align-items-center mt-3 gap-2'}>
                                     <li className={'fw-bold mb-0 h-5'}>{service?.name}</li>
                                     <div className={'d-flex gap-2'}>
                                         {user?.loginStatus && user?.isTaxPro && !publicView &&
                                             <>
                                             <div
                                                 data-bs-toggle="modal"
                                                 data-bs-target="#addProfileService"
                                             >
                                                 <img className={'cursor-pointer'} src={EditSvg}
                                                      alt={'edit service'}/>
                                             </div>
                                                 <img onClick={() => handleTaxProServiceClick(service?.id)} className={'cursor-pointer'} src={DeleteSvg}
                                                      alt={'delete service'}/>
                                                 <SweetAlert2 {...swalProps[service?.id]} />
                                             </>
                                         }
                                     </div>
                                 </div>)
                             })}
                             {user?.loginStatus && services?.length === 0 && <div  style={{fontSize:'15px'}}>No services have been added yet. Start by listing your first service to let others know how you can help!</div>}
                             {!user?.loginStatus && services?.length === 0 && <div  style={{fontSize:'15px'}}>This professional hasn’t listed any services yet. Check back soon for updates!</div>}
                             {/*<div className={'d-flex justify-content-between align-items-center mt-3'}>*/}
                             {/*    <li className={'fw-bold mb-0 h-5'}>Tax Strategy</li>*/}
                             {/*    <div className={'d-flex gap-2'}>*/}
                             {/*        {user?.loginStatus && user?.isTaxPro && !publicView &&*/}
                             {/*            <>*/}
                             {/*                <img className={'cursor-pointer'} src={EditSvg} alt={'edit service'}/>*/}
                             {/*                <img className={'cursor-pointer'} src={DeleteSvg}*/}
                             {/*                     alt={'delete service'}/>*/}
                             {/*            </>*/}
                             {/*        }*/}
                             {/*    </div>*/}
                             {/*</div>*/}
                         </ul>
                     </div>
                 </div>
                    {(user?.loginStatus || languages?.length > 0) && 
                        <div className="card mt-4 rounded-4 border border-light rounded p-4">
                        <div className="card-body px-0">
                            <div className={'d-flex justify-content-between align-items-center cursor-pointer'}>
                                <h5 className={'fw-bold text-primary'}>Languages</h5>
                                <div
                                     onClick={() => setLanguageToEdit({})}
                                     data-bs-toggle="modal"
                                     data-bs-target="#addProfileLanguage">
                                    {user?.loginStatus && user?.isTaxPro && !publicView &&
                                    <img src={AddSvg} alt={'addSvg'}/>
                                    }
                                </div>
                            </div>
                            <ul className="list-unstyled">
                                {languages.map((lang, index) => (
                                    <div key={index} className={'mb-3 d-flex align-items-center justify-content-between mt-3'}>
                                        <div>
                                            <div className={'fw-bold mb-0 h-5'}>{lang.name}</div>
                                            <small className={'mb-0 h-6 text-muted'}>{lang.type}</small>
                                        </div>
                                        <div className={'d-flex gap-2'}>
                                            <div
                                                data-bs-toggle="modal"
                                                data-bs-target="#addProfileLanguage"
                                                onClick={() => setLanguageToEdit(lang)}
                                            >
                                             {user?.loginStatus && user?.isTaxPro && !publicView &&
                                                 <>
                                                    <img className={'cursor-pointer'} src={EditSvg} alt={'edit service'}/>
                                                 </>
                                             }
                                            </div>
                                            {user?.loginStatus && user?.isTaxPro && !publicView &&
                                                <>
                                                  <img onClick={() => handleTaxProLanguageDeleteClick(lang?.id)} className={'cursor-pointer'} src={DeleteSvg} alt={'delete service'}/>
                                                    <SweetAlert2 {...swalProps[lang?.id]} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                ))}
                                {user?.loginStatus && languages?.length === 0 &&
                                    <div style={{fontSize:'15px'}} className={'mt-2'}>
                                    No languages have been added yet. Start by adding your first language to showcase your skills!
                                </div>}
                                {!user?.loginStatus && languages?.length === 0 &&
                                    <div style={{fontSize:'15px'}} className={'mt-2'}>
                                        No languages have been added yet. Check back later to see the languages this professional speaks!
                                    </div>}
                            </ul>
                        </div>
                    </div>
                    }

                  {(user?.loginStatus || education?.length > 0 )  && 
                    <div className="card mt-4 rounded-4 border border-light rounded p-4">
                    <div className="card-body px-0">
                        <div className={'d-flex justify-content-between align-items-center cursor-pointer'}>
                            <h5 className={'fw-bold text-primary'}>Education</h5>
                            <div 
                                onClick={() => setEducationToEdit({})}
                                data-bs-toggle="modal"
                                 data-bs-target="#addProfileEducation">
                                {user?.loginStatus && user?.isTaxPro && !publicView &&
                                     <img src={AddSvg} alt={'addSvg'}/>
                                }
                            </div>
                        </div>
                        {education?.length > 0 && education?.map((edu, index) => (
                            <div className="mb-3 d-flex justify-content-between align-items-center" key={index}>
                            <div className="mt-4">
                                <div className={'d-flex align-items-center gap-3'}>
                                    <h6 className={'fw-bold'}>{edu.school}</h6>
                                </div>
                                {/* <p className={'m-0'} style={{fontSize: '13px'}}>{edu.description}</p> */}

                                {/* <p className={'mt-1 mb-0 fw-bold'} style={{fontSize: '15px'}}>{edu.state}</p> */}
                                <small className={'mb-0 mt-0 h-6 text-muted'} style={{fontSize: '15px'}}>{edu.achievement}</small>
                                {/* <p className={'mt-1 mb-0 fw-bold text-primary'}
                                   style={{fontSize: '13px'}}>Completed at - {edu.completed_at}</p> */}
                            </div>
                            <div className={'d-flex gap-2'}>
                        <div
                            data-bs-toggle="modal"
                            data-bs-target="#addProfileEducation"
                            onClick={() => setEducationToEdit(edu)}
                        >
                            {user?.loginStatus && user?.isTaxPro && !publicView &&
                                <>
                                    <img className={'cursor-pointer'} src={EditSvg}
                                         alt={'edit service'}/>
                                </>
                            }
                        </div>
                        {user?.loginStatus && user?.isTaxPro && !publicView &&
                            <>
                                <img onClick={() => handleDeleteEducationClick(edu?.id)} className={'cursor-pointer'} src={DeleteSvg}
                                     alt={'delete service'}/>
                                <SweetAlert2 {...swalProps[edu?.id]} />
                            </>
                        }
                    </div>
                            </div>

                        ))}
                        {user?.loginStatus && education?.length === 0 && <div  style={{fontSize:'15px'}}>No educational background has been added yet. Share your academic achievements to showcase your expertise!</div>}
                        {!user?.loginStatus && education?.length === 0 && <div  style={{fontSize:'15px'}}>No educational background has been shared yet. Stay tuned for more details!</div>}
                    </div>
                </div>
                  }

                   {(user?.loginStatus || certifications?.length > 0) && 
                    <div className="card mt-4 rounded-4 border border-light rounded p-4">
                    <div className="card-body px-0">
                        <div className={'d-flex justify-content-between align-items-center cursor-pointer mb-3'}>
                            <h5 className={'fw-bold text-primary'}>Certifications</h5>
                            <div 
                                 onClick={() => setCertificationToEdit({})}
                                 data-bs-toggle="modal"
                                 data-bs-target="#addProfileCertificate">
                                {user?.loginStatus && user?.isTaxPro && !publicView &&
                                     <img src={AddSvg} alt={'addSvg'}/>
                                }
                            </div>
                        </div>
                        {certifications.map((cert, index) => (
                            <div className="mb-3 d-flex justify-content-between align-items-center " key={index}>
                                <div>
                                    <h6 className={'fw-bold'}>{cert.institution}</h6>
                                    {/* <p className={'mt-2 mb-0'}>{cert.description}</p> */}
                                    {/* <p className={'mt-1 mb-0 fw-bold text-primary'}
                                       style={{fontSize: '15px'}}>Year Completed - {cert.complete_at}</p> */}
                                    <small className={'mb-0 h-6 text-muted mt-0'}>{cert.achievement}</small>
                                </div>
                                <div className={'d-flex gap-2'}>
                                    <div
                                        data-bs-toggle="modal"
                                        data-bs-target="#addProfileCertificate"
                                        onClick={() => setCertificationToEdit(cert)}
                                    >
                                        {user?.loginStatus && user?.isTaxPro && !publicView &&
                                            <>
                                                <img className={'cursor-pointer'} src={EditSvg}
                                                     alt={'edit service'}/>
                                            </>
                                        }
                                    </div>
                                    {user?.loginStatus && user?.isTaxPro && !publicView &&
                                        <>
                                            <img onClick={() => handleDeleteCertificationClick(cert?.id)} className={'cursor-pointer'} src={DeleteSvg}
                                                 alt={'delete service'}/>
                                            <SweetAlert2 {...swalProps[cert?.id]} />
                                        </>
                                    }
                                </div>
                            </div>
                        ))}
                        {user?.loginStatus && certifications?.length === 0 && <div style={{fontSize:'15px'}}>No certifications have been added yet. Add your professional credentials to highlight your qualifications!</div>}
                        {!user?.loginStatus && certifications?.length === 0 && <div style={{fontSize:'15px'}}>No certifications have been added yet. Keep an eye out for updates!</div>}
                    </div>
                </div>
                }
                </div>

                <div className="col-md-8 rounded-4   rounded p-4">
                    <div className="px-3">
                        <div className="card-body px-0">
                            <div className={'d-flex justify-content-between align-items-center'}>
                                <h4 className={'fw-bold text-primary'}>About</h4>
                                <div data-bs-toggle="modal"
                                     data-bs-target="#editAboutModal">
                                    {user?.loginStatus && user?.isTaxPro && !publicView &&
                                        <img className={'cursor-pointer'} src={EditSvg} alt={'edit about'}/>}
                                </div>
                            </div>
                            <p style={{fontSize:'16px'}} className={'mt-3 text-spacing'}>
                                {user?.loginStatus && Object?.keys(about)?.length > 0 && <span>
                                      {readMore && about?.details?.length > 436 ? <span>{about?.details?.substring(0, 436)}...</span> : <span>{about?.details}</span>}
                                    </span>}
                                {about?.details?.length > 436 && 
                                <span>{readMore ? <small onClick={() => setReadMore(prev => !prev)} className="text-primary cursor-pointer mx-2">Read more</small> : <small  onClick={() => setReadMore((prev) => !prev)} className="text-primary cursor-pointer mx-2">Read less</small>}</span>
                                }
                                {user?.loginStatus && Object?.keys(about)?.length === 0 && 'About information is currently missing. Please provide details to complete this section.'}
                                {!user?.loginStatus && Object?.keys(about)?.length === 0 && 'This professional has not provided any information about themselves yet. Check other sections for more details!'}
                            </p>
                        </div>
                    </div>

                    <div className="mt-5">
                        <div className="card-body">
                            <h4 className={'fw-bold text-primary px-3'}>Work History</h4>
                            <div className=" mt-4">
                            {workHistory?.data?.length > 0 && workHistory?.data?.map((work) => {
                                    return <div className="mb-3 card p-4 rounded-4 border border-light" key={work?.id}>
                                        <h5 className={'text-primary fw-bold'}>{work?.service?.name || work?.others || work?.description}</h5>
                                        <div className={'d-flex align-items-center gap-2'}>
                                            <p className="text-black small m-0 p-0"
                                               style={{fontSize: '13px'}}>{dayjs(work?.created_at).format('MMM DD YYYY')}</p>
                                           {work?.completed_at && work?.created_at && <div>-</div>}
                                            {
                                                work?.completed_at && <p className="text-black small m-0 p-0"
                                                style={{fontSize: '13px'}}>{dayjs(work?.completed_at).format('MMM DD YYYY')}</p>
                                            }
                                        </div>
                                        <div className={'d-flex gap-3'}>
                                            <div className={'d-flex gap-2'}>
                                                <img src={FileSvg} alt={'file'}/>
                                                <p className={'m-0 fw-bold'}>{work?.service?.name || work?.others || work?.description}</p>
                                            </div>
                                            <p className={'m-0'}><span></span>{moneyFormatter((work?.milestones?.length > 0 && work?.milestones?.reduce((sum, milestone) => {
                                                // if(milestone?.status_id === 3){
                                                    return Number(sum) + Number(milestone?.invoices?.amount);
                                                // }
                                            }, '')) || 0)}</p>
                                            {/*<div className={'d-flex gap-2'}>*/}
                                            {/*    <img src={HourglassSvg} alt={'file'}/>*/}
                                            {/*    <p className={'m-0'}>{work.duration}</p>*/}
                                            {/*</div>*/}
                                        </div>
                                        {work?.ratings?.length > 0 && work?.ratings[0]?.ratings > 0 &&
                                            <div className={'d-flex align-items-center my-3'}>
                                                {work?.ratings?.length > 0 && work?.ratings[0]?.ratings > 0 && Array.from({length: work?.ratings[0]?.ratings})?.map((rating, index) => {
                                                    return <img src={StarSvg} alt={'starsvg'}/>
                                                })}
                                                {work?.ratings?.length > 0 &&
                                                <p className={'m-0 gap-2 mx-2'}>{work?.ratings[0]?.ratings}.0</p>
                                                }
                                            </div>}
                                        {work?.ratings?.length > 0 &&
                                            <p className={'mt-2'}>{work?.ratings[0]?.details}</p>
                                        }

                                    </div>
                            })}
                                {taxProPublicDetails?.users?.userpro?.jobs?.length === 0 && user?.loginStatus &&
                                    <div className={'px-3'}>No recent jobs completed. Once you complete jobs, your work
                                        history will appear here.</div>}
                                {(taxProPublicDetails?.users?.userpro?.jobs?.length === 0 || taxProPublicDetails?.users?.userpro?.jobs?.length === undefined  ) && !user?.loginStatus &&
                                    <div className={'px-3'}>This tax professional has not completed any jobs yet.</div>}
                                 {workHistory?.count > 5 && <Pagination {...{
                                     pageCount: Math.ceil(workHistory?.count / perPage),
                                     currentPage: parseInt(page),
                                     setCurrentPage: moveTo,
                                     perPage: perPage,
                                     setPerPage: (pc) => {
                                         changePerPage(pc);
                                         // Move to first page
                                         if (page !== 1) moveTo(1);
                                     },
                                 }} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="Edit about"
                bodyClass="px-2 py-4 text-center"
                trigger="editAboutModal"
                btnClass={'closeAddOrofileAbout'}
                showBtn={false}
                size="lg"
            >
                <ProfileAboutModal reload={getAbout} aboutToEdit={about} setAboutToEdit={setAbout}/>
            </Modal>
            <Modal
                title="Add language"
                bodyClass="px-2 py-4 text-center"
                trigger="addProfileLanguage"
                btnClass={'closeAddOrofileLanguage'}
                showBtn={false}
                size="lg"
            >
                <ProfileAddLanguageModal reload={getTaxProLanguages} languageToEdit={languageToEdit} setLanguageToEdit={setLanguageToEdit}/>
            </Modal>
            <Modal
                title="Add Service"
                bodyClass="px-2 py-4 text-center"
                trigger="addProfileService"
                btnClass={'closeAddOrofileService'}
                showBtn={false}
                size="lg"
            >
                <ProfileAddServicesModal reload={getServices} servicesData={services} setServicesData={setServices}/>
            </Modal>
            <Modal
                title="Add education"
                bodyClass="px-2 py-4 text-center"
                trigger="addProfileEducation"
                btnClass={'closeAddOrofileAducation'}
                showBtn={false}
                size="lg"
            >
                <ProfileAddEducationModal reload={getEducation} educationToEdit={educationToEdit} setEducationToEdit={setEducationToEdit}/>
            </Modal>
            <Modal
                title="Add certification"
                bodyClass="px-2 py-4 text-center"
                trigger="addProfileCertificate"
                btnClass={'closeAddOrofileCertificate'}
                showBtn={false}
                size="lg"
            >
                <ProfileAddCertificateModal reload={getTaxProCertifications} certificationToEdit={certificationToEdit} setCertificationToEdit={setCertificationToEdit}/>
            </Modal>
        </div>
    );
};

export default Profile;

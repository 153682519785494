import React from 'react'
import MailIcon from '../../../assets/img/Mail.png'
const ReminderSuccess = () => {
    return (
        <div className='pb-4 px-3 d-flex flex-column align-items-center justify-content-center'>
            <img src={MailIcon} alt="mail_icon" style={{ height: 80, width: 100 }} />
            <p className='mt-3'>Your reminder has been sent successfully!</p>
            <div className="d-flex justify-content-center mt-3 gap-2">
                <button data-bs-dismiss="modal" className={`btn btn-primary px-3 rounded-2`}>Done</button>
            </div>
        </div>
    )
}

export default ReminderSuccess
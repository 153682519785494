/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import allActions from "../../Store/action";
import CreateSectionIcon from "../../assets/img/createsection.svg";
import DeleteIcon2 from "../../assets/img/delete1.svg";
import DotsIcon from "../../assets/img/dots.svg";
import EditIcon from "../../assets/img/edit.svg";
import EmplymentIcon from "../../assets/img/empoyment.svg";
import SocyalSecurityIcon from "../../assets/img/social-security 1.svg";
import StatesOrLocalTaxIcon from "../../assets/img/tax 1.svg";
import InterestRateIcon from "../../assets/img/interest-rate 1.svg";
import RentsIcon from "../../assets/img/rent 1.svg";
import EducationCostIcon from "../../assets/img/education-cost 1.svg";
import PartnershipIcon from "../../assets/img/partnership.svg";
import EstateOrTrustIcon from "../../assets/img/estateortrust.svg";
import RetirementIcon from "../../assets/img/retirement.svg";
import SelfEmploymentIcon from "../../assets/img/self-employed 1.svg";
import CancelDebtIcon from "../../assets/img/cancel 1.svg";
import UnemploymentIcon from "../../assets/img/unemployment.svg";
import DividendsIcon from "../../assets/img/dividends 1.svg";
import HsaOrMsaIcon from "../../assets/img/hsaormsa.svg";
import RoyalitiesIcon from "../../assets/img/royalities.svg";
import GamblingIcon from "../../assets/img/gambling.svg";
import FarmingIcon from "../../assets/img/planting 1.svg";
import OtherIncomeIcon from "../../assets/img/otherincome.svg";
import SoldStockIcon from "../../assets/img/soldstock.svg";
import SectxporationIcon from "../../assets/img/sectxporation.svg";
import LockerSvg from "../../assets/img/locker.svg";
import CloseSvg from "../../assets/img/close.svg";
import axios from 'axios';
import { BASEURL } from '../../utilites';
import Message from './fields/Message'
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import AddNewQuestion from './Modal/DynamicFormModals/AddNewQuestion';
import "antd/dist/reset.css";
import ReactSelect from "react-select";
import { expensesData,incomeSources } from './Modal/DynamicFormModals/StaticData';
import CreateSectionTitleModal from "./Modal/DynamicFormModals/CreateSectionTitleModal";
import CreateSubSectionTitleModal from "./Modal/DynamicFormModals/CreateSubSectionTitleModal";
import CustomDeleteSubSectionModal from "./Modal/DynamicFormModals/CustomDeleteSubSectionModal";
import CustomAddOptionModal from "./Modal/DynamicFormModals/CustomAddOptionModal";
import CustomAddCardOptionModal from "./Modal/DynamicFormModals/CustomAddCardOptionModal";
import AddCardColumnModal from "./Modal/DynamicFormModals/AddCardColumnModal";
import AddGridColumnModal from "./Modal/DynamicFormModals/AddGridColumnModal";
import AddGridRowModal from "./Modal/DynamicFormModals/AddGridRowModal";
import EditSubSectionModal from "./Modal/DynamicFormModals/EditSubSectionModal";
import EditSectionModal from "./Modal/DynamicFormModals/EditSectionModal";
import EditCardColumnTitleModal from "./Modal/DynamicFormModals/EditCardColumnTitleModal";
import EditGridColumnTitleModal from "./Modal/DynamicFormModals/EditGridColumnTitleModal";
import EditGridRowTitleModal from "./Modal/DynamicFormModals/EditGridRowTitleModal";
import CustomDeleteSectionModal from "./Modal/DynamicFormModals/CustomDeleteSectionModal"
import AddListColumnModal from "./Modal/DynamicFormModals/AddListColumnModal"
import EditListColumnTitleModal from "./Modal/DynamicFormModals/EditListColumnTitleModal"
import EditFormTitleModal from "./Modal/DynamicFormModals/EditFormTitleModal"
import CustomAddListOptionModal from "./Modal/DynamicFormModals/CustomAddListOptionModal"
import { VscEye } from "react-icons/vsc";
import Modal from '../Dashboard/Modal';
import RenderMultiPagePDF from '../../pages/dashboard/components/RenderMultiPagePDF';
import { FileUploader } from 'react-drag-drop-files';
import { useSelector } from 'react-redux';
import ItemizedDeductions from './Modal/DynamicFormModals/Fields/ItemizedDeductions';
import PartnersList from './Modal/DynamicFormModals/AdminSideComponents/PartnersList';
import OfficersList from "./Modal/DynamicFormModals/AdminSideComponents/OfficersList";
import Input from "./fields/Input";
import ShareholdersList from "./Modal/DynamicFormModals/AdminSideComponents/ShareholdersList";
import CustomAddGridOptionsModal from "./Modal/DynamicFormModals/CustomAddGridOptionsModal";

const generateUniqueId = () => {
  const timestamp = Date.now().toString(36);
  const randomString = Math.random().toString(36).substring(2, 15);
  const randomSegment = Math.random().toString(36).substring(2, 15);
  return timestamp + randomString + randomSegment;
};




const CreateNewForm = ({
  setSelectedDynamicForms,
  selectedDynamicForms,
  formTitle,
  setFormTitle,
  setCreateFormModalOpen,
  editmode,
  adminEditMode,
  formToEdit,
  byAdmin,
  formId
}) => {
  const [{ LoginData }] = useSelector((state) => [
		state.user,
	]);
  const [taxForm, setTaxForm] = useState(editmode || adminEditMode ? formToEdit : {});
  const [sectionTitle, setSectionTitle] = useState("");
  const [subSectionTitle, setSubSectionTitle] = useState("");
  const [activeSectionId, setActiveSectionId] = useState(null);
  const [activeSubSectionId, setActiveSubSectionId] = useState(null);
  const [editingSection, setEditingSection] = useState(null);
  const [customModalOpen, setCustomModalOpen] = useState(null);
  const [addOptionModalOpen, setAddOptionModalOpen] = useState(false);
  const [addCardDropdownOptionModalOpen, setAddCardDropdownOptionModalOpen] = useState(false);
  const [addGridDropdownOptionModalOpen, setAddGridDropdownOptionModalOpen] = useState(false);
  const [addListDropdownOptionModalOpen, setAddListDropdownOptionModalOpen] = useState(false);
  const [addGridRowModalOpen, setAddGridRowModalOpen] = useState(false);
  const [editformtitleModalOpen,setEditFormTitleModalOpen] = useState(false)
  const [moreOptions,setMoreOptions] = useState(false)
  const [alertMessage,setAlertMessage] = useState('')
  const [selectedDocument,setSelectedDocument] = useState({})
  const [uploadProgress, setUploadProgress] = useState(0);
  const [activeFileID,setActiveFileId] = useState('')
  const [addCardColumnModalOpen, setAddCardColumnModalOpen] = useState(false);
  const [addGridColumnModalOpen, setAddGridColumnModalOpen] = useState(false);
  const [editCardColumnTitleModalOpen,setEditCardColumnTitleModalOpen] = useState(false)
  const [editGridColumnTitleModalOpen,setEditGridColumnTitleModalOpen] = useState(false)
  const [addListColumnModalOpen,setAddListColumnModalOpen] = useState(false)  
  const [editGridRowTitleModalOpen,setEditGridRowTitleModalOpen] = useState(false)
  const [customDropdownOptions, setCustomDropdownOptions] = useState([""]);
  const [editListColumnTitleModalOpen,setEditListColumnTitleModalOpen] = useState(null)
  const [uploading,setUploading] = useState(false)
  const [CardFields, setCardFields] = useState([]);
  const [addPartner,setAddPartner] = useState(false)
  const [addOfficer,setAddOfficer] = useState(false)
  const [addShareholder,setAddShareholder] = useState(false)
  const [partnerToEdit,setPartnerToEdit] = useState({state:false, data:null})
  const [officerToEdit,setOfficerToEdit] = useState({state:false, data:null})
  const [shareholderToEdit,setShareholderToEdit] = useState({state:false, data:null})

  const [gridData, setGridData] = useState({
    columns: [
    ],
    rows: [
    ],
    answers:{}
  });
  const [listFields, setListFields] = useState({
    columns: [
    ],
    rows: [
    ],
  });
  const [dependent, setDependent] = useState({
    firstName: "",
    middleInitial: "",
    lastName: "",
    prefix: "",
    ssnOrItin: "",
    dateOfBirth: "",
    relationship: "",
    amountPaidToCareProvider: "",
    yearsLivedWithTaxpayer: "",
    fullTimeStudent: false,
    married: false,
    disabled: false,
    hadIncome: false,
    filedOwnReturn: false,
    noneOfAbove: false,
  });
  const [dependents, setDependents] = useState([]);
  const [message, setMessage] = useState({ show: false });

  const handleAddDependent = (e) => {
    e.preventDefault();
    setDependents([...dependents, dependent]);
    setDependent({
      firstName: "",
      middleInitial: "",
      lastName: "",
      prefix: "",
      ssnOrItin: "",
      dateOfBirth: "",
      relationship: "",
      amountPaidToCareProvider: "",
      yearsLivedWithTaxpayer: "",
      fullTimeStudent: false,
      married: false,
      disabled: false,
      hadIncome: false,
      filedOwnReturn: false,
      noneOfAbove: false,
    });
  };

  const dispatch = useDispatch();


  const conditionCheck = (activeSection,activeSubSection,question) => {
    const answer =  taxForm?.sections
    ?.find((sec) => sec?.id === activeSection)
    ?.subsections?.find((sub) => sub?.id === activeSubSection)
    ?.fields?.find(
      (field) => field?.question === question?.conditionQuestion
    )
    ?.answer
    if(typeof answer !== 'string' ){
      return false
    }else{
      return answer?.toLowerCase()  === question?.condition.toLowerCase()
    }
  
  };

  const isFormFilled = (taxForm) => {
    if (!taxForm || !taxForm.sections || taxForm.sections.length === 0) {
      return false;
    }
  
    for (const section of taxForm.sections) {
      for (const subsection of section?.subsections) {
        for (const field of subsection?.fields) {
          if (
            field?.question === 'divider' ||
            field?.type === 'list' ||
            field?.type === 'file-upload' ||
            field?.type === 'text-header' ||
            field?.type === 'horizontal-line'
          ) {
            continue;
          }
          if (field.condition && !conditionCheck(section?.id, subsection?.id, field)) {
            continue;
          }
          if (field.type === 'grid') {
            const { answers, columns, rows } = field.questionData;
  
            if (field.required && Object.keys(answers).length < rows.length) {
              return false;
            }
  
            for (const rowId of rows.map((row) => row.id)) {
              const rowAnswers = answers[rowId];
              if (field.required && !rowAnswers) {
                return false;
              }
  
              for (const col of columns) {
                const colAnswer = rowAnswers?.[col.id];
                if (field.required && (colAnswer === undefined || colAnswer === '')) {
                  return false;
                }
              }
            }
            continue;
          }
  
          if (field.type === 'card') {
            for (const question of field?.questionData) {
              if (
                typeof question?.answer === 'string' &&
                (!question?.answer || question?.answer?.trim() === '')
              ) {
                return false;
              }
            }
            continue;
          }
  
          if (field?.required && Array.isArray(field.answer)) {
            if (field.answer.length === 0) {
              if (field?.type === 'expenses-list') {
                continue;
              } else {
                return false;
              }
            }
          } else if (field.required && (!field.answer || field?.answer?.trim() === '')) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setDependent((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };



  const createNewSection = () => {
    setTaxForm((prev) => {
      return {
        ...prev,
        sections: [...(prev?.sections || []), { id: generateUniqueId(), name: sectionTitle, subsections: [] }]
      };
    });
    
    setSectionTitle("");
    setCustomModalOpen(null);
  };
  const createNewSubSection = (sectionId) => {
      let updatedForm = {...taxForm} 
      let uniqId = generateUniqueId()
      if (updatedForm?.sections) {
        updatedForm.sections = updatedForm.sections?.map((section) => {
          if(section?.id === sectionId){
              return {
                ...section,
                subsections:[...section?.subsections,{id:uniqId,name:subSectionTitle,fields:[]}]
              }
          }
          return section
        }); 
      }
    setActiveSubSectionId(uniqId)
    setActiveSectionId(sectionId)
    setTaxForm(updatedForm)
    setCustomModalOpen(null)
    setSubSectionTitle("");
  };


  function checkSections(sections) {
    const allSectionsValid = sections?.every((section) => {
      if (!section?.subsections || section?.subsections.length === 0) {
        setAlertMessage(`The section "${section?.name}" is missing subsections. Please ensure all sections have at least one subsection.`)
        return false; 
      }
      return true; 
    });
  
    return allSectionsValid
  }


  const handleSaveForm = async () => {
    const isValid = checkSections(taxForm?.sections)
     if(isValid){
      setSelectedDynamicForms((prev) => [
        ...prev,
        {
          name: formTitle,
          formData: taxForm,
          data:taxForm,
          message: "",
        },
      ]);

      setCreateFormModalOpen(false);
      setFormTitle("");
      setEditingSection(false);
      setActiveSectionId(null);
      setActiveSubSectionId(null);
      setSectionTitle("");
      setSubSectionTitle("");
      setTaxForm({});
      document.body.style.overflowY = '';

     }
  };

  const changeLoading = (status) => {
    dispatch(allActions.global.Loader(status));
  };

  const handleSaveFormByAdmin = async () => {
    changeLoading(true)
		try { 
		 await axios.post(`${BASEURL}/api/taxform/create-dynamic-form-byadmin`,{  form_data:{name:formTitle,data:taxForm,byAdmin:true}})
      changeLoading(false)
      setCreateFormModalOpen(false);
      setFormTitle("");
      setEditingSection(false);
      setActiveSectionId(null);
      setActiveSubSectionId(null);
      setSectionTitle("");
      setSubSectionTitle("");
      setTaxForm({});
      document.body.style.overflowY = '';
		} catch (error) {
      changeLoading(false)
      setMessage({
        show: true,
        type: 'Error',
        text:error?.response?.data?.error?error?.response?.data?.error:'server error'
      });
		}
  }

  const handleSaveEditedForm = () => {
    let updatedDynamicFormss = [...selectedDynamicForms]
    updatedDynamicFormss = updatedDynamicFormss?.map((dform) => {
      if(dform?.name === formTitle){
        console.log("@!!!!!!! step 2",taxForm);
        return {
          ...dform,
          data:taxForm,
          formData:taxForm,
          status:isFormFilled(taxForm) ? 1 : 0
        }
      }
      return dform
    }
  )
  
  setSelectedDynamicForms(updatedDynamicFormss)
    setCreateFormModalOpen(false);
    setFormTitle("");
    setEditingSection(false);
    setActiveSectionId(null);
    setActiveSubSectionId(null);
    setSectionTitle("");
    setSubSectionTitle("");
    setTaxForm({});
    document.body.style.overflowY = '';
  }

  const handleSaveEditedFormByAdmin = async () => {
    changeLoading(true)
		try { 
		 await axios.patch(`${BASEURL}/api/taxform/edit-dynamic-form-byadmin`,{form_id:formId,form_data:{name:formTitle,data:taxForm,byAdmin:true}})
      changeLoading(false)
      setCreateFormModalOpen(false);
      setFormTitle("");
      setEditingSection(false);
      setActiveSectionId(null);
      setActiveSubSectionId(null);
      setSectionTitle("");
      setSubSectionTitle("");
      setTaxForm({});
      document.body.style.overflowY = '';
		} catch (error) {
      changeLoading(false)
      setMessage({
        show: true,
        type: 'Error',
        text:error?.response?.data?.error?error?.response?.data?.error:'server error'
      });
		  console.log(error);
		}
  }

  useEffect(() => {
    if(!editmode && !adminEditMode){
      setCustomModalOpen({state:'sectiontitle',dara:{}})
    }
    document.body.style.overflowY = 'hidden';
  },[])


  const addRow = (question) => {
    const newRow = {};
    question?.questionData?.columns.forEach(col => {
      newRow[col.name] = ''; 
    });
    setTaxForm((prevForm) => {
      return {
          ...prevForm,
          sections:prevForm?.sections?.map(sec => {
            if(sec?.id === activeSectionId){
              return {
                ...sec,
                subsections: sec?.subsections?.map(sub => {
                  if(sub?.id === activeSubSectionId){
                    return {
                      ...sub,
                      fields: sub?.fields?.map((field) => {
                        if(field?.id === question?.id){
                          return {
                            ...field,
                            questionData: {
                              ...field?.questionData,
                              rows:[...field?.questionData?.rows,newRow]
                            }
                          }
                        }
                        return field
                      })
                    }
                  }
                  return sub
                })
              }
            }
            return sec
          })
      }
    })

  };


  useEffect(() => {
    const fields = taxForm?.sections?.find((sec) => sec?.id === activeSectionId)?.subsections?.find((sub) => sub?.id === activeSubSectionId)?.fields
    function checkSectionDivider(arr) {
      const hasSectionDivider = arr?.find(item => item?.type === 'divider');
      
      if (hasSectionDivider) {
        return arr[0]?.type === 'divider';
      } else {
        return true;
      }
    }
    if(fields?.length > 0 ){
      if(!checkSectionDivider(fields)){
        setTaxForm((prevForm) => {
          return {
            ...prevForm,
            sections: prevForm?.sections?.map((sec => {
                if(sec?.id === activeSectionId){
                  return {
                    ...sec,
                    subsections: sec?.subsections?.map(sub => {
                        if(sub?.id === activeSubSectionId){
                          return {
                            ...sub,
                            fields:[{
                              id: generateUniqueId(),
                              identifier: 'divider' + generateUniqueId(),
                              required: false,
                              type: "divider",
                              condition: '', 
                              conditionQuestion: '',
                              fieldType: "",
                              question: 'Top Section Divider',
                              answer: "",
                            },...sub?.fields]
                          }
                        }
                      return sub
                    })
                  }
                }
              return sec
            }))
          }
        } )
      }

    }

  },[taxForm])

  useEffect(() => {
    if(editmode || adminEditMode){
      const activeSectionId = taxForm?.sections[0]?.id
      const activeSubSectionId = taxForm?.sections[0]?.subsections[0]?.id
      setActiveSectionId(activeSectionId)
      setActiveSubSectionId(activeSubSectionId)
    }
  },[editmode,adminEditMode])

  const onDragEnd = (result, taxForm, setTaxForm) => {
    const { destination, source, type } = result;
  
    if (!destination) return;
  
    if (type === "section") {
      const sections = Array.from(taxForm.sections);
      const [removed] = sections.splice(source.index, 1);
      sections.splice(destination.index, 0, removed);
  
      setTaxForm((prev) => ({ ...prev, sections }));
    }
  
    if (type === "subsection") {
      const sections = Array.from(taxForm.sections);
      const section = sections[source.droppableId];
      const subsections = Array.from(section.subsections);
      const [removed] = subsections.splice(source.index, 1);
      subsections.splice(destination.index, 0, removed);
  
      sections[source.droppableId].subsections = subsections;
  
      setTaxForm((prev) => ({ ...prev, sections }));
    }
  };


  const handleQuestionInputChange = (value,question) => {
    setTaxForm((prevForm) => {
      return {
        ...prevForm,
        sections:taxForm?.sections?.map(section => {
          return {
            ...section,
            subsections: section?.subsections?.map((sub) => {
              if(sub?.id === activeSubSectionId){
                return {
                  ...sub,
                  fields: sub?.fields?.map(field => {
                    if (field?.question === question) {
                      return {
                        ...field,
                        answer: value, 
                      };
                    }
                    return { ...field }; 
                  })
                }
              }
              return sub
            } )
          }
        })
      }
    })
  }
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onQuestionDrag = (result) => {
    if (!result.destination) return; 

    const updatedQuestions = reorder(
      taxForm.sections
        ?.find((sec) => sec?.id === activeSectionId)
        ?.subsections?.find((subsection) => subsection?.id === activeSubSectionId)?.fields,
      result.source.index,
      result.destination.index
    );

    const updatedForm = { ...taxForm }; 

const activeSection = updatedForm.sections?.find(
  (sec) => sec?.id === activeSectionId
);
if (activeSection) {
  const activeSubsection = activeSection?.subsections?.find(
    (subsection) => subsection?.id === activeSubSectionId
  );

  if (activeSubsection) {
    activeSubsection.fields = updatedQuestions;
  }
}
setTaxForm(updatedForm);
  };


  /////////////////////////////////// TODO:
  const handleFileUpload = async (file,questionId,setActiveFileId) => {
  if (file) {
    setUploading(true);
    setActiveFileId(questionId)

    const formData = new FormData();
    
    formData.append('documents', file);

    formData.append('user_id', LoginData?.id);
    formData.append('type', 1);
      formData.append('document_name', 'Custom form file');
    try {
     const resp = await axios
        .post(`${BASEURL}/api/documents`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            // Calculate the upload progress percentage
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

            // Update the uploadProgress state for the current file
            setUploadProgress(progress);
          },
        })
        setActiveFileId('')
        const document = resp?.data?.data?.document
        setTaxForm((prevForm) => {
          return {
            ...prevForm,
            sections:
              taxForm?.sections?.map(
                (section) => {
                  return {
                    ...section,
                    subsections:
                      section?.subsections?.map(
                        (sub) => {
                          if (
                            sub?.id ===
                            activeSubSectionId
                          ) {
                            return {
                              ...sub,
                              fields:
                                sub?.fields?.map(
                                  (
                                    field
                                  ) => {
                                    if (
                                      field?.id ===
                                      questionId
                                    ) {
                                      return {
                                        ...field,
                                        file: [...field?.file,document],
                                      };
                                    }
                                    return {
                                      ...field,
                                    };
                                  }
                                ),
                            };
                          }
                          return sub;
                        }
                      ),
                  };
                }
              ),
          };
        });
    setUploading(false);
    setUploading(false);
    setUploadProgress(0);
    setSelectedDocument({})

        
    } catch (error) {
      console.error(error.message);
    setUploading(false);
    setUploadProgress(0);
  }
 }
}

 const handleDeleteFile = async (uuid,questionId) => {
  if (uuid) {
    setUploading(true);
    setActiveFileId(questionId)
    setUploadProgress(50)
    try {
       await axios
        .delete(`${BASEURL}/api/documents/${uuid}`)
        setActiveFileId('')
        setUploadProgress(100)
        setTaxForm((prevForm) => {
          return {
            ...prevForm,
            sections:
              taxForm?.sections?.map(
                (section) => {
                  return {
                    ...section,
                    subsections:
                      section?.subsections?.map(
                        (sub) => {
                          if (
                            sub?.id ===
                            activeSubSectionId
                          ) {
                            return {
                              ...sub,
                              fields:
                                sub?.fields?.map(
                                  (
                                    field
                                  ) => {
                                    if (
                                      field?.id ===
                                      questionId
                                    ) {
                                      return {
                                        ...field,
                                        file: field?.file?.filter((file) => file?.uuid !== uuid),
                                      };
                                    }
                                    return {
                                      ...field,
                                    };
                                  }
                                ),
                            };
                          }
                          return sub;
                        }
                      ),
                  };
                }
              ),
          };
        });
    setUploading(false);
    setUploading(false);
    setUploadProgress(0);
    setSelectedDocument({})
        
    } catch (error) {
      console.error(error.message);
    setUploading(false);
    setUploadProgress(0);
  }
 }
}

const handleSavePartner = (partner,question) => {
  setTaxForm((prevForm) => {
      return {
        ...prevForm,
        sections:taxForm?.sections?.map(section => {
          return {
            ...section,
            subsections: section?.subsections?.map((sub) => {
              if(sub?.id === activeSubSectionId){
                return {
                  ...sub,
                  fields: sub?.fields?.map(field => {
                    if (field?.id === question?.id) {
                      return {
                        ...field,
                        answer: [...field?.answer, {id:generateUniqueId(),...partner}], 
                      };
                    }
                    return { ...field }; 
                  })
                }
              }
              return sub
            } )
          }
        })
      }
    })
  setAddPartner(false)
  setAddShareholder(false)
  setPartnerToEdit({state:false,data:null})
  setShareholderToEdit({state:false,data:null})
}

  const handleSaveOfficer = (partner,question) => {
    setTaxForm((prevForm) => {
      return {
        ...prevForm,
        sections:taxForm?.sections?.map(section => {
          return {
            ...section,
            subsections: section?.subsections?.map((sub) => {
              if(sub?.id === activeSubSectionId){
                return {
                  ...sub,
                  fields: sub?.fields?.map(field => {
                    if (field?.id === question?.id) {
                      return {
                        ...field,
                        answer: [...field?.answer, {id:generateUniqueId(),...partner}],
                      };
                    }
                    return { ...field };
                  })
                }
              }
              return sub
            } )
          }
        })
      }
    })
    setAddOfficer(false)
    setOfficerToEdit({state:false,data:null})
  }
const handleSaveEditedPartner = (partner,question) => {
  setTaxForm((prevForm) => {
    return {
      ...prevForm,
      sections:taxForm?.sections?.map(section => {
        return {
          ...section,
          subsections: section?.subsections?.map((sub) => {
            if(sub?.id === activeSubSectionId){
              return {
                ...sub,
                fields: sub?.fields?.map(field => {
                  if (field?.id === question?.id) {
                    return {
                      ...field,
                      answer: field?.answer?.map((part) => {
                        if(part?.id === partner?.id ){
                          return partner
                        }
                        return part
                      }), 
                    };
                  }
                  return { ...field }; 
                })
              }
            }
            return sub
          } )
        }
      })
    }
  })
  setAddPartner(false)
  setAddShareholder(false)
  setPartnerToEdit({state:false,data:null})
  setShareholderToEdit({state:false,data:null})

}

  const handleSaveEditeOfficer = (partner,question) => {
    setTaxForm((prevForm) => {
      return {
        ...prevForm,
        sections:taxForm?.sections?.map(section => {
          return {
            ...section,
            subsections: section?.subsections?.map((sub) => {
              if(sub?.id === activeSubSectionId){
                return {
                  ...sub,
                  fields: sub?.fields?.map(field => {
                    if (field?.id === question?.id) {
                      return {
                        ...field,
                        answer: field?.answer?.map((part) => {
                          if(part?.id === partner?.id ){
                            return partner
                          }
                          return part
                        }),
                      };
                    }
                    return { ...field };
                  })
                }
              }
              return sub
            } )
          }
        })
      }
    })
    setAddOfficer(false)
    setOfficerToEdit({state:false,data:null})
  }

  const handlePrimaryContactInfoChange = (fieldName,value,question) => {
    setTaxForm((prevForm) => {
      return {
        ...prevForm,
        sections:taxForm?.sections?.map(section => {
          return {
            ...section,
            subsections: section?.subsections?.map((sub) => {
              if(sub?.id === activeSubSectionId){
                return {
                  ...sub,
                  fields: sub?.fields?.map(field => {
                    if (field?.id === question?.id) {
                      return {
                        ...field,
                        primaryContactInfo:{
                          ...field?.primaryContactInfo,
                          [fieldName]:value
                        }
                      };
                    }
                    return { ...field };
                  })
                }
              }
              return sub
            } )
          }
        })
      }
    })
  }

  return (
    <div>
      {customModalOpen?.state === "sectiontitle" && (
        <CreateSectionTitleModal
          setCustomModalOpen={setCustomModalOpen}
          sectionTitle={sectionTitle}
          setSectionTitle={setSectionTitle}
          createNewSection={createNewSection}
        />
      )}
      {customModalOpen?.state === "subsectiontitle" && (
        <CreateSubSectionTitleModal
          setCustomModalOpen={setCustomModalOpen}
          subSectionTitle={subSectionTitle}
          setSubSectionTitle={setSubSectionTitle}
          createNewSubSection={createNewSubSection}
          customModalOpen={customModalOpen}
        />
      )}
      {customModalOpen?.state === "addquestion" && (
        <AddNewQuestion
          setCustomModalOpen={setCustomModalOpen}
          setTaxForm={setTaxForm}
          taxForm={taxForm}
          activeSectionId={activeSectionId}
          activeSubSectionId={activeSubSectionId}
          setAddOptionModalOpen={setAddOptionModalOpen}
          setCustomDropdownOptions={setCustomDropdownOptions}
          customDropdownOptions={customDropdownOptions}
          addOptionModalOpen={addOptionModalOpen}
          setAddCardColumnModalOpen={setAddCardColumnModalOpen}
          CardFields={CardFields}
          setCardFields={setCardFields}
          setAddCardDropdownOptionModalOpen={setAddCardDropdownOptionModalOpen}
          setAddGridDropdownOptionModalOpen={setAddGridDropdownOptionModalOpen}
          setAddGridColumnModalOpen={setAddGridColumnModalOpen}
          setAddGridRowModalOpen={setAddGridRowModalOpen}
          gridData={gridData}
          setGridData={setGridData}
          editmode={customModalOpen?.editmode}
          questionToEdit={customModalOpen?.questionToEdit}
          moreOptions={moreOptions}
          setMoreOptions={setMoreOptions}
          setEditCardColumnTitleModalOpen={setEditCardColumnTitleModalOpen}
          setEditGridColumnTitleModalOpen={setEditGridColumnTitleModalOpen}
          setEditGridRowTitleModalOpen={setEditGridRowTitleModalOpen}
          listFields={listFields}
          setListFields={setListFields}
          setAddListColumnModalOpen={setAddListColumnModalOpen}
          setEditListColumnTitleModalOpen={setEditListColumnTitleModalOpen}
          setAddListDropdownOptionModalOpen={setAddListDropdownOptionModalOpen}
        />
      )}
      {customModalOpen?.state === "deletesection" && (
        <CustomDeleteSectionModal
          setCustomModalOpen={setCustomModalOpen}
          customModalOpen={customModalOpen}
          setTaxForm={setTaxForm}
          taxForm={taxForm}
        />
      )}
      {customModalOpen?.state === "deletesubsection" && (
        <CustomDeleteSubSectionModal
          setCustomModalOpen={setCustomModalOpen}
          customModalOpen={customModalOpen}
          setTaxForm={setTaxForm}
          taxForm={taxForm}
        />
      )}
      {customModalOpen?.state === "editsubsection" && (
        <EditSubSectionModal
          taxForm={taxForm}
          setTaxForm={setTaxForm}
          setCustomModalOpen={setCustomModalOpen}
          activeSectionId={activeSectionId}
          activeSubSectionId={activeSubSectionId}
          setActiveSubSectionId={setActiveSubSectionId}
        />
      )}
      {customModalOpen?.state === "editsectiontitle" && (
        <EditSectionModal
          taxForm={taxForm}
          setTaxForm={setTaxForm}
          setCustomModalOpen={setCustomModalOpen}
          customModalOpen={customModalOpen}
        />
      )}
      {editformtitleModalOpen && (
        <EditFormTitleModal
          formTitle={formTitle}
          setFormTitle={setFormTitle}
          setEditFormTitleModalOpen={setEditFormTitleModalOpen}
        />
      )}
      {editCardColumnTitleModalOpen?.state === "editcardcolumntitle" && (
        <EditCardColumnTitleModal
          editCardColumnTitleModalOpen={editCardColumnTitleModalOpen}
          setEditCardColumnTitleModalOpen={setEditCardColumnTitleModalOpen}
          CardFields={CardFields}
          setCardFields={setCardFields}
        />
      )}
      {editListColumnTitleModalOpen?.state === "editlistcolumntitle" && (
        <EditListColumnTitleModal
          editListColumnTitleModalOpen={editListColumnTitleModalOpen}
          setEditListColumnTitleModalOpen={setEditListColumnTitleModalOpen}
          listFields={listFields}
          setListFields={setListFields}
        />
      )}
      
       {editGridColumnTitleModalOpen?.state === "editgridcolumntitle" && (
        <EditGridColumnTitleModal
          editGridColumnTitleModalOpen={editGridColumnTitleModalOpen}
          setEditGridColumnTitleModalOpen={setEditGridColumnTitleModalOpen}
          gridData={gridData}
          setGridData={setGridData}
        />
      )}
       {editGridRowTitleModalOpen?.state === "editgridrowtitle" && (
        <EditGridRowTitleModal
          editGridRowTitleModalOpen={editGridRowTitleModalOpen}
          setEditGridRowTitleModalOpen={setEditGridRowTitleModalOpen}
          gridData={gridData}
          setGridData={setGridData}
        />
      )}
      {addOptionModalOpen && (
        <CustomAddOptionModal
          setCustomModalOpen={setCustomModalOpen}
          customModalOpen={customModalOpen}
          setCustomDropdownOptions={setCustomDropdownOptions}
          setAddOptionModalOpen={setAddOptionModalOpen}
          customDropdownOptions={customDropdownOptions}
        />
      )}
      {addCardColumnModalOpen && (
        <AddCardColumnModal
          setCustomModalOpen={setCustomModalOpen}
          customModalOpen={customModalOpen}
          setCardFields={setCardFields}
          setAddCardColumnModalOpen={setAddCardColumnModalOpen}
          addCardColumnModalOpen={addCardColumnModalOpen}
        />
      )}
      {addCardDropdownOptionModalOpen.state && (
        <CustomAddCardOptionModal
          setCustomModalOpen={setCustomModalOpen}
          customModalOpen={customModalOpen}
          addCardDropdownOptionModalOpen={addCardDropdownOptionModalOpen}
          setAddCardDropdownOptionModalOpen={setAddCardDropdownOptionModalOpen}
          CardFields={CardFields}
          setCardFields={setCardFields}
        />
      )}
      {addGridDropdownOptionModalOpen.state && (
          <CustomAddGridOptionsModal
              setCustomModalOpen={setCustomModalOpen}
              customModalOpen={customModalOpen}
              addGridDropdownOptionModalOpen={addGridDropdownOptionModalOpen}
              setAddGridDropdownOptionModalOpen={setAddGridDropdownOptionModalOpen}
              gridData={gridData}
              setGridData={setGridData}
          />
      )}
        {addListDropdownOptionModalOpen.state && (
        <CustomAddListOptionModal
           addListDropdownOptionModalOpen={addListDropdownOptionModalOpen}
          setAddListDropdownOptionModalOpen={setAddListDropdownOptionModalOpen}
          listFields={listFields}
          setListFields={setListFields}
        />
      )}
      {addGridColumnModalOpen.state && (
        <AddGridColumnModal
          gridData={gridData}
          setAddGridColumnModalOpen={setAddGridColumnModalOpen}
          setGridData={setGridData}
        />
      )}
      {addGridRowModalOpen.state && (
        <AddGridRowModal
          gridData={gridData}
          setAddGridRowModalOpen={setAddGridRowModalOpen}
          setGridData={setGridData}
        />
      )}
      {
        addListColumnModalOpen &&
         <AddListColumnModal 
          listFields={listFields}
          setListFields={setListFields}
          setAddListColumnModalOpen={setAddListColumnModalOpen}
        />
      }
      	<Modal
				title="Document Viewer"
				bodyClass="p-4 text-center text-dark text-opacity-50"
				trigger="openDocumentModal"
				showBtn={false}
				btnClass="documentModalClose"
				size="lg"
			>
				<div className="clearfix">
					{selectedDocument &&
						(selectedDocument?.location?.includes('.pdf') ? (
							<>
								<RenderMultiPagePDF file={selectedDocument.path} />
							</>
						) : (
							<img
								src={selectedDocument?.path}
								className="w-100 img-fluid rounded"
								alt={selectedDocument?.name}
								style={{ objectFit: 'contain' }}
							/>
						))}
						{/* {downloadOption?
					<Link
						to={selectedDocument?.path}
						className="btn btn-outline-primary btn-md d-inline-block mt-2"
						target="_blank"
						download
					>
						Download
					</Link>
					:
					<small className="text-danger  mb-0 ">Document will be available for download once the balance is paid</small>

					} */}
				</div>
			</Modal>
      <div className='d-flex justify-content-end'>
        <img src={CloseSvg} className='cursor-pointer mb-3' alt='close' onClick={() => {
             document.body.style.overflowY = '';
          setCreateFormModalOpen(null)}
        }
          
          />
      </div>
      <div className="row g-5 justify-content-center">
        <div className="col-lg-4">
          <div className="border-cont w-100">
            <div className="d-flex gap-3">
              <div
                onClick={() => {
                  setCustomModalOpen({ state: "sectiontitle", data: {} });
                }}
                className="w-100 d-flex justify-content-between align-items-center sections-bg "
              >
                <div className="">Sections</div>
                <img
                  className="cursor-pointer icon-delete "
                  src={CreateSectionIcon}
                  alt="Create section"
                />
              </div>
            </div>
            <div>
              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, taxForm, setTaxForm)}
              >
                <Droppable droppableId="sections" type="section">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {taxForm?.sections?.map((section, index) => (
                        <Draggable
                          key={section?.id}
                          draggableId={`section-${section?.id}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="d-flex flex-column"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div className="d-flex justify-content-between p-3 border-bottom align-items-center">
                                <div className="d-flex align-items-center gap-3">
                                  <img
                                    src={DotsIcon}
                                    className="icon-dots"
                                    alt="icon"
                                    {...provided.dragHandleProps}
                                  />
                                  <div
                                    className={`text-start custom-font cursor-pointer ${
                                      activeSectionId === section?.id
                                        ? "text-primary"
                                        : "text-black"
                                    }`}
                                    onClick={() =>
                                      setActiveSectionId(section?.id)
                                    }
                                  >
                                    {section?.name}
                                  </div>
                                  <img
                                    className="cursor-pointer px-3"
                                    src={CreateSectionIcon}
                                    alt="Create section"
                                    onClick={() =>
                                      setCustomModalOpen({
                                        state: "subsectiontitle",
                                        data: section?.id,
                                      })
                                    }
                                  />
                                </div>
                                <div className="d-flex gap-2">
                                  <img
                                    src={EditIcon}
                                    alt="icon"
                                    className="icon-delete"
                                    onClick={() =>
                                      setCustomModalOpen({
                                        state: "editsectiontitle",
                                        data: { sectionId: section?.id },
                                      })
                                    }
                                  />
                                  <img
                                    src={DeleteIcon2}
                                    alt="icon"
                                    className="icon-delete"
                                    onClick={() =>
                                      setCustomModalOpen({
                                        state: "deletesection",
                                        data: { sectionId: section?.id },
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <Droppable
                                droppableId={`${index}`}
                                type="subsection"
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    {section?.subsections?.map(
                                      (subsection, subIndex) => (
                                        <Draggable
                                          key={subsection?.id}
                                          draggableId={`subsection-${subsection?.id}`}
                                          index={subIndex}
                                        >
                                          {(provided) => (
                                            <div
                                              className="d-flex justify-content-between py-2 sub-section-cont"
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                            >
                                              <div className="d-flex align-items-center">
                                                <img
                                                  src={DotsIcon}
                                                  className="icon-dots-sub"
                                                  alt="icon"
                                                  {...provided.dragHandleProps}
                                                />
                                                <div
                                                  className={`text-start mx-3 custom-font-sub cursor-pointer ${
                                                    activeSubSectionId ===
                                                    subsection?.id
                                                      ? "text-primary"
                                                      : ""
                                                  }`}
                                                  onClick={() => {
                                                    setActiveSubSectionId(
                                                      subsection?.id
                                                    );
                                                    setActiveSectionId(
                                                      section?.id
                                                    );
                                                  }}
                                                >
                                                  {subsection?.name}
                                                </div>
                                              </div>
                                              <img
                                                src={DeleteIcon2}
                                                alt="icon"
                                                className="icon-delete"
                                                onClick={() =>
                                                  setCustomModalOpen({
                                                    state: "deletesubsection",
                                                    data: {
                                                      sectionId: section?.id,
                                                      subsectionId:
                                                        subsection?.id,
                                                    },
                                                  })
                                                }
                                              />
                                            </div>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div
            style={{ borderColor: "red", border: "1px solid #DEE2E6" }}
            className="p-3 w-100  rounded"
          >
            <div className="form-heading d-flex align-items-center gap-2">
              {formTitle} 
              {adminEditMode && 
               <img src={EditIcon} alt='edit'  className='cursor-pointer' onClick={() => setEditFormTitleModalOpen(true)} />
              }
             </div>
            <div className="form-questoins-cont">
              {activeSubSectionId && (
                <div className="form-seub-section-title d-flex gap-3 align-items-center">
                  {
                    taxForm?.sections
                      ?.find((section) => section?.id === activeSectionId)
                      ?.subsections?.find(
                        (subsection) => subsection?.id === activeSubSectionId
                      )?.name
                  }
                  <img
                    src={EditIcon}
                    alt="edit"
                    className="cursor-pointer"
                    onClick={() =>
                      setCustomModalOpen({
                        state: "editsubsection",
                        data: taxForm?.sections
                          ?.find((section) => section?.id === activeSectionId)
                          ?.subsections?.find(
                            (subsection) =>
                              subsection?.id === activeSubSectionId
                          )?.id,
                      })
                    }
                  />
                </div>
              )}
            </div>
            <div className="d-flex flex-column">
              {taxForm?.sections?.length > 0 &&
                activeSectionId &&
                activeSubSectionId && (
                  <div className="add-cuestions-cont">
                    <div className="text-center add-question-title">
                      Add Question
                    </div>
                    <div className="text-center add-question-description">
                      {activeSubSectionId
                        ? "Click the button below to add a question to this section and specify the field type. You can pick from text fields, dropdowns, lists and more."
                        : "To add a new question, you need to create a sub-section first"}
                    </div>
                  </div>
                )}

              {!activeSubSectionId && (
                <div className="add-cuestions-cont">
                  <div className="text-center add-question-title">
                    Add Sub-section
                  </div>
                  <div className="text-center add-question-description">
                    {activeSubSectionId
                      ? "Click the button below to add a question to this section and specify the field type. You can pick from text fields, dropdowns, lists and more."
                      : "To add a new question, you need to create a sub-section first"}
                  </div>
                </div>
              )}
              <DragDropContext onDragEnd={onQuestionDrag}>
                {activeSectionId && activeSubSectionId && (
                  <Droppable droppableId="questions">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {taxForm.sections
                          ?.find((sec) => sec?.id === activeSectionId)
                          ?.subsections?.find(
                            (subsection) =>
                              subsection?.id === activeSubSectionId
                          )
                          ?.fields?.map((question, index) => (
                            <Draggable
                              key={question?.id}
                              draggableId={question?.id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className=""
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  {(() => {
                                     if (question?.type === "divider") {
                                      return (
                                        <div className="section-divider">
                                          <div className="d-flex align-items-center">
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <div className="divider-title">
                                              {question?.question}
                                            </div>
                                          </div>
                                          <div className='d-flex gap-2'>
                                             <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                            <img
                                              src={DeleteIcon2}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() => {
                                               setTaxForm((prevForm) => {
                                                 return {
                                                  ...prevForm,
                                                  sections: prevForm?.sections?.map((sec) => {
                                                    if(sec?.id === activeSectionId){
                                                      return {
                                                        ...sec,
                                                        subsections: sec?.subsections?.map((sub) => {
                                                          if(sub?.id === activeSubSectionId){
                                                            return {
                                                              ...sub,
                                                              fields:sub?.fields?.filter(field => field?.question !== question?.question)
                                                            }
                                                          }
                                                          return sub
                                                        })
                                                      }
                                                    }
                                                    return sec
                                                  })
                                                }
                                               })
                                              }}
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if(question?.type === 'horizontal-line'){
                                      return <div className='d-flex justify-content-between align-items-center gap-2 deductions-cont'>
                                         <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <div className='horizontal-hr'></div>
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                         </div>
                                    }
                                    if (question?.type === "text-one-line") {
                                      return (
                                        <div
                                          className={`question-cont gap-2 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                            <div className='d-flex align-items-center'>
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <label htmlFor={question?.question}>
                                              {question?.question}
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="text"
                                              id={question?.question}
                                              placeholder="Enter your answer here..."
                                              className="question-input-form input-cont"
                                              value={question?.answer}
                                              onChange={(e) => handleQuestionInputChange(e.target.value,question?.question)}
                                            />
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (question?.type === "text-header") {
                                      return (
                                        <div
                                        style={{padding:'10px 20px'}}
                                          className={` d-flex mt-2  gap-2 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon mt-2"
                                              {...provided.dragHandleProps}
                                            />
                                          <div 
                                           className='w-100'
                                          style={{
                                            fontSize:question?.fontSize ? `${question?.fontSize}px` : '20px',
                                            color:question?.color || 'black',
                                            fontStyle:question?.fontStyle?.value || 'normal',
                                            fontWeight:question?.fontWeight?.value || 'normal',
                                            textAlign: question?.textPosition?.value ||  'center',
                                          }}
                                          >{question?.question}</div>
                                          <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon mt-2"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                        </div>
                                      );
                                    }
                                    if (question?.type === "file-upload") {
                                      return (
                                        <div
                                          key={index}
                                          className={`d-flex flex-column  gap-2 mt-3  ${
                                            question?.condition
                                              ? "condition-cont"
                                              : ""
                                          }`}
                                          style={{padding:'0 20px'}}
                                        >
                                          <div className='d-flex justify-content-between align-items-center'>
                                          <div className="d-flex align-items-center">
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <label htmlFor={question?.question}>
                                              {question?.question}
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                          
                                            {!uploading && (
                                              <FileUploader
                                                classes="form-control mx-auto m-w-25 file-upload file-upload-custom-styles "
                                                multiple={false}
                                                values={[]}
                                                handleChange={(files) => {
                                                  handleFileUpload(
                                                    files,
                                                    question?.id,
                                                    setActiveFileId
                                                  );
                                                }}
                                                name="documents"
                                                types={[
                                                  "jpg",
                                                  "png",
                                                  "jpeg",
                                                  "pdf",
                                                ]}
                                                maxSize={5}
                                                onSizeError={() => {
                                                  console.log(
                                                    "File size exceeds the limit"
                                                  );
                                                }}
                                                onTypeError={() => {
                                                  console.log(
                                                    "Incorrect file type"
                                                  );
                                                }}
                                                label="Click here or Drag & Drop files to upload documents"
                                              />
                                            )}

                                            {uploading &&
                                              question?.id === activeFileID && (
                                                <div className="progress w-100 custom-progress-styles">
                                                  <div
                                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                                    role="progressbar"
                                                    style={{
                                                      width: `${uploadProgress}%`,
                                                    }}
                                                    aria-valuenow={
                                                      uploadProgress
                                                    }
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                  >
                                                    {uploadProgress}%
                                                  </div>
                                                </div>
                                              )}
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                          </div>
                                          </div>
                                          {question?.file?.length > 0 ? (
                                              <div className="d-flex flex-column gap-2 align-items-end w-100 ">
                                                {question?.file?.map((file,idx) => {
                                                  return (
                                                      <div key={idx}
                                                           className="d-flex  justify-content-end align-items-center w-100 mt-3 gap-3">
                                                        <div className="text-primary">{`taxDoc${file?.extension}`}</div>
                                                        <div
                                                            className="d-flex justify-content-end align-items-center gap-2">
                                                          <div
                                                              onClick={() =>
                                                                  setSelectedDocument(
                                                                      file
                                                                  )
                                                              }
                                                              className="file-open-icon-cont d-flex align-items-center justify-content-center"
                                                              role="button"
                                                              data-bs-toggle="modal"
                                                              data-bs-target="#openDocumentModal"
                                                              aria-controls="openDocumentModal"
                                                              data-bs-id={file.uuid}
                                                              key={index}
                                                          >
                                                            <VscEye
                                                                color="#4B57E7"
                                                                size={13}
                                                            />
                                                          </div>
                                                          <img
                                                              style={{
                                                                height: "20px",
                                                              }}
                                                              src={DeleteIcon2}
                                                              className="cursor-pointer"
                                                              alt="delete"
                                                              onClick={() => {
                                                                console.log();
                                                                handleDeleteFile(
                                                                    file?.uuid,
                                                                    question?.id,
                                                                    setActiveFileId
                                                                );
                                                              }}
                                                          />
                                                        </div>
                                                      </div>
                                                  );
                                                })}
                                              </div>
                                            ) : null}
                                        </div>
                                      );
                                    }
                                    if (question?.type === "text-multi-line") {
                                      return (
                                        <div
                                          className={`question-cont gap-2 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                          <div className='d-flex align-items-center '>
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <label  htmlFor={question?.question}>
                                              {question?.question}
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <textarea
                                              id={question?.question}
                                              placeholder="Enter your answer here..."
                                              className="question-textarea input-cont"
                                              value={question?.answer}
                                              onChange={(e) => handleQuestionInputChange(e.target.value,question?.question)}                                
                                            ></textarea>
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (
                                      question?.type === "pre-defined-partners"
                                    ) {
                                      return (
                                        <div className="border border-1 rounded p-3 mt-3">
                                          <div className='d-flex justify-content-between align-items-center gap-2 deductions-cont'>
                                         <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                         </div>
                                          <div className="my-3">
                                            <table className="table">
                                              <thead className="table-cont">
                                                <tr>
                                                  <th scope="col">
                                                    Partner name
                                                  </th>
                                                  <th scope="col">
                                                    Partner type
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {question?.answer?.map(
                                                  (partner) => {
                                                    return (
                                                      <tr
                                                        key={index}
                                                        className="dependant-rows"
                                                      >
                                                        <td>
                                                          {partner?.type_of_entity === 'Individual' ? partner?.firstname : partner?.entity_name}
                                                        </td>
                                                        <td>
                                                          {
                                                            partner?.type_of_entity
                                                          }
                                                        </td>
                                                        <td className="d-flex align-items-center gap-2">
                                                          <img
                                                            onClick={() => {
                                                              setPartnerToEdit({
                                                                state: true,
                                                                data: partner,
                                                              });
                                                            }}
                                                            className="cursor-pointer"
                                                            style={{
                                                              height: "24px",
                                                            }}
                                                            src={EditIcon}
                                                            alt="edit"
                                                          />
                                                          <img
                                                            className="cursor-pointer"
                                                            style={{
                                                              height: "24px",
                                                            }}
                                                            src={DeleteIcon2}
                                                            alt="delete"
                                                            onClick={() => {
                                                              setTaxForm((prevForm) => {
                                                                return {
                                                                  ...prevForm,
                                                                  sections:taxForm?.sections?.map(section => {
                                                                    return {
                                                                      ...section,
                                                                      subsections: section?.subsections?.map((sub) => {
                                                                        if(sub?.id === activeSubSectionId){
                                                                          return {
                                                                            ...sub,
                                                                            fields: sub?.fields?.map(field => {
                                                                              if (field?.id === question?.id) {
                                                                                return {
                                                                                  ...field,
                                                                                  answer: field?.answer?.filter((part) => part?.id !== partner?.id), 
                                                                                };
                                                                              }
                                                                              return { ...field }; 
                                                                            })
                                                                          }
                                                                        }
                                                                        return sub
                                                                      } )
                                                                    }
                                                                  })
                                                                }
                                                              })
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </table>
                                            {addPartner === false && (
                                              <div
                                                className="text-primary fw-bold text-center cursor-pointer"
                                                onClick={() => {
                                                  setAddPartner(true);
                                                }}
                                              >
                                                + Add partner
                                              </div>
                                            )}
                                          </div>
                                          {(addPartner ||
                                            partnerToEdit?.state) && (
                                            <PartnersList
                                              setAddPartner={setAddPartner}
                                              taxForm={taxForm}
                                              setTaxForm={setTaxForm}
                                              question={question}
                                              activeSection={activeSectionId}
                                              handleSavePartner={handleSavePartner}
                                              handleSaveEditedPartner={handleSaveEditedPartner}
                                              activeSubSection={
                                                activeSubSectionId
                                              }
                                              partnerToEdit={partnerToEdit}
                                              setPartnerToEdit={
                                                setPartnerToEdit
                                              }
                                            />
                                          )}
                                        </div>
                                      );
                                    }
                                    if (
                                        question?.type === "pre-defined-shareholders"
                                    ) {
                                      return (
                                          <div className="border border-1 rounded p-3 mt-3">
                                            <div className='d-flex justify-content-between align-items-center gap-2 deductions-cont'>
                                              <img
                                                  src={DotsIcon}
                                                  alt="dots"
                                                  className="dots-icon"
                                                  {...provided.dragHandleProps}
                                              />
                                              <img
                                                  src={EditIcon}
                                                  alt="edit"
                                                  className="delete-section-icon"
                                                  onClick={() =>
                                                      setCustomModalOpen({
                                                        state: "addquestion",
                                                        editmode: true,
                                                        questionToEdit: question,
                                                      })
                                                  }
                                              />
                                            </div>
                                            <div className="my-3">
                                              <table className="table">
                                                <thead className="table-cont">
                                                <tr>
                                                  <th scope="col">
                                                   Shareholder name
                                                  </th>
                                                  <th scope="col">
                                                    Taxpayer identification number
                                                  </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {question?.answer?.map(
                                                    (partner) => {
                                                      return (
                                                          <tr
                                                              key={index}
                                                              className="dependant-rows"
                                                          >
                                                            <td>
                                                              {partner?.type_of_entity === 'Individual' ? partner?.firstname : partner?.entity_name}
                                                            </td>
                                                            <td>
                                                              {partner?.type_of_entity === 'Individual' ? partner?.ssn : partner?.ein}
                                                            </td>
                                                            <td className="d-flex align-items-center gap-2">
                                                              <img
                                                                  onClick={() => {
                                                                    setShareholderToEdit({
                                                                      state: true,
                                                                      data: partner,
                                                                    });
                                                                  }}
                                                                  className="cursor-pointer"
                                                                  style={{
                                                                    height: "24px",
                                                                  }}
                                                                  src={EditIcon}
                                                                  alt="edit"
                                                              />
                                                              <img
                                                                  className="cursor-pointer"
                                                                  style={{
                                                                    height: "24px",
                                                                  }}
                                                                  src={DeleteIcon2}
                                                                  alt="delete"
                                                                  onClick={() => {
                                                                    setTaxForm((prevForm) => {
                                                                      return {
                                                                        ...prevForm,
                                                                        sections:taxForm?.sections?.map(section => {
                                                                          return {
                                                                            ...section,
                                                                            subsections: section?.subsections?.map((sub) => {
                                                                              if(sub?.id === activeSubSectionId){
                                                                                return {
                                                                                  ...sub,
                                                                                  fields: sub?.fields?.map(field => {
                                                                                    if (field?.id === question?.id) {
                                                                                      return {
                                                                                        ...field,
                                                                                        answer: field?.answer?.filter((part) => part?.id !== partner?.id),
                                                                                      };
                                                                                    }
                                                                                    return { ...field };
                                                                                  })
                                                                                }
                                                                              }
                                                                              return sub
                                                                            } )
                                                                          }
                                                                        })
                                                                      }
                                                                    })
                                                                  }}
                                                              />
                                                            </td>
                                                          </tr>
                                                      );
                                                    }
                                                )}
                                                </tbody>
                                              </table>
                                              {addPartner === false && (
                                                  <div
                                                      className="text-primary fw-bold text-center cursor-pointer"
                                                      onClick={() => {
                                                        setAddShareholder(true);
                                                      }}
                                                  >
                                                    + Add shareholder
                                                  </div>
                                              )}
                                            </div>
                                            {(addShareholder ||
                                                shareholderToEdit?.state) && (
                                                <ShareholdersList
                                                    corporation={question?.corporation || ''}
                                                    setAddShareholder={setAddShareholder}
                                                    taxForm={taxForm}
                                                    setTaxForm={setTaxForm}
                                                    question={question}
                                                    activeSection={activeSectionId}
                                                    handleSaveShareholder={handleSavePartner}
                                                    handleSaveEditedShareholder={handleSaveEditedPartner}
                                                    activeSubSection={
                                                      activeSubSectionId
                                                    }
                                                    shareholderToEdit={shareholderToEdit}
                                                    setShareholderToEdit={
                                                      setShareholderToEdit
                                                    }
                                                />
                                            )}
                                          </div>
                                      );
                                    }
                                    if (
                                        question?.type === "pre-defined-officers"
                                    ) {
                                      return (
                                          <div className="border border-1 rounded p-3 mt-3">
                                            <div
                                                className='d-flex justify-content-between align-items-center gap-2 deductions-cont'>
                                              <img
                                                  src={DotsIcon}
                                                  alt="dots"
                                                  className="dots-icon"
                                                  {...provided.dragHandleProps}
                                              />
                                              <img
                                                  src={EditIcon}
                                                  alt="edit"
                                                  className="delete-section-icon"
                                                  onClick={() =>
                                                      setCustomModalOpen({
                                                        state: "addquestion",
                                                        editmode: true,
                                                        questionToEdit: question,
                                                      })
                                                  }
                                              />
                                            </div>
                                            <div className="my-3">
                                              <table className="table">
                                                <thead className="table-cont">
                                                <tr>
                                                  <th scope="col">
                                                    Officer name
                                                  </th>
                                                  <th scope="col">
                                                    Title
                                                  </th>
                                                  <th scope="col">
                                                    Compensation
                                                  </th>
                                                  <th scope="col">
                                                    Shareholder
                                                  </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {question?.answer?.map(
                                                    (officer) => {
                                                      return (
                                                          <tr
                                                              key={index}
                                                              className="dependant-rows"
                                                          >
                                                            <td>
                                                              {officer?.firstname}
                                                            </td>
                                                            <td>
                                                              {
                                                                officer?.title
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                officer?.compensation
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                officer?.shareholder ? 'Yes' : "No"
                                                              }
                                                            </td>
                                                            <td className="d-flex align-items-center gap-2">
                                                              <img
                                                                  onClick={() => {
                                                                    setOfficerToEdit({
                                                                      state: true,
                                                                      data: officer,
                                                                    });
                                                                  }}
                                                                  className="cursor-pointer"
                                                                  style={{
                                                                    height: "24px",
                                                                  }}
                                                                  src={EditIcon}
                                                                  alt="edit"
                                                              />
                                                              <img
                                                                  className="cursor-pointer"
                                                                  style={{
                                                                    height: "24px",
                                                                  }}
                                                                  src={DeleteIcon2}
                                                                  alt="delete"
                                                                  onClick={() => {
                                                                    setTaxForm((prevForm) => {
                                                                      return {
                                                                        ...prevForm,
                                                                        sections: taxForm?.sections?.map(section => {
                                                                          return {
                                                                            ...section,
                                                                            subsections: section?.subsections?.map((sub) => {
                                                                              if (sub?.id === activeSubSectionId) {
                                                                                return {
                                                                                  ...sub,
                                                                                  fields: sub?.fields?.map(field => {
                                                                                    if (field?.id === question?.id) {
                                                                                      return {
                                                                                        ...field,
                                                                                        answer: field?.answer?.filter((part) => part?.id !== officer?.id),
                                                                                      };
                                                                                    }
                                                                                    return {...field};
                                                                                  })
                                                                                }
                                                                              }
                                                                              return sub
                                                                            })
                                                                          }
                                                                        })
                                                                      }
                                                                    })
                                                                  }}
                                                              />
                                                            </td>
                                                          </tr>
                                                      );
                                                    }
                                                )}
                                                </tbody>
                                              </table>
                                              {addPartner === false && (
                                                  <div
                                                      className="text-primary fw-bold text-center cursor-pointer"
                                                      onClick={() => {
                                                        setAddOfficer(true);
                                                      }}
                                                  >
                                                    + Add officer
                                                  </div>
                                              )}
                                            </div>
                                            {(addOfficer ||
                                                officerToEdit?.state) && (
                                                <OfficersList
                                                    corporation={question?.corporation || ''}
                                                    setAddOfficer={setAddOfficer}
                                                    taxForm={taxForm}
                                                    setTaxForm={setTaxForm}
                                                    question={question}
                                                    activeSection={activeSectionId}
                                                    handleSaveOfficer={handleSaveOfficer}
                                                    handleSaveEditeOfficer={handleSaveEditeOfficer}
                                                    activeSubSection={
                                                      activeSubSectionId
                                                    }
                                                    officerToEdit={officerToEdit}
                                                    setOfficerToEdit={
                                                      setOfficerToEdit
                                                    }
                                                />
                                            )}
                                            {question?.answer?.find(off => off?.will_sign_tax_return) ? <>
                                              <hr className={'mt-5'}/>
                                              <div className="fw-bold">The following officer has been selected to sign
                                                the
                                                return
                                              </div>
                                              <div>{question?.answer?.find(off => off?.will_sign_tax_return)?.firstname}</div>
                                              <div
                                                  className="fw-bold d-flex justify-content-between align-items-center gap-2">
                                                <div>Is this officer the primary contact person for the corporation?
                                                </div>
                                                <div className="d-flex gap-3">
                                                  {['yes', 'no']?.map((answer) => {
                                                    return (
                                                        <div
                                                            className={`yes-no-cont ${
                                                                question?.isPrimaryContact === answer
                                                                    ? "yes-no-cont-active"
                                                                    : ""
                                                            }`}
                                                            onClick={() => {
                                                              setTaxForm((prevForm) => {
                                                                return {
                                                                  ...prevForm,
                                                                  sections:taxForm?.sections?.map(section => {
                                                                    return {
                                                                      ...section,
                                                                      subsections: section?.subsections?.map((sub) => {
                                                                        if(sub?.id === activeSubSectionId){
                                                                          return {
                                                                            ...sub,
                                                                            fields: sub?.fields?.map(field => {
                                                                              if (field?.id === question?.id) {
                                                                                return {
                                                                                  ...field,
                                                                                  isPrimaryContact:answer
                                                                                };
                                                                              }
                                                                              return { ...field };
                                                                            })
                                                                          }
                                                                        }
                                                                        return sub
                                                                      } )
                                                                    }
                                                                  })
                                                                }
                                                              })
                                                            }}
                                                        >
                                                          {answer}
                                                        </div>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                              {question?.isPrimaryContact === 'no' && <>
                                                <div className={'mt-4'}>Primary contact</div>
                                                <hr className={'mt-1'}/>
                                                <div className="fw-bold mt-3">First name</div>
                                                <div className="w-50 mt-2">
                                                  <input
                                                      type="text"
                                                      className="w-full form-control"
                                                      onChange={(e) => handlePrimaryContactInfoChange("firstname",e.target.value,question)}
                                                      value={question?.primaryContactInfo?.firstname || ''}
                                                  />
                                                </div>
                                                <div className="fw-bold mt-3">Middle initial</div>
                                                <div className="w-50 mt-2">
                                                  <input
                                                      type="text"
                                                      className="w-full form-control"
                                                      onChange={(e) => handlePrimaryContactInfoChange("middleInitial",e.target.value,question)}
                                                      value={question?.primaryContactInfo?.middleInitial || ""}
                                                  />
                                                </div>
                                                <div className="fw-bold mt-3">Last name</div>
                                                <div className="w-50 mt-2">
                                                  <input
                                                      type="text"
                                                      className="w-full form-control"
                                                      onChange={(e) => handlePrimaryContactInfoChange("lastname",e.target.value,question)}
                                                      value={question?.primaryContactInfo?.lastname || ""}
                                                  />
                                                </div>
                                                <div className="fw-bold mt-3">Title</div>
                                                <div className="w-50 mt-2">
                                                  <input
                                                      type="text"
                                                      className="w-full form-control"
                                                      onChange={(e) => handlePrimaryContactInfoChange("title",e.target.value,question)}
                                                      value={question?.primaryContactInfo?.title || ""}
                                                  />
                                                </div>
                                                <div className="fw-bold mt-3">SSN</div>
                                                <div className="w-50 mt-2">
                                                  <Input
                                                      className={`form-control`}
                                                      value={question?.primaryContactInfo?.ssn || ""}
                                                      fn={(e) => {
                                                        handlePrimaryContactInfoChange("ssn",e,question)
                                                      }}
                                                      placeholder=""
                                                      showLabel={false}
                                                      mask={"999-99-9999"}
                                                      max={10}
                                                  />
                                                </div>
                                                <div className="fw-bold mt-3">Email</div>
                                                <div className="w-50 mt-2">
                                                  <input
                                                      type="text"
                                                      className="w-full form-control"
                                                      onChange={(e) => handlePrimaryContactInfoChange("email",e.target.value,question)}
                                                      value={question?.primaryContactInfo?.email || ""}
                                                  />
                                                </div>
                                                <div className="fw-bold mt-3">Phone number</div>
                                                <div className="w-50 mt-2">
                                                  <Input
                                                      className={`form-control`}
                                                      value={question?.primaryContactInfo?.phone_number || ""}
                                                      fn={(e) => {
                                                        handlePrimaryContactInfoChange("phone_number",e,question)

                                                      }}
                                                      placeholder=""
                                                      showLabel={false}
                                                      mask={"(999) 999-9999"}
                                                      max={10}
                                                  />
                                                </div>
                                              </>}
                                            </> : question?.answer?.length > 0 ?
                                                <div>
                                                  <div className={'fw-bold'}>No signing officer selected</div>
                                                  <hr/>
                                                  <div className={'mt-3'}>Select the officer who will sign the tax
                                                    return. Create or open an entry for the signing officer above and
                                                    check the "Officer will sign the tax return" box.
                                                  </div>
                                                </div>
                                                : null}
                                          </div>

                                      );
                                    }
                                    if (
                                        question?.type ===
                                        "pre-defined-deductions-list"
                                    ) {
                                      return (
                                          <div
                                              className={`d-flex mt-3 deductions-cont justify-content-between gap-2 ${
                                                  question?.condition
                                                      ? "condition-cont"
                                                      : ""
                                              }`}
                                          >
                                            <div className="d-flex  ">
                                              <img
                                                  src={DotsIcon}
                                                  alt="dots"
                                                  className="dots-icon mt-2"
                                                  {...provided.dragHandleProps}
                                              />
                                              <div>
                                                <ItemizedDeductions
                                                    question={question?.question}
                                                />
                                              </div>
                                            </div>
                                            <div className="">
                                              <img
                                                  src={EditIcon}
                                                  alt="edit"
                                                  className="delete-section-icon"
                                                  onClick={() =>
                                                      setCustomModalOpen({
                                                        state: "addquestion",
                                                        editmode: true,
                                                        questionToEdit: question,
                                                      })
                                                  }
                                              />
                                            </div>
                                          </div>
                                      );
                                    }

                                    if (question?.type === "text-email") {
                                      return (
                                          <div
                                              className={`question-cont gap-2 ${
                                                  question?.condition ? "condition-cont" : ""
                                              }`}
                                          >
                                            <div className="d-flex align-items-center ">
                                              <img
                                                  src={DotsIcon}
                                                  alt="dots"
                                                  className="dots-icon"
                                                  {...provided.dragHandleProps}
                                              />
                                              <label htmlFor={question?.question}>
                                                {question?.question}
                                              </label>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                              <input
                                                  type="email"
                                                  id={question?.question}
                                                  placeholder="Enter your answer here..."
                                                  className="question-input-form input-cont"
                                                  value={question?.answer}
                                                  onChange={(e) => handleQuestionInputChange(e.target.value, question?.question)}
                                              />
                                              <img
                                                  src={EditIcon}
                                                  alt="edit"
                                                  className="delete-section-icon"
                                                  onClick={() =>
                                                      setCustomModalOpen({
                                                        state: "addquestion",
                                                        editmode: true,
                                                        questionToEdit: question,
                                                      })
                                                  }
                                              />
                                            </div>
                                          </div>
                                      );
                                    }
                                    if (question?.type === "text-comment") {
                                      return (
                                        <div
                                          className={`question-cont gap-2 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                          <div className="d-flex align-items-center ">
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <label htmlFor={question?.question}>
                                              {question?.question}
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <textarea
                                              id={question?.question}
                                              placeholder="Enter..."
                                              className="question-input-form input-cont"
                                              value={question?.answer}
                                              onChange={(e) => handleQuestionInputChange(e.target.value,question?.question)}
                                            ></textarea>
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (question?.type === "number-any-number") {
                                      return (
                                        <div
                                          className={`question-cont gap-2 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                          <div className="d-flex align-items-center">
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <label htmlFor={question?.question}>
                                              {question?.question}
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="number"
                                              id={question?.question}
                                              placeholder="Enter your answer here..."
                                              className="question-input-form input-cont"
                                              value={question?.answer}
                                              onChange={(e) => handleQuestionInputChange(e.target.value,question?.question)}
                                              onWheel={(e) => e.target.blur()}
                                            />
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (question?.type === "number-amount") {
                                      return (
                                        <div
                                          className={`question-cont ga-2 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                          <div className="d-flex align-items-center">
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <label htmlFor={question?.question}>
                                              {question?.question}
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <div className="amount-input-cont input-cont">
                                              <div className="amount-icon-cont">$</div>
                                              <input
                                                type="number"
                                                id={question?.question}
                                                placeholder="Enter your answer here..."
                                                value={question?.answer}
                                              onChange={(e) => handleQuestionInputChange(e.target.value,question?.question)}  
                                              onWheel={(e) => e.target.blur()}                                
                                              />
                                            </div>
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
              
                                    if (question?.type === "number-percentage") {
                                      return (
                                        <div
                                          className={`question-cont  gap-2 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                          <div className="d-flex align-items-center">
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <label htmlFor={question?.question}>
                                              {question?.question}
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <div className="amount-input-cont input-cont">
                                              <input
                                                type="number"
                                                id={question?.question}
                                                placeholder="Enter your answer here..."
                                                value={question?.answer}
                                                onChange={(e) => handleQuestionInputChange(e.target.value,question?.question)}
                                                onWheel={(e) => e.target.blur()}
                                              />
                                              <div className="amount-icon-cont">%</div>
                                            </div>
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (question?.type === "number-phone") {
                                      return (
                                        <div
                                          className={`question-cont gap-2 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                          <div className="d-flex align-items-center">
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <label htmlFor={question?.question}>
                                              {question?.question}
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="number"
                                              id={question?.question}
                                              placeholder="Enter your answer here..."
                                              className="question-input-form input-cont"
                                              value={question?.answer}
                                              onChange={(e) => handleQuestionInputChange(e.target.value,question?.question)}
                                              onWheel={(e) => e.target.blur()}
              
                                            />
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (question?.type === "number-zipcode") {
                                      return (
                                        <div
                                          className={`question-cont gap-2 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                          <div className="d-flex align-items-center">
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <label htmlFor={question?.question}>
                                              {question?.question}
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <input
                                              type="number"
                                              id={question?.question}
                                              placeholder="Enter your answer here..."
                                              className="question-input-form input-cont"
                                              value={question?.answer}
                                              onChange={(e) => handleQuestionInputChange(e.target.value,question?.question)}
                                              onWheel={(e) => e.target.blur()}
                                            />
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (question?.type === "yes-no") {
                                      return (
                                        <div
                                          className={`question-cont  gap-2 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                          <div className="d-flex align-items-center">
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <label htmlFor={question?.question}>
                                              {question?.question}
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <div className="d-flex gap-3">
                                              {question?.questionData?.map((answer) => {
                                                return (
                                                  <div
                                                    className={`yes-no-cont ${
                                                      question?.answer === answer
                                                        ? "yes-no-cont-active"
                                                        : ""
                                                    }`}
                                                     onClick={() => handleQuestionInputChange(answer,question?.question)}
                                                  >
                                                    {answer}
                                                  </div>
                                                );
                                              })}
                                            </div>
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (question?.type === "date") {
                                      return (
                                        <div
                                          className={`question-cont gap-2 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                          <div className="d-flex align-items-center">
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <label htmlFor={question?.question}>
                                              {question?.question}
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                          <DatePicker
                                         className="question-input-form input-cont"
                                         placeholder={question?.validation === 'year' ? "YYYY" : "DD/MM/YYYY"}
                                         format={question?.validation === 'year' ? "YYYY" : "DD/MM/YYYY"}
                                         disabledDate={
                                          question?.validation === 'birthdate'
                                            ? (current) => current && current.isAfter(dayjs())
                                            : undefined 
                                        }
                                         picker={
                                          question?.validation === 'year' ? "year" : undefined 
                                        }
                                        />
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (question?.type?.includes("dropdown")) {
                                      const options =
                                        question?.questionData?.map(
                                          (state) => ({
                                            value: state,
                                            label: state,
                                          })
                                        );
                                      return (
                                        <div
                                          className={`question-cont gap-2 ${
                                            question?.condition
                                              ? "condition-cont"
                                              : ""
                                          }`}
                                        >
                                          <div className="d-flex align-items-center">
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="dots-icon"
                                              {...provided.dragHandleProps}
                                            />
                                            <label htmlFor={question?.question}>
                                              {question?.question}
                                            </label>
                                          </div>
                                          <div className="d-flex align-items-center gap-2">
                                            <ReactSelect
                                              className="input-cont question-input"
                                              options={options}
                                              isMulti={
                                                question?.type ===
                                                "dropdown-custom-multy-select"
                                              }
                                              onChange={(e) =>
                                                handleQuestionInputChange(
                                                  e.value,
                                                  question?.question
                                                )
                                              }
                                            />
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="delete-section-icon"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (
                                      question?.type ===
                                      "pre-defined-dependents"
                                    ) {
                                      return (
                                        <div>
                                          <div style={{ overflowX: "auto" }}>
                                          <table
                                            className="table"
                                            style={{ minWidth: "1500px" }}
                                          >
                                            <thead className="table-cont">
                                              <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">
                                                  Middle Initial
                                                </th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Prefix</th>
                                                <th scope="col">SSN/ITIN</th>
                                                <th scope="col">
                                                  Date of Birth
                                                </th>
                                                <th scope="col">
                                                  Relationship
                                                </th>
                                                <th scope="col">Amount paid</th>
                                                <th scope="col">Years lived</th>
                                                <th scope="col">
                                                  All that apply to this persone
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {dependents?.answer?.map(
                                                (dependent, index) => (
                                                  <tr
                                                    key={index}
                                                    className="dependant-rows"
                                                  >
                                                    <td>
                                                      {dependent.firstName}
                                                    </td>
                                                    <td>
                                                      {dependent.middleInitial}
                                                    </td>
                                                    <td>
                                                      {dependent.lastName}
                                                    </td>
                                                    <td>{dependent.prefix}</td>
                                                    <td>
                                                      {dependent.ssnOrItin}
                                                    </td>
                                                    <td>
                                                      {dependent.dateOfBirth}
                                                    </td>
                                                    <td>
                                                      {dependent.relationship}
                                                    </td>
                                                    <td>
                                                      {
                                                        dependent.amountPaidToCareProvider
                                                      }
                                                    </td>
                                                    <td>
                                                      {
                                                        dependent.yearsLivedWithTaxpayer
                                                      }
                                                    </td>
                                                    <td>
                                                      {dependent.selectAllthatApplayToThisPerson?.map(
                                                        (a) => {
                                                          return (
                                                            <div className="fs-6 mx-2">
                                                              {a}
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                          </div>
                                          <form
                                            onSubmit={handleAddDependent}
                                            className="dependents-form"
                                          >
                                            <div className="d-flex justify-content-between align-items-center pb-3">
                                              <img
                                                className="cursor-pointer"
                                                src={DotsIcon}
                                                alt="dots"
                                                {...provided.dragHandleProps}
                                              />
                                              <img
                                                className="cursor-pointer"
                                                src={EditIcon}
                                                alt="dots"
                                                onClick={() =>
                                                  setCustomModalOpen({
                                                    state: "addquestion",
                                                    editmode: true,
                                                    questionToEdit: question,
                                                  })
                                                }
                                              />
                                            </div>
                                            {/* Input fields for each property */}
                                            <div className="dependent-header">
                                              Dependent
                                            </div>
                                            <div className="d-flex flex-column gap-3 mt-3">
                                              <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="firstname">
                                                  First name
                                                </label>
                                                <input
                                                  name="firstName"
                                                  value={dependent.firstName}
                                                  onChange={handleChange}
                                                  placeholder="Enter your answer here...."
                                                  id="firstName"
                                                  className="question-input-form input-cont"
                                                />
                                              </div>
                                              <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="middleInitial">
                                                  Middle Initial
                                                </label>
                                                <input
                                                  className="question-input-form input-cont"
                                                  name="middleInitial"
                                                  value={
                                                    dependent.middleInitial
                                                  }
                                                  onChange={handleChange}
                                                  placeholder="Enter your answer here...."
                                                  id="middleInitial"
                                                />
                                              </div>
                                              <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="lastName">
                                                  Last Name
                                                </label>
                                                <input
                                                  name="lastName"
                                                  value={dependent.lastName}
                                                  onChange={handleChange}
                                                  placeholder="Enter your answer here...."
                                                  id="lastName"
                                                  className="question-input-form input-cont"
                                                />
                                              </div>
                                              <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="lastName">
                                                  Select Prefix
                                                </label>
                                                <ReactSelect
                                                  placeholder="Enter your answer here...."
                                                  className="input-cont question-input"
                                                  options={[
                                                    {
                                                      label: "Mr.",
                                                      value: "Mr.",
                                                    },
                                                    {
                                                      label: "Miss.",
                                                      value: "Miss.",
                                                    },
                                                  ]}
                                                  onChange={(e) =>
                                                    setDependent({
                                                      ...dependent,
                                                      prefix: e.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                              <div className="dependant-form-divider">
                                                TaxPayer Other Information
                                              </div>
                                              <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="SSN/ITIN">
                                                  SSN/ITIN
                                                </label>
                                                <input
                                                  name="ssnOrItin"
                                                  value={dependent.ssnOrItin}
                                                  onChange={handleChange}
                                                  placeholder="Enter your answer here...."
                                                  className="question-input-form input-cont"
                                                  id="SSN/ITIN"
                                                />
                                              </div>
                                              <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="dateOfBirth">
                                                  Date Of Birth
                                                </label>
                                             
                                                 <DatePicker
                                          format="DD/MM/YYYY"
                                          placeholder="DD/MM/YYYY"
                                          className="question-input-form input-cont"
                                        />
                                              </div>
                                              <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="Relationship">
                                                  Relationship
                                                </label>
                                                <ReactSelect
                                                  name="relationship"
                                                  placeholder="Enter your answer here...."
                                                  onChange={(e) =>
                                                    setDependent({
                                                      ...dependent,
                                                      relationship: e.value,
                                                    })
                                                  }
                                                  options={[
                                                    {
                                                      label: "Relative",
                                                      value: "Relative",
                                                    },
                                                    {
                                                      label: "Others",
                                                      value: "Others",
                                                    },
                                                  ]}
                                                  id="Relationship"
                                                  className=" input-cont question-input"
                                                />
                                              </div>
                                              <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="amountPaidToCareProvider">
                                                  Amount paid to care provider
                                                </label>
                                                <div className="amount-input-cont input-cont">
                                                  <div className="amount-icon-cont">
                                                    $
                                                  </div>
                                                  <input
                                                    type="number"
                                                    id={question?.question}
                                                    placeholder="Enter your answer here..."
                                                    value={
                                                      dependent.amountPaidToCareProvider
                                                    }
                                                    onChange={handleChange}
                                                  />
                                                </div>
                                              </div>
                                              <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="yearsLivedWithTaxpayer">
                                                  Years lived with taxpayer
                                                </label>
                                                <ReactSelect
                                                  name="yearsLivedWithTaxpayer"
                                                  placeholder="Enter your answer here...."
                                                  onChange={(e) =>
                                                    setDependent({
                                                      ...dependent,
                                                      yearsLivedWithTaxpayer:
                                                        e.value,
                                                    })
                                                  }
                                                  options={[
                                                    {
                                                      label: "1-5",
                                                      value: "1-5",
                                                    },
                                                    {
                                                      label: "5-10",
                                                      value: "5-10",
                                                    },
                                                  ]}
                                                  id="yearsLivedWithTaxpayer"
                                                  className="input-cont question-input"
                                                />
                                              </div>
                                              <div className="d-flex justify-content-between align-items-center w-100">
                                                <label htmlFor="yearsLivedWithTaxpayer">
                                                  Select all that apply to this
                                                  person{" "}
                                                </label>
                                                <ReactSelect
                                                  name="yearsLivedWithTaxpayer"
                                                  placeholder="Enter your answer here...."
                                                  onChange={(e) =>
                                                    setDependent({
                                                      ...dependent,
                                                      yearsLivedWithTaxpayer:
                                                        e.value,
                                                    })
                                                  }
                                                  options={[
                                                    {
                                                      label:
                                                        "Married as of 12/31/2023",
                                                      value:
                                                        "Married as of 12/31/2023",
                                                    },
                                                    {
                                                      label:
                                                        "Fill-time student during 2023",
                                                      value:
                                                        "Fill-time student during 2023",
                                                    },
                                                    {
                                                      label:
                                                        "Totally and permanently disabled at any time during 2023",
                                                      value:
                                                        "Totally and permanently disabled at any time during 2023",
                                                    },
                                                    {
                                                      label:
                                                        "Had income during 2023",
                                                      value:
                                                        "Had income during 2023",
                                                    },
                                                    {
                                                      label:
                                                        "Filed or will file their own 2023 income tax return",
                                                      value:
                                                        "Filed or will file their own 2023 income tax return",
                                                    },
                                                    {
                                                      label: "None of above",
                                                      value: "None of above",
                                                    },
                                                  ]}
                                                  id="yearsLivedWithTaxpayer"
                                                  className="input-cont question-input"
                                                />
                                              </div>
                                            </div>
                                            {/* Add other checkboxes similarly */}
                                            <div className="add-question-btn-cont">
                                              <div
                                                className="add-dependent-btn"
                                                onClick={handleAddDependent}
                                              >
                                                Add dependent
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      );
                                    }
                                    if (question?.type === "pre-defined-income-sources") {
                                      return (
                                        <div
                                          className={`income-sources-cont border border-1 rounded m-3 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                          <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                              <img
                                                className="cursor-pointer"
                                                src={DotsIcon}
                                                alt="dots"
                                                {...provided.dragHandleProps}
                                              />
                                              <img
                                                className="cursor-pointer"
                                                src={EditIcon}
                                                alt="dots"
                                                onClick={() =>
                                                  setCustomModalOpen({
                                                    state: "addquestion",
                                                    editmode: true,
                                                    questionToEdit: question,
                                                  })
                                                }
                                              />
                                            </div>
                                            <div className="income-sources-cont-title mt-3">
                                              {question?.question}
                                            </div>
                                            <div className="income-sources-cont-subtitle">
                                              Select all that apply, even If you didn't
                                              receive a form
                                            </div>
                                            <div className="income-sources-cards-cont">
                                              {incomeSources?.map((source) => {
                                                return (
                                                  <div className="col-md-4 income-sources-card">
                                                    <img
                                                      className="income-source-icon"
                                                      src={source?.icon}
                                                      alt={source?.name}
                                                    />
                                                    <div className="">{source?.name}</div>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (question?.type === "expenses-list") {
                                      return (
                                        <div className={`income-sources-cont border border-1 rounded mt-3 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}>
                                          <div className='d-flex justify-content-between align-items-center py-3'>
                                          <img
                                                className="cursor-pointer"
                                                src={DotsIcon}
                                                alt="dots"
                                                {...provided.dragHandleProps}
                                              />
                                              <img
                                                className="cursor-pointer"
                                                src={EditIcon}
                                                alt="dots"
                                                onClick={() =>
                                                  setCustomModalOpen({
                                                    state: "addquestion",
                                                    editmode: true,
                                                    questionToEdit: question,
                                                  })
                                                }
                                              />
                                          </div>
                                          <div className="d-flex flex-column gap-3">
                                            {expensesData?.map((expense) => {
                                              return (
                                                <div className="d-flex align-items-center gap-3">
                                                  <img
                                                    className="expenses-list-source-icon"
                                                    src={LockerSvg}
                                                    alt={expense}
                                                  />
                                                  <div className="fw-bold expenses-list-title">
                                                    {expense}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (question?.type === "expenses-select") {
                                      return (
                                        <div
                                        key={index}
                                          className={`income-sources-cont border border-1 rounded mt-3 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                            <div className='d-flex justify-content-between align-items-center py-2'>
                                          <img
                                                className="cursor-pointer"
                                                src={DotsIcon}
                                                alt="dots"
                                                {...provided.dragHandleProps}
                                              />
                                              <img
                                                className="cursor-pointer"
                                                src={EditIcon}
                                                alt="dots"
                                                onClick={() =>
                                                  setCustomModalOpen({
                                                    state: "addquestion",
                                                    editmode: true,
                                                    questionToEdit: question,
                                                  })
                                                }
                                              />
                                          </div>
                                          <div className='income-sources-cont-title'>
                                            {question?.question}
                                          </div>
                                          <div className="income-sources-cards-cont">
                                            {expensesData?.map((expense) => {
                                              return (
                                                <div className="col-md-4 income-sources-card">
                                                  <img
                                                    className="expenses-select-icon"
                                                    src={LockerSvg}
                                                    alt={expense}
                                                  />
                                                  <div className="expenses-select-title">
                                                    {expense}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (question?.type === "card") {
                                      return (
                                        <div
                                          className={`form-card-cont border border-1 rounded mt-3 p-3 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                          <div className="d-flex justify-content-between align-items-center">
                                            <img
                                              className="cursor-pointer"
                                              src={DotsIcon}
                                              alt="dots"
                                              {...provided.dragHandleProps}
                                            />
                                            <img
                                              className="cursor-pointer"
                                              src={EditIcon}
                                              alt="dots"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                              />
                                            </div>
                                            {question?.showTitle &&
                                             <div className="card-question my-3 fw-bold fs-5 ">
                                              {question?.question}
                                            </div>}
                                            <div className='row px-3 mb-3 mt-2' >
                                              {question?.questionData?.length > 0 &&
                                                question?.questionData?.map((q,index) => {
                                                  if (q?.type === "text-one-line") {
                                                    return (
                                                      <div className="row  p-0 justify-content-between align-items-start mb-3" key={index}>
                                                        <div className="col-md-7 text-start">
                                                          <label htmlFor={q?.label}>
                                                            {q?.label}
                                                          </label>
                                                        </div>
                                                        <div className="col-md-5 ">
                                                          <input
                                                            type="text"
                                                            id={q?.label}
                                                            placeholder="Enter your answer here..."
                                                            className="form-control"
                                                          />
                                                        </div>
                                                      </div>
                                                    );
                                                  } 
                                                if (q?.type === "text-one-line") {
                                                  return (
                                                    <div className="row p-0 justify-content-between align-items-start mb-3" key={index}>
                                                      <div className="col-md-7 text-start">
                                                        <label htmlFor={q?.label}>
                                                          {q?.label}
                                                        </label>
                                                      </div>
                                                      <div className="col-md-5">
                                                        <input
                                                          type="text"
                                                          id={q?.label}
                                                          placeholder="Enter your answer here..."
                                                          className="form-control"
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                
                                                if (q?.type === "text-email") {
                                                  return (
                                                    <div className="row p-0 justify-content-between align-items-start mb-3" key={index}>
                                                      <div className="col-md-7 text-start">
                                                        <label htmlFor={q?.label}>
                                                          {q?.label}
                                                        </label>
                                                      </div>
                                                      <div className="col-md-5">
                                                        <input
                                                          type="email"
                                                          id={q?.label}
                                                          placeholder="Enter your answer here..."
                                                          className="form-control"
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                
                                                if (q?.type === "number-any-number") {
                                                  return (
                                                    <div className="row p-0 justify-content-between align-items-start mb-3" key={index}>
                                                      <div className="col-md-7 text-start">
                                                        <label htmlFor={q?.label}>
                                                          {q?.label}
                                                        </label>
                                                      </div>
                                                      <div className="col-md-5">
                                                        <input
                                                          type="number"
                                                          id={q?.label}
                                                          placeholder="Enter your answer here..."
                                                          className="form-control"
                                                          onWheel={(e) => e.target.blur()}
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                
                                                if (q?.type === "number-amount") {
                                                  return (
                                                    <div className="row p-0 justify-content-between align-items-start mb-3" key={index}>
                                                      <div className="col-md-7 text-start">
                                                        <label htmlFor={q?.label}>
                                                          {q?.label}
                                                        </label>
                                                      </div>
                                                      <div className="col-md-5 d-flex align-items-center">
                                                     <div className='input-group'>
                                                     <span className="input-group-text grid-amount">$</span>
                                                        <input
                                                          type="number"
                                                          id={q?.label}
                                                          placeholder="Enter your answer here..."
                                                          className="form-control"
                                                          onWheel={(e) => e.target.blur()}
                                                        />
                                                     </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                if (q?.type === "number-percentage") {
                                                  return (
                                                    <div className="row p-0 justify-content-between align-items-start mb-3" key={index}>
                                                         <div className="col-md-7 text-start">
                                                        <label htmlFor={q?.label}>
                                                          {q?.label}
                                                        </label>
                                                      </div>
                                                     <div className="col-md-5 d-flex align-items-center">
                                                     <div className='input-group'>
                                                        <input
                                                          type="number"
                                                          id={q?.label}
                                                          placeholder="Enter your answer here..."
                                                          className="form-control"
                                                          onWheel={(e) => e.target.blur()}
                                                        />
                                                       <span className="input-group-text grid-amount">%</span>
                                                      </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                if(q?.type === 'horizontal-line'){
                                                  return <hr className="horizontal-line p-0"/>
                                                }
                                                if (q?.type === "yes-no") {
                                                  return (
                                                    <div className="row p-0 justify-content-between align-items-start mb-3" key={index}>
                                                       <div className="col-md-7 text-start">
                                                        <label htmlFor={q?.label}>
                                                          {q?.label}
                                                        </label>
                                                      </div>
                                                      <div className="col-md-5 d-flex justify-content-end align-items-center gap-2">
                                                        <div className="d-flex gap-3">
                                                          {q?.questionData?.map(
                                                            (answer) => {
                                                              return (
                                                                <div className="yes-no-cont">
                                                                  {answer}
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                if (q?.type === "date") {
                                                  return (
                                                    <div
                                                      className="row p-0 justify-content-between align-items-start mb-3"
                                                      key={index}
                                                    >
                                                      <div className="col-md-7 text-start">
                                                        <label
                                                          htmlFor={q?.label}
                                                        >
                                                          {q?.label}
                                                        </label>
                                                      </div>
                                                      <div className="col-md-5 d-flex justify-content-end align-items-center gap-2">
                                                        <DatePicker
                                                          format="DD/MM/YYYY"
                                                          placeholder="DD/MM/YYYY"
                                                          className="question-input-form input-cont"
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                if (q?.type?.includes("dropdown")) {
                                                  const options = q?.questionData?.map(
                                                    (state) => ({
                                                      value: state,
                                                      label: state,
                                                    })
                                                  );
                                                  return (
                                                    <div
                                                    className="row p-0 justify-content-between align-items-start mb-3"
                                                    key={index}
                                                  >
                                                      <div className='col-md-7'>
                                                        <label htmlFor={q?.label}>
                                                          {q?.label}
                                                        </label>
                                                      </div>
                                                      <div className="col-md-5 d-flex justify-content-end align-items-center gap-2">
                                                        <ReactSelect
                                                          className="input-cont question-input"
                                                          options={options}
                                                          isMulti={
                                                            question?.type ===
                                                            "dropdown-custom-multy-select"
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              })}
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (question?.type === "grid") {
                                      return (
                                        <div
                                          className={`mt-4 border px-3 py-3 border-1 rounded mb-3 ${
                                            question?.condition ? "condition-cont" : ""
                                          }`}
                                        >
                                          {/* Header with Icons */}
                                          <div className="d-flex justify-content-between align-items-center px-3">
                                            <div className="d-flex gap-3">
                                              <img
                                                src={DotsIcon}
                                                alt="dots"
                                                className="cursor-pointer"
                                                {...provided.dragHandleProps}
                                              />
                                             {question?.showTitle && 
                                              <div className="grid-header-item grid-question">
                                              {question?.question}
                                            </div>}
                                            </div>
                                            <img
                                              src={EditIcon}
                                              alt="edit"
                                              className="cursor-pointer"
                                              onClick={() =>
                                                setCustomModalOpen({
                                                  state: "addquestion",
                                                  editmode: true,
                                                  questionToEdit: question,
                                                })
                                              }
                                            />
                                          </div>
                                          <div className="row mt-3">
                                            <div className="col-md-5"></div>
                                            {question?.questionData?.columns.map((column) => (
                                              <div key={column.id} className="col text-center">
                                                {column.label}
                                              </div>
                                            ))}
                                          </div>
                                    
                                          {/* Grid Rows */}
                                          {question?.questionData?.rows.map((row) => (
                                            <div key={row.id} className="row mb-2  mt-3 d-flex align-items-center">
                                              <div className="col-md-5 d-flex align-items-center"> {/* Label column */}
                                                {row.label}
                                              </div>
                                              {question?.questionData?.columns.map((column, columnIndex) => {
                                                if (column?.type === "text-one-line") {
                                                  return (
                                                    <div key={columnIndex} className="col px-1 text-center"> 
                                                      <input
                                                        type="text"
                                                        id={column?.label}
                                                        placeholder="Enter your answer..."
                                                        className="form-control"
                                                        style={{ width: "100%" }} 
                                                      />
                                                    </div>
                                                  );
                                                }
                                                if (column?.type === "text-multi-line") {
                                                  return (
                                                    <div key={columnIndex} className="col px-1">
                                                      <textarea
                                                        id={column?.label}
                                                        placeholder="Enter your answer..."
                                                        className="form-control"
                                                        style={{ width: "100%" }}
                                                      ></textarea>
                                                    </div>
                                                  );
                                                }
                                                if (column?.type === "text-email") {
                                                  return (
                                                    <div key={columnIndex} className="col px-1"> 
                                                      <input
                                                        type="email"
                                                        id={column?.label}
                                                        placeholder="Enter your email here..."
                                                        className="form-control"
                                                        style={{ width: "100%" }} 
                                                      />
                                                    </div>
                                                  );
                                                }
                                                
                                                if (column?.type === "number-any-number") {
                                                  return (
                                                    <div key={columnIndex} className="col px-1">
                                                      <input
                                                        type="number"
                                                        id={column?.label}
                                                        placeholder="Enter your number here..."
                                                        className="form-control"
                                                        style={{ width: "100%" }}
                                                        onWheel={(e) => e.target.blur()}
                                                      />
                                                    </div>
                                                  );
                                                }
                                                
                                                if (column?.type === "number-amount") {
                                                  return (
                                                    <div key={columnIndex} className="col px-1 d-flex align-items-center justify-content-center">
                                                      <div className="input-group"  style={{ width: "100%" }}>
                                                        <span className="input-group-text grid-amount">$</span>
                                                        <input
                                                          type="number"
                                                          id={column?.label}
                                                          placeholder="Enter amount"
                                                          className="form-control"
                                                          onWheel={(e) => e.target.blur()}
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                
                                                if (column?.type === "number-percentage") {
                                                  return (
                                                    <div key={columnIndex} className="col px-1 d-flex align-items-center justify-content-center">
                                                      <div className="input-group " 
                                                      style={{ width: "100%" }}
                                                      >
                                                        <input
                                                          type="number"
                                                          id={column?.label}
                                                          placeholder="Enter percentage"
                                                          className="form-control"
                                                          onWheel={(e) => e.target.blur()}
                                                          
                                                        />
                                                        <span className="input-group-text grid-amount">%</span>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                
                                                if (column?.type === "yes-no") {
                                                  return (
                                                    <div key={columnIndex} className="col px-1 d-flex align-items-center justify-content-center"> 
                                                      <div className="d-flex gap-2">
                                                        {column?.questionData?.map((answer) => (
                                                          <div key={answer} className="yes-no-cont">
                                                            {answer}
                                                          </div>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                if (
                                                    column?.type?.includes(
                                                        "dropdown"
                                                    )
                                                ) {
                                                  const options =
                                                      column?.questionData?.map(
                                                          (state) => ({
                                                            value: state,
                                                            label: state,
                                                          })
                                                      );
                                                  return (
                                                      <div
                                                          className="col px-1"
                                                          key={index}
                                                      >
                                                        <ReactSelect
                                                            className="question-input-list"
                                                            options={
                                                              options
                                                            }
                                                            isMulti={
                                                                question?.type ===
                                                                "dropdown-custom-multy-select"
                                                            }
                                                            menuPosition="fixed"

                                                        />
                                                      </div>
                                                  );
                                                }
                                                if (column?.type === "date") {
                                                  return (
                                                    <div key={columnIndex} className="col px-1">
                                                      <DatePicker
                                                        format="DD/MM/YYYY"
                                                        placeholder="DD/MM/YYYY"
                                                        className="form-control"
                                                        style={{ width: "100%" }} 
                                                      />
                                                    </div>
                                                  );
                                                }
                                                
                                                return null; 
                                              })}
                                            </div>
                                          ))}
                                        </div>
                                      );
                                    }

                                    if (
                                      question?.type ===
                                      "list"
                                    ) {
                                    return (
                                      <div
                                        className={`mt-4 border px-3 py-3 border-1 rounded mb-3 ${
                                          question?.condition
                                            ? "condition-cont"
                                            : ""
                                        }`}
                                      >
                                        {/* Header with Icons */}
                                        <div className="d-flex justify-content-between align-items-center px-3">
                                          <div className="d-flex gap-3">
                                            <img
                                              src={DotsIcon}
                                              alt="dots"
                                              className="cursor-pointer"
                                              {...provided.dragHandleProps}
                                            />
                                            <div className="grid-header-item grid-question">
                                              {question?.question}
                                            </div>
                                          </div>
                                          <img
                                            src={EditIcon}
                                            alt="edit"
                                            className="cursor-pointer"
                                            onClick={() =>
                                              setCustomModalOpen({
                                                state: "addquestion",
                                                editmode: true,
                                                questionToEdit: question,
                                              })
                                            }
                                          />
                                        </div>
                                        <div className="container mt-5">
                                          <div className="table-responsive">
                                            <div className="table-scroll-container">
                                              {/* Header Row (Columns) */}
                                              <div className="row ">
                                                {question?.questionData?.columns.map(
                                                  (col, index) => (
                                                    <div
                                                      key={index}
                                                      className="col text-sm fw-bold"
                                                    >
                                                      {col.name}
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                              {/* Rows */}

                                              {question?.questionData?.rows.map(
                                                (row, rowIndex) => (
                                                  <div
                                                    key={rowIndex}
                                                    className="row mb-2 position-relative"
                                                  >
                                                    {question?.questionData?.columns.map(
                                                      (col, colIndex) => {
                                                        if (
                                                          col?.type ===
                                                          "text-one-line"
                                                        ) {
                                                          return (
                                                            <div
                                                              key={colIndex}
                                                              className="col px-1 text-center"
                                                            >
                                                              <input
                                                                type="text"
                                                                id={col?.name}
                                                                placeholder="Enter your answer..."
                                                                className="form-control"
                                                                style={{
                                                                  width: "100%",
                                                                }}
                                                              />
                                                            </div>
                                                          );
                                                        }
                                                        if (
                                                          col?.type ===
                                                          "text-multi-line"
                                                        ) {
                                                          return (
                                                            <div
                                                              key={colIndex}
                                                              className="col px-1"
                                                            >
                                                              <textarea
                                                                id={col?.name}
                                                                placeholder="Enter your answer..."
                                                                className="form-control"
                                                                style={{
                                                                  width: "100%",
                                                                }}
                                                              ></textarea>
                                                            </div>
                                                          );
                                                        }
                                                        if (
                                                          col?.type ===
                                                          "text-email"
                                                        ) {
                                                          return (
                                                            <div
                                                              key={colIndex}
                                                              className="col px-1"
                                                            >
                                                              <input
                                                                type="email"
                                                                id={col?.name}
                                                                placeholder="Enter your email here..."
                                                                className="form-control"
                                                                style={{
                                                                  width: "100%",
                                                                }}
                                                              />
                                                            </div>
                                                          );
                                                        }

                                                        if (
                                                          col?.type ===
                                                          "number-any-number"
                                                        ) {
                                                          return (
                                                            <div
                                                              key={colIndex}
                                                              className="col px-1"
                                                            >
                                                              <input
                                                                type="number"
                                                                id={col?.name}
                                                                placeholder="Enter your number here..."
                                                                className="form-control"
                                                                style={{
                                                                  width: "100%",
                                                                }}
                                                                onWheel={(e) => e.target.blur()}
                                                              />
                                                            </div>
                                                          );
                                                        }

                                                        if (
                                                          col?.type ===
                                                          "number-amount"
                                                        ) {
                                                          return (
                                                            <div
                                                              key={colIndex}
                                                              className="col px-1 d-flex align-items-center justify-content-center"
                                                            >
                                                              <div
                                                                className="input-group"
                                                                style={{
                                                                  width: "100%",
                                                                }}
                                                              >
                                                                <span className="input-group-text grid-amount">
                                                                  $
                                                                </span>
                                                                <input
                                                                  type="number"
                                                                  id={col?.name}
                                                                  placeholder="Enter amount"
                                                                  className="form-control"
                                                                  onWheel={(e) => e.target.blur()}
                                                                />
                                                              </div>
                                                            </div>
                                                          );
                                                        }

                                                        if (
                                                          col?.type ===
                                                          "number-percentage"
                                                        ) {
                                                          return (
                                                            <div
                                                              key={colIndex}
                                                              className="col px-1 d-flex align-items-center justify-content-center"
                                                            >
                                                              <div
                                                                className="input-group "
                                                                style={{
                                                                  width: "100%",
                                                                }}
                                                              >
                                                                <input
                                                                  type="number"
                                                                  id={col?.name}
                                                                  placeholder="Enter percentage"
                                                                  className="form-control"
                                                                  onWheel={(e) => e.target.blur()}
                                                                />
                                                                <span className="input-group-text grid-amount">
                                                                  %
                                                                </span>
                                                              </div>
                                                            </div>
                                                          );
                                                        }

                                                        if (
                                                          col?.type === "yes-no"
                                                        ) {
                                                          return (
                                                            <div
                                                              key={colIndex}
                                                              className="col px-1 d-flex align-items-center justify-content-center"
                                                            >
                                                              <div className="d-flex gap-2">
                                                                {col?.questionData?.map(
                                                                  (answer) => (
                                                                    <div
                                                                      key={
                                                                        answer
                                                                      }
                                                                      className="yes-no-cont"
                                                                    >
                                                                      {answer}
                                                                    </div>
                                                                  )
                                                                )}
                                                              </div>
                                                            </div>
                                                          );
                                                        }

                                                        if (
                                                          col?.type === "date"
                                                        ) {
                                                          return (
                                                            <div
                                                              key={colIndex}
                                                              className="col px-1"
                                                            >
                                                              <DatePicker
                                                                format="DD/MM/YYYY"
                                                                placeholder="DD/MM/YYYY"
                                                                className="form-control"
                                                                style={{
                                                                  width: "100%",
                                                                }}
                                                              />
                                                            </div>
                                                          );
                                                        }
                                                        if (
                                                          col?.type?.includes(
                                                            "dropdown"
                                                          )
                                                        ) {
                                                          const options =
                                                            col?.questionData?.map(
                                                              (state) => ({
                                                                value: state,
                                                                label: state,
                                                              })
                                                            );
                                                          return (
                                                            <div
                                                              className="col px-1"
                                                              key={index}
                                                            >
                                                              <ReactSelect
                                                                className="question-input-list"
                                                                options={
                                                                  options
                                                                }
                                                                isMulti={
                                                                  question?.type ===
                                                                  "dropdown-custom-multy-select"
                                                                 }
                                                                  menuPosition="fixed" 

                                                              />
                                                            </div>
                                                          );
                                                        }

                                                        return null;
                                                      }
                                                    )}
                                                    <div
                                                      className="col-auto d-flex cursor-pointer  del-row-icon"
                                                      onClick={() => {
                                                        setTaxForm(
                                                          (prevForm) => {
                                                            return {
                                                              ...prevForm,
                                                              sections:
                                                                prevForm?.sections?.map(
                                                                  (sec) => {
                                                                    if (
                                                                      sec?.id ===
                                                                      activeSectionId
                                                                    ) {
                                                                      return {
                                                                        ...sec,
                                                                        subsections:
                                                                          sec?.subsections?.map(
                                                                            (
                                                                              sub
                                                                            ) => {
                                                                              if (
                                                                                sub?.id ===
                                                                                activeSubSectionId
                                                                              ) {
                                                                                return {
                                                                                  ...sub,
                                                                                  fields:
                                                                                    sub?.fields?.map(
                                                                                      (
                                                                                        field
                                                                                      ) => {
                                                                                        if (
                                                                                          field?.id ===
                                                                                          question?.id
                                                                                        ) {
                                                                                          return {
                                                                                            ...field,
                                                                                            questionData:
                                                                                              {
                                                                                                ...field?.questionData,
                                                                                                rows: field?.questionData?.rows?.filter(
                                                                                                  (
                                                                                                    _,
                                                                                                    idx
                                                                                                  ) =>
                                                                                                    idx !==
                                                                                                    rowIndex
                                                                                                ),
                                                                                              },
                                                                                          };
                                                                                        }
                                                                                        return field;
                                                                                      }
                                                                                    ),
                                                                                };
                                                                              }
                                                                              return sub;
                                                                            }
                                                                          ),
                                                                      };
                                                                    }
                                                                    return sec;
                                                                  }
                                                                ),
                                                            };
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={DeleteIcon2}
                                                        alt="closeIcon"
                                                        style={{
                                                          height: "25px",
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                            <div className="d-flex justify-content-center">
                                              <div
                                                className="cursor-pointer fw-normal text-primary mt-3"
                                                onClick={() => addRow(question)}
                                              >
                                                Add Row
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );

                                    }

                                  })()}
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                )}
              </DragDropContext>
              {activeSectionId && activeSubSectionId && (
                <div className="add-question-btn-cont">
                  <div
                    className="add-question-btn"
                    onClick={() =>
                      setCustomModalOpen({ state: "addquestion", data: null,editmode:false })
                    }
                  >
                    Add Question
                  </div>
                </div>
              )}
              {!editingSection &&
                !byAdmin &&
                !editmode &&
                Object.keys(taxForm)?.length > 0 && (
                  <div className="d-flex justify-content-center mt-5">
                    <div
                      disabled={!activeSubSectionId}
                      className="close-form-btn"
                      onClick={handleSaveForm}
                    >
                      {`Done ${"(Close)"} `}
                    </div>
                  </div>
                )}
              {alertMessage && (
                <div class="alert alert-danger mt-3 text-center" role="alert">
                  {alertMessage}
                </div>
              )}
              {!editingSection &&
                byAdmin &&
                !adminEditMode &&
                !editmode &&
                Object.keys(taxForm)?.length > 0 && (
                  <>
                    <div className="d-flex justify-content-center mt-5">
                      <button
                        disabled={!activeSubSectionId}
                        className="close-form-btn"
                        onClick={handleSaveFormByAdmin}
                      >
                        {`Save ${"(Close)"} `}
                      </button>
                    </div>
                  </>
                )}
              {!editingSection &&
                editmode &&
                Object.keys(taxForm)?.length > 0 && (
                  <div className="d-flex justify-content-center mt-5">
                    <button
                      disabled={!activeSubSectionId}
                      className="close-form-btn"
                      onClick={handleSaveEditedForm}
                    >
                      {`Done editing ${"(Close)"} `}
                    </button>
                  </div>
                )}
              {!editingSection &&
                adminEditMode &&
                Object.keys(taxForm)?.length > 0 && (
                  <div className="d-flex justify-content-center mt-5">
                    <button
                      disabled={!activeSubSectionId}
                      className="close-form-btn"
                      onClick={handleSaveEditedFormByAdmin}
                    >
                      {`Done editing ${"(Close)"} `}
                    </button>
                  </div>
                )}
              {message.show ? <Message message={message} /> : <></>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};  

export default CreateNewForm;

import { BASEURL, PRE_BASEURL } from '../../utilites';
import { dashboard } from '../dashboard/action';
import { FIREBASE_ACTION_TYPE } from '../firebase/Type';
import { firebase } from '../firebase/action';
import { membership } from '../membership/action';
import { USER_ACTION_TYPE } from './Type';
import axios from 'axios';
// Global Variable
const websiteUrl1 = window.location.href;
const url1 = new URL(websiteUrl1);
const status1 = url1.pathname.split('/')[3];
const token1 = url1.pathname.split('/')[4];
const finalUrl = BASEURL + '/api/auth/' + status1 + '/' + token1;
//console.log("finalUrl", finalUrl);

// User Registration Function
const Signup = (user_obj, setMessage, resetForm, change_spinner) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/auth/registration`, user_obj)
			.then((response) => {
				// Handle the response data here

				// Show Message
				setMessage({
					show: true,
					type: 'Success',
					text: 'Please check Your Inbox To Verify Your Mail',
				});

				// Change Button Spinner
				change_spinner(false);

				// Reset form Of Sign up
				resetForm();
			})
			.catch((error) => {
				console.log(error);
				change_spinner(false);
				// Handle any errors that occurred during the request
				if (error.code === 'ERR_NETWORK') {
					setMessage({
						show: true,
						type: 'Error',
						text: error?.message,
					});
					return;
				}
				setMessage({
					show: true,
					type: 'Error',
					text: error?.response?.data?.error,
				});
			});
	};
};

// User Login Function
const Login = (user_obj, setMessage, resetForm, change_spinner, navigate, modalLogin) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/auth/login`, user_obj)
			.then(async (response) => {
				dispatch(await ReGetUserData());
				
				// get user data with dashboard
				// Dispatch according to userRole
				let role = response.data.data.roles[0];
				// dispatch membership
				if(role?.name === 'taxpro' && response.data.data.userpro.status_id===2){
					dispatch(membership.initialRegisterMembership())
				}
				if (role?.name === 'taxpro' || role?.name === 'kdaagent') {
					dispatch(taxproRole(USER_ACTION_TYPE.taxproRole, role?.name));
					// dispatch statusId of user
					const statusId = response.data.data.userpro.status_id;
					dispatch({
						type: USER_ACTION_TYPE.statusId,
						payload: statusId,
					});
				}
				if (role?.name === 'admin' || role?.name === 'support') {
					dispatch(adminRole(USER_ACTION_TYPE.adminRole, role?.name));
				}
				if (role?.name === 'customer') {
					dispatch(customerRole(USER_ACTION_TYPE.customerRole, role?.name));
				}
				if (role?.name === 'accountmanager') {
					dispatch(accountManagerRole(USER_ACTION_TYPE.accountManagerRole, role?.name));
				}

				// Change Button Spinner
				// Reset form Of Sign up
				localStorage.setItem('token', response.data.token);
				resetForm();
				//Store Phone and Email To Local Don't Put this Conditon in SetTimeout so thats why Number/Email will Show Empty
				if (parseInt(response.data.data.authorization) === 1) {
					localStorage.setItem('phoneNo', response.data.data.phone);
					localStorage.setItem('email', response.data.data.email);
				}
				setTimeout(async () => {
					change_spinner(false);
					if (parseInt(response.data.data.authorization) === 1) {
						navigate('/otp');
					}
					// else if (role?.name === 'customer') {
					// 	navigate('/dashboard/messages');
					// }
					else if (!modalLogin) {
						navigate('/dashboard/');
					}else{
						return
					}
				}, 2000);
			})
			.catch((error) => {
				console.log('eeeeeeeeeeee', error);
				change_spinner(false);
				// Handle any errors that occurred during the request
				dispatch({
					type: USER_ACTION_TYPE.user,
					data: null,
					status: false,
					error: error.response?.data?.error,
				});
				if (error.code === 'ERR_NETWORK') {
					setMessage({
						show: true,
						type: 'Error',
						text: error?.message,
					});
					return;
				}
				if (error?.response?.data?.error === 'User Unverify') {
					setMessage({
						show: true,
						type: 'Error',
						html: (
							<font>
								Look like you haven't verified your email yet.Please check your email for the
								verification link or{' '}
								<font
									onClick={() => {
										dispatch(ResendLink({ email: user_obj?.email }, setMessage));
									}}
								>
									<u className='pe-auto'>
										<a href='#@'>click to resend</a>
									</u>
								</font>
							</font>
						),
					});
					return;
				}
				setMessage({
					show: true,
					type: 'Error',
					text: error?.response?.data?.error,
				});
			});
	};
};

const VerifyEmail = (setMessage, navigate) => {
	return async (dispatch) => {
		axios
			.patch(
				`${finalUrl}`,
				{},
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
			.then((response) => {
				dispatch({
					type: USER_ACTION_TYPE.verify,
					payload: {
						type: 'verify',
						mode: true,
						text: 'Account Verified',
					},
				});
				setTimeout(() => {
					navigate('/login');
				}, 2000);
			})
			.catch((error) => {
				console.error(':>>>>>>>>>', error);
				if (error.code === 'ERR_NETWORK') {
					dispatch({
						type: USER_ACTION_TYPE.verify,
						payload: {
							type: 'verify',
							mode: false,
							text: error?.message,
						},
					});
					navigate('/login');
					return;
				}
				dispatch({
					type: USER_ACTION_TYPE.verify,
					payload: {
						type: 'verify',
						mode: false,
						text: `Link Not Verify Successfully, ${error?.response?.data?.error}`,
					},
				});
				navigate('/login');
			});
	};
};

// ForgotPassword Function
const ForgotPassword = (user_obj, setMessage, resetForm, change_spinner) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/auth/forgot_password`, user_obj)
			.then((response) => {
				// Handle the response data here

				// Show Message
				setMessage({
					show: true,
					type: 'Success',
					text: 'If you have an account with us, you will receive an email with with password reset instructions.',
				});

				// Change Button Spinner
				change_spinner(false);

				// Reset form Of Sign up
				resetForm();
			})
			.catch((error) => {
				change_spinner(false);
				// Handle any errors that occurred during the request
				if (error.code === 'ERR_NETWORK') {
					setMessage({
						show: true,
						type: 'Error',
						text: error?.message,
					});
					return;
				}
				setMessage({
					show: true,
					type: 'Error',
					text: error?.response?.data?.error,
				});
			});
	};
};

// ResetPassword Function
const ResetPassword = (user_obj, setMessage, resetForm, change_spinner, navigate) => {
	return async (dispatch) => {
		axios
			.patch(`${finalUrl}`, user_obj, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				// Change Button Spinner
				change_spinner(false);

				// Reset form Of Sign up
				resetForm();
				dispatch({
					type: USER_ACTION_TYPE.verify,
					payload: {
						type: 'reset',
						mode: true,
						text: 'Password Update Successfully',
					},
				});
				setTimeout(() => {
					navigate('/login');
				}, 2000);
			})
			.catch((error) => {
				change_spinner(false);
				console.error(':>>>>>>>>>', error);
				if (error.code === 'ERR_NETWORK') {
					setMessage({
						show: true,
						type: 'Error',
						text: error?.message,
					});
					return;
				}
				setMessage({
					show: true,
					type: 'Error',
					text: `Link Not Verify Successfully, ${error?.response?.data?.error}`,
				});
			});
	};
};
const reset_user = () => {
	return async (dispatch) => {
		dispatch({
			type: USER_ACTION_TYPE.verify,
			payload: { type: 'null', mode: false, text: '' },
		});
	};
};

// ResendLink Function
const ResendLink = (user_obj, setMessage) => {
	console.log(user_obj);
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/auth/resendverificationlink`, user_obj)
			.then((response) => {
				// Handle the response data here
				console.log('response', response);
				// Show Message
				setMessage({
					show: true,
					type: 'Success',
					text: 'If you have an account with us, you will receive an email with with password reset instructions.',
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				if (error.code === 'ERR_NETWORK') {
					setMessage({
						show: true,
						type: 'Error',
						text: error?.message,
					});
					return;
				}
				setMessage({
					show: true,
					type: 'Error',
					text: error?.response?.data?.error,
				});
			});
	};
};

// Re Get User Data Function
const ReGetUserData = (callback) => {
	console.log('REGET DATA>>>>>>>>>>>>>>');
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/dashboard`)
			.then((response) => {
				// Handle the response data here

				// Get Notification Data For Messages
				if (response?.data?.data?.uuid) {
					dispatch(firebase.readPersonalConnections(response?.data?.data?.uuid));
				}
				dispatch({
					type: FIREBASE_ACTION_TYPE.myUuid,
					uuid: response?.data?.data?.uuid,
				});
				dispatch({
					type: FIREBASE_ACTION_TYPE.LoginDataUuid,
					uuid: response?.data?.data?.uuid,
				});
				// Dispatch Login Data
				dispatch({
					type: USER_ACTION_TYPE.user,
					data: response?.data?.data,
					status: true,
					error: '',
				});
				// GET ALL SERVICES LIST
				dispatch(dashboard.getServices());

				if (callback) {
					callback();
				}
				// Dispatch according to userRole
				let role = response.data.data.roles[0];
				if (role?.name === 'taxpro' || role?.name === 'kdaagent') {
					dispatch(taxproRole(USER_ACTION_TYPE.taxproRole, role?.name));
					// dispatch statusId of user
					const statusId = response.data.data.userpro.status_id;
					dispatch({
						type: USER_ACTION_TYPE.statusId,
						payload: statusId,
					});
				}
				if (role?.name === 'admin' || role?.name === 'support') {
					dispatch(adminRole(USER_ACTION_TYPE.adminRole, role?.name));
				}
				if (role?.name === 'customer') {
					dispatch(customerRole(USER_ACTION_TYPE.customerRole, role?.name));
				}
				if (role?.name === 'accountmanager') {
					dispatch(accountManagerRole(USER_ACTION_TYPE.accountManagerRole, role?.name));
				}
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.log('zzzzzzzzzzzzz', error);
				// localStorage.removeItem('token');
				dispatch({
					type: USER_ACTION_TYPE.user,
					data: null,
					status: false,
					error: error.response?.data?.error,
				});
				console.log(error);
			});
	};
};

const update_store = (type, obj) => {
	return async (dispatch) => {
		dispatch({
			type: type,
			payload: obj,
		});
	};
};

const adminRole = (type, obj) => {
	return async (dispatch) => {
		dispatch({
			type: type,
			payload: obj,
		});
	};
};

const taxproRole = (type, obj) => {
	return async (dispatch) => {
		dispatch({
			type: type,
			payload: obj,
		});
	};
};

const customerRole = (type, obj) => {
	return async (dispatch) => {
		dispatch({
			type: type,
			payload: obj,
		});
	};
};

const accountManagerRole = (type, obj) => {
	return async (dispatch) => {
		dispatch({
			type: type,
			payload: obj,
		});
	};
};

const logout = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/auth/logout`)
			.then((response) => {
				console.log('LOGOUT>>>>>>>>>>>>', response);
				localStorage.removeItem('token');
				localStorage.removeItem('email');
				localStorage.removeItem('phoneNo');
				dispatch({
					type: 'USER_LOGOUT',
				});
			})
			.catch((error) => {
				// alert(
				// 	error.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error
				// );
			});
	};
};

const toggleTaxProPause = (pro_id, reload) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/professionals/toggle-pause`, { ...(pro_id && { pro_id }) })
			.then(() => {
				if (reload) {
					reload();
				} else {
					dispatch(ReGetUserData());
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

const deleteUser = (id, reload) => {
	return async (dispatch) => {
		axios
			.delete(`${BASEURL}/api/users/${id}`)
			.then(() => {
				if (reload) {
					reload();
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

const removeUserData = () => {
	return (dispatch) => {
		dispatch({
			type: USER_ACTION_TYPE.reset,
		});
	};
};
const checkExistingUser = (email, setMessage, resetForm, change_spinner) => {
	return (dispatch) => {
		axios
			.get(`${PRE_BASEURL}/api/test/retrive-user/${email}`)
			.then((response) => {
				dispatch({ status: true, type: USER_ACTION_TYPE.existEmail, data: response?.data });
			})
			.catch((error) => {
				dispatch({
					status: false,
					type: USER_ACTION_TYPE.existEmail,
					error: error?.response?.data?.error?.message || 'Server Error',
				});
				change_spinner(false);
				console.error(':>>>>>>>>>', error);
				setMessage({
					show: true,
					type: 'Error',
					text: error?.response?.data?.error?.message || 'Server Error',
				});
			});
	};
};
const checkExistingUserReset = () => {
	return (dispatch) => {
		dispatch({
			type: USER_ACTION_TYPE.existEmailReset,
		});
	};
};
const addExistingUser = (payload) => {
	return (dispatch) => {
		axios
			.post(`${BASEURL}/api/auth/existing-user`, payload)
			.then((response) => {
				dispatch({ status: true, type: USER_ACTION_TYPE.addExistingUser, data: response?.data });
			})
			.catch((error) => {
				dispatch({
					status: false,
					type: USER_ACTION_TYPE.addExistingUser,
					error: error?.response?.data?.error || 'Server Error',
				});
			});
	};
};
const signRequestIdle=()=>{
	return async (dispatch) => {
		dispatch({
			type: USER_ACTION_TYPE.esignRequestIdle,
		})

	}
}
const signRequest=()=>{
	return async (dispatch) => {
		axios
		.post(`${BASEURL}/api/signnow/create-signin-link`)
		.then((response) => {
			// HANDLE RESPONSE
			dispatch({
				type: USER_ACTION_TYPE.esignRequestSuccess,
				payload: {
					message: 'Successfully',
					status: true,
					data: response?.data?.data,
				},
			});
		})
		.catch((error) => {
			// HANDLE ERROR MESSAGE
			dispatch({
				type: USER_ACTION_TYPE.esignRequestError,
				payload: {
					message: handleJobsError('simple', error),
					status: false,
					data: {},
				},
			});
		});

	}
}
const contractSignRequestCustomerIdle=()=>{
	return async (dispatch) => {
		dispatch({
			type: USER_ACTION_TYPE.contractSignRequestCustomerIdle,
		})

	}
}
const contractSignRequestCustomer=(job_uuid)=>{
	return async (dispatch) => {
		axios
		.post(`${BASEURL}/api/signnow/create-contract-link?job_uuid=${job_uuid}`)
		.then((response) => {
			// HANDLE RESPONSE
			dispatch({
				type: USER_ACTION_TYPE.contractSignRequestCustomerSuccess,
				payload: {
					message: 'Successfully',
					status: true,
					data: response?.data?.data,
				},
			});
		})
		.catch((error) => {
			// HANDLE ERROR MESSAGE
			dispatch({
				type: USER_ACTION_TYPE.contractSignRequestCustomerError,
				payload: {
					message: handleJobsError('simple', error),
					status: false,
					data: {},
				},
			});
		});

	}
}
// FUNCTION TO HANDLE Jobs API ERRORS
const handleJobsError = (type, error) => {
	if (type === 'simple') {
		return error.code === 'ERR_NETWORK'
			? error?.message
			: error?.response?.data?.error
			? error?.response?.data?.error
			: 'Internel Server Error!';
	}
};
export const user = {
	Signup,
	Login,
	ForgotPassword,
	ResetPassword,
	reset_user,
	VerifyEmail,
	update_store,
	ResendLink,
	ReGetUserData,
	logout,
	toggleTaxProPause,
	deleteUser,
	removeUserData,
	checkExistingUser,
	checkExistingUserReset,
	addExistingUser,
	signRequestIdle,
	signRequest,
	contractSignRequestCustomerIdle,
	contractSignRequestCustomer
};

/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import Select from "react-select";
import axios from "axios";
import {BASEURL} from "../../../utilites";
import {useSelector, useDispatch} from "react-redux";
import allActions from "../../../Store/action";

const ProfileAddLanguageModal = ({reload,languageToEdit, setLanguageToEdit}) => {
    const [
       {LoginData}
    ] = useSelector((state) => [state.user]);
    const languageOptions = [
        { value: "english", label: "English" },
        { value: "spanish", label: "Spanish" },
        { value: "french", label: "French" },
        { value: "german", label: "German" },
        { value: "mandarin", label: "Mandarin" },
        { value: "hindi", label: "Hindi" },
        { value: "arabic", label: "Arabic" },
        { value: "russian", label: "Russian" },
        { value: "japanese", label: "Japanese" },
        { value: "korean", label: "Korean" },
    ];

    const proficiencyOptions = [
        { value: "beginner", label: "Beginner" },
        { value: "intermediate", label: "Intermediate" },
        { value: "advanced", label: "Advanced" },
        { value: "native", label: "Native" },
    ];

    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [selectedProficiency, setSelectedProficiency] = useState(null);
    const [message,setMessage] = useState({show:false,type:null,text:null});
    const dispatch = useDispatch();

    const handleSave = (e) => {
        e.preventDefault();
        console.log("Selected Language:", selectedLanguage);
        console.log("Selected Proficiency:", selectedProficiency);
    };

    const createLanguage = async () => {
        dispatch(allActions.global.Loader(true))
        try{
            const resp = await axios.put(`${BASEURL}/api/professionals/profile/languages`,
                {
                    userId:LoginData?.userpro?.user_id,
                    name:selectedLanguage?.label,
                    type:selectedProficiency?.label,
                }
            )
            document.querySelector(".closeAddOrofileLanguage").click();
            setMessage({show:false,type:'success',text:resp?.data});
            setSelectedProficiency(null)
            setSelectedLanguage(null)
            reload()
            dispatch(allActions.global.Loader(false))

        }catch (err){
            console.log(err);
            setMessage({show:false,type:'error',text:'Server error'});
            setSelectedProficiency(null)
            setSelectedLanguage(null)
            dispatch(allActions.global.Loader(false))
        }
    }

    const editLanguage = async  () => {
        dispatch(allActions.global.Loader(true))
        try{
            const resp = await axios.patch(`${BASEURL}/api/professionals/profile/languages`,
                {
                    id:languageToEdit?.id,
                    name:selectedLanguage?.label,
                    type:selectedProficiency?.label,
                }
            )
            document.querySelector(".closeAddOrofileLanguage").click();
            setMessage({show:false,type:'success',text:resp?.data});
            setSelectedProficiency(null)
            setSelectedLanguage(null)
            reload()
            dispatch(allActions.global.Loader(false))

        }catch (err){
            console.log(err);
            setMessage({show:false,type:'error',text:'Server error'});
            setSelectedProficiency(null)
            setSelectedLanguage(null)
            setLanguageToEdit({})
            dispatch(allActions.global.Loader(false))
        }
    }

    const handleSubmit =  (e) => {
        e.preventDefault();
        if(Object.keys(languageToEdit)?.length > 0){
            editLanguage()
        }else{
            createLanguage()
        }
    }



    useEffect(() => {
        if(Object.keys(languageToEdit)?.length > 0){
            setSelectedProficiency({value:languageToEdit?.type,label:languageToEdit?.type})
            setSelectedLanguage({value:languageToEdit?.name,label:languageToEdit?.name})
        }
    },[languageToEdit])

        useEffect(() => {
            const modalElement = document.querySelector('#closeAddOrofileLanguage');

            const handleModalHidden = () => {
                myHiddenFunction();
            };

            modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

            return () => {
                modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
            };
        }, []);

        const myHiddenFunction = () => {
            setSelectedProficiency(null)
            setSelectedLanguage(null)
            setLanguageToEdit({})
        };


        useEffect(() => {
            if(Object.keys(languageToEdit)?.length === 0){
                setSelectedProficiency(null)
                setSelectedLanguage(null)
            }
        },[languageToEdit])
    

    return (
        <div className="container  text-start">
            <form onSubmit={handleSave}>
                <div className="mb-3">
                    <label htmlFor="language" className="form-label text-black fw-bold">
                        Language
                    </label>
                    <Select
                        className={'border border-1 rounded'}
                        id="language"
                        options={languageOptions}
                        placeholder="Language"
                        value={selectedLanguage}
                        onChange={setSelectedLanguage}
                        isClearable
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="proficiency" className="form-label  text-black fw-bold">
                        Proficiency
                    </label>
                    <Select
                        className={'border border-1 rounded'}
                        id="proficiency"
                        options={proficiencyOptions}
                        placeholder="Select"
                        value={selectedProficiency}
                        onChange={setSelectedProficiency}
                        isClearable
                    />
                </div>
                <div className="d-flex justify-content-end gap-2">
                <button
                        type="button"
                        className="btn text-muted border border-1 py-2 px-3 fw-bold "
                        onClick={() => {
                            document.querySelector(".closeAddOrofileLanguage").click();
                        }}
                    >
                        Close
                    </button>
                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                        {Object.keys(languageToEdit)?.length > 0 ?  'Save edited' : 'Save'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ProfileAddLanguageModal;

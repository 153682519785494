export default function ChartStatisticBox({ 
	value, 
	caption,
	className='',
}) {
	return (
		<div className='col text-start'>
			<div className={`clearfix border-end border-light p-0 p-md-3 ${className}`}>
				<p className='fw-bold'>{caption}</p>
				<h3 className='fw-bold mb-0'>
					{value}
				</h3>
			</div>
		</div>
	);
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import {useNavigate } from 'react-router-dom';

const MilestoneMessageCard = ({ data, index, milestoneData }) => {
	const [{ LoginData }] = useSelector((state) => [state.user]);
	const sender = data?.senderId === LoginData?.uuid;
	const navigate = useNavigate();

	const reDirect=()=>{
if(LoginData.isCustomer){
	navigate(`projects/${milestoneData?.job_uuid}`)
}else{	
navigate(`/dashboard/jobs/${milestoneData?.job_uuid}`)
	}


	}
	return (
		<div
			className={
				sender
					? 'bg-dark text-white  chat-item d-flex flex-column message-width'
					: 'bg-primary  bg-opacity-10  chat-item d-flex flex-column w-75 message-width'
			}
		>
			<font
				style={{ width: 'fit-content' }}
				className={
					sender
						? 'bg-dark text-white px-3 py-2 chat-item text-break fold-Text-small message-width'
						: 'bg-primary bg-opacity-10 px-3 py-2 chat-item text-break fold-Text-small message-content message-width'
				}
			>
				{milestoneData?.milestone_action === 'create-contract' ? (
					<>
							<p>Gather and review all relevant financial documents, including income statements, deductions, receipts, and previous tax filings. Conduct an initial assessment of tax liabilities and identify potential deductions or credits.</p>
						<div className="milesstone-message-bg" >
							<div className='message-innerBox rounded-2 px-3 py-2'>
					{milestoneData?.milestones.map((v) => (
						<>
							<div className="d-flex gap-2">
								<p className="ff-alshaus-bold text-grey-500">Total cost:</p>
								<span className='text-grey-500'>${v?.amount}</span>
							</div>
							<div className="d-flex gap-2">
								<p className="ff-alshaus-bold text-grey-500">Milestone:</p>
								<span className='text-grey-500'>{v?.name}</span>
							</div>
							<div className="d-flex gap-2">
								<p className="ff-alshaus-bold text-grey-500">Due date:</p>
								<span className='text-grey-500' >{moment(v.dueDate).format('D MMM YYYY')}</span>
							</div>
						</>
					))}
					
							<a  onClick={reDirect} href=''   className="ff-alshaus-bold text-decoration-none" style={{color:'#4C56E7'}}>
								View Contract
							</a>
							</div>
					</div>
					</>
				) : milestoneData?.milestone_action === 'contract_signed' || milestoneData?.milestone_action === 'contract-update' ? (
					
					<div className="milesstone-message-bg d-flex align-items-end ">
						<div className='message-innerBox rounded-2 px-3 py-2 height-100 d-flex align-items-end'>
							<a onClick={reDirect}  href=''   className="ff-alshaus-bold text-decoration-none" style={{color:'#4C56E7'}}>
							View Contract
						</a>
					</div>
					</div>
				) : (
					<div className="milesstone-message-bg">
													<div className='message-innerBox rounded-2 px-3 py-2'>

						<div className="d-flex gap-2">
							<p className="ff-alshaus-bold text-grey-500">Milestone cost:</p>
							<span className='text-grey-500'>$ {milestoneData?.amount}</span>
						</div>
						<div className="d-flex gap-2">
							<p className="ff-alshaus-bold text-grey-500">Milestone:</p>
							<span  className='text-grey-500'>{milestoneData?.name} </span>
						</div>
						<div className="d-flex gap-2">
							<p className="ff-alshaus-bold text-grey-500">Due date:</p>
							<span className='text-grey-500'>{moment(milestoneData?.due_date).format('D MMM YYYY')}</span>
						</div>
						<a  onClick={reDirect} href=''  className="ff-alshaus-bold text-decoration-none" style={{color:'#4C56E7'}}>
							View Details
						</a>
					</div>
					</div>
				)}
			</font>
		</div>
	);
};
export default MilestoneMessageCard;

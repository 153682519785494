import React, { useEffect, useState } from 'react';
import PaymentForm from '../../forms/PaymentForm';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import MembershipSelectedCard from './memberShipSelectedCard';
import { BsPlus, BsThreeDots } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../Modal';
import { RiAlertFill } from "react-icons/ri";
import allActions from '../../../Store/action';
import Message from '../../forms/fields/Message';
import { MEMBERSHIP_ACTION_TYPE } from '../../../Store/membership/Type';

const BillingCard = () => {
	let dispatch = useDispatch();
	const [membership, setMemberShip] = useState();
	const [editCardNested, setEditCardNested] = useState();
	const [selectedCard, setSelectedCard] = useState()
	const [deletedCardNested, setDeletedCardNested] = useState();
	const navigate = useNavigate();

	// Other states
	const [message, setMessage] = useState({ show: false });
	useEffect(() => {
		setMemberShip(true);
	}, []);

	const [{ LoginData }, { deleteMembershipPlan }] = useSelector((state) => [state.user, state.membership]);

	const cardData = [
		{
			planType: 'Free',
			price: '0',
			increment: '+40%',
			availability: '/3 mo free access',
			details: 'TPG Students',
			includes: [
				'High-Groth Tier',
				'Enterprise Tier',
				'TGP Progream (UNK Certified)',
				'UNK Pro (UNK Certified + Mastermind)',
				'lorem ipsum',
			],
			btnText: 'Upgrade to Basic',
		},
		{
			planType: 'Basic',
			price: '499',
			increment: '+40%',
			availability: '/mo',
			details: 'TPG Students Or SMB Tax Pros',
			includes: ['lorem ipsum', 'lorem ipsum', 'lorem ipsum', 'lorem ipsum', 'lorem ipsum'],
			btnText: 'Upgrade to pro',
		},
		{
			planType: 'High-Growth',
			price: '1999',
			increment: '+40%',
			availability: '/mo',
			details: 'Small Business/Tax Prep Firms',
			includes: ['lorem ipsum', 'lorem ipsum', 'lorem ipsum', 'lorem ipsum', 'lorem ipsum'],
			btnText: 'Upgrade to Plus',
		},
		{
			planType: 'Enterprise',
			price: '2499',
			increment: '+40%',
			availability: '/mo',
			details: 'Medium/Large Sized Tax Firms',
			includes: ['lorem ipsum', 'lorem ipsum', 'lorem ipsum'],
			btnText: 'Upgrade to Premium',
		},
		// {
		// 	planType: 'Custom',
		// 	price: '?',
		// 	increment: '+40%',
		// 	availability: '/mo',
		// 	details: 'Medium/Large Sized Tax Firms',
		// 	includes: ['lorem ipsum', 'lorem ipsum', 'lorem ipsum', 'lorem ipsum', 'lorem ipsum'],
		// 	btnText: 'Upgrade to Premium',
		// },
	];

	// Filtering current  membership data
	let filteredMembershipData = LoginData?.usermembership?.find(({ sub_endTime }) =>
		moment(sub_endTime).isAfter(moment())
	);
	let trial = false;

	//    check for trial
	if (!filteredMembershipData) {
		var trialFilteredMembershipData = LoginData?.usermembership?.find(({ trial_endTime }) =>
			moment(trial_endTime).isAfter(moment())
		);
		if (trialFilteredMembershipData) {
			trial = true;
			filteredMembershipData = trialFilteredMembershipData;
		}
	}
	const card = cardData?.find((data, index) => filteredMembershipData?.membership_Id === index + 1);
	// CANCEL SUBSCRIPTION
	// 
	useEffect(() => {
		setMessage({ show: false });
		// if Failure
		if (!filteredMembershipData) {
			setMessage({ show: false });
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: "Looks like you do not have an active membership right now. click on the button below to subscribe to one of our membership packages.",
			});
		}

	}, [dispatch, filteredMembershipData]);

	useEffect(() => {
		if (!deleteMembershipPlan.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: deleteMembershipPlan.message,
			});
		}
		if (deleteMembershipPlan.status === true) {
			setMessage({
				show: true,
				type: 'Success',
				text: deleteMembershipPlan.message,
			});
			dispatch(allActions.user.ReGetUserData())
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, deleteMembershipPlan]);
	 
	const cancelSubscription = () => {
		if (filteredMembershipData?.stripe_sub_Id) {
			dispatch(allActions.global.Loader(true));
			dispatch({type: MEMBERSHIP_ACTION_TYPE.deleteMembershipPlanIdle});
			dispatch(allActions.membership.deleteMembershipPlan(filteredMembershipData?.stripe_sub_Id))
			setMessage({show:false})
		} else {
			setMessage({
				show: true,
				type: 'Error',
				text: "Free plan cannot be cancel",
			});
		}
	}

	const renderCard = (data, index) => {
		return (<div key={index} className="col-lg-12 col-md-12 col-sm-12 d-flex flex-row align-items-center justify-content-between py-1">
			<p className=' small-text px-2'>{data?.payment_method_data?.name} ending in {data?.payment_method_data?.last4} ,exp {`${data?.payment_method_data?.exp_month}/ ${data?.payment_method_data?.exp_year} `}</p>

			<div className="dropdown-center">
				<button
					type="button"
					className={`border-0 bg-transparent`}
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<BsThreeDots className="" />
				</button>
				<ul className="dropdown-menu border-light">
					<li>
						<Link className={`dropdown-item py-2`} onClick={() => {
							setEditCardNested(data)

							setTimeout(() => {
								setEditCardNested()
							}, 300);
						}}>
							Edit Card
						</Link>
					</li>
					<li>
						<Link className={`dropdown-item text-danger py-2`}
							to="#"
							data-bs-toggle="modal"
							data-bs-target="#removeCard"
							onClick={() => setSelectedCard(data)}
						>
							Remove Card
						</Link>
					</li>
				</ul>
			</div>
		</div>)
	}
	return (
		<div className="row">
			<div className="container">
				{message.show ? <Message message={message} /> : <></>}
				<div className="card rounded p-4 cutomBorderBox mb-4">
					{filteredMembershipData ? (
						<>
							<p className="fw-bold">Current Plan</p>
							<MembershipSelectedCard
								card={card}
								DBData={filteredMembershipData?.membership}
								index={filteredMembershipData?.membership_Id}
								trial={trial}
								currentPlan={true}
							/>
							<div className="col-lg-4 col-md-5 col-sm-12 d-flex align-items-center justify-content-between">
								<button
									className={`btn mt-3 col-5 bg-white text-black mb-2 border border-1`}
									onClick={() => {
										navigate('/dashboard/membership');
									}}
								>
									Change Plan
								</button>
								<button className={`btn mt-3 col-6 text-danger mb-2`}  data-bs-toggle="modal" data-bs-target="#cancelSubcription">
									Cancel subscription
								</button>
							</div>
						</>
					) : (
						<div className="col-lg-4 col-md-5 col-sm-12 d-flex align-items-center justify-content-between">
							<button
								className={`btn mt-3 col-5 bg-white text-black mb-2 border border-1`}
								onClick={() => {
									navigate('/dashboard/membership');
								}}
							>
								Choose Plan
							</button>
						</div>
					)}
				</div>
				<PaymentForm membership={membership} invoice={true} editCardNested={editCardNested} deletedCardNested={deletedCardNested} />
				<div className="card rounded p-4 cutomBorderBox my-4">
					<p className="fw-bold">Payment method</p>
					<div className="d-flex flex-column align-items-start justify-content-around">
						{LoginData?.userpaymentmethod?.map(
							(data, index) => data.payment_method_id.startsWith('pm') && renderCard(data, index)
						)}
					</div>
					<div className="col-lg-4 col-md-5 col-sm-12 d-flex align-items-center justify-content-between">
						<button
							className={`btn mt-3 col-5 text-primary mb-2 border border-1 border-primary d-flex align-items-center justify-content-center`}
							onClick={() => document.querySelector('.paymentformmodal').click()}
						>
							<BsPlus size={23} /> Add Card
						</button>
					</div>
				</div>
				<Modal
					title=""
					bodyClass="p-4 text-center text-dark text-opacity-50"
					btnText="Update"
					btnClass="profilePictureModalClose"
					trigger="removeCard"
					showBtn={false}
				>
					<div className='w-100 d-flex flex-column align-items-center justify-content-center'>
						<RiAlertFill size={100} color='red' />
						<h5 className='text-black fw-bold'>Card Removal</h5>
						<p className='text-black col-lg-8 col-md-10 col-sm-11'>Are you sure you want to remove card end with <span className='fw-bold'>{selectedCard?.payment_method_data?.last4}</span></p>
						<div className='col-lg-8 d-flex align-items-center justify-content-around'>
							<button className={`btn btn-outline-primary mt-3 col-md-5 col-lg-4 mb-2 border border-1`}
								data-bs-toggle="modal"
								data-bs-target="#removeCard"
							>
								No
							</button>
							<button
								className={`btn btn-primary mt-3 col-6 mb-2`}
								data-bs-toggle="modal"
								data-bs-target="#removeCard"
								onClick={() => {
									setDeletedCardNested(selectedCard);
									setTimeout(() => {
										setDeletedCardNested()
									}, 300);
								}}
							>
								Confirm
							</button>
						</div>
					</div>
				</Modal>
				<Modal
					title=""
					bodyClass="p-4 text-center text-dark text-opacity-50"
					btnText="Update"
					btnClass="profilePictureModalClose"
					trigger="cancelSubcription"
					showBtn={false}
				>
					<div className='w-100 d-flex flex-column align-items-center justify-content-center'>
						<RiAlertFill size={100} color='red' />
						<h5 className='text-black fw-bold'>Cancel Subscription</h5>
						<p className='text-black col-lg-8 col-md-10 col-sm-11'>Are you sure you want to cancel your subscription</p>
						<div className='col-lg-8 d-flex align-items-center justify-content-around'>
							<button className={`btn btn-outline-primary mt-3 col-md-5 col-lg-4 mb-2 border border-1`}
								data-bs-toggle="modal"
								data-bs-target="#cancelSubcription"
							>
								No
							</button>
							<button
								className={`btn btn-primary mt-3 col-6 mb-2`}
								data-bs-toggle="modal"
								data-bs-target="#cancelSubcription"
								onClick={() =>cancelSubscription()}
							>
								Confirm
							</button>
						</div>
					</div>
				</Modal>
			</div>
		</div>
	);
}

export default BillingCard
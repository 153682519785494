/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */

import io from 'socket.io-client';
import { BASEURL } from '../../../../utilites';
import allActions from '../../../../Store/action';
import { useEffect, useState, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../../../components/forms/fields/Message';
import UsersList from '../../../../components/Messaging/UsersList';
import Offcanvas from '../../../../components/Dashboard/Offcanvas';
import Alert from '../../../dashboard/components/Alert';
import UserTopBar from '../../../../components/Messaging/UserTopBar';
import SubmitArea from '../../../../components/Messaging/SubmitArea';
import MessageCard from '../../../../components/Messaging/MessageCard';
import { useIsTyping } from '../../../../components/Custom/UserisTyping.js';
import { EditorState, convertToRaw, getDefaultKeyBinding, Modifier,ContentState, convertFromHTML, } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SideMenuButton from '../../../../components/Messaging/SideMenuButton';
import { FIREBASE_ACTION_TYPE } from '../../../../Store/firebase/Type';
import { Scrollbar } from 'react-scrollbars-custom';
import { useLocation } from 'react-router-dom';
import { CALL_ACTION_TYPE } from '../../../../Store/call/Type';
import { AiOutlineSearch } from 'react-icons/ai';
import Loader from '../../../../components/Loader.js';
import { db } from '../../../../config/firebase.js';
import {
	updateDoc,
	doc,
} from 'firebase/firestore';

const CustomerMessageBoard = ({ uuid, group, editable = true, isIndexPage }) => {
	// ---------------------------------------
	// Variables , States & Store States
	// ---------------------------------------
	//
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [showAlertForCustomer, setShowAlertForCustomer] = useState(true);

	const onEditorStateChange = (newEditorState) => {
		const rawData = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
		const currentContentTextLength = editorState.getCurrentContent().getPlainText().length;
		const newContentTextLength = newEditorState.getCurrentContent().getPlainText().length;

		if (currentContentTextLength === 0 && newContentTextLength === 1) {
			// WORKAROUND: listens to input changes and focuses/moves cursor to back after typing in first character
			setEditorState(EditorState.moveFocusToEnd(newEditorState));
		} else {
			setEditorState(newEditorState);
		}
		const convertLinksToAnchorTags = (html) => {
			const regex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
			const containsAnchorTag = /<a\s+href=["'].*?["'].*?>.*?<\/a>/i.test(html);
			if (containsAnchorTag) {
			  return html;
			} else {
			  return html.replace(regex, '<a href="$1" target="_blank">$1</a>');
			}
		  };
		  

		// Function to blur email addresses
		const blurEmailAddresses = (html) => {
			const emailRegex = /\b[A-Z0-9._%+-]+\s?@\s?[A-Z0-9.-]+\s?\.\s?[A-Z]{2,}\b/ig;
			return html.replace(emailRegex, '<span class="blurred">fakeemail@gmail.com</span>');
		};

		  // Function to blur email domains like @gmail.com or any other
		  const blurEmailDomains = (html) => {
			const domainRegex = /@gmail\.com|@yahoo\.com|@outlook\.com|@hotmail\.com|@\w+\.\w+/ig;
			return html.replace(domainRegex, '<span class="blurred">protected@domain.com</span>');
		};

		// Function to blur phone numbers
		const blurPhoneNumbers = (html) => {
			const phoneRegex = /\b(\+?\d{1,4}[\s-]?)?(\(?\d{1,4}\)?[\s-]?)?[\d\s-]{7,}\b/g;
			return html.replace(phoneRegex, '<span class="blurred">fakephone@gmail.com</span>');
		};

		// Convert links in the raw HTML content
		let processedData = convertLinksToAnchorTags(rawData);

		// Blur email addresses and phone numbers
		processedData = blurEmailAddresses(processedData);
		processedData = blurPhoneNumbers(processedData);
		processedData = blurEmailDomains(processedData);
	
		if(editText && editId){
			setEditText((prev) => processedData)
		}else{
			setMessageContent(processedData);
		}
	};

	const count = 10;
	const dispatch = useDispatch();
	const messagesEndRef = useRef(null);
	const params = useLocation();
	const scrollContainerRef = useRef();
	const scrollContainerRef1 = useRef();
	
	let [
		{ user, dashboard },
		{ LoginData, loginStatus },
		{
			saveDocId,
			adminRole,
			storageLinks,
			readPersonalChats,
			writeStorageStatus,
			writePersonalStatus,
			personalConnections,
			writePersonalMessage,
			readPersonalChatsStatus,
			writePersonalReplyStatus,
			readPersonalChatsMessage,
			writePersonalReplyMessage,
			personalGroupConnections,
			chatStatus,
		},
	] = useSelector((state) => [state, state.user, state.firebase]);
	personalConnections = group ? personalGroupConnections : personalConnections;
	const [search, setSearch] = useState('');
	const [isTyping, setIsTyping] = useIsTyping();
	const [chatUsers, setChatUsers] = useState([]);
	const [openChat, setOpenChat] = useState(false);
	const [imageLinks, setimageLinks] = useState([]);
	const [imagesArray, setImagesArray] = useState([]);
	const [manageLocal, setmanageLocal] = useState(false);
	const [messageContent, setMessageContent] = useState();
	const [message, setMessage] = useState({ show: false });
	const [selectDefault, setSelectDefault] = useState(false);
	const [selectedReceiver, setselectedReceiver] = useState();
	const [seenCheckerEvent, setSeenCheckerEvent] = useState(0);
	const [initialLoad, setInitialLoad] = useState(count);
	const [editId,setEditId] = useState(null)
	const [editText,setEditText] = useState('')

	const [load, setLoad] = useState(false);
	let myIds = [
		group && !group?.includes(undefined) ? group.find((data) => data !== uuid) : LoginData?.uuid,
		selectedReceiver?.uuid,
	];
	const chatDisable =
	LoginData?.isTaxPro &&
	Number(
		dashboard?.leads?.data?.find((data) => data?.user?.id === selectedReceiver?.id)?.status
	) === 3;
	// --------------------------------------------
	// HOOKS (UseEffect used For Different Purposes)
	// --------------------------------------------
	
	// *****--Hooks To Handle Paste Image--****
	useEffect(() => {
		if (chatDisable) return;
		const handlePaste = (event) => {
			const items = (event.clipboardData || event.originalEvent.clipboardData).items;
			const newImages = [];

			for (const item of items) {
				if (item.kind === 'file' && item.type.startsWith('image/')) {
					const file = item.getAsFile();
					newImages.push(file);
				}
			}
			if (newImages.length > 0) {
				fileUpload(newImages[0], 'add');
			}
		};

		document.addEventListener('paste', handlePaste);

		return () => {
			document.removeEventListener('paste', handlePaste);
		};
	}, [imagesArray]);

	// *****--Hooks To check image Upload--****
	useEffect(() => {
		if (writeStorageStatus === true) {
			setimageLinks(storageLinks);
		}
		if (!writeStorageStatus) {
			setMessage({
				show: true,
				type: 'Error',
				text: 'Something Went Wrong While Uploading media',
			});
		}
	}, [storageLinks, writeStorageStatus]);

	// *****--Hooks To check that user is Typing or not--****
	useEffect(() => {
		handleIsTyping(isTyping);
	}, [isTyping]);

	//  *****--HOOKS Which work on Enter key press trigger event--****
	useEffect(() => {
		setOpenChat(false);
		window.addEventListener('keypress', (e) => {
			if (e.key === 'Enter' && document.getElementById('sendMessage')) {
				document.getElementById('sendMessage').submit();
			}
		});
		// Use Interval to get latest Status of Last Seen
		setInterval(() => {
			setSeenCheckerEvent(seenCheckerEvent + Math.random() * 10000);
		}, 60000);
	}, []);

	//  *****--HOOKS To Retrive Chat Users(last seen , Online /offline)--****
	useEffect(() => {
		if (chatUsers?.length > 0) {
			dispatch(allActions.firebase.getFirebaseUsers(chatUsers));
		}
	}, [chatUsers]);

	//  *****--HOOKS To Apply Filter on Chats according to Last Chat--****
	useEffect(() => {
		manageFilter(loginStatus, adminRole.loaded, personalConnections);
	}, [loginStatus, adminRole.loaded, personalConnections, openChat, user, chatUsers.length]);

	// *****--Handle Store Response Of Create Topic--*****
	useEffect(() => {
		dispatch(allActions.firebase.resetWritePersonalMessage());

		if (!writePersonalStatus) {
			setmanageLocal(false);
			setMessage({
				show: true,
				type: 'Error',
				text: writePersonalMessage,
			});
		}
		if (writePersonalStatus === true) {
			loadChats(selectedReceiver, false, true);
			// reset Meeting Link
			dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.videoCallRoomIdle }));
		}
	}, [writePersonalStatus, writePersonalMessage]);

	// *****--Handle Store Response Of Reply Chat--*****
	useEffect(() => {
		if (!writePersonalReplyStatus) {
			setmanageLocal(false);
			setMessage({
				show: true,
				type: 'Error',
				text: writePersonalReplyMessage,
			});
		}
		if (writePersonalReplyStatus === true) {
			setmanageLocal(false);
			// reset Meeting Link
			dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.videoCallRoomIdle }));
		}
	}, [writePersonalReplyStatus, writePersonalReplyMessage]);

	// *****--Handle Store Response Of Read Chats--*****
	useEffect(() => {
		if (readPersonalChatsStatus === false) {
			setmanageLocal(false);
			setMessage({
				show: true,
				type: 'Error',
				text: readPersonalChatsMessage,
			});
			setOpenChat(true);
		}
		if (readPersonalChatsStatus === true) {
			setmanageLocal(false);
			setSelectDefault(undefined);
			setOpenChat(true);
			if (saveDocId === selectedReceiver?.docId && !group) {
				dispatch(
					allActions.firebase.updateNotification({
						docId: saveDocId,
						notificationObj: { [LoginData?.uuid]: 0 },
					})
				);
			}
			setTimeout(() => {
				messagesEndRef.current?.scrollToBottom();
			}, 10);
		}
	}, [readPersonalChatsStatus, readPersonalChatsMessage, readPersonalChats]);

	// GET DATA
	useEffect(() => {
		// FOR TAX PRO
		if (loginStatus && !user?.isRegular && !user?.isAdmin && !user?.isAccountManager) {
			dispatch(allActions.dashboard?.getAllLeads('', ''));
		}

		// FOR ADMIN
		if (loginStatus && !user?.isRegular && !user?.isTaxPro) {
			dispatch(allActions.dashboard?.getAllUsers('', ''));
		}

		// FOR ACCOUNT MANAGER
		if (loginStatus && user?.isAccountManager) {
			dispatch(allActions.dashboard?.getAllLeads('', ''));
		}
		if (loginStatus && user?.isAccountManager) {
			dispatch(allActions.dashboard?.getAllUsers('', ''));
		}
	}, [window.location.href]);

	// *****--Load Customer & Account manager If User is Tax Pro--*****
	useEffect(() => {
		if (
			loginStatus &&
			!user?.isRegular &&
			!user?.isAdmin &&
			!user?.isAccountManager &&
			dashboard?.leads.loaded &&
			adminRole.loaded === true
		) {
			let options = [];
			let filteredData = dashboard?.leads?.data?.map((data) => {
				return {
					role: 'Customer',
					id: data?.user?.id,
					uuid: data?.user?.uuid,
					lastname: data?.user?.lastname,
					firstname: data?.user?.firstname,
					email: data?.user?.email,
					phone: data?.phone,
					avatar:data?.user?.avatar
				};
			});
			options = filteredData;
			// check account manager exist or not
			if (user?.LoginData?.userpro?.manager) {
				let manager = user?.LoginData?.userpro?.manager;
				manager.role = 'Account Manager';
				options.push(manager);
			}

			let admin = adminRole?.data?.map((data) => {
				return {
					firstname: data?.firstname,
					lastname: data?.lastname,
					email: data?.email,
					id: data?.roles[0].userrole?.user_id,
					uuid: data?.uuid,
					role: 'Admin',
					avatar:data?.avatar
				};
			});
			options.push(admin[0]);
			setChatUsers(options);
			manageFilter(loginStatus, adminRole.loaded, personalConnections);
		}
	}, [
		loginStatus,
		dashboard?.leads.data,
		user?.isRegular,
		user?.isAdmin,
		user.isAccountManager,
		adminRole.loaded,
	]);
	// *****--Load Customers & TaxPro If User is Admin--*****
	useEffect(() => {
		if (
			loginStatus &&
			!user?.isRegular &&
			!user?.isTaxPro &&
			dashboard?.allUsers.loaded &&
			!user?.isAccountManager
		) {
			let filteredData = dashboard?.allUsers?.data
				.filter((data) => {
					if (!group) return data.roles[0].name !== 'customer';
					else return data;
				})
				?.map((data) => {
					if (data.roles[0].display_name !== 'Admin') {
						return {
							firstname: data?.firstname,
							lastname: data?.lastname,
							email: data?.email,
							id: data?.id,
							uuid: data?.uuid,
							role: data?.roles[0]?.display_name,
							phone: data?.userpro?.phone,
							avatar:data?.avatar

						};
					} else return undefined;
				});
			let others = filteredData.filter((data) => data !== undefined);
			setChatUsers(others);
			manageFilter(loginStatus, adminRole.loaded, personalConnections);
		}
	}, [
		loginStatus,
		dashboard.allUsers.data,
		user?.isTaxPro,
		user?.isRegular,
		user?.isAccountManager,
	]);

	// *****--Load TaxPro & Account manager If User is Customer--*****
	useEffect(() => {
		let options = [];
		if (
			loginStatus &&
			!user?.isTaxPro &&
			!user?.isAdmin &&
			!user?.isAccountManager
			// &&
			// adminRole.loaded === true
		) {
			let taxpro = LoginData?.lead[0]?.userpro?.user;
			if (taxpro) {
				taxpro.role = 'Tax Pro';
				options.push(taxpro);
			}
			// check account manager exist or not
			if (LoginData?.lead[0]?.userpro?.manager) {
				let manager = LoginData?.lead[0]?.userpro?.manager;
				manager.role = 'Account Manager';
				options.push(manager);
			}
			// handle admin role there
			// let admin = adminRole?.data?.map((data) => {
			// 	return {
			// 		firstname: data?.firstname,
			// 		lastname: data?.lastname,
			// 		id: data?.roles[0].userrole?.user_id,
			// 		uuid: data?.uuid,
			// 		role: "Admin",
			// 	};
			// });
			// options.push(admin[0]);
			setChatUsers(options);
			manageFilter(loginStatus, adminRole.loaded, personalConnections);
		}
	}, [
		loginStatus,
		user?.isTaxPro,
		user?.isAdmin,
		user?.isAccountManager,
		LoginData,
		// adminRole.loaded,
	]);

	// *****--RESET STATE--*****
	useEffect(() => {
		setOpenChat(false);
	}, [window.location.href]);

	// *****--Check Leads Exist or not & Customers as well  if user is account manager--*****
	useEffect(() => {
		// check leads exist or not
		if (
			loginStatus &&
			!user?.isRegular &&
			!user?.isAdmin &&
			!user?.isTaxPro &&
			dashboard?.allUsers.loaded &&
			adminRole.loaded === true
		) {
			let options = [];
			// check users exist or not
			let filteredUsersData = dashboard?.allUsers?.data?.map((data) => {
				return {
					id: data?.id,
					uuid: data?.uuid,
					firstname: data?.firstname,
					lastname: data?.lastname,
					email: data?.email,
					role: data?.roles[0]?.display_name,
					phone: data?.userpro.phone,
					avatar:data?.avatar
				};
			});
			options = filteredUsersData;
			let admin = adminRole?.data?.map((data) => {
				return {
					firstname: data?.firstname,
					lastname: data?.lastname,
					email: data?.email,
					id: data?.roles[0].userrole?.user_id,
					uuid: data?.uuid,
					role: 'Admin',
					avatar:data?.avatar
				};
			});
			options.push(admin[0]);
			console.log('CHECKK>>>>>>>', options);
			setChatUsers(options);
			manageFilter(loginStatus, adminRole.loaded, personalConnections);
		}
	}, [
		loginStatus,
		dashboard?.allUsers.loaded,
		user?.isRegular,
		user?.isTaxPro,
		user?.isAdmin,
		adminRole.loaded,
	]);

	console.log('dashboard?.allUsers', dashboard?.allUsers);
	// *****--Handle Store Response Of Loading Of Every HOOKS--*****
	useEffect(() => {
		// check user is login
		if (loginStatus) {
			if (
				manageLocal === true ||
				// For Leads Put Condition
				(!user?.isRegular && !user?.isAdmin && !dashboard?.leads.loaded) ||
				// For Customer Put Condition
				(!user?.isTaxPro && !user?.isAdmin && !LoginData?.lead[0]?.userpro?.user) ||
				// For Getting Admin user
				(!user?.isAdmin ? adminRole.loaded !== 'idle' : false) ||
				// personal firebase connection details
				personalConnections?.loaded === 'idle'
			) {
				dispatch(allActions.global.Loader(true));
			}
			if (
				// For Leads Put Condition
				((!user?.isRegular && !user?.isAdmin && dashboard?.leads.loaded) ||
					// For Customer Put Condition
					(!user?.isTaxPro && !user?.isAdmin && LoginData?.lead[0]?.userpro?.user) ||
					// For admin Put Condition
					(!user?.isTaxPro &&
						!user?.isTaxPro &&
						!user?.isAccountManager &&
						dashboard?.allUsers.loaded)) &&
				manageLocal === false &&
				// For Getting Admin user
				(!user?.isAdmin ? adminRole.loaded !== 'idle' : true) &&
				// personal firebase connection details
				personalConnections?.loaded === true
			) {
				dispatch(allActions.global.Loader(false));
			}
		}
	}, [
		dashboard?.allUsers.loaded,
		dashboard?.leads.loaded,
		user.isRegular,
		user.isAdmin,
		user.isTaxPro,
		user.isAccountManager,
		LoginData?.lead,
		manageLocal,
		loginStatus,
		adminRole?.loaded,
		personalConnections?.loaded,
	]);
	// *****--Function to Manage Filter According to Last Chat--****
	const manageFilter = () => {
		if (adminRole.loaded === 'idle' && loginStatus) {
			dispatch(allActions.firebase.getAdminUser());
		}
		// Read personalConnections if not loaded
		if (personalConnections?.loaded === 'idle' && loginStatus) {
			dispatch(allActions.firebase.readPersonalConnections(LoginData?.uuid));

			if (group && !group?.includes(undefined) && group.find((data) => data !== uuid)) {
				dispatch(
					allActions.firebase.readPersonalGroupConnections(group.find((data) => data !== uuid))
				);
			}
			// Submit User Id
			dispatch({
				type: FIREBASE_ACTION_TYPE.myUuid,
				uuid:
					group && !group?.includes(undefined)
						? group.find((data) => data !== uuid)
						: LoginData?.uuid,
			});
		}
		if (personalConnections?.loaded === true) {
			filteredChatUsers(personalConnections.data);
		}
	};
	const htmlToEditorState = (html) => {
		const blocksFromHTML = convertFromHTML(html);
		const contentState = ContentState.createFromBlockArray(
			blocksFromHTML.contentBlocks,
			blocksFromHTML.entityMap
		);
		return EditorState.createWithContent(contentState);
	};
	useEffect(() => {
		if(editText && editId){
			setEditorState(htmlToEditorState(editText))
		}else if (editText === '') {
			setEditorState(EditorState.createEmpty())
		}
	},[editId])

	//  *****--Function to Manage Handle Is Typing--****
	const handleIsTyping = (isTyping) => {
		let filteredConnection = personalConnections?.data?.filter(
			(data) => data?.ids?.includes(myIds[1]) && data?.ids?.includes(myIds[0])
		);

		// if user connection not exist
		if (filteredConnection.length === 0 && isTyping === true) {
			let topicObject = {
				title: 'General Chat Room',
				senderId: LoginData?.uuid,
				receiverId: selectedReceiver?.uuid,
				createdAt: new Date(),
				draft: [],
				isTyping: [LoginData?.uuid],
			};
			dispatch(allActions.firebase.writeChatPersonalConnection(topicObject));
		}

		if (filteredConnection.length !== 0) {
			// if user connection exist
			if (!isTyping) {
				dispatch(
					allActions.firebase.updateConnection(
						filteredConnection?.[0]?.docId,
						LoginData?.uuid,
						'remove'
					)
				);
			} else {
				dispatch(
					allActions.firebase.updateConnection(
						filteredConnection?.[0]?.docId,
						LoginData?.uuid,
						'add'
					)
				);

				if (filteredConnection?.[0]?.docId && LoginData?.uuid) {
					const socket = io(BASEURL, { transports: ['websocket'] });
					socket.emit('IsTyping', {
						uuid: LoginData?.uuid,
						docId: filteredConnection?.[0]?.docId,
					});
				}
			}
		}
	};

	//  *****--Function to Retrive User Chats--****
	const loadChats = (data, openChat, loading) => {
		if (LoginData?.uuid === undefined || data?.uuid === undefined || openChat === true) {
			return;
		}
		if (!loading) {
			setmanageLocal(true);
		}
		setselectedReceiver(data);
		setMessage({ show: false });
		let myIds = [
			group && !group?.includes(undefined) ? group.find((data) => data !== uuid) : LoginData?.uuid,
			data?.uuid,
		];
		let filteredConnection = personalConnections?.data?.find(
			(data) => data?.ids?.includes(myIds[1]) && data?.ids?.includes(myIds[0])
		);
		setSelectDefault(true);
		if (filteredConnection === undefined) {
			// if a connection not exist create a new connection for that
			dispatch(allActions.firebase.resetReadPersonalChatsArray());
			dispatch(allActions.firebase.saveDocID('idle'));
			setmanageLocal(false);
			setOpenChat(false);
		} else {
			let notificationObj = {
				docId: filteredConnection?.docId,
				notificationObj: { [LoginData?.uuid]: 0 },
			};
			if (parseInt(filteredConnection[LoginData?.uuid]) !== 0 && !group) {
				// Update Notificaiton Here
				dispatch(allActions.firebase.updateNotification(notificationObj));
			}
			dispatch(allActions.firebase.saveDocID(filteredConnection?.docId));
			dispatch(allActions.firebase.readPersonalChats(filteredConnection?.docId, LoginData?.uuid));
		}
		// Reset Values and Storage Media
		resetValues();
	};
	//  *****--Function to Filter Chat Users--****
	const filteredChatUsers = (connection) => {
		chatUsers?.map((data) => {
			let filtered = connection.find((v) => v?.ids?.includes(data?.uuid || ''));
			if (filtered) {
				data['updatedAt'] = filtered['updatedAt'];
				data[LoginData?.uuid] = filtered[LoginData?.uuid];
				data['docId'] = filtered['docId'];
			}
			return 0;
		});
		const sortedArray = chatUsers
			.slice()
			.sort((a, b) => (a.updatedAt ? b.updatedAt - a.updatedAt : 1) || -1);

		// Check if Array Contain Element then Save
		if (sortedArray.length > 0) {
			setChatUsers(group ? chatUsers.slice() : sortedArray);
		}
		// if from params any data came open that data chat
		if (params?.state?.uuid && !selectDefault && !openChat) {
			dispatch(allActions.firebase.resetReadPersonalChatsArray());
			loadChats(
				sortedArray.find((data) => data?.uuid === params?.state?.uuid),
				openChat
			);
			return;
		}
		if (uuid && sortedArray.find((data) => data?.uuid === uuid) && !openChat) {
			dispatch(allActions.firebase.resetReadPersonalChatsArray());
			loadChats(
				sortedArray.find((data) => data?.uuid === uuid),
				openChat
			);
			return;
		}
		// else open first chat
		if (!selectDefault && !openChat) {
			dispatch(allActions.firebase.resetReadPersonalChatsArray());
			loadChats(sortedArray[0], openChat);
		}
	};
	//  *****--Function to Reset New Connection Write--****
	const resetWriteConnection = () => {
		// reset Socket backup images
		const socket = io(BASEURL, { transports: ['websocket'] });
		socket.emit('removeImageUpload', storageLinks);

		// Reset Local States
		setImagesArray([]);
		setimageLinks([]);
		dispatch({ type: FIREBASE_ACTION_TYPE.storageLinks, payload: [] });
		setMessage({ show: false });
		dispatch(allActions.firebase.resetReplyPersonalMessage());
	};
	//  *****--Function to Submit Chat || Connection --****
	const handleSubmit = ({ type, encodedString }) => {
		// Check Validity
		if (selectedReceiver === undefined || chatDisable) return;
		if ((messageContent === '<p></p>\n' || messageContent === undefined) && imageLinks.length === 0)
			return;

		let filteredConnection = personalConnections?.data?.filter(
			(data) => data?.ids?.includes(myIds[1]) && data?.ids?.includes(myIds[0])
		);
		console.log('SUBMIT MESSAGE>>>>');
		resetWriteConnection();
		if (filteredConnection.length === 0) {
			let topicObject = {
				title: 'General Chat Room',
				// Check if Meeting link Exist use this otherwiste use messageContent
				body: encodedString ? encodedString : messageContent === '<p></p>\n' ? '' : messageContent,
				senderId: LoginData?.uuid,
				receiverId: selectedReceiver?.uuid,
				createdAt: new Date(),
				draft: [],
				updatedAt: new Date(),
				isTyping: [LoginData?.uuid],
				messageType: type,
			};
			let addMessage = true;
			dispatch(allActions.firebase.writeChatPersonalConnection(topicObject, addMessage));
		} else {
			let chatObject = {
				body: encodedString ? encodedString : messageContent === '<p></p>\n' ? '' : messageContent,
				senderId: LoginData?.uuid,
				receiverId: selectedReceiver?.uuid,
				createdAt: new Date(),
				messageType: type,
			};
			// it Update Notification as well updated At property
			let updateChatConn = {
				[selectedReceiver?.uuid]: filteredConnection?.[0]?.[selectedReceiver?.uuid] + 1,
				updatedAt: new Date(),
			};
			if (imageLinks.length > 0) {
				chatObject.images = imageLinks;
			}
			dispatch(
				allActions.firebase.writeChatPersonalReply(
					chatObject,
					filteredConnection?.[0].docId,
					updateChatConn
				)
			);
		}
		// SEND EMAIL REMINDER
		const status = allActions.firebase.getMyStatus(
			chatStatus?.data,
			selectedReceiver?.uuid,
			'status'
		);
		if (status !== 'online') {
			dispatch(
				allActions.sgMail.sendMailReminder({
					customerEmail: selectedReceiver.email,
					operatorFirstname: user?.LoginData?.firstname,
					operatorAvatar: user?.LoginData?.avatar[0]?.path,
					shortenedMessage: encodedString
						? encodedString.slice(0, 144) + '...'
						: messageContent === '<p></p>\n'
						? ''
						: messageContent.slice(0, 144) + '...',
					accountId: selectedReceiver?.uuid,
				})
			);
		}
		// Reset Values and Storage Media
		resetValues();
	};

	const handleEdit = async (docId) => {
		let filteredConnection = personalConnections?.data?.filter(
			(data) => data?.ids?.includes(myIds[1]) && data?.ids?.includes(myIds[0])
		);
		const docRef = doc(db, 'messages', filteredConnection[0]?.docId, 'chats', docId );
		let topicObject = {
			body: editText,
			updatedAt: new Date()
		}
		try {
			await updateDoc(docRef, topicObject );
			setEditId(null)
			setEditText('')
		} catch (error) {
		  console.error('Error in message update:', error);
		}
	  };

	console.log('SELECTED RECEOVER>>>', selectedReceiver?.email);
	const handleKeyPress = (event) => {
		if (event) {
			// FOR ENTER AND SHIFT
			if (event.key === 'Enter' && event.shiftKey) {
				event.preventDefault(); // Prevents the default behavior of the Enter key

				const currentContent = editorState.getCurrentContent();
				const selectionState = editorState.getSelection();
				const currentBlock = currentContent.getBlockForKey(selectionState.getStartKey());
				const currentBlockType = currentBlock.getType();

				const contentWithNewLine = Modifier.splitBlock(currentContent, selectionState);
				const newEditorState = EditorState.push(
					editorState,
					contentWithNewLine,
					'insert-characters'
				);
				const newSelection = newEditorState.getSelection();
				const newContent = Modifier.setBlockType(
					contentWithNewLine,
					newSelection,
					currentBlockType
				);
				setEditorState(EditorState.push(newEditorState, newContent, 'change-block-type'));
				return;
			}
			// FOR ENTER
			if (event.key === 'Enter' && !event.shiftKey) {
				event.preventDefault(); // Prevents the default behavior of the Enter key

				// Here, you can send the message using the content of the editor
				// document.getElementById("sendMessage").submit();
				handleSubmit({ type: 'simple' });
			}
		}
		// FOR BACKSPACE
		if (event.key === 'Backspace') return getDefaultKeyBinding(event);
	};
	const fileUpload = (file, option) => {
		if (!file) return;

		let path = `user/${LoginData?.uuid}/${Date.now()}`;
		let emitPath = {
			uuid: LoginData?.uuid,
			path,
		};
		setMessage({ show: false });
		dispatch(allActions.firebase.writeStorageIdle());
		dispatch(allActions.firebase.writeStorge(path, file, emitPath));

		if (option === 'add') {
			file.path = path;
			let images = [...imagesArray, file];
			setImagesArray(images);
		}
	};
	const handleRemoveImage = (imageObject) => {
		if (imageObject?.path) {
			// local Array image
			const newArray = imagesArray.filter((e) => {
				return e.path !== imageObject.path;
			});
			setImagesArray(newArray);

			// local Already Uploded Link
			let linksArray = imageLinks.filter((e) => {
				return e.path !== imageObject.path;
			});
			setimageLinks(linksArray);
			// local Already Uploded Link
			let storageLinkArray = storageLinks.filter((e) => {
				return e.path !== imageObject.path;
			});
			dispatch({ type: FIREBASE_ACTION_TYPE.storageLinks, payload: storageLinkArray });

			// // Remove Emit Socket
			const socket = io(BASEURL, { transports: ['websocket'] });
			socket.emit('removeImageUploadPerm', imageObject?.path);
		}
	};
	const resetValues = () => {
		// Reset Messages
		setMessageContent(undefined);
		setEditorState(EditorState.createEmpty());
		setImagesArray([]);
		setimageLinks([]);
		dispatch({ type: FIREBASE_ACTION_TYPE.storageLinks, payload: [] });
	};
	const handleScroll = () => {
		const scrollContainer = scrollContainerRef.current;
		const scrollContainer1 = scrollContainerRef1.current;
		// Check if the user has reached the bottom of the scroll container
		if (
			scrollContainer.scrollTop + scrollContainer.clientHeight >=
			scrollContainer.scrollHeight - 1
		) {
			handleMore();
			console.log('Reached the end of the scroll');
			// Perform any action you want when the scroll reaches the end
		}
		// Check if the user has reached the bottom of the scroll container
		if (
			scrollContainer1.scrollTop + scrollContainer1.clientHeight >=
			scrollContainer1.scrollHeight - 1
		) {
			handleMore();
			console.log('Reached the end of the scroll');
			// Perform any action you want when the scroll reaches the end
		}
	};

	const handleMore = () => {
		if (initialLoad < chatUsers?.length) {
			setLoad(true);
			setTimeout(() => {
				setInitialLoad(initialLoad + count);
				setLoad(false);
			}, 2000);
		} else {
			setInitialLoad(chatUsers?.length);
		}
	};

	if (isIndexPage) {
		return (
			<Fragment>
				{selectedReceiver && (
					<div
						className={`${
							uuid
								? 'border border-1 border-light  py-2 rounded'
								: 'border border-1 border-light  py-2 rounded'
						} chat-Box overflow-hidden d-flex flex-column justify-content-between`}
					>
						<>
							<UserTopBar
								chatDisable={chatDisable}
								group={group}
								chatUsers={chatUsers.filter((data) => group && group?.includes(data?.uuid) && data)}
								editable={editable}
								setMessage={setMessage}
								handleSubmit={handleSubmit}
								// selectedReceiver={selectedReceiver}
								setMessageContent={setMessageContent}
								getMyStatus={allActions.firebase.getMyStatus}
							/>
							<Scrollbar width={3} className="w-100 h-100" ref={messagesEndRef}>
								{showAlertForCustomer && LoginData?.isCustomer ? (
									<Alert
										className="position-sticky top-0"
										type="warning"
										message="Protections for customers are only valid if Customers utilize the payment and job system in Uncle Kam. If payment of agreements are made outside of Uncle Kam, we cannot provide support in dispute resolution"
										clickHandler={() => setShowAlertForCustomer(false)}
									/>
								) : null}
								<div className="chats-container">
									<div className="clearfix chatroom p-2 chats-area">
										{readPersonalChats &&
											readPersonalChats
												.sort((a, b) => a.createdAt - b.createdAt)
												?.map((data, index) => {
													return (
														<MessageCard
															group={group}
															data={data}
															index={index}
															key={index}
															chatUsers={chatUsers}
															editId={editId}
															setEditId={setEditId}
															setEditText={setEditText}
														/>
													);
												})}
									</div>
								</div>
							</Scrollbar>
						</>
						{editable && (
							<SubmitArea
								handleEdit={handleEdit}
								myIds={myIds}
								chatDisable={chatDisable}
								imageLinks={imageLinks}
								fileUpload={fileUpload}
								setIsTyping={setIsTyping}
								imagesArray={imagesArray}
								messageContent={messageContent}
								selectedReceiver={selectedReceiver}
								handleRemoveImage={handleRemoveImage}
								editorState={editorState}
								handleKeyPress={handleKeyPress}
								onEditorStateChange={onEditorStateChange}
								handleSubmit={handleSubmit}
								editMode={editId}
								setEditId={setEditId}
								setEditText={setEditText}
								docId={editId}
							/>
						)}
					</div>
				)}
			</Fragment>
		);
	}
	return (
		<div className="border-secondary-100 p-0 mb-3 rounded main ">
			<div className="row justify-content-between">
				{message?.show ? <Message message={message} /> : <></>}
				{!uuid && (
					<div className="col-md-3 chat-Box  chat-list-side">
						<div className="clearfix mb-2">
							<AiOutlineSearch
								className="position-absolute search-icon"
								size={23}
								color="#6A737B"
							/>
							<input
								type="text"
								className="form-control"
								style={{ paddingLeft: 35 }}
								placeholder="Find user..."
								onChange={(e) => setSearch(e.target.value)}
							/>
						</div>
						<Scrollbar
							onScroll={handleScroll}
							width={3}
							ref={scrollContainerRef}
							className="w-100 m-0 p-0 chat-user-scrollbar"
						>
							<UsersList
								search={search}
								chatUsers={
									chatUsers?.length > initialLoad ? chatUsers?.slice(0, initialLoad) : chatUsers
								}
								loadChats={loadChats}
								setOpenChat={setOpenChat}
								getMyStatus={allActions.firebase.getMyStatus}
								selectedReceiver={selectedReceiver}
							/>
							{load && (
								<Loader
									size={{ width: '2rem', height: '2rem' }}
									padding={10}
									local={true}
									loading={load}
									customBg={'rgba(0,0,0,0)'}
									viewClass="bg-transparent"
								/>
							)}
						</Scrollbar>
					</div>
				)}
				{!uuid && (
					<SideMenuButton
						selectedReceiver={selectedReceiver}
						getMyStatus={allActions.firebase.getMyStatus}
					/>
				)}
				{selectedReceiver && (
					<div
						className={`${
							uuid
								? 'col-md-12 border border-1 border-light  py-2 rounded'
								: 'col-md-9 border border-1 border-light  py-2 rounded'
						} chat-Box overflow-hidden d-flex flex-column justify-content-between`}
					>
						<>
							<UserTopBar
								group={group}
								chatDisable={chatDisable}
								chatUsers={chatUsers.filter((data) => group && group?.includes(data?.uuid) && data)}
								editable={editable}
								setMessage={setMessage}
								handleSubmit={handleSubmit}
								selectedReceiver={selectedReceiver}
								setMessageContent={setMessageContent}
								getMyStatus={allActions.firebase.getMyStatus}
							/>
							<Scrollbar width={3} className="w-100 h-100" ref={messagesEndRef}>
								{showAlertForCustomer && LoginData?.isCustomer ? (
									<Alert
										className="position-sticky top-0"
										type="warning"
										message="Protections for customers are only valid if Customers utilize the payment and job system in Uncle Kam. If payment of agreements are made outside of Uncle Kam, we cannot provide support in dispute resolution"
										clickHandler={() => setShowAlertForCustomer(false)}
									/>
								) : null}
								<div className="chats-container">
									<div className="clearfix chatroom p-2 chats-area">
										{readPersonalChats &&
											readPersonalChats
												.sort((a, b) => a.createdAt - b.createdAt)
												?.map((data, index) => {
													return (
														<MessageCard
															group={group}
															data={data}
															index={index}
															key={index}
															chatUsers={chatUsers}
															editId={editId}
															setEditId={setEditId}
															setEditText={setEditText}
														/>
													);
												})}
									</div>
								</div>
							</Scrollbar>
						</>
						{editable && (
							<SubmitArea
								handleEdit={handleEdit}
								chatDisable={chatDisable}
								myIds={myIds}
								imageLinks={imageLinks}
								fileUpload={fileUpload}
								setIsTyping={setIsTyping}
								imagesArray={imagesArray}
								messageContent={messageContent}
								selectedReceiver={selectedReceiver}
								handleRemoveImage={handleRemoveImage}
								editorState={editorState}
								handleKeyPress={handleKeyPress}
								onEditorStateChange={onEditorStateChange}
								handleSubmit={handleSubmit}
								editMode={editId}
								setEditId={setEditId}
								setEditText={setEditText}
								docId={editId}
							
							/>
						)}
					</div>
				)}
			</div>
			{!uuid && (
				<Offcanvas
					header="Chat Users"
					trigger="messageMobileView"
					style={{ width: 300, zIndex: 1 }}
					bodyClass="chat-offCanvas"
					enableScroll={false}
				>
					<div className="clearfix p-2 mb-2">
						<AiOutlineSearch className="position-absolute search-icon" size={23} color="#6A737B" />
						<input
							type="text"
							className="form-control"
							style={{ paddingLeft: 35 }}
							placeholder="Find user..."
							onChange={(e) => setSearch(e.target.value)}
						/>
					</div>
					<Scrollbar
						onScroll={handleScroll}
						width={3}
						ref={scrollContainerRef1}
						className="w-100"
						style={{ height: '92%' }}
					>
						<UsersList
							search={search}
							chatUsers={
								chatUsers?.length > initialLoad ? chatUsers?.slice(0, initialLoad) : chatUsers
							}
							loadChats={loadChats}
							setOpenChat={setOpenChat}
							getMyStatus={allActions.firebase.getMyStatus}
							selectedReceiver={selectedReceiver}
						/>
						{load && (
							<Loader
								size={{ width: '2rem', height: '2rem' }}
								padding={10}
								local={true}
								loading={load}
								customBg={'rgba(0,0,0,0)'}
								viewClass="bg-transparent"
							/>
						)}
					</Scrollbar>
				</Offcanvas>
			)}
		</div>
	);
};

export default CustomerMessageBoard;

import React, { useState } from 'react'

const TagGenerator = ({tags,setTags}) => {
    const [inputValue, setInputValue] = useState('')

    const createTags = () => {
        const newTags = inputValue.split(',').map((tag) => tag.trim()).filter((tag) => tag.length > 0)
        if (newTags.length > 0) {
            setTags([...tags, ...newTags])
            setInputValue('')
        }
    }

    const handleGenerateTag = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            createTags()
        }
    }

    const handleDeleteTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove))
    }

    return (
        <div className="mt-5">
            <div>Deliverable</div>
            <div className='d-flex align-items-center h-5'>
                <div className='flex-grow-1'>
                    <input
                        type='text'
                        placeholder="Enter Deliverable"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleGenerateTag}
                        className="form-control"
                    />
                </div>
                <div
                    className="btn btn-outline-primary ms-2"
                    style={{ width: '15%' }}
                    onClick={createTags}
                >
                    + Add Deliverable
                </div>
            </div>

            <div className="mt-3">
                <div className="row">
                    {tags.map((tag, index) => (
                        <div key={index} className="col-6 mb-2 min">
                            <div
                                className="d-flex justify-content-between text-primary p-2 rounded border border-1 shadow-sm cursor-pointer text-wrap"
                            >
                                <span className='d-flex'><span>• </span> <span className='ms-1'>{tag}</span></span>
                                <span className='align-self-start' onClick={() => handleDeleteTag(index)} >
                                    ✖
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TagGenerator
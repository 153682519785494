import { BASEURL } from '../../utilites';
import axios from 'axios';
import { JOBS_ACTION_TYPE } from './Type';

//  Function To Get Jobs By Tax Pro
const getJobsByTaxPro = ({ uuid }) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/jobs/getJob/${uuid}`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: JOBS_ACTION_TYPE.getJobs,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: JOBS_ACTION_TYPE.getJobs,
					payload: {
						message: handleJobsError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};

//  Function To GET JOBS BY LEAD
const getJobsByLead = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/jobs/getJob-bylead`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: JOBS_ACTION_TYPE.getJobs,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: JOBS_ACTION_TYPE.getJobs,
					payload: {
						message: handleJobsError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const cancelJobRequest = ({ uuid, obj }) => {
	return async (dispatch) => {
		axios
			.patch(`${BASEURL}/api/jobs/job-request/${uuid}`, obj)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: JOBS_ACTION_TYPE.cancelJobRequest,
					payload: {
						message: 'Request Submitted Successfully',
						status: response?.data?.success,
						data: response?.data?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: JOBS_ACTION_TYPE.cancelJobRequest,
					payload: {
						message: handleJobsError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const markJobAsCompleted = ({ uuid }) => {
	return async (dispatch) => {
		axios
			.put(`${BASEURL}/api/jobs/mark-job-completed/${uuid}`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: JOBS_ACTION_TYPE.markJobAsCompleted,
					payload: {
						message: 'Request Submitted Successfully',
						status: true,
						data: response?.data?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: JOBS_ACTION_TYPE.markJobAsCompleted,
					payload: {
						message: handleJobsError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const markJobAsClosed = ({ uuid,note }) => {
	return async (dispatch) => {
		axios
			.put(`${BASEURL}/api/jobs/close-job/${uuid}`,{
				note
			})
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: JOBS_ACTION_TYPE.markJobAsClosed,
					payload: {
						message: 'Job closed successfully.',
						status: true,
						data: response?.data?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: JOBS_ACTION_TYPE.markJobAsClosed,
					payload: {
						message: handleJobsError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const resetMarkJobAsClosed = () => {
	return async (dispatch) => {
		dispatch({
			type: JOBS_ACTION_TYPE.markJobAsClosedIdle,
		});
	};
};
// FUNCTION TO HANDLE Jobs API ERRORS
const handleJobsError = (type, error) => {
	if (type === 'simple') {
		return error.code === 'ERR_NETWORK'
			? error?.message
			: error?.response?.data?.error
			? error?.response?.data?.error
			: 'Internel Server Error!';
	}
};

const taskDocumentForm =(body)=>{
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/jobs/task/documents-forms-init`,body)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: JOBS_ACTION_TYPE.taskDocumentFormSuccess,
					payload: {
						message: 'Successfully',
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: JOBS_ACTION_TYPE.taskDocumentFormError,
					payload: {
						message: handleJobsError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
}
const taskDocumentFormIdle =()=>{
	return async (dispatch) => {
		dispatch({
		  type: JOBS_ACTION_TYPE.taskDocumentFormIdle,
		});
	  };
}

const reminderDocumentForm =(reminder_type,job_id)=>{
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/jobs/send-instantreminder-ByJobId?reminder_type=${reminder_type}&job_id=${job_id}`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: JOBS_ACTION_TYPE.reminderDocumentFormSuccess,
					payload: {
						message: 'Successfully',
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: JOBS_ACTION_TYPE.reminderDocumentFormError,
					payload: {
						message: handleJobsError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
}
const reminderDocumentFormIdle =()=>{
	return async (dispatch) => {
		dispatch({
		  type: JOBS_ACTION_TYPE.reminderDocumentFormIdle,
		});
	  };
}
const reminderDocumentFormSingle =(reminder_type,job_id,doc_id,invoice_id)=>{
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/jobs/send-instantreminder-ByJobId?reminder_type=${reminder_type}&job_id=${job_id}&doc_id=${doc_id}&invoice_id=${invoice_id}`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: JOBS_ACTION_TYPE.reminderDocumentFormSingleSuccess,
					payload: {
						message: 'Successfully',
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: JOBS_ACTION_TYPE.reminderDocumentFormSingleError,
					payload: {
						message: handleJobsError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
}
const reminderDocumentFormSingleIdle =()=>{
	return async (dispatch) => {
		dispatch({
		  type: JOBS_ACTION_TYPE.reminderDocumentFormSingleIdle,
		});
	  };
}
const resetJobs = () => {
	return async (dispatch) => {
		dispatch({ type: JOBS_ACTION_TYPE.resetJobs });
	};
};

export const jobs = {
	getJobsByTaxPro,
	getJobsByLead,
	resetJobs,
	cancelJobRequest,
	markJobAsCompleted,
	markJobAsClosed,
	resetMarkJobAsClosed,
	taskDocumentForm,
	taskDocumentFormIdle,
	reminderDocumentForm,
	reminderDocumentFormIdle,
	reminderDocumentFormSingle,
	reminderDocumentFormSingleIdle
};

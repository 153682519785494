/* eslint-disable react-hooks/exhaustive-deps */
import allActions from "../../../Store/action";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Message from "../fields/Message";
import PaymentForm from "../PaymentForm";
import { useAutoReplyHook } from "../../../utilites";


export default function FundMilestone({milestone,job,user}) {
    const dispatch = useDispatch();
    const [{fundMilestone},{LoginData},{personalConnections}] = useSelector((state) => [state.milestone, state.user, state.firebase]);
    const [message,setMessage] = useState({show:false,type:'',text:''});
    const handleFundMilestone = () => {
        dispatch(allActions.global.Loader(true));
        dispatch(allActions.milestone.fundMilestoneReset())
        dispatch(allActions.milestone.fundMilestone({job_id:job?.id,invoice_id:milestone?.invoice_id,status_id:1,milestone_id:milestone?.id}));
    }
	const handleAutoReply = useAutoReplyHook(dispatch);

    useEffect(() => {
        if(fundMilestone?.status === true){
            dispatch(allActions.global.Loader(false));
            dispatch(allActions.milestone.getJobMilestone(job?.id))
            document.querySelector('.fundMilestoneModalClose').click();
           
            var encodedString = window.btoa(
				JSON.stringify({
					amount: milestone?.invoices?.amount,
					name: milestone?.name,
					due_date: milestone?.invoices?.due_date,
                    milestone_action:'fund',
                    job_uuid:job?.uuid
				
				
				})
			);
            handleAutoReply({
                personalConnections,
                LoginData,
                receiver: user,
                type: "milestone",
                encodedString: encodedString,
              });
              dispatch(allActions.milestone.fundMilestoneReset())
        }

        if(fundMilestone?.status === false){
            dispatch(allActions.global.Loader(false));
            setMessage({
                show:true,
                type:'Error',
                text:fundMilestone?.message
            }
            );
        }
    },[fundMilestone?.status])
    return (
        <div>
                {message?.show ? <Message message={message} /> : <></>}
            <div className="d-flex flex-column align-items-start gap-3">
                <p>Fund the milestones so that your Tax Professional can start working on your project. Funding remains in our escrow and will only be released to the Tax Professional upon your approval.</p>
                <div className="">
                    <p className="text-start text-black mt-0 p-0 fs-5 text-secondary-emphasis fw-bold">
                        {milestone?.name}
                    </p>
                </div>
                <div className="w-50 d-flex flex-column align-items-start gap-2">
                    <label className="text-start fs-5 text-black" htmlFor="milestonePrice">
                        Payment amount
                    </label>
                    <input disabled={false} className="form-control w-100"  type="number"
                           id="milestonePrice" value={milestone?.invoices?.amount} />
                </div>
                <div className={'text-black w-75 text-start text-secondary-emphasis'}>
                    Your payment method will be charged for this milestone, and funds are held securely until you approve.
                </div>
                {/*<div className={'d-flex gap-3 align-items-center gap-2'}>*/}
                    {/*<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*    <circle cx="5" cy="5.875" r="5" fill="#4B57E7"/>*/}
                    {/*    <circle cx="4.99935" cy="5.88021" r="2.83333" fill="#4B57E7" stroke="white"/>*/}
                    {/*</svg>*/}
                    {/*<img src={Visa} alt="Visa"/>*/}
                    {/*<div className={'d-flex gap-2 fw-bold text-black'}>*/}
                    {/*    <div>Visa ending in</div>*/}
                    {/*    <div>7822</div>*/}
                    {/*</div>*/}
                <div className={'w-100'}>
                <PaymentForm invoice={milestone?.invoices}  deleteButton={false}/>
													<div className="clearfix text-center">
														{LoginData?.userpaymentmethod?.length === 0 && (
															<small className="d-block mt-5">
																You do not have any payment method available.{' '}
															</small>
														)}
													</div>
                </div>
                {/*</div>*/}
                <div className="d-flex justify-content-end w-100 align-items-center">
                    <button className="btn text-primary fw-bold" data-bs-dismiss="modal">
                        Cancel
                    </button>
                    <button onClick={handleFundMilestone}  className="btn btn-primary">
                        Activate
                    </button>
                </div>
            </div>
        </div>
    );
}

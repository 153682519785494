import React from 'react';
import { useNavigate } from 'react-router-dom';
const MembershipSelectedCard = ({ selectedCard, index, DBData, card, onClick, currentPlan = false, trial }) => {
const navigate= useNavigate()

return (
	<div
		className={`col-lg-3 col-md-6 col-sm-12 mb-3`}
		key={index}
		id={`membership-card-${index}`}
		role="button"
	>
		<div
			className={`h-100 card shadow-sm border border-1 border-primary-300 ${true && 'bg-primary text-white'
				}`}
		>
			<div className="card-body d-flex align-items-start justify-content-between flex-column">
				<div>
					{trial && (
						<>
							<h5
								className={
									true
										? 'btn border-1 bg-white fw-bold'
										: 'btn border border-1 border-primary-300 rounded bg-transparent fw-bold text-uppercase'
								}
							>
								Trial
							</h5>
							<hr />
						</>

					)}
					<h5
						className={
							true
								? 'btn border-1 bg-white fw-bold'
								: 'btn border border-1 border-primary-300 rounded bg-transparent fw-bold text-uppercase'
						}
					>
						{DBData?.membership_name}
					</h5>
					<hr />
					<div className="d-flex align-items-end">
						<h3 className="fw-bold m-0 p-0">{DBData?.id !== 1 && "$ "}{DBData?.membership_type?.Price}</h3>
						{DBData?.id !== 1 && <p className="small fw-bold my-1 p-0">&nbsp;{card?.availability}</p>}
					</div>
					<p className="successText my-3 fw-bold">{card?.increment}</p>
					<hr />
				</div>
				<div className="w-100 d-flex flex-column align-items-center justify-content-center">
					{onClick && (
						<button
							onClick={() => onClick(DBData?.id)}
							className={`btn mt-3 w-100 ${selectedCard === index ? 'btn bg-white text-primary' : 'bg-primary text-white'
								}`}
						>
							Choose Plan
						</button>
					)}
					{currentPlan && (
						<button className={`btn mt-3 w-100 btn bg-white text-primary mb-2`}
							onClick={() => {
								navigate('/dashboard/membership');
							}}
						>
							Current Plan
						</button>
					)}
					{!currentPlan && (
						<p className="small-text text-align-center my-1">No Credit Card Required</p>
					)}
				</div>
			</div>
		</div>
	</div>
	);

}

export default MembershipSelectedCard
import { USER_ACTION_TYPE } from './Type';

const DEFAULT_STATE = {
	LoginData: null,
	loginStatus: false,
	error: '',
	verify: { type: 'null', mode: false, text: '' },
	roleName: '',
	isAdmin: false,
	isTaxPro: false,
	isRegular: false,
	isAccountManager: false,
	statusId: '',
	status: '',
	existEmail:{data:'null',status:'idle',error:'null'}, 
	addExistingUser:{data:'null',status:'idle',error:'null'},
	esignRequest:{data:{},status:'idle',error:'null'},
	contractSignRequestCustomer:{data:{},status:'idle',error:'null'}

};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const login = (state = INITIAL_STATE, action) => {
	if (action.type === USER_ACTION_TYPE.user) {
		return {
			...state,
			LoginData: action.data,
			loginStatus: action.status,
			error: action.error,
		};
	} else if (action.type === USER_ACTION_TYPE.verify) {
		return {
			...state,
			verify: action.payload,
		};
	} else if (action.type === USER_ACTION_TYPE.taxproRole) {
		return {
			...state,
			roleName: action.payload,
			isTaxPro: true,
		};
	} else if (action.type === USER_ACTION_TYPE.adminRole) {
		return {
			...state,
			roleName: action.payload,
			isAdmin: true,
		};
	} else if (action.type === USER_ACTION_TYPE.customerRole) {
		return {
			...state,
			roleName: action.payload,
			isRegular: true,
		};
	} else if (action.type === USER_ACTION_TYPE.accountManagerRole) {
		return {
			...state,
			roleName: action.payload,
			isAccountManager: true,
		};
	} else if (action.type === USER_ACTION_TYPE.statusId) {
		console.log('vvvvvvvv', action.payload);
		return {
			...state,
			statusId: action.payload,
		};
	} else if (action.type === USER_ACTION_TYPE.reset) {
		return { ...state, ...DEFAULT_STATE };
	}
	else if (action.type === USER_ACTION_TYPE.existEmail) {
		return {
			...state,
			existEmail: { status: action.status, data: action.data, error: action.error },
		};
	}
	else if (action.type === USER_ACTION_TYPE.existEmailReset) {
		return { ...state, ...DEFAULT_STATE };

	}
	else if (action.type === USER_ACTION_TYPE.addExistingUser) {
		return {
			...state,
			addExistingUser: { status: action.status, data: action.data, error: action.error },
		};
	}
	else if (action.type === USER_ACTION_TYPE.esignRequestIdle) {
		return {
			...state,
			esignRequest: DEFAULT_STATE.esignRequest,
		};
	}
	else if (action.type === USER_ACTION_TYPE.esignRequestSuccess) {
		return {
			...state,
			esignRequest: action.payload,
		};
	}
	else if (action.type === USER_ACTION_TYPE.esignRequestError) {
		return {
			...state,
			esignRequest: action.payload,
		};
	}
	else if (action.type === USER_ACTION_TYPE.contractSignRequestCustomerIdle) {
		return {
			...state,
			contractSignRequestCustomer: DEFAULT_STATE.contractSignRequestCustomer,
		};
	}
	else if (action.type === USER_ACTION_TYPE.contractSignRequestCustomerSuccess) {
		return {
			...state,
			contractSignRequestCustomer: action.payload,
		};
	}
	else if (action.type === USER_ACTION_TYPE.contractSignRequestCustomerError) {
		return {
			...state,
			contractSignRequestCustomer: action.payload,
		};
	}
	return state;
};

export default login;
export const loginState={INITIAL_STATE}
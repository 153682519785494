import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoIosLock } from "react-icons/io";
import DatePicker from 'react-datepicker';
import SweetAlert2 from 'react-sweetalert2';
import {
	getJobStatus,
	setQueryParams,
	getQueryParam,
	removeQueryParam,
	getJobStatusClass,
} from '../../../utilites';
import allActions from '../../../Store/action';
import Modal from '../../../components/Dashboard/Modal';
import ListPageWrapper from '../components/ListPageWrapper';
import Input from '../../../components/forms/fields/Input';
import JobRequestForm from '../../../components/forms/JobRequestForm';
import JobStatusUpdateForm from '../../../components/forms/JobStatusUpdateForm';
import AddJobForm from '../../../components/forms/AddJobForm';
import Message from '../../../components/forms/fields/Message';
import UserAvatar from '../../../components/UserAvatar';
import { JOBS_ACTION_TYPE } from '../../../Store/jobs/Type';

const JobList = () => {
	const [{ loginStatus, LoginData, isAdmin }, { services, professionals, jobs }] = useSelector(
		(state) => [state.user, state.dashboard]
	);
	const dispatch = useDispatch();
	const [alertMessage, setAlertMessage] = useState('');
	const [message, setMessage] = useState({ show: false });
	const [swalProps, setSwalProps] = useState({});
	const [selectedJob, setSelectedJob] = useState();
	const [keyword, setKeyword] = useState(getQueryParam('search') || '');
	const [service, setService] = useState(getQueryParam('service') || '');
	const [status, setStatus] = useState(getQueryParam('status') || '');
	const [pro, setPro] = useState(getQueryParam('taxpro') || '');
	const [createdDate, setCreatedDate] = useState(
		getQueryParam('created_after') ? new Date(getQueryParam('created_after')) : null
	);
	const jobStatuses = [0, 1, 2, 3, 4,6];
	// Pagination
	const [perPage, setPerPage] = useState(getQueryParam('per_page') || 10);
	const [page, setPage] = useState(getQueryParam('page') || 1);

	useEffect(() => {
		if (LoginData.isAdmin || LoginData.isAccountManager) {
			dispatch(allActions.dashboard.getTaxProfessionals());
		}
	}, [dispatch, LoginData.isAdmin, LoginData.isAccountManager]);

	useEffect(() => {
		if (loginStatus) {
			dispatch(allActions.dashboard.getJobs(keyword, createdDate, pro, service, status, page, perPage));
		}
	}, [loginStatus, dispatch, keyword, createdDate, pro, service, status, page, perPage]);

	const loadJobs = () => {
		dispatch(allActions.dashboard.getJobs(keyword, createdDate, pro, service, status, page, perPage));
		if (selectedJob) {
			setSelectedJob(null);
		}
	};

	const deleteJobs = (id) => {
		dispatch(allActions.dashboard.deleteJob(id, setAlertMessage, loadJobs));
	};

	const handleDeleteClick = (id) => {
		setSwalProps((prev) => ({
			...prev,
			[id]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				text: 'You will not be able to recover this job!',
				confirmButtonText: 'Confirm',
				denyButtonText: 'Cancel',
				didClose: () => setSwalProps((prev) => ({ ...prev, [id]: { show: false } })),
				onConfirm: () => deleteJobs(id),
			},
		}));
	};

	const moveTo = (page) => {
		setPage(page);
		// Set query param
		setQueryParams('page', page);
	};

	const changePerPage = (perPage) => {
		setPerPage(perPage);
		// Set query param
		setQueryParams('per_page', perPage);
	};

	const filterByCreationDate = (date) => {
		setCreatedDate(date);
		// Set query param
		if (!date) {
			removeQueryParam('created_after');
		} else {
			setQueryParams('created_after', date);
		}
	};

	const filterByTaxpro = (pro) => {
		setPro(pro);
		// Set query param
		if (!pro) {
			removeQueryParam('taxpro');
		} else {
			setQueryParams('taxpro', pro);
		}
	};

	const filterByService = (serv) => {
		setService(serv);
		// Set query param
		if (!serv) {
			removeQueryParam('service');
		} else {
			setQueryParams('service', serv);
		}
	};
	const filterStatus = (status) => {
		setStatus(status);
		// Set query param
		if (!status) {
			removeQueryParam('status');
		} else {
			setQueryParams('status', status);
		}
	};

	const filterByKeyword = (keyword) => {
		setKeyword(keyword);
		// Set query param
		if (!keyword) {
			removeQueryParam('search');
		} else {
			setQueryParams('search', keyword);
		}
	};

	return (
		<ListPageWrapper
			heading="Jobs"
			headerControls={
				<Fragment>
					<div className="col-md-12">
						{message.show ? <Message message={message} /> : <></>}
						<div className="row g-2">
							<div className="col-md-2">
								<Input
									type="search"
									value={keyword}
									name="name"
									placeholder="Search by name"
									fn={filterByKeyword}
									showLabel={false}
									inputClass="search-input border h-auto min-height-initial"
									extraSetter={() => {
										if (page !== 1) moveTo(1);
									}}
									required={false}
								/>
							</div>
							<div className="col-md-2">
								<div className="form-floating">
									<DatePicker
										className="form-select"
										wrapperClassName="d-block"
										selected={createdDate}
										onChange={(date) => {
											filterByCreationDate(date);
											if (page !== 1) moveTo(1);
										}}
										maxDate={new Date()}
										dateFormat="MMMM d, yyyy"
										placeholderText="Date created"
										isClearable
									/>
								</div>
							</div>
							{(LoginData.isAdmin || LoginData.isAccountManager) &&
								professionals &&
								professionals.length > 0 && (
									<div className="col-md-1">
										<select
											className="form-select"
											id="filter"
											name="professionals"
											onChange={(e) => {
												filterByTaxpro(e.target.value);
												if (page !== 1) moveTo(1);
											}}
											value={pro}
										>
											<option value="">tax pro</option>
											{professionals?.map((professional) => (
												<option key={professional.userpro.id} value={professional.userpro.id}>
													{`${professional.firstname} ${professional.lastname}`}
												</option>
											))}
										</select>
									</div>
								)}
							<div className="col-md-2">
								<select
									className="form-select"
									id="filter"
									name="services"
									onChange={(e) => {
										filterByService(e.target.value);
										if (page !== 1) moveTo(1);
									}}
									value={service}
								>
									<option value="">Service</option>
									{services?.map((service) => (
										<option key={service.id} value={service.id}>
											{service.name}
										</option>
									))}
								</select>
							</div>
							<div className="col-md-1">
								<select
									className="form-select"
									id="filter"
									name="status"
									onChange={(e) => {
										filterStatus(e.target.value);
										if (page !== 1) moveTo(1);
									}}
									value={status}
								>
									<option value="">Status</option>
									{jobStatuses?.map((s) => (
									<option key={s} value={s}>
										{getJobStatus(s)}
									</option>
								))}
								</select>
							</div>
							<div
								className={`col-md-${LoginData.isAdmin || LoginData.isSupport || LoginData.isAccountManager ? '4' : '5'}`}
							>
								{LoginData?.userpro?.status_id === 6 ? <button
									className="btn btn-outline-primary px-4 float-md-end float-none d-flex align-tems-center gap-1"
									disabled={true}
								>
									Add Job  <IoIosLock style={{ fontSize: '20px' }} />
								</button> : <Link
									to="#"
									className="btn btn-outline-primary px-4 float-md-end float-none"
									data-bs-toggle="modal"
									data-bs-target="#jobAdditionForm"
									disabled={true}
								>
									Add Job
								</Link>
								}
							</div>
						</div>
					</div>
				</Fragment>
			}
			alert={{ message: alertMessage, setMessage: setAlertMessage }}
			dataState={{
				loaded: jobs.loaded,
				fetching: jobs.fetching,
			}}
			tableHeader={
				<Fragment>
					<th scope="col">ID</th>
					<th scope="col">Customer</th>
					<th scope="col">Job type</th>
					{(isAdmin || LoginData.isAccountManager) && <th scope="col">Assigned To</th>}
					<th scope="col">Created On</th>
					<th scope="col">Status</th>
					<th scope="col"></th>
				</Fragment>
			}
			tableBody={
				<Fragment>
					{jobs?.data?.length === 0 ? (
						<tr>
							<td className="text-center opacity-50" colSpan={7}>
								There are no jobs available at this time
							</td>
						</tr>
					) : (
						jobs?.data?.map((job) => {
							return (
								<tr className="align-middle" key={job.id}>
									<th scope="row">{job.id}</th>
									<td className="text-md-start text-center">
										<Link
											to={`/dashboard/jobs/${job.uuid}`}
											className="text-decoration-none"
											onClick={() => {
												dispatch(allActions.milestone.getJobMilestonereset())
												dispatch({ type: JOBS_ACTION_TYPE.cancelJobRequestIdle });
												setMessage({ show: false });
											}}
										>
											<UserAvatar
												avatar={job.leads?.user.avatar}
												alt={`${job.leads?.user.firstname} ${job.leads?.user.lastname}`}
												className="rounded-circle"
												style={{ width: 35, height: 35 }}
											/>
											<span className="text-dark ps-1">{`${job.leads?.user.firstname} ${job.leads?.user.lastname}`}</span>
										</Link>
									</td>
									<td width="30%">
										<div className="w-75 text-truncate">{job?.service?.name || job?.others}</div>
									</td>
									{(isAdmin || LoginData.isAccountManager) && job.userpro?.user && (
										<td>
											<Link
												className="text-decoration-none"
												to={`/dashboard/users/${job.userpro.user?.uuid}`}
											>
												{`${job.userpro.user?.firstname} ${job.userpro.user?.lastname}`}
											</Link>
										</td>
									)}
									<td>
										{new Date(job.created_at).toLocaleDateString('en-US', {
											year: 'numeric',
											month: 'short',
											day: 'numeric',
										})}
									</td>
									<td>
										{job?.is_closed ? <span className='text-danger'>Closed</span> : LoginData?.userpro?.status_id === 6 ?  <IoIosLock style={{ fontSize: '26px',color:'#a8a8a8'}} /> : <span
											className={`text-${getJobStatusClass(job.status_id)}${
												!LoginData.isAdmin && !LoginData.isAccountManager
													? ' pe-none'
													: ' cursor-pointer'
											}`}
											data-bs-toggle="modal"
											data-bs-target="#updateJobStatusModal"
											onClick={() => {
												setSelectedJob(job);
											}}
										>
											{getJobStatus(job.status_id)}
										</span>}
									</td>
									<td className="text-end">
										<div className="dropdown-center">
											{LoginData?.userpro?.status_id === 6 ? ''  : <button
												type="button"
												className="btn btn-sm btn-outline-primary rounded-circle"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												<BsThreeDotsVertical className="" />
											</button>}
											<ul className="dropdown-menu border-light">
												<li>
													<Link to={`/dashboard/jobs/${job.uuid}`} className="dropdown-item">
														Details
													</Link>
												</li>
												<li>
													<hr className="dropdown-divider border-light" />
												</li>
												{LoginData.isTaxPro && job.status_id > 0 && job.status_id < 5 ? (
													<li>
														<Link
															to="#"
															onClick={() => {
																setSelectedJob(job);
																setMessage({ show: false });
																dispatch({ type: JOBS_ACTION_TYPE.cancelJobRequestIdle });
															}}
															className={`dropdown-item text-danger`}
															data-bs-toggle="modal"
															data-bs-target="#jobCanelRequestForm"
														>
															Request to Cancel
														</Link>
													</li>
												) : (
													<li>
														<Fragment>
															<Link
																to="#"
																onClick={() => handleDeleteClick(job.id)}
																className={`dropdown-item text-${
																	job.status_id > 0 && job.status_id < 5
																		? 'grey-100 disabled'
																		: 'danger'
																}`}
															>
																Delete
																<SweetAlert2 {...swalProps[job.id]} />
															</Link>
														</Fragment>
													</li>
												)}
											</ul>
										</div>
									</td>
								</tr>
							);
						})
					)}
				</Fragment>
			}
			paginationProps={{
				pageCount: Math.ceil(jobs.count / perPage),
				currentPage: parseInt(page),
				setCurrentPage: moveTo,
				perPage: perPage,
				setPerPage: (pc) => {
					changePerPage(pc);
					// Move to first page
					if (page !== 1) moveTo(1);
				},
			}}
			modals={
				<Fragment>
					<Modal
						title="Job Cancel Request"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="jobCanelRequestForm"
						showBtn={false}
						btnClass="jobCanelRequestModalClose"
					>
						<JobRequestForm
							triggerClass=".jobCanelRequestModalClose"
							uuid={selectedJob?.uuid}
							setMessage={setMessage}
						/>
					</Modal>
					<Modal
						title="Create a new job request"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="jobAdditionForm"
						showBtn={false}
						btnClass="jobAdditionModalClose"
					>
						<AddJobForm />
					</Modal>
					{LoginData.isAdmin || LoginData.isAccountManager ? (
						<Modal
							title="Update job status"
							bodyClass="p-4 text-center text-dark text-opacity-50"
							btnText="Update"
							trigger="updateJobStatusModal"
							btnClass="updateJobStatusModalClose"
							showBtn={false}
						>
							{selectedJob && professionals && professionals.length > 0 ? (
								<JobStatusUpdateForm
									uuid={selectedJob?.uuid}
									currentStatus={selectedJob?.status_id}
									currentProfessional={selectedJob?.userpro?.id}
									professionals={professionals}
									setMessage={setMessage}
									reload={loadJobs}
								/>
							) : null}
						</Modal>
					) : null}
				</Fragment>
			}
		/>
	);
};

export default JobList;

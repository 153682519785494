import React, { useEffect, useState } from 'react';
import { IoMdCheckmarkCircle } from "react-icons/io";
import { useSelector } from 'react-redux';
import moment from 'moment';
const MembershipCards = ({ selectedCard, index, DBData, card, onClick, updateCardBGColor,setSelectedCard, freeMembershipOnClick }) => {
    const [{ user }] = useSelector((state) => [state]);
    const [freeMembership, setFreeMembership] = useState();
    const [isCurrentPlanActive,setIsCurrentPlanActive]=useState()
    const anyMembershipExist=user?.LoginData?.usermembership?.length>0 && index===0;

    if ((freeMembership && selectedCard === 0) || anyMembershipExist) {selectedCard=undefined}
    
    useEffect(() => {
        // Filtering current  membership data
        let filteredMembershipData = user?.LoginData?.usermembership?.find(({ sub_endTime }) => moment(sub_endTime).isAfter(moment()));
        //    check for trial
        if (!filteredMembershipData) {
            var trialFilteredMembershipData = user?.LoginData?.usermembership?.find(({ trial_endTime }) => moment(trial_endTime).isAfter(moment()));
            if (trialFilteredMembershipData) {
                filteredMembershipData = trialFilteredMembershipData;
            }
        }
        if (filteredMembershipData?.membership_Id === card.id) {
            setIsCurrentPlanActive(true)
            if(setSelectedCard)setSelectedCard(card.id>0?card.id-1:0)
        }

        const freeMembership = user?.LoginData?.usermembership?.find((i) => i.membership_Id === 1)
        if (freeMembership) setFreeMembership(true)

    }, [card.id, isCurrentPlanActive, setSelectedCard, user?.LoginData])

    return (
        <div className={`col-lg-3 col-md-6 col-sm-12 mb-3`} key={index} id={`membership-card-${index}`} role={((selectedCard === index ) || anyMembershipExist) ? 'none' : "button"}>
            <div className={`card shadow-sm border border-1 border-primary-300 custom-membership-card ${selectedCard === index && "bg-primary text-white"}  ${((index===0 && freeMembership) || anyMembershipExist) && 'text-grey-300'}`} onClick={() => updateCardBGColor(index)}>
                <div className="card-body d-flex align-items-start justify-content-between flex-column">
                    <div>
                        <h5 className={selectedCard === index ? "btn border-1 bg-white fw-bold" : 'btn border border-1 border-primary-300 rounded bg-transparent fw-bold text-uppercase'}>{card?.membership_name}</h5>
                        <hr />
                        <div className='d-flex align-items-end'>
                            <h3 className='fw-bold m-0 p-0'>{index===0?"":"$"}{card?.membership_type?.Price}</h3>
                            <p className='small fw-bold my-1 p-0'>&nbsp;{card?.availability}</p>
                        </div>
                        <p className='successText my-3 fw-bold'>{card?.increment}</p>
                        <hr />
                        {index!==0 && <p className="card-text"><span className='fw-bold'>Target Group: </span>{card?.membership_type["Target Group"].value}</p>}
                        <p className='fw-bold m-0 p-0'>Upgrade Pathway:</p>
                        <ul className="list-group list-group-flush">
                            {card?.membership_type["Upgrade Pathway"]?.nested?.map((inc, i) => (
                                <div className='d-flex py-2 align-items-start' key={i}>
                                    <p className='m-0'><IoMdCheckmarkCircle className=' successText' size={18} /></p>
                                    <p className='px-2 m-0 p-0'>{inc?.name}</p>
                                </div>
                            ))}
                        </ul>
                        <p className='fw-bold m-0 p-0'>Plan Features:</p>
                        <ul className="list-group list-group-flush">
                            {card?.membership_type["Plan Features"]?.map((inc, i) => (
                                <div>
                                    <div className='d-flex py-2 align-items-start' key={i}>
                                        <p className='m-0'><IoMdCheckmarkCircle className=' successText' size={18} /></p>
                                        <p className='px-2 m-0 p-0'>{inc?.name}</p>
                                    </div>
                                    {inc?.nested?.length > 0 && inc?.nested?.map((data, index) => (
                                        <div className='ml-3 d-flex align-items-start card-nested'>
                                            <p className='m-0'><IoMdCheckmarkCircle className=' successText' size={14} /></p>
                                            <p className='px-2 m-0 p-0 small-text'>{data?.name}</p>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </ul>
                    </div>
                    <div className='w-100 d-flex flex-column align-items-center justify-content-center'>
                        {onClick && <button onClick={() => {
                            if(index===0 && freeMembershipOnClick){
                                freeMembershipOnClick()
                            }else{
                                onClick(DBData.id)
                            }
                        }} className={`btn mt-3 w-100 ${selectedCard === index ? "btn bg-white text-primary" : "bg-primary text-white"}`} disabled={(freeMembership&&index===0) || isCurrentPlanActive || anyMembershipExist}>{((freeMembership && index===0) || anyMembershipExist)? "plan has expired":isCurrentPlanActive?"Current Plan" :"Choose Plan"}</button>}
                        {index ===0 && <p className='small-text text-align-center my-1'>No Credit Card Required</p>}
                    </div>
                </div>
            </div>			
        </div>
    )

}

export default MembershipCards
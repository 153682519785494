export const JOBS_ACTION_TYPE = {
	// REDUCER TYPES
	getJobs: 'getJobs',
	getJobsIdle: 'getJobsIdle',
	resetJobs: 'resetJobs',

	cancelJobRequest: 'cancelJobRequest',
	cancelJobRequestIdle: 'cancelJobRequestIdle',

	markJobAsCompleted: 'markJobAsCompleted',
	markJobAsCompletedIdle: 'markJobAsCompletedIdle',

	markJobAsClosed: 'markJobAsClosed',
	markJobAsClosedIdle: 'markJobAsClosedIdle',

	// document form task init
	taskDocumentFormIdle: 'taskDocumentFormIdle',
	taskDocumentFormSuccess: 'taskDocumentFormSuccess',
	taskDocumentFormError: 'taskDocumentFormError',

		// document form reminder immeditely
		reminderDocumentFormIdle: 'reminderDocumentFormIdle',
		reminderDocumentFormSuccess: 'reminderDocumentFormSuccess',
		reminderDocumentFormError: 'reminderDocumentFormError',

		//single  document form reminder immeditely
		reminderDocumentFormSingleIdle: 'reminderDocumentFormSingleIdle',
		reminderDocumentFormSingleSuccess: 'reminderDocumentFormSingleSuccess',
		reminderDocumentFormSingleError: 'reminderDocumentFormSingleError',		

		
};

/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import axios from "axios";
import { BASEURL } from "../../../utilites";
import {useSelector, useDispatch} from "react-redux";
import allActions from "../../../Store/action";

const ProfileAboutModal = ({ reload, aboutToEdit, setAboutToEdit }) => {
    const dispatch = useDispatch();
    const [
        {LoginData}
    ] = useSelector((state) => [state.user]);
    const [aboutText, setAboutText] = useState(aboutToEdit?.details);
    const [error, setError] = useState(false);

    const handleInputChange = (e) => {
        setAboutText(e.target.value);
        setError(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!aboutText.trim()) {
            setError(true);
            return;
        }

        const endpoint = aboutToEdit
            ? `${BASEURL}/api/professionals/profile/about`
            : `${BASEURL}/api/professionals/profile/about`;

        const method = aboutToEdit?.details ? "patch" : "put";
        dispatch(allActions.global.Loader(true))
        try {
            await axios[method](endpoint, {
                user_id: LoginData?.userpro?.user_id,
                details: aboutText,
                ...(aboutToEdit ? { id: aboutToEdit.id } : {}),
            });

            reload();
            resetForm();
            dispatch(allActions.global.Loader(false))
            document.querySelector(".closeAddOrofileAbout").click();
        } catch (err) {
            console.error("Error saving about information:", err);
            dispatch(allActions.global.Loader(false))
            document.querySelector(".closeAddOrofileAbout").click();
        }
    };

    const resetForm = () => {
        setAboutText("");
        setError(false);
        setAboutToEdit({});
    };


    console.log(aboutToEdit,'edit')


    useEffect(() => {
        setAboutText(aboutToEdit?.details);
    }, [aboutToEdit]);

    return (
        <div className="container px-2 text-start">
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="about" className="form-label text-start text-black fw-bold">
                        About
                    </label>
                    <textarea
                        id="about"
                        className={`form-control pb-5 pt-3 ${error ? "is-invalid" : ""}`}
                        placeholder="About"
                        rows="3"
                        value={aboutText}
                        onChange={handleInputChange}
                    ></textarea>
                    {error && (
                        <div className="invalid-feedback">
                            About text is required.
                        </div>
                    )}
                </div>
                <div className="d-flex justify-content-end">
                    <button
                        type="button"
                        className="btn btn-outline-secondary me-2"
                        onClick={resetForm}
                    >
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                        {aboutToEdit ? "Save Changes" : "Save"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ProfileAboutModal;

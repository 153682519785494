import { useEffect, useRef } from "react";

const Modal = ({
	title,
	children,
	fullscreen = false,
	showBtn = true,
	btnText = 'Submit',
	btnClass,
	bodyClass = '',
	trigger,
	size,
	centerClass,
	close,
	headcenter,
	width,
	backdrop = true, // Add this prop
    keyboard = true,  // Add this prop
	modalHeader = true,
	alignVerticallyCenter=false,
	onClose=()=>{}
}) => {

	const modalRef = useRef(null);

	useEffect(() => {
		const modalElement = modalRef.current;

		// Add event listener for `hidden.bs.modal`
		const handleHidden = () => {
			if (onClose) {
				onClose();
			}
		};
		modalElement?.addEventListener('hidden.bs.modal', handleHidden);

		// Cleanup event listener
		return () => {
			modalElement?.removeEventListener('hidden.bs.modal', handleHidden);
		};
	}, [onClose]);


	return (
		<div
			ref={modalRef}
			className={`modal fade ${centerClass ? centerClass : ''}`}
			id={trigger}
			tabIndex="-1"
			aria-labelledby={`${trigger}Label`}
			aria-hidden="true"
			data-bs-backdrop={backdrop ? "true" : "static"}  // Use the prop
            data-bs-keyboard={keyboard}  // Use the prop
		>
			<div
			className={`modal-dialog modal-${size ? size : 'md'} ${
					fullscreen ? 'modal-fullscreen' : ''
				} ${alignVerticallyCenter ? "modal-dialog-centered" : ''}` }
				
			>
				<div 
					style={{width:width ? width  :''}}
					className="modal-content">
					{ modalHeader && <div className="modal-header border-0">
						<h1
							className={
								headcenter ? 'modal-title fs-5 w-100 text-center' : 'modal-title fs-5'
							}
							id={`${trigger}Label`}
						>
							{title}
						</h1>
						{close === false ? (
							''
						) : (
							<button
								type="button"
								className={`btn-close${btnClass ? ` ${btnClass}` : ''}`}
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						)}
					</div>}
					<div className={`modal-body ${bodyClass}`}>{children}</div>
					{showBtn && (
						<div className="modal-footer justify-content-center border-0">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
								Close
							</button>
							<button type="button" className="btn btn-primary">
								{btnText}
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Modal;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../Store/action';
import Message from '../../../components/forms/fields/Message';
import MembershipCards from '../../../components/Dashboard/billing/memberShipCards';
import { useNavigate } from 'react-router-dom';
import { MEMBERSHIP_ACTION_TYPE } from '../../../Store/membership/Type';
import Footer from '../components/Footer';

const Membership = () => {
    const dispatch= useDispatch();
    const [{user},{ updateMembershipPlan, allMembershipPlan, initialRegisterMembership }] = useSelector((state) => [state, state.membership]);
    console.log("currentUserAndItsPlans", user?.loginData?.usermembership[0]);
    const [message, setMessage] = useState({show: false});
    const [selectedCard, setSelectedCard] = useState()
    console.log("selectedCard", selectedCard)
    const [mode,setMode]=useState('Monthly')
    const navigate = useNavigate();
    const updateCardBGColor = (selectedCardIndex) => {
        setSelectedCard(selectedCardIndex)
    }
    // useEffect(()=>{
    //     setSelectedCard(user?.LoginData?.usermembership[0]?.membership?.id-1)
    // }, [user?.LoginData?.usermembership])

    useEffect(()=>{
        if(initialRegisterMembership.status===false){
            dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: initialRegisterMembership.message,
			});
        }
        if(initialRegisterMembership.status===true){
			dispatch(allActions.global.Loader(false));
            dispatch(allActions.user.ReGetUserData());
            navigate('/dashboard/successThanks');
            dispatch({ type: MEMBERSHIP_ACTION_TYPE.registerMembershipIdle });
        }
    }, [initialRegisterMembership.message, initialRegisterMembership.status])

    useEffect(()=>{
        dispatch(allActions.user.ReGetUserData())
        dispatch(allActions.membership.allMembershipPlan());
    }, [dispatch])
 
    useEffect(() => {
		if (!updateMembershipPlan.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: updateMembershipPlan.message,
			});
		}
		if (updateMembershipPlan.status === true) {
			setMessage({
				show: false
			});
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, updateMembershipPlan]);
    

    const updateMembership=(plan)=>{
        // setMessage({ show: false });
        // dispatch(allActions.global.Loader(true));
        // dispatch({ type: MEMBERSHIP_ACTION_TYPE.updateMembershipIdle });
        // dispatch(allActions.membership.updateMembershipPlan({ membershipId: index}));
        navigate("/dashboard/addPaymentMethods",{state:{plan}})
    }
    const freeMembershipOnClick=()=>{
        dispatch(allActions.global.Loader(true));
        dispatch({ type: MEMBERSHIP_ACTION_TYPE.registerMembershipIdle});
        dispatch(allActions.membership.initialRegisterMembership());
        setMessage({show:false})
    }
    // const cardData = [
    //     { planType: "Free", price: "0",increment: '+40%',availability: "/3 mo free access", details: "TPG Students", includes: ["High-Groth Tier", "Enterprise Tier", "TGP Progream (UNK Certified)", "UNK Pro (UNK Certified + Mastermind)", "lorem ipsum"], btnText: "Upgrade to Basic" },
    //     { planType: "Basic", price: "499",increment: '+40%', availability: "/mo", details: "TPG Students Or SMB Tax Pros", includes: ["lorem ipsum", "lorem ipsum", "lorem ipsum", "lorem ipsum", "lorem ipsum"], btnText: "Upgrade to pro" },
    //     { planType: "High-Growth", price: "1999",increment: '+40%', availability: "/mo", details: "Small Business/Tax Prep Firms", includes: ["lorem ipsum", "lorem ipsum", "lorem ipsum", "lorem ipsum", "lorem ipsum"], btnText: "Upgrade to Plus" },
    //     { planType: "Enterprise", price: "2499",increment: '+40%', availability: "/mo", details: "Medium/Large Sized Tax Firms", includes: ["lorem ipsum", "lorem ipsum", "lorem ipsum"], btnText: "Upgrade to Premium" },
    //     // { planType: "Custom", price: "?",increment: '+40%', availability: "/mo", details: "Medium/Large Sized Tax Firms", includes: ["lorem ipsum", "lorem ipsum", "lorem ipsum", "lorem ipsum", "lorem ipsum"], btnText: "Upgrade to Premium" }
    // ]

    // console.log("REFRENCE CARD>?>>>",document.getElementById('membership-card-2'),document.getElementById('membership-card-2')?.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center"
    //   }));

    return (
        <div>
             {message.show ? <Message message={message} /> : <></>}
            <div className='row'>
                <div className='container d-flex align-items-center justify-content-center'>
                    <div className='col-lg-5 col-md-8 col-sm-12 d-flex align-items-center flex-column justify-content-center'>
                        <h1 className='fw-bold text-black'>Choose Your Plan</h1>

                        <div className='col-sm-12 col-md-6 col-lg-10 border border-primary d-flex align-items-center justify-content-between rounded my-3'>
                        <button type="button" className={`w-50 fw-bold m-1 ${mode==='Monthly' ? 'btn btn-dark':'border-0 bg-transparent'}`} onClick={()=>setMode('Monthly')}>
							Monthly
						</button>
                        <button type="button" className={`w-50 fw-bold m-1 ${mode==='Yearly' ? 'btn btn-dark':'border-0 bg-transparent'}`}  onClick={()=>setMode('Yearly')}>
							Yearly <span className='text-secondary-500 small'></span>
						</button>
                        </div>
                    </div>
                </div>
                <div className='container my-3 col-md-12 overflow-scroll hidescroll'>
                    <div className="row flex-nowrap">
                        {allMembershipPlan?.data?.length >0 && allMembershipPlan?.data?.map((card, index) => {
                            const DBData = allMembershipPlan?.data?.find((data) => data.id === index + 1)
                            return (<MembershipCards card={card} DBData={DBData} index={index} selectedCard={selectedCard}
                                        onClick={() => {
                                            updateMembership(DBData);
                                            dispatch({ type: 'paymentCardAddIdle' });
                                            dispatch({ type: MEMBERSHIP_ACTION_TYPE.updateMembershipPlanIdle });
                                        }}
                                        setSelectedCard={setSelectedCard}
                                        updateCardBGColor={updateCardBGColor}
                                        freeMembershipOnClick={freeMembershipOnClick}
                                        />)

                        })}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Membership
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
// Components
import Modal from '../../../components/Dashboard/Modal';
import ReviewAndAcceptDraftDocumentsForm from '../../../components/forms/ReviewAndAcceptDraftDocumentsForm';
import ReviewProfessionalForm from '../../../components/forms/ReviewProfessionalForm';
import Message from '../../../components/forms/fields/Message';
import Button from '../../../components/forms/fields/Button';
// Store
import allActions from '../../../Store/action';
import { moneyFormatter } from '../../../utilites';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import JobRequestForm from '../../../components/forms/JobRequestForm';
import FundMilestone from "../../../components/forms/Modal/FundMilestone";
import ApproveMilestone from "../../../components/forms/Modal/ApproveMilestone";
import dayjs from "dayjs";
import RenderMultiPagePDF from './RenderMultiPagePDF';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { FaCheckCircle } from 'react-icons/fa';

export default function ProjectTimeline({ data, userpro, user, reload, milestones, setMilestones }) {
    const [selectedMilestone, setSelectedMilestone] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState();
        ///////////////////////////

    const [{ invoices }, { projects }, { LoginData }] =
        useSelector((state) => [state.invoice, state.dashboard, state.user]);
    const [message, setMessage] = useState({ show: false });
    const [invoiceDeadline, setInvoiceDedline] = useState(moment().add(1, 'days').toDate());
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // INVOICES
  

    let paidAmount = 0;
    let remainingAmount = 0;
    invoices?.data?.map((data) =>
        data?.payments?.map((i, v) => (paidAmount = paidAmount + parseFloat(i.amount_paid)))
    );
    invoices?.data?.map((data) => (remainingAmount = remainingAmount + parseFloat(data.amount)));
    let selectedRemaining = 0;
    data?.invoices?.map((data) => (selectedRemaining = selectedRemaining + parseFloat(data.amount)));
    // Conditional rendering variables
    const isDraft = data.status_id === 5 ? true : false;
    const isContractSigned =
        data.confirmed_at && data.status_id !== 0 && data.status_id !== 5 ? true : false;
 

    useEffect(() => {
        dispatch(allActions.dashboard.getProjects());
    }, [dispatch]);

    // ******************************
    // HOOKS FOR GETTING INVOICES
    // ******************************
    useEffect(() => {
        // FALILURE
        if (!invoices?.status && invoices?.message !== 'No customer found') {
            // setMessage({
            //     show: true,
            //     type: 'Error',
            //     text: invoices?.message,
            // });
            dispatch(allActions.global.Loader(false));
        }
        // SUCCESS
        if (invoices?.status === true) {
            dispatch(allActions.global.Loader(false));
        }
        // IDLE
        if (invoices?.status === 'idle' && projects?.data?.[0]?.leads) {
            dispatch(allActions.invoice.getLeadInvoices(projects?.data?.[0]?.leads?.uuid));
        }
    }, [invoices?.message, invoices?.status]);

    // ******************************
    // HOOKS FOR CREATE INVOICES
    // ******************************



    // To Generate Ramming Balance
    const generateRemaningDueInvoice = (e) => {
        e.preventDefault();

        let invoiceObj = {
            user_id: LoginData?.id,
            dueDate: moment(new Date(invoiceDeadline), 'YYYY-MM-DD').format('MM/DD/YYYY'),
            name: data?.service?.name?data?.service?.name:"Others",
            amount: data?.invoice_data?.remainingAmount,
            job_id: data?.id,
            pro_id: LoginData?.lead?.[0]?.pro_id,
        };
        dispatch(allActions.global.Loader(true));
        dispatch(allActions.invoice.createInvoice(invoiceObj, LoginData?.leads?.uuid));
    };

const milestonePaid=(v)=>{
    return  v.invoices?.stripe_transfer_id && v.invoices?.stripe_transfer_amount?true:false
}

    return (
        <div className="clearfix">
            {message.show ? <Message message={message} /> : <></>}

            <ul className="JobCard list-group">
					<li
						className={`list-group-item border-0 ${
							isContractSigned ? ' JobCard__item--completed' : 'JobCard__item--disabled'
						}`}
					>
						<h3 className="fs-6 fw-bold">Project start</h3>
						{!isContractSigned && !isDraft ? (
							<Button
                                disabled={data?.is_closed}
								callback={() => {
									navigate(`/dashboard/contract/${data.uuid}`, {
										state: { projects },
									});
								}}
								mainClass={`btn btn-primary btn-sm text-white w-100 ${
									data.status_id === 4 ? 'disabled' : 'enabled'
								}`}
								wrapperClass={'col-md-3 custon-button  my-2'}
								buttonText="Sign contract"
							/>
						) : isDraft ? (
							<small>
								This is a draft contract. Please see the documents tab to upload the Requested
								Documents for your Tax Pro to get started.
							</small>
						) : (
							<div
								onClick={() => {
									navigate(`/dashboard/contract/${data.uuid}`, {
										state: { projects },
									});
								}}
							>
								{data?.status_id >= 1 ? (
									<>
										<div className={'text-muted fs-6 '}>
                                            {data?.confirmed_at &&
                                                moment(data?.confirmed_at, 'YYYY-MM-DD HH:mm:ss').format('MMMM DD')}
                                        </div>
										<div className={'d-flex align-items-center gap-2 mt-2'}>
											<Link to={``} className={'text-muted fs-6 '}>
												Contract signed
											</Link>
                                            <FaCheckCircle className="text-secondary-800" />
										</div>
									</>
								) : (
									<div className={'d-flex align-items-center gap-2 mt-2'}>
										<Link to={``} className={'fw-bold text-black fs-6 '}>
											Contract is not signed yet
										</Link>
									</div>
								)}
							</div>
						)}
					</li>

					{milestones?.map((milestone, idx) => {
						return (
							<li
								className={`list-group-item border-0 ${
									milestonePaid(milestone) ? 'JobCard__item--completed' : 'JobCard__item--disabled'
								}`}
							>
								<h3 className="fs-6 fw-bold">{milestone?.name}</h3>

								<div className={'text-muted fs-6 '}>
									{dayjs(milestone?.invoices?.due_date).format('MMMM DD')}
								</div>

								<div className={'text-muted fs-6 mt-2'}>{milestone?.description}</div>
                               { (milestone?.status_id === 1 || milestone?.status_id === 2 || milestone?.status_id === 3) && milestone?.documents?.id?
								<Link
									data-bs-toggle="modal"
									data-bs-target="#openDocumentModal"
									aria-controls="openDocumentModal"
									onClick={() => setSelectedDocument(milestone?.documents)}
								>
									<div
										className="col-md-2 mt-2"
										key={milestone?.documents?.id}
										role="button"
										onClick={() => {}}
									>
										<div className="card d-flex flex-column align-items-center justify-content-center">
											<IoDocumentTextOutline size={70} />
										</div>
									</div>
								</Link>
                                :<p className={'mt-2'}></p> }
								<div className={'d-flex align-items-center gap-2 mt-3'}>
									<div className={'fs-5 fw-bold'}>${milestone?.invoices?.amount}</div>
									{milestone?.status_id === 0 && (
										<button
											onClick={() => setSelectedMilestone(milestone)}
											disabled={!isContractSigned}
											data-bs-toggle="modal"
											data-bs-target="#fundMilestoneModal"
											className={'btn btn-outline-primary px-3 py-1'}
										>
											Fund milestone
										</button>
									)}
									{(milestone?.status_id === 1 || milestone?.status_id === 2) && (
										<button
											onClick={() => setSelectedMilestone(milestone)}
											disabled={milestone?.status_id === 1 ? true : false}
											data-bs-toggle="modal"
											data-bs-target="#approveMilestoneModal"
											className={'btn btn-primary px-3 py-1'}
										>
											Approve
										</button>
									)}
									{milestone?.status_id === 3 && (
										<div
											onClick={() => setSelectedMilestone(milestone)}
											className={'bg-black text-white rounded px-3 py-1 fw-bold '}
										>
											Paid
										</div>
									)}
								</div>
							</li>
						);
					})}
				</ul>
            <Modal
                title="Fund Milestone"
                bodyClass="p-4 text-center text-dark text-opacity-50"
                trigger="fundMilestoneModal"
                showBtn={false}
                btnClass="fundMilestoneModalClose"
                size="lg"
            >
                <FundMilestone user={user} milestone={selectedMilestone} job={data} />
            </Modal>
            <Modal
                title="Approve Milestone"
                bodyClass="p-4 text-center text-dark text-opacity-50"
                trigger="approveMilestoneModal"
                showBtn={false}
                btnClass="approveMilestoneModalClose"
                size="lg"
            >
                <ApproveMilestone  user={user} milestone={selectedMilestone} job={data}/>
            </Modal>

            <Modal
                title="Approve project completion"
                bodyClass="p-4 text-center text-dark text-opacity-50"
                trigger="ReviewAndAcceptDraftDocumentsModal"
                showBtn={false}
                btnClass="ReviewAndAcceptDraftDocumentsModalClose"
                size="lg"
            >
                <ReviewAndAcceptDraftDocumentsForm
                    drafts={data.drafts}
                    uuid={data.uuid}
                    reload={reload}
                    setMessage={setMessage}
                />
            </Modal>
            <Modal
                title="Request Modification"
                bodyClass="p-4"
                trigger="jobCanelRequestForm"
                showBtn={false}
                btnClass="jobCanelRequestFormClose"
            >
                <div className="clearfix p-2"></div>
            </Modal>
            <Modal
                title="Rate your provider."
                bodyClass="p-4 text-center text-dark text-opacity-50"
                trigger="ReviewProfessionalModal"
                showBtn={false}
                btnClass="ReviewProfessionalModalClose"
                size="lg"
            >
                <ReviewProfessionalForm
                    proData={userpro}
                    customerData={user}
                    job_id={data.id}
                    reload={reload}
                    setMessage={setMessage}
                    job_closed={data?.is_closed}
                />
            </Modal>

            <Modal
                title="Pay Job Balance"
                bodyClass="p-4"
                btnText="Pay Balance"
                trigger="requestbalancepayment"
                showBtn={false}
                btnClass="requestbalancepaymentInfoModalClose"
            >
                <div className="clearfix p-2">
                    <form
                        className="clearfix w-100"
                        onSubmit={(e) => {
                            generateRemaningDueInvoice(e);
                        }}
                    >
                        <div className="text-dark w-100 text-align-left my-1">Deadline to Pay</div>
                        <div className="mb-2">
                            <DatePicker
                                className="form-control"
                                wrapperClassName="d-block"
                                selected={invoiceDeadline || moment().add(1, 'days').toDate()}
                                onChange={(date) => setInvoiceDedline(date)}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="mm/dd/yyyy"
                                minDate={new Date()}
                                required
                                disabled
                            />
                        </div>
                        <p className="text-dark w-100 text-align-left my-1">
                            Balance:{' '}
                            <span className="ms-2">{moneyFormatter(data?.cost - selectedRemaining)}</span>
                        </p>
                        <Button
                            mainClass="btn btn-primary text-white w-100"
                            wrapperClass={'w-75 custon-button mx-auto mt-4'}
                            buttonText={'Pay Balance'}
                        ></Button>
                    </form>
                </div>
            </Modal>

            <Modal
                title="Job Modification Request"
                bodyClass="p-4 text-center text-dark text-opacity-50"
                trigger="jobCanelRequestForm1"
                showBtn={false}
                btnClass="jobCanelRequestModalClose"
            >
                <JobRequestForm
                    type={3}
                    triggerClass=".jobCanelRequestModalClose"
                    uuid={data?.uuid}
                    setMessage={setMessage}
                    receiver={data?.userpro?.user}
                    sender={LoginData}
                    selectable={false}
                />
            </Modal>
            <Modal
						title="Document Viewer"
						bodyClass="p-4 text-center text-dark text-opacity-50"
						trigger="openDocumentModal"
						showBtn={false}
						btnClass="documentModalClose"
						size="lg"
					>
						{selectedDocument &&
							(selectedDocument.path.includes('.pdf') ? (
								<>
									<RenderMultiPagePDF file={selectedDocument.path} />
								</>
							) : (
								<img
									src={selectedDocument.path}
									className="w-100 img-fluid rounded"
									alt={selectedDocument.name}
									style={{ objectFit: 'contain' }}
								/>
							))}
					</Modal>
        </div>
    );
}

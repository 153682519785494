/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from "react";
import axios from "axios";
import { BASEURL } from "../../../utilites";
import { DatePicker } from "antd";
// import "antd/dist/antd.css";
import moment from "moment";
import {useSelector, useDispatch} from "react-redux";
import allActions from "../../../Store/action";
import dayjs from 'dayjs'

const ProfileAddCertificateModal = ({ reload, certificationToEdit, setCertificationToEdit }) => {
    const dispatch = useDispatch();
    const [
        {LoginData}
    ] = useSelector((state) => [state.user]);
    const [newCertification, setNewCertification] = useState({
        institution: "",
        description: "",
        completed_at: "",
        achievement: "",
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCertification({ ...newCertification, [name]: value });
        setErrors({ ...errors, [name]: false });
    };

    const handleYearChange = (date, dateString) => {
        setNewCertification({ ...newCertification, completed_at: dateString });
        setErrors({ ...errors, completed_at: false });
    };

    const validateFields = () => {
        const newErrors = {};
        if (!newCertification.institution) newErrors.institution = true;
        if (!newCertification.description) newErrors.description = true;
        if (!newCertification.completed_at) newErrors.completed_at = true;
        if (!newCertification.achievement) newErrors.achievement = true;
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateFields()) return;
        dispatch(allActions.global.Loader(true))
        const endpoint = certificationToEdit
            ? `${BASEURL}/api/professionals/profile/certifications`
            : `${BASEURL}/api/professionals/profile/certifications`;

        const method = Object.keys(certificationToEdit)?.length > 0 ? "patch" : "put";
        const data = Object.keys(certificationToEdit)?.length > 0
            ? { ...newCertification, id: certificationToEdit.id }
            : {...newCertification, user_id:LoginData?.userpro?.user_id};

        try {
            await axios[method](endpoint, data);
            reload();
            resetForm();
            setCertificationToEdit({});
            dispatch(allActions.global.Loader(false))
            document.querySelector(".closeAddOrofileCertificate").click();
        } catch (err) {
            console.error("Error saving certification:", err);
            dispatch(allActions.global.Loader(false))
            document.querySelector(".closeAddOrofileCertificate").click();

        }
    };

    const resetForm = () => {
        setNewCertification({
            institution: "",
            description: "",
            completed_at: "",
            achievement: "",
        });
        setErrors({});
    };

    React.useEffect(() => {
        if (certificationToEdit) {
            setNewCertification({
                institution: certificationToEdit.institution,
                description: certificationToEdit.description,
                completed_at: certificationToEdit.completed_at,
                achievement: certificationToEdit.achievement,
            });
        }
    }, [certificationToEdit]);

    useEffect(() => {
        if(Object.keys(certificationToEdit)?.length === 0){
            resetForm()
        }
    },[certificationToEdit])

    return (
      <div className="container text-left">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label
              htmlFor="institution"
              className="form-label text-black fw-bold"
            >
              Institution
            </label>
            <input
              type="text"
              id="institution"
              name="institution"
              className={`form-control ${
                errors.institution ? "is-invalid" : ""
              }`}
              placeholder="Institution"
              value={newCertification.institution}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="description"
              className="form-label text-black fw-bold"
            >
              Description
            </label>
            <input
              type="text"
              id="description"
              name="description"
              className={`form-control ${
                errors.description ? "is-invalid" : ""
              }`}
              placeholder="Description"
              value={newCertification.description}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="completed_at"
              className="form-label text-black fw-bold"
            >
              Year Completed
            </label>
            {/* <DatePicker
              picker="year"
              id="completed_at"
              name="completed_at"
              className={`form-control ${
                errors.completed_at ? "is-invalid" : ""
              }`}
              placeholder="Select Year"
              value={
                newCertification.completed_at
                  ? moment(newCertification.completed_at, "YYYY")
                  : null
              }
              onChange={handleYearChange}
              getPopupContainer={(trigger) => trigger.parentNode} 
              disabledDate={(current) =>
                current && current.year() > moment().year()
              } 
            /> */}

            <DatePicker
              picker="year"
              id="completed_at"
              name="completed_at"
              className={`form-control ${
                errors.completed_at ? "is-invalid" : ""
              }`}
              placeholder="Select Year"
              value={
                newCertification.completed_at
                  ? typeof newCertification.completed_at === "string"
                    ? dayjs(newCertification.completed_at)
                    : newCertification.completed_at
                  : null
              }
              onChange={handleYearChange}
              getPopupContainer={(trigger) => trigger.parentNode}
              disabledDate={(current) =>
                current && current.year() > moment().year()
              }
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="achievement"
              className="form-label text-black fw-bold"
            >
              Achievement
            </label>
            <input
              type="text"
              id="achievement"
              name="achievement"
              className={`form-control ${
                errors.achievement ? "is-invalid" : ""
              }`}
              placeholder="Achievement"
              value={newCertification.achievement}
              onChange={handleInputChange}
            />
          </div>
          <div className="d-flex justify-content-end gap-2">
            <button
              type="button"
              className="btn text-muted border border-1 py-2 px-3 fw-bold "
              onClick={() => {
                document.querySelector(".closeAddOrofileCertificate").click();
              }}
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              {certificationToEdit ? "Save Changes" : "Add Certification"}
            </button>
          </div>
        </form>
      </div>
    );
};

export default ProfileAddCertificateModal;

export const USER_ACTION_TYPE = {
	user: ' user',
	verify: 'verify',
	status: 'Status',
	adminRole: 'adminRole',
	taxproRole: 'taxproRole',
	customerRole: 'customerRole',
	accountManagerRole: 'accountManagerRole',
	reset: 'reset',
	statusId: 'statusId',
	existEmail: 'existEmail',
	existEmailReset: 'existEmailReset',
	addExistingUser: 'addExistingUser',

		//create E-sign request
		esignRequestIdle: 'esignRequestIdle',
		esignRequestSuccess: 'esignRequestSuccess',
		esignRequestError: 'esignRequestError',		

		//create E-sign request
		contractSignRequestCustomerIdle: 'contractSignRequestCustomerIdle',
		contractSignRequestCustomerSuccess: 'contractSignRequestCustomerSuccess',
		contractSignRequestCustomerError: 'contractSignRequestCustomerError',	


};

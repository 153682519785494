/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { BASEURL } from '../../utilites';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/action';
import axios from 'axios';
import Message from './fields/Message';

const AddPayoutManually = ({ modalMessage, setModalMessage }) => {
  const dispatch = useDispatch();
  const [dashboard] = useSelector((state) => [state.dashboard]);
  const [formData, setFormData] = useState({
    user_id: null, 
    payout_stripe_id: '',
    amount: '',
  });

  const [options, setOptions] = useState([]); 
  const [inputValue, setInputValue] = useState(''); 

  const fetchTaxPros = async (value) => {
    dispatch(allActions.dashboard.getUsers(value, null, 3, null, 1, 10));
  };

  useEffect(() => {
    fetchTaxPros('');
  }, []);

  useEffect(() => {
    setOptions(
      dashboard?.users?.data?.map((user) => ({
        label: `${user?.firstname} ${user?.lastname}`,
        value: user?.id,
      }))
    );
  }, [dashboard]);

  const handleInputChange = (value) => {
    setInputValue(value);
    fetchTaxPros(value);
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      user_id: selectedOption ? selectedOption.value : null, 
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(allActions?.global.Loader(true));
    try {
      const resp = await axios.post(`${BASEURL}/api/stripe/add-payout-manually`, { ...formData });
      setModalMessage({
        show: true,
        type: 'Success',
        text: resp?.data?.data?.message,
      });
      setFormData({
        user_id: null,
        payout_stripe_id: '',
        amount: '',
      });
      setInputValue(''); 
      dispatch(allActions.payout.getPayout());
      dispatch(allActions?.global.Loader(false));
      setTimeout(() => {
        document.querySelector('.AddPayoutModalClose').click();
        setModalMessage({
          show: false,
        });
      }, 1000);
    } catch (error) {
      dispatch(allActions?.global.Loader(false));
      setFormData({
        user_id: null,
        payout_stripe_id: '',
        amount: '',
      });
      setModalMessage({
        show: true,
        type: 'Error',
        text: error?.message,
      });
      setTimeout(() => {
        document.querySelector('.AddPayoutModalClose').click();
        setModalMessage({
          show: false,
        });
      }, 1000);
    }
  };

  return (
    <div className="container mt-2">
      {modalMessage.show ? <Message message={modalMessage} /> : null}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="user_id" className="form-label">
            Tax Pro
          </label>
          <Select
            id="user_id"
            className="question-input"
            value={options.find((opt) => opt.value === formData.user_id) || null} 
            options={options}
            onChange={handleSelectChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            placeholder="Search and select a Tax Pro"
            isClearable
            isSearchable
          />
        </div>
        <div className="mb-3">
          <label htmlFor="payout_stripe_id" className="form-label">
            Payout Stripe ID
          </label>
          <input
            type="text"
            className="form-control"
            id="payout_stripe_id"
            name="payout_stripe_id"
            value={formData.payout_stripe_id}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="amount" className="form-label">
            Amount
          </label>
          <input
            type="number"
            className="form-control"
            id="amount"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            required
            min="0"
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddPayoutManually;

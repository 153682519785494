import React from 'react'
import Message from '../../forms/fields/Message'

const SendReminder = ({successHanler,errorMessage}) => {
    return (
        <div className='pt-4 px-3' style={{ paddingBottom: "30px" }}>
            <h6 className='fw-semibold'>Send Reminders</h6>
            <p className='my-4'>Would you like to send an immediate reminder to your client regarding this pending task?</p>
            <div className="d-flex justify-content-end gap-2" style={{ marginTop: ".84rem" }}>
                <button
                    className={`border-0 px-3 py-1 rounded-2 fw-medium`}
                    style={{ background: "#E3E8FF", color: "#404AC4" }}
                    data-bs-dismiss="modal"
                >
                    No
                </button>
                <button
                    // data-bs-target="#ReminderSuccessModal"
                    className={`border-0 px-3 py-1 rounded-2 fw-medium`}
                    style={{ color: "#FFF", background: "#404AC4" }}
                    onClick={()=>successHanler()}
                >
                    Yes
                </button>
            </div>{errorMessage
             &&<Message message={errorMessage}/>}
        </div>
    )
}

export default SendReminder
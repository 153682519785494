import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import { Scrollbar } from 'react-scrollbars-custom';
import { PiBellRingingLight } from "react-icons/pi";
import allActions from '../../../Store/action';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Message from '../../forms/fields/Message';
import moment from 'moment';
import { BsDot } from "react-icons/bs";

const ReminderPopover = ({job_id,task_id,documentList,reload,trigger_type,pendingInvoices}) => {
    const [open, setOpen] = useState(false);
    const [immediately, setImmediately] = useState(false);
    const [scheduleReminder, setscheduleReminder] = useState(task_id?true:false);
    const [message, setMessage] = useState({ show: false });
    const [triggerType, settriggerType] = useState(trigger_type||'Every 2 days');

    const dispatch = useDispatch();
	const [{reminderDocumentForm,taskDocumentForm},{ LoginData }] = useSelector(
		(state) => [state.jobs,state.user]
	);


// reminderDocumentForm
useEffect(() => {
	// if Success
	
	if (reminderDocumentForm?.status === true) {
		setMessage({ show: false });
		// changeLoading(false);
		reload()
		resetState()
		hide()
	}

	// if Error
	if (reminderDocumentForm?.status=== false) {
		// Show Message Alert
		setMessage({
			show: true,
			type: 'Error',
			text: reminderDocumentForm.message,
		});
		changeLoading(false);
	}
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [reminderDocumentForm?.status]);



// remindertaskDocumentForm
useEffect(() => {
	// if Success
	if (taskDocumentForm?.status === true) {
        if(immediately){
            dispatch (allActions.jobs.reminderDocumentForm('job',job_id))
               }else{
                   setMessage({ show: false });
                //    changeLoading(false);
				reload()
				   resetState()
				   hide()

               }
	}

	// if Error
	if (taskDocumentForm?.status=== false) {
		// Show Message Alert
		setMessage({
			show: true,
			type: 'Error',
			text: taskDocumentForm.message,
		});
		changeLoading(false);
	}
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [taskDocumentForm?.status]);

const resetState=()=>{
	setImmediately(false)
	// setscheduleReminder(false)
	// settriggerType('Every 2 days')
}
    const hide = () => {
        setOpen(false);
    };

    const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	  };
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const setReminder= async()=>{
let triggerAt;
		if(triggerType==='Every 2 days'){
			triggerAt= moment.utc().add(2, 'days').format('YYYY-MM-DD HH:mm:ss')
		  } else if(triggerType==='Every 3 days'){
			triggerAt= moment.utc().add(3, 'days').format('YYYY-MM-DD HH:mm:ss')
		  }
		  else if(triggerType==='Every 4 days'){
			triggerAt= moment.utc().add(4, 'days').format('YYYY-MM-DD HH:mm:ss')
		  }
		  else if(triggerType==='Every 5 days'){
			triggerAt= moment.utc().add(5, 'days').format('YYYY-MM-DD HH:mm:ss')
		  }
		  else if(triggerType==='Every 6 days'){
			triggerAt= moment.utc().add(6, 'days').format('YYYY-MM-DD HH:mm:ss')
		  }
		  else if(triggerType==='Weekly'){
			triggerAt= moment.utc().add(1, 'week').format('YYYY-MM-DD HH:mm:ss')
		  }
		  else if(triggerType==='Biweekly'){
			triggerAt= moment.utc().add(2, 'week').format('YYYY-MM-DD HH:mm:ss')
		  }


  changeLoading(true)      
 dispatch(allActions.jobs.taskDocumentFormIdle())
 dispatch(allActions.jobs.reminderDocumentFormIdle())
   let body={
    job_id:job_id,
    trigger_type:triggerType,
    trigger_at:triggerAt,
    timezone:LoginData?.lead?.[0]?.user?.setting?.timezone||"America/Los_Angeles",
    reminder:scheduleReminder
   }
   dispatch (allActions.jobs.taskDocumentForm(body))

    }
    const content = (
			<div style={{ width: '350px', height: '400px' }} class="p-2 overflow-auto">
				<Scrollbar trackYProps={{ style: { visibility: 'hidden' } }} className="w-100 h-100">
					<h5 class="mb-3">Send Reminders</h5>
					<p>
						We’ll send your client a friendly reminder on the following schedule for the pending
						request(s) below:
					</p>
					{/* <h6>Forms</h6>
					<ol class="list-group list-group-numbered">
						<li class="list-group-item border-0 opacity-75" style={{ fontSize: 12 }}>
							Tax Planning Individuals
						</li>
					</ol> */}
					<h6>Documents</h6>
					<ol class="list-group list-group-numbered">
						{documentList?.map((document) => {
							return (
								!document?.path &&
								!document?.link?.length > 0 && (
									<li class="list-group-item border-0 opacity-75" style={{ fontSize: 12 }}>
										{document?.jobdocument?.name}
									</li>
								)
							);
						})}
					</ol>
					<h6>Invoice</h6>

					<ol class="list-group list-group-numbered">
					{pendingInvoices?.map((v)=>{return(
						<li class="list-group-item border-0 opacity-75" style={{ fontSize: 12 }}>
							{v}
						</li>

					)})}
					</ol>
					<div class="form-check my-3 mx-1">
						<input checked={immediately} onChange={()=>setImmediately(!immediately)} class="form-check-input" type="checkbox" id="immediateReminder" />
						<label class="form-check-label" for="immediateReminder">
							Immediately (instantly send out reminder to your client)
						</label>
					</div>
					<div className="d-flex align-items-center gap-2">
						<label class="form-check-label fw-semibold mb-1">Schedule Reminders</label>
						<div class="form-check form-switch mt-1">
							<input
							  checked={scheduleReminder}
                                 onChange={()=>setscheduleReminder(!scheduleReminder)}
								class="form-check-input"
								type="checkbox"
								role="switch"
								id="flexSwitchCheckChecked"
							/>
						</div>
					</div>
					<div class=" d-flex gap-3 mt-3">
						<p className="mt-2">Every</p>
						<select     defaultValue={triggerType}                   value={triggerType}
 onChange={(v)=>settriggerType(v.target.value)
                        } class="form-select h-25" style={{ width: '120px' }} id="scheduleReminder">
							<option value="Every 2 days">2 days</option>
							<option value="Every 3 days">3 days</option>
							<option value="Every 4 days">4 days</option>
							<option value="Every 5 days">5 days</option>
							<option value="Every 6 days">6 days</option>
							<option value="Weekly">Week</option>
							<option value="Biweekly">Biweekly</option>
						</select>
					</div>
					<div class="mt-3 d-flex justify-content-end">
						<button
							onClick={hide}
							class="btn btn-secondary me-2"
							style={{ background: 'none', border: 'none' }}
						>
							Cancel
						</button>
						<button
                            onClick={()=> setReminder()}
							class="btn btn-primary"
							style={{ '--bs-btn-padding-y': '.25rem', '--bs-btn-padding-x': '1.5rem' }}
						>
							Save
						</button>

					</div>
                        {message.show ? <Message message={message}/> : <></>}
				</Scrollbar>
			</div>
		);

    return (

        <Popover
            content={content}
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
            placement="bottomRight"
        >
            <button className='btn text-primary border-0 d-flex align-items-center gap-1'><PiBellRingingLight size={"18px"} /> Reminders {task_id && <BsDot color='#5EEC7F' size='53'/>}</button> 

        </Popover>
    )
}

export default ReminderPopover

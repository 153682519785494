import Header from './Header';
import Alert from './Alert';
import Preloader from './Preloader';
import Pagination from '../../../components/Pagination';
import Footer from './Footer';

export default function ListPageWrapper({
	heading,
	headerControls,
	underHeader,
	alert: { message, setMessage,alertError=false },
	alertElement,
	dataState: { loaded, fetching },
	aside,
	content,
	tableHeader,
	tableBody,
	paginationProps,
	modals,
	showFooter=true
}) {
	return (
		<div className='clearfix'>
			<Header name={heading} />

			<div className='container page'>
				<div className='clearfix mb-4'>
					<div className='row justify-content-center'>
						<div className='col-md-12 p-0'>
							<div className='clearfix mt-4'>
								{headerControls ? (
									<div className='clearfix mt-4 mb-5'>
										<div className='row justify-content-between'>{headerControls}</div>
									</div>
								) : null}
								{underHeader ? (
									<div className='clearfix mt-4 mb-5'>
										<div className='row justify-content-center'>{underHeader}</div>
									</div>
								) : null}
								{message && <Alert message={message} clickHandler={() => setMessage('')} type={alertError?'danger':'success'} />}
								{alertElement && alertElement}
								<div className='card border-0'>
									<div className='clearfix'>
										{!loaded ? (
											<Preloader wrapperClass='h-100' />
										) : (
											<>
												{aside ? (
													<div className='row'>
														<div className='col-md-3'>{aside}</div>
														<div className='col-md-9'>
															<div className={`row g-2${fetching ? ' pe-none opacity-50' : ''}`}>
																{content}
															</div>
														</div>
													</div>
												) : (
													<div className='table-responsive'>
														<table className='table'>
															<thead className='text-dark text-opacity-50'>
																<tr>{tableHeader}</tr>
															</thead>
															<tbody className={fetching ? 'pe-none opacity-50' : ''}>
																{tableBody}
															</tbody>
														</table>
													</div>
												)}
											</>
										)}
									</div>
									{paginationProps && <Pagination {...paginationProps} />}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showFooter	&& <Footer />}
			{modals}
		</div>
	);
}
